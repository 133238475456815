// Import interface
import type { API, UserAPI } from "./interface";

const baseURL = "https://api.exampathfinder.com/";

const userAPI: UserAPI = {
  USER_SIGN_IN: {
    url: `${baseURL}data/sign-in/`,
    headers: false,
    karma: 0,
    credentials: false,
    id: "USER#1",
  },
};

/**
 * Function to check user karma before calling API and return the API object if the user has the minimum required karma to access the API
 *
 * @param {string} apiKey - The key of the API call whose karma value needs to be checked before access
 * @returns {object} APIObject - Object containing information for the specific API on successfully passing the user karma test
 */
function getUserAPI(apiKey: keyof UserAPI): API {
  return userAPI[apiKey];
}

export default getUserAPI;
