// Lib imports
import { createRouter, createWebHistory } from "@/plugins/vueRouter";
import LangJSON from "@/assets/json/langCodes.json";
import store from "@/store/index.js";
import EventBus from "@/helpers/eventBus";
import { sanitizeRoute } from "@/helpers/routesHandler";
import { isLowerCase, isEmptyObject } from "@/helpers/validations";
import displayConsole from "@/helpers/displayConsole";

import {
  NavigationGuardNext, RouteLocationNormalized, RouteParams, Router, RouteRecordName, RouteLocationRaw,
} from "@/plugins/interface";
import routes from "./routerMain";

const allowedRoutesSchema: string[] = routes.filter((route) => !route.meta).map((obj) => obj.path);
const prepLangRoutes: (routeSchema: string) => string[] = (routeSchema: string) => {
  const langRoutes = LangJSON.langCodes.reduce((acc: string[], langCode) => {
    const accumulator = acc;
    accumulator.push(routeSchema.replace(":langCode", langCode));
    return accumulator;
  }, []);
  return langRoutes;
};

// all multilingual routes
const allowedRoutes: string[] = allowedRoutesSchema.reduce((acc: string[], route) => {
  let accumulator = acc;
  const isLangCodeRoute = route.includes(":langCode");
  if (!isLangCodeRoute) {
    accumulator.push(route);
    return accumulator;
  }
  // need to disable the rule here no-param assign
  const langRoutes = prepLangRoutes(route);
  accumulator = accumulator.concat(langRoutes);
  return accumulator;
}, []);

const isRouteAllowed: () => boolean = () => {
  const userRoute = window.location.pathname;
  return allowedRoutes.includes(userRoute);
};

/**
 * Creating router object using createRouter function passing,
 * in history and routes as per vue-router docs
 */
const router: Router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

/**
 * Function to check the route,
 * If the route is correct then it return the return empty route object
 * if the route is incorrect ("route is captilised or ends with a ending slash") return the correct route object
 *
 * @param {object} route -  route that needs to be checked
 * @returns {object} - correct route incase the route is wrong
 */
function routeChecker(route: RouteLocationNormalized): { name: RouteRecordName; params: RouteParams; } {
  const routePathArray = route.path.split("/");
  // removing the first element of the array as it is always empty
  routePathArray.shift();
  let sanitizedRoute = {} as { name: RouteRecordName; params: RouteParams; };
  // if there is a trailing slash (ending slash) remove the blank element from the route path array in the end
  if (!routePathArray[routePathArray.length - 1] && route.name != null) {
    sanitizedRoute = { name: route.name, params: route.params };
    routePathArray.pop();
  }
  // check if the route path has an id , if it has id then removes the id from the route path array
  const idIndex: number = typeof route.params?.id === "string" ? routePathArray.indexOf(route.params?.id) : -1;
  if (idIndex !== -1) routePathArray.splice(idIndex, 1);
  const { latestUpdateType } = route.params;
  const lowerCaseLatestUpdateType = typeof latestUpdateType === "string" ? latestUpdateType.toLowerCase() : undefined;
  // check if the latestUpadeType in the route pasth is lower case or not,  if it is then put the correct latestUpdateType in the params
  if ((latestUpdateType !== lowerCaseLatestUpdateType) && (route.name != null)) {
    sanitizedRoute = { name: route.name, params: { ...route.params, latestUpdateType: lowerCaseLatestUpdateType as string } };
  }
  // check if the route has any unnecessary captilization in the route path, if it is not then send the correct route path
  for (let i = 0; i < routePathArray.length; i += 1) {
    if (!isLowerCase(routePathArray[i]) && route.name != null && sanitizedRoute.params) {
      sanitizedRoute = { name: route.name, params: (sanitizedRoute.params) ? sanitizedRoute.params : route.params };
      sanitizedRoute.params.id = sanitizeRoute(sanitizedRoute.params.id as string);
      break;
    }
  }
  // return the correct and sanitized route path
  return sanitizedRoute;
}

/**
 * Function to redirect to the correct route
 *
 * @param {string} path - route path where we are supposed to be redirected to
 */
function redirectPath(path: string): void {
  const link = document.createElement("meta");
  link.setAttribute("http-equiv", "refresh");
  link.setAttribute("content", `0; url=${path}`);
  document.getElementsByTagName("head")[0].appendChild(link);
}

/**
 * Function to redirect route path to correct path
 *
 * @param {object} route - route object
 */
function redirectRoute(route: RouteLocationNormalized): void {
  //  sanitize the route and redirect it to the correct route
  const sanitizedRoute = routeChecker(route);
  if (!isEmptyObject(sanitizedRoute)) {
    const routeObject = router.resolve(sanitizedRoute as RouteLocationRaw);
    const newSlug = routeObject.path;
    redirectPath(newSlug);
  }
}

/**
 * Function to redirects route aliases to primary path
 *
 * @param {object} route - route object
 */
function redirectAliases(route: RouteLocationNormalized): void {
  const routeParams = route.params;
  const matchedRoute = route.matched[route.matched.length - 1];
  const { aliasOf } = matchedRoute;
  if (!aliasOf) return;

  // replace route placeholders with route params
  let primaryRoutePath: string = aliasOf.path;
  const pathBlocks: string[] = primaryRoutePath.split("/");
  Object.entries(routeParams).forEach((v) => {
    const [key, value] = v;
    for (let i = 0; i < pathBlocks.length; i += 1) {
      if (pathBlocks[i].startsWith(`:${key}`)) { pathBlocks[i] = value as string; break; }
    }
  });
  primaryRoutePath = pathBlocks.join("/");
  redirectPath(primaryRoutePath);
  // TODO logic below could be replaced by vue meta plugin
  // const redirectMetaTag = metaTagSlugRedirection({ matchFound: false, newSlug: primaryRoutePath });
  // this.$meta.meta = redirectMetaTag;
  // console.log(this.$meta);
  // const link = document.createElement("meta");
  // link.setAttribute("http-equiv", "refresh");
  // link.setAttribute("content", `0; url=${primaryRoutePath}`);
  // document.getElementsByTagName("head")[0].appendChild(link);
}

/**
* router.beforeEach fucntion runs everytime when we change the route and checks if the
* user has karma to enter to that page or not
*
* @param {object} to - which route we are going to
* @param {object} from - from which route we are going from
* @param {Function} next - function which directs to the page
*/
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  if (to.meta.pageType === "latestUpdatePage") {
    // eslint-disable-next-line no-param-reassign
    to.meta.type = to.params.latestUpdateType;
  } else if (to.meta.pageType === "filteredTagListPage") {
    // eslint-disable-next-line no-param-reassign
    to.meta.filterType = to.params.latestUpdateType;
  }
  /**
   * Disable footer on route change, Enabled once dataLoaded
   */
  const toLangCode = (typeof to?.name === "string") ? to.name.split("_")[1] : undefined;
  const fromLangCode = (typeof from?.name === "string") ? from.name?.split("_")[1] : undefined;
  if (toLangCode === fromLangCode || !fromLangCode) EventBus.$emit("dataLoaded", false);

  // lang code check
  const currentLangCode: string = store.getters["base/getLangCode"];
  if (to.params.langCode && !LangJSON.langCodes.includes(to.params.langCode as string)) {
    displayConsole(
      `router.beforeEach redirected to not Found for langCode ${to.params.langCode} at ${new Date().toLocaleString()}`,
      { name: `notFound_${currentLangCode}`, params: { langCode: currentLangCode } },
      "Page not found redirection error",
    );
    next({ name: `notFound_${currentLangCode}`, params: { langCode: currentLangCode } });
    return;
  }
  // karma check
  const userKarma: number = store.getters["user/getUserKarma"];
  const minkarma = to.meta.minKarma as number;
  if (userKarma >= minkarma || minkarma === 0) {
    next();
  } else {
    next(from.path);
  }
});

// ? redirect the route
router.afterEach((to, from) => {
  redirectRoute(to);
  redirectAliases(to);
});

export { router, allowedRoutes, isRouteAllowed };
