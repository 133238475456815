// Cache Store to store the cached data
let cache: { [key: string]: any; } = {};

// ? Boolean to display cache information
const displayCacheInfo = false;

/**
 * Function to display cached data and its size
 */
function displayCacheAndSize() {
  if (!displayCacheInfo) return;
  const size = new TextEncoder().encode(JSON.stringify(cache)).length;
  const kiloBytes = size / 1024;
  console.log({ cachedData: cache, cacheSize: kiloBytes });
}

/**
 * Function to generate a key on the basis of payload
 *
 * @param {any} payload - the information about data to be get by the worker
 * @returns {string} payloadKey - the key which stores the value
 */
function generatePayloadKey(payload: any): string {
  // array to store the strings first
  const keyStrings: string[] = [];

  // array of values achieved from object
  const payloadKeys = Object.keys(payload);
  payloadKeys.sort();

  // iterate values arrays
  payloadKeys.forEach((key) => {
    const value = payload[key];
    // case type string
    if (typeof (value) === "string") {
      keyStrings.push(value);
    } else if (Array.isArray(value)) { // case type array
      // check array is empty
      if (value.length !== 0) {
        // case strings in array
        if (typeof (value[0]) === "string") {
          value.sort();
          keyStrings.push(value.join("_"));
        } else if (typeof (value[0]) === "object") { // case objects in array
          const objStrings: string[] = [];

          // iterate array of objects
          value.forEach((obj) => {
            const val = Object.values(obj);
            val.sort();
            objStrings.push(val.join("_"));
          });

          objStrings.sort();
          keyStrings.push(objStrings.join("_"));
        }
      }
    } else if (typeof (value) === "number") { // case number in array
      keyStrings.push(String(value));
    } else if (typeof (value) === "boolean") { // case boolean in params
      keyStrings.push(String(value));
    }
  });

  // return the string joined with underscore
  return keyStrings.join("_");
}

/**
 * Function to set the data in cache if it doesn't exist
 *
 * @param {any} payload - the key data to be set by the worker
 * @param {any} value - the value data set on the key in cache object
 */
function setCacheData(payload: any, value: any) {
  const key = generatePayloadKey(payload);
  cache[key] = value;
  displayCacheAndSize();
}

/**
 * Function to get cache data
 *
 * @param {any} payload - the key data to be get by the worker
 * @returns {any} the data which gets from cache
 */
function getCacheData(payload: any): any {
  const key = generatePayloadKey(payload);
  if (cache[key]) return cache[key];
  return null;
}

/**
 * Function to clear cache data
 */
function clearCacheData() {
  cache = {};
}

export { setCacheData, getCacheData, clearCacheData };
