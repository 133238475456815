export default {
  /**
   * Mutation to set preset data for field names in store's state
   *
   * @param {object} state - Current state of the store storing tags data
   * @param {string[]} payload - Array of nameIds of fieldNames having their preset set
   */
  SET_PRESETS(state, payload) {
    const stateObj = state;
    stateObj.presets = payload;
  },

  /**
   * Mutation to clear the preset data for field names in store's state
   *
   * @param {object} state - Current state of the store storing tags data
   */
  RESET_PRESETS(state) {
    const stateObj = state;
    stateObj.presets = [];
  },
};
