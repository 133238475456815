import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "pb-4 mx-6 md:mx-10 pt-8 font-arial md:flex items-center md:justify-between"
}
const _hoisted_2 = { class: "flex justify-between items-center w-full" }
const _hoisted_3 = { class: "text-2xl md:text-3xl font-medium text-gray-900 dark:text-gray-300" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { class: "mt-6 flex-shrink-0 flex md:mt-0 md:ml-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bread_crumbs = _resolveComponent("bread-crumbs")!
  const _component_share_dialog = _resolveComponent("share-dialog")!
  const _component_ShareIcon = _resolveComponent("ShareIcon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createVNode(_component_bread_crumbs, {
        class: "mb-4",
        onBreadcrumbsLoaded: _ctx.breadcrumbHandler
      }, null, 8, ["onBreadcrumbsLoaded"])
    ]),
    _createVNode(_component_share_dialog, {
      "share-page": _ctx.openShare,
      title: `Exampathfinder Alert - ${_ctx.$t(_ctx.title)}`,
      onCloseShare: _cache[0] || (_cache[0] = ($event: any) => (_ctx.openShare = false))
    }, null, 8, ["share-page", "title"]),
    (_ctx.title && _ctx.showTitle)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.isSpecificPageEventList ? 'w-full md:flex md:justify-center' : ''])
            }, [
              _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t(_ctx.title)), 1)
            ], 2),
            (_ctx.enableShare)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createElementVNode("button", {
                    type: "button",
                    class: "inline-flex items-center h-8 w-8 md:h-12 md:w-12 border border-gray-100 rounded-full shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-primary-1 dark:bg-dark-primary-3 dark:border-dark-text-1",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openShare = !_ctx.openShare))
                  }, [
                    _createVNode(_component_ShareIcon, { class: "h-4 w-4 md:h-6 md:w-6 m-auto dark:text-dark-text-1" })
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}