
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  name: "HomePageBanner",
  computed: {
    /**
     * Computed property to get banner title as per route
     */
    bannerTitle(): string {
      return this.$route.meta.mode === "job" ? "bannerHeadingOnJobAlert" : "bannerHeadingOnAdmissionAlert";
    },
    /**
     * Computed property to get banner description as per route
     */
    bannerDescription(): string {
      return this.$route.meta.mode === "job" ? "bannerDescriptionOnJobAlert" : "bannerDescriptionOnAdmissionAlert";
    },
  },
});
