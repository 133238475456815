
import { getEventsAndFilters, getEvents, getFilters } from "@/scripts/searchWorkerAdapterConnector.js";
import SearchResults from "@/components/Search/SearchResults.vue";
import CircularLoader from "@/components/Generic/CircularLoader.vue";
import SearchText from "@/components/Search/SearchText.vue";
import SearchFilter from "@/components/Search/SearchFilter.vue";
import NoInformation from "@/components/Shared/NoInformation.vue";
import { DESKTOP_FILTER_ID, MOBILE_FILTER_ID } from "@/helpers/constants";
import EventBus from "@/helpers/eventBus";
import { defineComponent } from "@/plugins/vue";

interface SearchResult {
  created: number;
  eventId: string;
  examIds: string[];
  fieldIds: string[];
  lastUpdated: number;
  organisationIds: string[];
  tagsData: { nameId: string; valueId: string; }[];
  title: { en: string; hi: string; };
  title_roman: { hi: string; };
}

interface FilterValue {
  id: string;
  count: number;
  text: string;
  selected: boolean;
  disabled: boolean;
}

interface TagFilterValue {
  id: string;
  text: string;
  type: string;
  values: FilterValue[];
}

interface FilterQuery {
  id: string;
  label: string;
  text?: string;
  nameId?: string;
}

interface SelectedFilters {
  id: string;
  nameId?: string;
  label: string;
  disabled: boolean;
  selected: boolean;
}

interface Filters {
  exams: FilterValue[];
  organisations: FilterValue[];
  tags: TagFilterValue[];
}

interface EventWithFilters {
  events: SearchResult[];
  filters: Filters;
}

export default defineComponent({
  name: "SearchPage",
  components: {
    SearchResults,
    CircularLoader,
    SearchText,
    SearchFilter,
    NoInformation,
  },
  props: {
    // The search query in URL
    query: {
      type: String,
      default: "",
    },
    // The filter query for URL
    filter: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    results: [] as SearchResult[],
    exams: [] as FilterValue[],
    organisations: [] as FilterValue[],
    tags: [] as TagFilterValue[],
    loadingResults: false,
    selections: [] as FilterQuery[],
    loadingFilters: true,
    searchInvoked: false,
    filterTimer: 0,
    searchText: "",
    filterId: "",
    routeFilter: "",
    routeQuery: "",
  }),
  computed: {
    /**
     * Property denoting if search is ready or not
     */
    isSearchReady() {
      return this.$store.getters["base/getSearchState"];
    },
  },
  watch: {
    /**
     * Watch search state and process search url
     */
    isSearchReady() {
      this.processUrl();
    },
  },
  /**
   * Created life cycle hook
   * Determine which filter to display and then process search url
   */
  created() {
    this.filterId = window.matchMedia("(max-width: 1024px)").matches ? MOBILE_FILTER_ID : DESKTOP_FILTER_ID;
    this.processUrl();
  },
  methods: {
    /**
     * Process search url and updates filters and after processing fetches result
     */
    processUrl() {
      if (!this.isSearchReady || !this.$route.query.query) return;

      this.routeFilter = (this.$route.query?.filter as string) || "";
      this.routeQuery = (this.$route.query?.query as string) || "";

      // Extract Data from Url
      const query = this.decodeStringToData(this.$route.query.query as string);
      this.searchText = query?.text ? query.text : "";
      this.selections = query?.payload ? query.payload : [];

      // If there are not extra filters except the searched ones
      // Fetch both events and filters data from worker in one go
      if (!this.$route.query?.filter) {
        this.fetchEventsAndFilters(this.selections, this.searchText);
        return;
      }

      // Else Fetch Events and filters seprately
      const searchFilterData = this.decodeStringToData(this.$route.query?.filter as string);

      getEvents(this.mapFiltersToIds(searchFilterData), this.searchText).then((events) => {
        this.searchInvoked = true;
        this.results = events as SearchResult[];
        this.loadingResults = false;
      });

      EventBus.$emit("setFiterButtonState", true);
      getFilters(this.mapFiltersToIds(this.selections), this.searchText).then((filters: Filters) => {
        this.organisations = filters.organisations.map((obj) => ({ ...obj, selected: false, disabled: false }));
        this.exams = filters.exams.map((obj) => ({ ...obj, selected: false, disabled: false }));
        this.tags = filters.tags.map((item) => ({
          ...item,
          values: item.values.map((obj) => ({ ...obj, selected: false, disabled: false })),
        }));
        this.updateFiltersState(this.selections, true, true);
        this.updateFiltersState(searchFilterData, false, true);
        this.updateSelectionLabels(this.selections);
        this.loadingFilters = false;
      });
    },

    /**
     * Function to map all filters to their respective isSearchReady
     *
     * @param {FilterQuery[]} filters - [{id, name, label, type}, {...}]
     */
    mapFiltersToIds(filters: FilterQuery[]) {
      return filters.map((item) => {
        if (item.label === "Organisation" || item.label === "Exam") return item.id;
        return `${item.nameId}-${item.id}`;
      });
    },

    /**
     * Function fetches events and filters from worker
     *
     * @param {FilterQuery[]} tags - List of filters
     * @param {string} eventQuery - string to search for
     */
    fetchEventsAndFilters(tags: FilterQuery[], eventQuery: string) {
      EventBus.$emit("setFiterButtonState", true);
      this.loadingFilters = true;
      getEventsAndFilters(this.mapFiltersToIds(tags), eventQuery).then((searchResults: EventWithFilters) => {
        this.results = searchResults.events;
        this.exams = searchResults.filters.exams.map((obj) => ({ ...obj, selected: false, disabled: false }));
        this.organisations = searchResults.filters.organisations.map((obj) => ({ ...obj, selected: false, disabled: false }));
        this.tags = searchResults.filters.tags.map((item) => ({
          ...item, values: item.values.map((obj) => ({ ...obj, selected: false, disabled: false })),
        }));
        this.updateFiltersState(tags, true, true);
        this.updateSelectionLabels(tags);
        this.loadingResults = false;
        this.searchInvoked = true;
        this.loadingFilters = false;
      });
    },

    /**
     * function to fetch search results
     */
    fetchResults() {
      if (!this.selections.length && !this.searchText) return;
      this.loadingResults = true;
      this.setSearchQuery();
      this.fetchEventsAndFilters(this.selections, this.searchText);
    },

    /**
     * Function to update selected labels in filter
     *
     * @param {FilterQuery[]} selections - List of selections in filter
     */
    updateSelectionLabels(selections: FilterQuery[]) {
      for (let i = 0; i < selections.length; i += 1) {
        const item = selections[i];
        if (item.label === "Organisation") {
          const foundOrg = this.organisations.find((organisation) => organisation.id === item.id);
          item.text = foundOrg?.text || "";
        } else if (item.label === "Exam") {
          const foundExam = this.exams.find((exam) => exam.id === item.id);
          item.text = foundExam?.text || "";
        } else {
          const foundTag = this.tags.find((tag) => tag.id === item.nameId);
          const foundTagValue = foundTag?.values.find((value) => value.id === item.id) || undefined;
          item.text = foundTagValue?.text || "";
          item.label = foundTag?.text || "";
        }
      }
    },

    /**
     * Function to update filter state
     *
     * @param {UpdatedFilterQuery[]} filters - filter object being displayed
     * @param {boolean} disableTags - whether to disable tags
     * @param {boolean} checkTags - whether to check tags or not
     */
    updateFiltersState(filters: FilterQuery[], disableTags: boolean = true, checkTags: boolean = true) {
      filters.forEach((item) => {
        let foundItem;
        if (item.label === "Organisation") foundItem = this.organisations.find((organisation) => organisation.id === item.id);
        else if (item.label === "Exam") foundItem = this.exams.find((exam) => exam.id === item.id);
        else if (item.label === "Event") return;
        else {
          const foundTag = this.tags.find((tag) => tag.id === item.nameId);
          foundItem = foundTag?.values.find((value) => value.id === item.id) || undefined;
        }
        if (foundItem) {
          if (disableTags) foundItem.disabled = true;
          if (checkTags) foundItem.selected = true;
        }
      });
    },

    /**
     * Function to clear the previous timeout and set a
     * new timeout for making a call to the processFilterQuery function
     */
    filterUpdate() {
      clearTimeout(this.filterTimer);
      this.filterTimer = window.setTimeout(this.processFilterQuery, 500);
    },

    /**
     * Function to process filter query and fetch events based on that
     */
    processFilterQuery() {
      performance.mark("fetchFilteredSearchResults");
      this.loadingResults = true;
      const filterQuery: SelectedFilters[] = [...this.exams.filter((exam) => exam.selected).map((item) => ({
        disabled: item.disabled, selected: item.selected, id: item.id, label: "Exam",
      })),
      ...this.organisations.filter((organisation) => organisation.selected).map((item) => ({ ...item, label: "Organisation" }))];

      this.tags.forEach((tag) => {
        tag.values.forEach((value) => {
          if (value.selected) {
            filterQuery.push({
              id: value.id, nameId: tag.id, label: tag.type, selected: true, disabled: value.disabled,
            });
          }
        });
      });

      this.setSearchFilter(filterQuery);
      getEvents(this.mapFiltersToIds(filterQuery), this.searchText).then((events) => {
        this.results = events;
        this.loadingResults = false;
      });
    },

    /**
     * Function to set search query
     */
    setSearchQuery() {
      const url = new URL(document.location.href);
      const params = new URLSearchParams(url.search);

      // Preparing data to for Search Query
      const queryText = this.searchText || "";

      const payload = this.selections?.map((item) => {
        if (item.label === "Exam" || item.label === "Organisation") return { id: item.id, label: item.label };
        return { id: item.id, nameId: item.nameId };
      }) || [];

      const query = {
        ...(queryText.trim().length > 0 && { text: queryText }),
        ...(Object.keys(payload).length > 0 && { payload }),
      };

      // Updating search query data in Url
      this.routeQuery = this.encodeDataToString(query);
      this.routeFilter = "";
      params.delete("filter");
      params.set("query", this.routeQuery);
      params.set("itemsPerPage", "50");
      params.set("pageNo", "1");
      this.$router.push(`${url.pathname}?${params}`);
    },

    /**
     * Function to set filters in search Url
     *
     * @param {SelectedFilters[]} selectedFilters - Filters selected
     */
    setSearchFilter(selectedFilters: SelectedFilters[]) {
      const url = new URL(document.location.href);
      const params = new URLSearchParams(url.search);
      const filteredData = selectedFilters.map((item) => {
        if (item.label === "Exam" || item.label === "Organisation") return { id: item.id, label: item.label };
        return { id: item.id, nameId: item.nameId, label: "field" };
      });

      if (!filteredData.length) {
        params.delete("filter");
        this.routeFilter = "";
      } else {
        this.routeFilter = this.encodeDataToString(filteredData);
        params.set("filter", this.routeFilter);
      }
      this.$router.push(`${url.pathname}?${params}`);
    },

    /**
     * Encode payload to string
     *
     * @param {Object} obj - Data payload
     * @returns {string} encoded data
     */
    encodeDataToString(obj: Object) {
      return btoa(JSON.stringify(obj));
    },

    /**
     * Decode string and retrive data
     *
     * @param {string} str - Encoded string
     * @returns {Object} decoded data
     */
    decodeStringToData(str: string) {
      return JSON.parse(atob(str));
    },
  },
});
