
import { defineComponent } from "@/plugins/vue";
import HeadingSection from "@/components/Generic/HeadingSection.vue";
import SubscriptionListView from "@/components/Subscription/SubscriptionListView.vue";

export default defineComponent({
  name: "SubscriptionList",
  components: { HeadingSection, SubscriptionListView },
  data() {
    return {
      mode: "event",
      tabs: [
        { name: "subscribedEvents", id: "event", current: true },
        { name: "subscribedExams", id: "exam", current: false },
      ],
    };
  },
  methods: {
    /**
     * Function to switch between Event subscription list and Exam subscription list
     *
     * @param {string} - id of current target tab
     */
    toggleSubscriptionListHandler(id: string) {
      this.tabs.find((tab) => tab.current)!.current = false;
      this.tabs.find((tab) => tab.id === id)!.current = true;
      this.mode = id;
    },
  },
});
