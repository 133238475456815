import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mt-4 space-y-5" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "flex items-center h-5" }
const _hoisted_4 = ["checked", "disabled", "onChange"]
const _hoisted_5 = { class: "ml-3 text-sm" }
const _hoisted_6 = { class: "font-medium text-gray-700 dark:text-dark-text-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("fieldset", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsToShow, (item) => {
        return (_openBlock(), _createElementBlock("div", {
          key: item.id,
          class: _normalizeClass(['relative flex items-start', item.disabled ? 'opacity-70' : '']),
          disabled: item.disabled
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("input", {
              "aria-describedby": "comments-description",
              type: "checkbox",
              class: "h-4 w-4 text-bg-primary-4 rounded dark:text-grey-8 dark:bg-dark-primary-1 dark:border-gray-400 dark:ring-0 focus:outline-none",
              "aria-label": "checkbox",
              checked: item.selected,
              disabled: item.disabled,
              onChange: ($event: any) => (item.selected = !item.selected, _ctx.$emit('selected', item))
            }, null, 40, _hoisted_4)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(item.text), 1)
          ])
        ], 10, _hoisted_2))
      }), 128))
    ]),
    (_ctx.viewMoreEnabled)
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          type: "button",
          class: "mt-4 inline-flex p-2 border border-transparent dark:text-gray-400 dark:ring-1 dark:ring-grey-4 text-primary-4 rounded-md shadow-sm text-xs font-medium",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateItemsCount(5)))
        }, _toDisplayString(_ctx.$t(`viewMore`)), 1))
      : _createCommentVNode("", true),
    (_ctx.viewLessEnabled)
      ? (_openBlock(), _createElementBlock("button", {
          key: 1,
          type: "button",
          class: "mt-4 inline-flex p-2 border border-transparent text-primary-4 rounded-md shadow-sm text-xs font-medium",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.updateItemsCount(-5)))
        }, _toDisplayString(_ctx.$t(`viewLess`)), 1))
      : _createCommentVNode("", true)
  ], 64))
}