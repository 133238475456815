
import ChromeInstallSnackbar from "@/components/Generic/ChromeInstallSnackbar.vue";
import AppUpdateSnackbar from "@/components/Generic/AppUpdateSnackBar.vue";
import AppInstallSnackbar from "@/components/Generic/AppInstallSnackbar.vue";
import { isChrome } from "@/helpers/validations";
import { defineComponent } from "@/plugins/vue";
import AllowNotificationSnackbar from "./AllowNotificationSnackbar.vue";

export default defineComponent({
  name: "ActionPanel",
  components: {
    AppUpdateSnackbar,
    AppInstallSnackbar,
    ChromeInstallSnackbar,
    AllowNotificationSnackbar,
  },
  data: () => ({
    showChromeSnackBar: false,
    showAllowNotificationSnackbar: false,
  }),
  computed: {
    /**
     * Function gets the subscribed event ids
     *
     * @returns {string[]} - subscribed event ids
     */
    subscribedEventIds(): string[] {
      return this.$store.getters["events/getSubscribedEventIds"];
    },
    /**
     * Function gets the subscribed exam ids
     *
     * @returns {string[]} - subscribed exam ids
     */
    subscribedExamIds(): string[] {
      return this.$store.getters["events/getSubscribedExamIds"];
    },
  },
  watch: {
    /**
     * Watcher to watch subscribed event ids
     * and update appropriate snackbar
     *
     * @param {string[]} val is Array of String
     */
    subscribedEventIds(val: string[]) {
      if (val?.length) this.handleSubscriptionUpdate();
    },
    /**
     * Watcher to watch subscribed exam ids
     * and update appropriate snackbar
     *
     * @param {string[]} val is Array of String
     */
    subscribedExamIds(val: string[]) {
      if (val?.length) this.handleSubscriptionUpdate();
    },
  },
  methods: {
    /**
     * Checks browser status for notifications and tunrs on chrome install
     * snackbar or all notifications snackbar conditionally
     */
    handleSubscriptionUpdate() {
      // If it is not chrome, then ask user to install chrome
      if (!isChrome()) {
        this.showChromeSnackBar = true;
        return;
      }

      // Let's check whether notification permissions have already been granted
      if (Notification.permission === "granted") return;

      // If user denied notification permissions return false
      if (Notification.permission === "denied") return;

      // Otherwise, we need to ask the user for permission
      this.showAllowNotificationSnackbar = true;
    },
  },
});
