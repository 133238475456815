import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "lg:mx-20 py-8 sm:py-12 px-4 sm:px-6 lg:py-24" }
const _hoisted_2 = { class: "grid grid-cols-1 gap-12 space-x-4 lg:grid-cols-3 lg:gap-8 justify-center items-center" }
const _hoisted_3 = { class: "space-y-5 sm:space-y-4 text-center lg:text-left" }
const _hoisted_4 = { class: "text-3xl sm:text-4xl font-arial dark:text-gray-300" }
const _hoisted_5 = { class: "text-lg sm:text-xl text-gray-700 dark:text-gray-500" }
const _hoisted_6 = { class: "lg:col-span-2" }
const _hoisted_7 = { class: "space-y-6 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-2" }
const _hoisted_8 = { class: "flex items-center space-x-4 xl:ml-28 lg:ml-10" }
const _hoisted_9 = { class: "flex justify-center items-center bg-primary-100 rounded-full dark:rounded-md xl:h-28 xl:w-28 lg:w-24 lg:h-24 h-16 w-16 bg-secondary-1 hover:bg-secondary-2 dark:bg-dark-primary-1 hover:dark:ring-grey-4 hover:dark:ring-1" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = { class: "font-sm text-sm xl:text-base font-arial leading-6 space-y-1 hover:text-secondary-3 dark:text-gray-400 dark:hover:text-gray-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.title)), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.description)), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.qualificationsIds, (id, index) => {
            return (_openBlock(), _createElementBlock("li", { key: id }, [
              _createVNode(_component_router_link, {
                to: _ctx.setHomeRoutePath(id,index)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createElementVNode("img", {
                        src: require(`@/assets/images/qualifications/${_ctx.qualificationsIconData[index]}`),
                        alt: `${_ctx.getStringData(index)} image`,
                        class: "w-16 h-16 xl:w-28 xl:h-28 lg:w-24 lg:h-24"
                      }, null, 8, _hoisted_10)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _createElementVNode("h3", null, _toDisplayString(_ctx.getStringData(index)), 1)
                    ])
                  ])
                ]),
                _: 2
              }, 1032, ["to"])
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}