
import { defineComponent } from "@/plugins/vue";
import { camelCaseConversion } from "@/helpers/dataFormat";
import LatestUpdatesList from "@/components/Shared/LatestUpdates/LatestUpdatesList.vue";
import HeadingSection from "@/components/Generic/HeadingSection.vue";
import { analyticsLogger } from "@/plugins/firebase";
import { analyticsViewItemList } from "@/helpers/analyticsEvents";
import { changeToSentenceCase } from "@/helpers/validations";
import { getTagName } from "@/scripts/workerAdapterConnector";
import { getSeperatedIdAndName } from "@/helpers/routesHandler";
import { SubFilterType, TagType } from "@/scripts/interface";

type ListType = SubFilterType | "sarkariResults";

export default defineComponent({
  name: "LatestUpdates",
  components: {
    LatestUpdatesList,
    HeadingSection,
  },
  /**
   * Data
   */
  data() {
    return {
      mode: "",
      listType: "" as ListType,
      isSpecific: false,
      search: "",
      specificPageMeta: [
        "category",
        "organisation",
        "qualification",
        "stream",
        "university",
        "exam",
        "state",
      ],
      showList: false,
    };
  },
  computed: {
    /**
     * Gets lang code from the store
     *
     * @returns {"hi" | "en"} - lang code
     */
    langCode(): "hi" | "en" {
      return this.$store.getters["base/getLangCode"];
    },
  },
  watch: {
    /**
     * Watcher to watch the route object
     * - Set Page Title and mode according to the route change
     *
     * @param {object} oldRoute - Old route value
     * @param {object} newRoute - New route value
     */
    $route(newRoute, oldRoute) {
      const newRouteName = newRoute.name;
      const oldRouteName = oldRoute.name;
      const newRouteType = newRoute.meta.type;
      const oldRouteType = oldRoute.meta.type;
      if (newRouteType !== oldRouteType && oldRouteName === newRouteName) {
        this.getPageTitle();
      }
    },

  },
  /**
   * Created hook
   * We are getting the page title and mode
   */
  created() {
    this.getPageTitle();
  },
  methods: {
    analyticsLogger,
    changeToSentenceCase,
    /**
    * Function to get the Page Title and  mode
    */
    getPageTitle(): void {
      this.mode = this.$route.meta.mode as string;
      if (this.specificPageMeta.includes(this.$route.meta.type as string)) {
        this.listType = camelCaseConversion(this.$route.meta.filterType as string) as ListType;
        this.isSpecific = true;
        const routeId = getSeperatedIdAndName(this.$route.params.id as string).id;
        getTagName(this.$route.meta.type as TagType, [routeId]).then((tagName) => {
          if (tagName[0]) analyticsViewItemList(`${tagName[0][this.langCode]?.string} ${this.changeToSentenceCase(this.listType)} list`);
        });
      } else {
        this.listType = camelCaseConversion(this.$route.meta.type as string) as ListType;
        analyticsViewItemList(`${this.changeToSentenceCase(this.listType)} list`);
      }
    },
  },
});
