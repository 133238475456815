
import { BellIcon } from "@/plugins/outlineHeroicon";
import ActionSnackbar from "@/components/Generic/ActionSnackbar.vue";
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  name: "AllowNotificationSnackbar",
  components: {
    ActionSnackbar,
  },
  data: () => ({
    isVisible: true,
    bellIcon: BellIcon,
  }),

  methods: {
    /**
     * Uses Notification browser API to request notification from user
     */
    async requestPermission(): Promise<void> {
      this.isVisible = false;
      await Notification.requestPermission();
    },
  },
});
