import { UpdatesAndLinks } from "@/helpers/interface";

const latestUpdatesTypes: UpdatesAndLinks = {
  // HI to EN
  en: {
    job: {
      "latest-notifications": "latest-notifications",
      "latest-admit-card": "latest-admit-card",
      "sarkari-results": "latest-results",
      "latest-walk-in-interview": "latest-walk-in-interview",
      "latest-fellowships": "latest-fellowships",
      "last-date-soon": "last-date-soon",
    },
    admission: {
      "latest-notifications": "latest-notifications",
      "latest-admit-card": "latest-admit-card",
      "sarkari-results": "latest-results",
      "latest-scholarships": "latest-scholarships",
      "last-date-soon": "last-date-soon",
      "latest-interview": "latest-interview",
    },

  },
  // EN to HI
  hi: {
    job: {
      "latest-notifications": "latest-notifications",
      "latest-admit-card": "latest-admit-card",
      "latest-results": "sarkari-results",
      "latest-walk-in-interview": "latest-walk-in-interview",
      "latest-fellowships": "latest-fellowships",
      "last-date-soon": "last-date-soon",
    },
    admission: {
      "latest-notifications": "latest-notifications",
      "latest-admit-card": "latest-admit-card",
      "latest-results": "sarkari-results",
      "latest-scholarships": "latest-scholarships",
      "last-date-soon": "last-date-soon",
      "latest-interview": "latest-interview",
    },
  },
};

const covertedLinks: UpdatesAndLinks = {
  en: {
    job: {
      "latest-notifications": "latest-notifications",
      "latest-admit-card": "latest-admit-card",
      "latest-results": "latest-results",
      "latest-walk-in-interview": "latest-walk-in-interview",
      "latest-fellowships": "latest-fellowships",
      "last-date-soon": "last-date-soon",
    },
    admission: {
      "latest-notifications": "latest-notifications",
      "latest-admit-card": "latest-admit-card",
      "latest-results": "latest-results",
      "latest-scholarships": "latest-scholarships",
      "last-date-soon": "last-date-soon",
      "latest-interview": "latest-interview",
    },
  },
  hi: {
    job: {
      "latest-notifications": "latest-notifications",
      "latest-admit-card": "latest-admit-card",
      "latest-results": "sarkari-results",
      "latest-walk-in-interview": "latest-walk-in-interview",
      "latest-fellowships": "latest-fellowships",
      "last-date-soon": "last-date-soon",
    },
    admission: {
      "latest-notifications": "latest-notifications",
      "latest-admit-card": "latest-admit-card",
      "latest-results": "sarkari-results",
      "latest-scholarships": "latest-scholarships",
      "last-date-soon": "last-date-soon",
      "latest-interview": "latest-interview",
    },
  },
};
/**
 * Function to get the lang code specific latest update type name for routes
 *
 * @param {string} langCode - Current lang code
 * @param {string} mode - Mode: job or admission
* @param {string} key - latest update type key
* @returns {string or null} converted latest update type based on params
*/
function getConvertedUpdateType(langCode: "en" | "hi", mode: "job" | "admission", key: string): string {
  const convertedTypeName = latestUpdatesTypes[langCode][mode][key];
  if (convertedTypeName) return convertedTypeName;
  return "";
}

/**
 * Function to get the latest update type code name for routes
 *
 * @param {string} langCode - Current lang code
 * @param {string} mode - Mode: job or admission
 * @param {string} key - latest update type key
 * @returns {string or null} latest update type code name for routes based on params
 */
function getCovertedKeysToLinks(langCode: "en" | "hi", mode: "job" | "admission", key: string): string | null {
  const convertedLinkKey = covertedLinks[langCode][mode][key];
  if (convertedLinkKey) return convertedLinkKey;
  return null;
}

export { getConvertedUpdateType, getCovertedKeysToLinks };
