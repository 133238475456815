import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_metainfo = _resolveComponent("metainfo")!
  const _component_base_snackbar = _resolveComponent("base-snackbar")!
  const _component_action_panel = _resolveComponent("action-panel")!
  const _component_base_app_shell_registration = _resolveComponent("base-app-shell-registration")!
  const _component_base_app_shell = _resolveComponent("base-app-shell")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.langCode === 'hi' ? 'font-arial':'font-roboto', 'h-full'])
  }, [
    _createVNode(_component_metainfo),
    _createVNode(_component_base_snackbar),
    _createVNode(_component_action_panel),
    _createVNode(_component_base_app_shell_registration, { ref: "registrationBox" }, null, 512),
    _createVNode(_component_base_app_shell, {
      class: _normalizeClass(_ctx.darkMode ? 'dark' : '')
    }, null, 8, ["class"])
  ], 2))
}