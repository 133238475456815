export default {
  "usefulLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगी लिंक"])},
  "importantLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["महत्वपूर्ण लिंक"])},
  "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["त्वरित लिंक"])},
  "lorem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोरेम इप्सम डोलर सिट एमेट कंसेक्टेटूर एडिपिसिंग एलीट। पोसीमस मैग्ना वॉलुप्टाटम क्यूपिडिटेट वेरिटैटिस इन एक्यूसामस क्विस्क्वाम।"])},
  "latestNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट नोटिफिकेशन"])},
  "admitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट प्रवेश पत्र"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट रिजल्ट"])},
  "walkInInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट वाक इन इंटरव्यू"])},
  "fellowshipApprenticeship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट फैलोशिप/शिक्षुता"])},
  "sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइट मैप"])},
  "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारे बारे में"])},
  "pricingAndRefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य निर्धारण और धनवापसी नीति"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डिस्कलेमर"])},
  "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नियम और शर्तें"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["गोपनीयता नीति"])},
  "communityForum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम्युनिटी प्लेटफार्म"])},
  "preparationPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रिपरेशन प्लेटफार्म"])},
  "rightReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समावेशी सर्वाधिकार सुरक्षित।"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सर्च"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डैशबोर्ड"])},
  "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अलर्ट्स"])},
  "eventList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट सूची"])},
  "fieldList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड सूची"])},
  "organisationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन सूची"])},
  "examList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा सूची"])},
  "translationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवाद सूची"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["होम"])},
  "collegeAdmissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कॉलेज प्रवेश"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम्युनिटी"])},
  "preparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रिपरेशन"])},
  "selectItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सेलेक्ट"])},
  "openMainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य मेनू खोलें"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन इन करें"])},
  "viewNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोटिफिकेशन देखें"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन आउट"])},
  "closeMainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मुख्य मेनू बंद करें"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मेन्यू"])},
  "openUserMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता मेनू खोलें"])},
  "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी प्रोफ़ाइल"])},
  "darkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डार्क मोड"])},
  "viewLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम देखें"])},
  "lastDateSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम तिथि"])},
  "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वर्ग"])},
  "streams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्ट्रीम"])},
  "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोकप्रिय"])},
  "latestNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट नोटिफिकेशन"])},
  "latestAdmitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट प्रवेश पत्र"])},
  "latestWalkInInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट वॉक इन इंटरव्यू"])},
  "latestResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट परिणाम"])},
  "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शीर्ष"])},
  "organisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन"])},
  "institutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्वविद्यालयों"])},
  "stateWise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राज्य के अनुसार "])},
  "admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पाठ्यक्रम प्रवेश"])},
  "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नौकरिया"])},
  "jobByQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["योग्यता के आधार पर नौकरियां"])},
  "admissionByQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["योग्यता के आधार पर प्रवेश"])},
  "communityPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कम्युनिटी प्लेटफार्म "])},
  "connectWithOtherPlatforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य प्लेटफॉर्म से जुड़े रहें"])},
  "goBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["वापीस जाएँ"])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पृष्ठ नहीं मिला"])},
  "pageNotFoundMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया पता बार में यू.आर.एल जांचें और पुन: प्रयास करें."])},
  "eventStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट की स्थिति"])},
  "createdEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाया गया इवेंट"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता ले "])},
  "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता रद्द"])},
  "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चर्चा"])},
  "editEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एडिट इवेंट"])},
  "eventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट की जानकारी"])},
  "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट विवरण"])},
  "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अटैचमेंट"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डाउनलोड"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देखें"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन"])},
  "eventHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट इतिहास"])},
  "continueWithProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रदाताओं के साथ जारी रखें"])},
  "signUpTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["साइन अप करके, आप हमारी उपयोग की शर्तों और गोपनीयता नीति से सहमत होते हैं।"])},
  "examPathFinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एग्जाम पाथ फाइंडर"])},
  "attachmentsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अटैचमेंट सूची"])},
  "similarResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इसी तरह के परिणाम"])},
  "fieldInputRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड इनपुट आवश्यक है!"])},
  "noDataFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डाटा प्राप्त नहीं हुआ!"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति पृष्ठ पंक्तियाँ"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खारिज"])},
  "visitNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अभी जाएँ"])},
  "lastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आखरी अपडेट"])},
  "noResultFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई परिणाम नहीं मिला"])},
  "noDataAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" कोई डेटा उपलब्ध नहीं है "])},
  "s.No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रमांक"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विवरण"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["समय"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रति"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["का"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोटिफिकेशन्स"])},
  "notificationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया इवेंट की सूचनाएं प्राप्त करने के लिए उस  इवेंट की सदस्यता लें ..."])},
  "subscribedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता चालू"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लाइट मोड"])},
  "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डार्क मोड"])},
  "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रणाली"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुधार करें"])},
  "postBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशक"])},
  "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आखिरी अपडेट"])},
  "eventTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट का शीर्षक"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया इवेंट"])},
  "vidyamantra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विद्यामंत्रा"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट्स"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नाम"])},
  "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ोन नंबर"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पासवर्ड्स"])},
  "notificationPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोटिफिकेशन प्रेफरेंस"])},
  "linkAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खाते लिंक करें"])},
  "signInWithEPF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नीचे दिए गए अपने खातों में से किसी एक को अपने ई.पी.एफ खाते के साथ एकीकृत करके एक क्लिक के साथ तेजी से साइन इन करें"])},
  "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपयोगकर्ता नाम"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रोफ़ाइल"])},
  "subscribedEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सब्स्क्राइब्ड इवेंट"])},
  "subscribedSearches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सब्स्क्राइब्ड सेअर्चेस"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूची"])},
  "searchPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह सर्च पृष्ठ है"])},
  "latestPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट पोस्ट"])},
  "popularPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लोकप्रिय पोस्ट"])},
  "fieldValuesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड मान सूची"])},
  "fieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड नाम"])},
  "examPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यह परीक्षा का पेज है"])},
  "dashboardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाएं देखने के लिए कृपया इवेंट्स  की सदस्यता लें"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पिछला भाग"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अगला भाग"])},
  "showingPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिख रहा पेज"])},
  "latestDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट तारीख"])},
  "latestFellowships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट फैलोशिप / शिक्षुता"])},
  "popularExamDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपको यहां भारत भर में नौकरियों के लिए सरकारी परीक्षाओं के बारे में सभी सूचनाएं और अपडेट प्रदान कर रहे हैं। आप अपने इच्छित क्षेत्र का चयन कर सकते हैं और उस क्षेत्र में नौकरियों की सभी जानकारी प्राप्त कर सकते हैं।"])},
  "popularStreamDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहां आपको अपनी वांछित स्ट्रीम में नौकरी पाने में मदद करने के लिए प्रवेश परीक्षा, एडमिट कार्ड, परिणाम और कई अन्य सूचनाएं प्राप्त होंगी।"])},
  "topOrganisationsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चुनने के लिए संगठनों की एक विशाल श्रृंखला। हम आपको आपके पसंदीदा संगठन में सरकारी नौकरी पाने के लिए यहां सभी जॉब अलर्ट और नोटिफिकेशन प्रदान कर रहे हैं।"])},
  "topInstitutionsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शीर्ष संस्थानों और कॉलेजों की हमारी विशाल सूची में से अपने करियर के लिए सबसे उपयुक्त संस्थान चुनें और उनके बारे में सभी सूचनाओं और अपडेट के बारे में जानकारी प्राप्त करें।"])},
  "otherPlatformsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारा उद्देश्य आपको हमारे अन्य दो प्लेटफार्मों द्वारा परीक्षा की तैयारी का समग्र अनुभव प्रदान करना है।"])},
  "communityPlatformDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारा कम्युनिटी प्लेटफॉर्म  वह है जहां आप प्रश्न पूछ सकते हैं, अपने उत्तर साझा कर सकते हैं और समान रुचियों वाले लोगों से जुड़ सकते हैं।"])},
  "preparationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सरकारी परीक्षाओं के लिए हमारे मुफ़्त मॉक टेस्ट, अभ्यास पत्र और आपके प्रदर्शन की विश्लेषण रिपोर्ट के माध्यम से अभ्यास करें, सीखें और अपने लक्ष्य को प्राप्त करें।"])},
  "jobByQualificationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी शैक्षणिक योग्यता चुनें और अपने लिए उपयुक्त नौकरियों की सूची प्राप्त करें। हम आपको आपकी शिक्षा के अनुसार सर्वोत्तम नौकरियों के बारे में जानकारी प्रदान कर रहे हैं।"])},
  "stateWiseJobDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["यहां आपकी पसंद के राज्यों के आधार पर नौकरियों के सभी विवरण और अलर्ट दिए गए हैं। अपने पसंदीदा स्थान का चयन करें और वहां पोस्ट होने के लिए नौकरी की सूचनाएं प्राप्त करें।"])},
  "stateWiseAdmissionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप अपने राज्य या इच्छुक राज्य के कॉलेजों और विश्वविद्यालयों में प्रवेश की सूचना और अपडेट प्राप्त करने के लिए यहां जा सकते हैं।"])},
  "admissionByQualificatonDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपनी पिछली योग्यताओं के आधार पर अपने आगामी पाठ्यक्रम में प्रवेश के लिए सभी जानकारी प्राप्त करें। अपनी योग्यता का चयन करें और अपनी आगे की शिक्षा के लिए सभी उपलब्ध विकल्प प्राप्त करें।"])},
  "jobAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जॉब अलर्ट्स"])},
  "admissionAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रवेश अलर्ट्स"])},
  "universityList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संस्थान सूची"])},
  "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विश्वविद्यालय"])},
  "translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवाद"])},
  "fieldname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड का नाम"])},
  "stateList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["राज्य सूची"])},
  "pageNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["पृष्ठ संख्या"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट"])},
  "fieldType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड के जैसा"])},
  "aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपनाम"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संपादित करें/ सुधार करें"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रतीक चिन्ह"])},
  "organisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन का नाम"])},
  "examName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा का नाम"])},
  "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड"])},
  "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यों"])},
  "Exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षाए"])},
  "Exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा"])},
  "Organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दिखा"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग"])},
  "roman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रोमन में"])},
  "tagName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टैग नाम"])},
  "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड का नाम"])},
  "pendingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["छूटा हुआ / पुरा हुआ"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["भाषा"])},
  "addexam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा जोड़ें"])},
  "addorganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन जोड़ें"])},
  "addField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड जोड़ें"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["टेक्स्ट"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तारीख"])},
  "range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["श्रेणी"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थान"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रद्द करें"])},
  "saveEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सुधार जमा करे"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जमा"])},
  "enterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य दर्ज करें"])},
  "createField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड बनाएं"])},
  "editField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एडिट फ़ील्ड"])},
  "addAliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["उपनाम जोड़ें"])},
  "fieldInputIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड इनपुट आवश्यक है"])},
  "fieldInputCanNotBeABlankString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड इनपुट एक खाली स्ट्रिंग नहीं हो सकता"])},
  "fieldInputCanNotContainAnApostrophe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड इनपुट में एपॉस्ट्रॉफ़ी नहीं हो सकता ( ' )"])},
  "onlyDigitsAndDecimalNumbersAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल अंक और दशमलव संख्या की अनुमति है"])},
  "onlyDigitsAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल अंकों की अनुमति है"])},
  "onlyAlphabetsDigitsAndSpacesAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केवल अक्षर, अंक और रिक्त स्थान की अनुमति है"])},
  "allowedSpecialCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["विशेष वर्ण की अनुमति:  ( ) . - / , "])},
  "createOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन बनाएं"])},
  "editOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन एडिट करें"])},
  "createExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा बनाएं"])},
  "editExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा एडिट करें"])},
  "examCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा कोड"])},
  "childrenTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाल टैग"])},
  "noChildrenTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बाल टैग नहीं है"])},
  "attachExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा अटैच करें"])},
  "organisationLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन प्रतीक चिन्ह"])},
  "uploadOrganisationLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन का प्रतीक चिन्ह अपलोड करें"])},
  "attachOrganisationParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन अभिभावक अटैच करें"])},
  "attachExamParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा अभिभावक अटैच करें"])},
  "saveOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन जमा करे"])},
  "saveExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा जमा करे"])},
  "attachFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड जोड़े"])},
  "pleaseEnterYourExamCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया अपना परीक्षा कोड दर्ज करें"])},
  "pleaseEnterYourExamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया अपना परीक्षा नाम दर्ज करें"])},
  "pleaseEnterYourOrganisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया अपने संगठन का नाम दर्ज करें"])},
  "fieldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फील्ड वैल्यू"])},
  "minimumValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["न्यूनतम मूल्य"])},
  "maximumValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अधिकतम मूल्य"])},
  "pickDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["तिथि चुनें"])},
  "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थान जोड़ें"])},
  "duplicateField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लिकेट फ़ील्ड"])},
  "deleteField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ील्ड हटाएं"])},
  "addNumberValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संख्या मान जोड़ें"])},
  "addEventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट की जानकारी जोड़ें"])},
  "editEventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट को सुधारें"])},
  "eventStatusInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट की स्थिति की जानकारी"])},
  "statusTitleEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति शीर्षक (अंग्रेजी में)"])},
  "statusTitleEnPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया स्थिति शीर्षक दर्ज करें (अंग्रेजी में)"])},
  "statusTitleHi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति शीर्षक (हिंदी में)"])},
  "statusTitleHiPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया स्थिति कार्यक्रम शीर्षक दर्ज करें (हिंदी में)"])},
  "statusMessageEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति संदेश (अंग्रेजी में)"])},
  "statusMessageEnPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया स्थिति विवरण दर्ज करें (अंग्रेजी में)"])},
  "statusMessageHi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थिति संदेश (हिंदी में)"])},
  "statusMessageHiPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया स्थिति विवरण दर्ज करें (हिंदी में)"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रकाशित"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एडिट करें"])},
  "publishChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परिवर्तन प्रकाशित करें"])},
  "eventDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट की जानकारी"])},
  "uploadAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अटैचमेंट्स अपलोड करें"])},
  "eventTitleEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट का शीर्षक (अंग्रेजी में)"])},
  "eventTitleHi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट का शीर्षक (हिंदी में)"])},
  "eventTitleRm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट का शीर्षक (हिंदी रोमन में)"])},
  "eventTitleEnPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृप्या इवेंट का शीर्षक दर्ज करें (अंग्रेजी में)"])},
  "eventTitleHiPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृप्या इवेंट का शीर्षक दर्ज करें (हिंदी में)"])},
  "eventTitleRmPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृप्या इवेंट का शीर्षक दर्ज करें (हिंदी रोमन में)"])},
  "noFileUploadedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस इवेंट के लिए कोई फ़ाइल अपलोड नहीं की गई है"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़ाइल अपलोड करें"])},
  "dragAndDropFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["या फ़ाइल यहाँ छोड़ो"])},
  "eventDescirptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट का विवरण (अंग्रेजी में)"])},
  "eventDescirptionHi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट का विवरण (हिंदी में)"])},
  "eventDescirptionEnPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृप्या इवेंट का विवरण दर्ज करें (अंग्रेजी में)"])},
  "eventDescirptionHiPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृप्या इवेंट का विवरण दर्ज करें (हिंदी में)"])},
  "pleaseChooseOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया एक संगठन चुनें"])},
  "pleaseSelectExams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया परीक्षा का चयन करें"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["बनाये"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हां"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नहीं"])},
  "sendNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नोटिफिकेशन भेजें?"])},
  "sendNotificationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप इस परिवर्तन को सूचना के रूप में भेजना चाहते हैं ?"])},
  "confirmRelocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थानांतरण की पुष्टि करें"])},
  "confirmRelocationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्या आप आगे बढ़ना चाहते हैं? किए गए परिवर्तन खो जाएंगे !"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यों"])},
  "valueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्यों के प्रकार"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जोड़ें"])},
  "addValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["मूल्य जोड़ें"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["दृश्य"])},
  "latestScholarships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नवीनतम स्कॉलरशिप / फैलोशिप / शिक्षुता"])},
  "addEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कार्यक्रम जोड़ें"])},
  "updateEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अद्यतन कार्यक्रम"])},
  "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा"])},
  "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जल्द आ रहा है"])},
  "pwaVersionUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संस्करण अद्यतन"])},
  "pwaUpdateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नया संस्करण उपलब्ध है। कृपया रीफ्रेश करें।"])},
  "refreshPWA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रीफ्रेश करें"])},
  "verifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ईमेल सत्यापित करें"])},
  "sarkariResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट परिणाम"])},
  "noInternetConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इंटरनेट कनेक्शन नहीं है"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ऑफ़लाइन"])},
  "offlineMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आप ऑफ़लाइन हैं, कृपया कार्यशील इंटरनेट कनेक्शन से कनेक्ट करें"])},
  "installApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["एप्लिकेशन इंस्टॉल करें"])},
  "installMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी जानकारी जल्द से जल्द प्राप्त करने के लिए EPF का ऍप तुरंत इनस्टॉल करें"])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["देश चुनें"])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता"])},
  "eventLastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट अंतिम अद्यतन"])},
  "eventCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट सृजन"])},
  "lastDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अंतिम तिथि"])},
  "admitCardDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रवेश पत्र तिथि"])},
  "resultDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["रिजल्ट तिथि"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रारंभ तिथि"])},
  "latestInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट इंटरव्यू"])},
  "updatingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जल्द आ रहा है ... "])},
  "scholarships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट स्कॉलरशिप"])},
  "interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["लेटेस्ट इंटरव्यू"])},
  "noInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई नई जानकारी नहीं है"])},
  "noInformationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इस खंड के लिए फिलहाल कोई नया अपडेट उपलब्ध नहीं है"])},
  "subscriptionList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सदस्यता सूची"])},
  "subscribedExams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सब्स्क्राइब्ड परीक्षा"])},
  "locationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थान सूची"])},
  "otherStates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अन्य राज्य"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["शेयर करें"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फेसबुक"])},
  "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ट्विटर"])},
  "gmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["जीमेल"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्लिपबोर्ड पर कॉपी करें"])},
  "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सब्स्क्राइब्ड"])},
  "unsubscribeEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट सदस्यता रद्द करें"])},
  "unsubscribeExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["परीक्षा सदस्यता रद्द करें"])},
  "recentUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["नवीनतम अपडेट"])},
  "installChrome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्रोम इंस्टाल करे"])},
  "installChromeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपके ब्राउज़र पर सूचना प्रदर्शन समर्थित नहीं है। अधिसूचना देखने के लिए कृपया क्रोम स्थापित करें।"])},
  "alertDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["आपकी सूचनाएं यहां रहती हैं"])},
  "alertDialogDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अपने नवीनतम अपडेट के बारे में सूचित करने के लिए अपनी पसंदीदा परीक्षाओं की सदस्यता लें"])},
  "orgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["संगठन"])},
  "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुवाद"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["स्थान"])},
  "EPFSearchPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ई.पी.एफ खोज पृष्ठ"])},
  "allowNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["अनुमति दें"])},
  "allowNotificationsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सूचनाओं के नवीनतम अपडेट प्राप्त करने के लिए अनुमति दे"])},
  "bannerHeadingOnJobAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["सभी सरकारी नौकरियां एक जगह पर"])},
  "bannerDescriptionOnJobAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केंद्र सरकार, राज्य सरकार एवं स्वचालित संस्थानों के द्वारा जारी हर अलर्ट को तुरंत प्राप्त करें"])},
  "bannerHeadingOnAdmissionAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["प्रवेश के अवसर"])},
  "bannerDescriptionOnAdmissionAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["केंद्र सरकार, केंद्र शासित प्रदेशों तथा राज्य सरकार के विश्वविद्यालयों के प्रवेश परीक्षाओं पर अलर्ट प्राप्त करें"])},
  "exploreCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["चर्चा करें, सुझाव दें तथा प्रश्न पूछें"])},
  "explorePreparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["हमारे मुफ़्त मॉक टेस्ट के साथ अपनी तैयारी को बेहतरीन बनाएं"])},
  "duplicateCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["डुप्लीकेट चेक"])},
  "createEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["इवेंट बनाएँ"])},
  "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कोई परिणाम नहीं मिला"])},
  "noResultMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["क्षमा करें, आपकी क्वेरी के लिए कोई मिलान परिणाम नहीं मिला।"])},
  "initSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["खोज प्रारंभ की जा रही है, कृपया प्रतीक्षा करें..."])},
  "fetchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["कृपया प्रतीक्षा करें, खोज परिणाम लाए जा रहे हैं..."])},
  "filterResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फ़िल्टर किया गया परिणाम लोड हो रहा है, कृपया प्रतीक्षा करें..."])},
  "loadingFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["फिल्टर्स लोड्स हो रहे हैं..."])}
}