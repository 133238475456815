

import CircularLoader from "@/components/Generic/CircularLoader.vue";
import ImageViewer from "@/components/Generic/ImageViewer.vue";
import { defineComponent } from "@/plugins/vue";

const mime = require("mime/lite");

interface ViewType extends XMLHttpRequest {
  viewType: string;
}

export default defineComponent({
  name: "FileViewer",
  components: {
    CircularLoader,
    ImageViewer,
  },
  props: {
    /**
     * v-model binding prop for v-overlay
     */
    modelValue: {
      default: false,
      type: Boolean,
    },
    /**
     * URL of the file to be viewed
     */
    url: {
      default: "",
      type: String,
    },
    /**
     * Name of the file to be viewed
     */
    fileName: {
      default: "",
      type: String,
    },
  },
  emits: ["update:modelValue"],
  data: () => ({
    fileSource: "",
    isLoading: true,
    viewType: "",
  }),
  watch: {
    /**
     * If url changed fetch the content again with the
     * updated url
     * @param {string} newUrl
     */
    url(newUrl: string) {
      this.fetchContent(newUrl);
    },
    /**
     * Whenever overlay is enabled fetch content again
     *
     * @param {true} val
     */
    modelValue(val: boolean) {
      if (val) this.fetchContent(this.url);
    },
  },
  /**
   * Fetches content to show on when created
   */
  created() {
    this.fetchContent(this.url);
  },
  methods: {
    /**
     * Emits the hideOverlay event which is resposible
     * for updating the overlay prop (model-prop) to true
     */
    hideOverlay() {
      this.$emit("update:modelValue", false);
      this.isLoading = true;
    },
    /**
     * Fetches the content to be shown in preview
     *
     * @param {string} url The URL of the file to be shown in preview
     */
    fetchContent(url: string) {
      /**
       * mimetypes: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
       */
      if (url === "") return;

      // Find mimeType of file
      const mimeType: string = mime.getType(url);

      // if mimetype not found set viewType to empty string
      if (!mimeType) {
        this.viewType = "";
        this.isLoading = false;
        return;
      }

      /**
       * Making a XMLHttpRequest for file
       * also expects a blob response
       */
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.responseType = "blob";
      const refThis = this;
      xhr.onload = function () {
        // If request completed successfully
        if (this.status === 200) {
          // New blob is constructed with the corresponding mimeType
          const newBlob: Blob = new Blob([this.response], { type: mimeType });

          // Sets src attribute in img and embed tags
          refThis.fileSource = URL.createObjectURL(newBlob);

          // Check if embed is to be used or img tag depending upon the file type
          refThis.viewType = mimeType.includes("image") ? "image" : "embed";
        } else {
          // This is infering the type of XMLHttpRequest in which viewType does not exist. So we extend XMLHttpRequest and add viewType type
          (this as ViewType).viewType = "";
        }
        refThis.isLoading = false;
      };
      xhr.send();
    },
    /**
     * Downloads the file being previewed
     */
    downloadFile() {
      document.location.href = this.url;
    },
  },
});
