
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton,
} from "@/plugins/headlessui";
import { getSearchSuggestions } from "@/scripts/searchWorkerAdapterConnector.js";
import { SearchIcon } from "@/plugins/solidHeroicon";
import { PropType, defineComponent } from "@/plugins/vue";

interface SearchSuggestion {
  id: string;
  label: string;
  nameId?: string;
  text?: string;
}

export default defineComponent({
  name: "SearchText",
  components: {
    Combobox,
    ComboboxInput,
    ComboboxButton,
    ComboboxOptions,
    ComboboxOption,
    SearchIcon,
  },
  props: {
    /**
     * Array of search selections
     */
    modelValue: {
      type: Array as PropType<SearchSuggestion[]>,
      default: () => [],
    },
    /**
     * Whether search text is disabled or not
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * The text value entered in the input field
     */
    searchText: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "search", "update:searchText"],
  data: () => ({
    query: "",
    selections: [] as SearchSuggestion[],
    items: [] as SearchSuggestion[],
    showOptions: false,
    typingTimer: 0,
    searchInvoked: false,
    modelProperty: { query: "" },
  }),
  watch: {
    /**
     * Whenever value in input field changes update model property
     */
    query(val) {
      this.modelProperty = { query: val };
    },
    /**
     * as Array of selections as prop change, update them in local property as well
     */
    modelValue(val) {
      this.selections = val;
    },
    /**
     * as searchText changes as prop change, update them in local property as well
     */
    searchText(val) {
      this.query = val;
    },
  },
  /**
   * Set local properties from prop values for selections, searchText
   */
  mounted() {
    this.selections = [...this.modelValue];
    this.query = this.searchText;
    this.modelProperty.query = this.query;
  },
  methods: {
    /**
     * Attaches an item to selections array
     * and emit updated value
     * @param {SearchSuggestion} item of selections
     */
    attach(item: SearchSuggestion): void {
      this.selections.push({ ...item });
      this.$emit("update:modelValue", this.selections);
      this.showOptions = false;
      this.query = "";
      this.$emit("update:searchText", this.query);
    },
    /**
     * Disconnects an item from selections array
     * and emit updated value
     * @param {number} index of selections
     */
    detach(index: number): void {
      this.selections.splice(index, 1);
      this.$emit("update:modelValue", this.selections);
    },
    /**
     * Function to clear the timeout for typingTimer
     */
    down(): void {
      clearTimeout(this.typingTimer);
    },

    /**
     * Function to clear the previous timeout and set a
     *  new timeout for making a call to the searchResults function
     */
    up(): void {
      clearTimeout(this.typingTimer);
      this.$emit("update:searchText", this.query.trim());
      this.typingTimer = window.setTimeout(this.fetchSuggestions, 400);
    },
    /**
     * Fetch suggestions as per the entered text in input field
     */
    async fetchSuggestions(): Promise<void> {
      getSearchSuggestions(this.query).then((suggestions) => {
        this.showOptions = true;
        this.items = suggestions;
        if (this.searchInvoked) {
          this.showOptions = false;
          this.searchInvoked = false;
        }
      });
    },
    /**
     * emits a search event to parent
     */
    search(): void {
      this.modelProperty = { query: this.query };
      clearTimeout(this.typingTimer);
      this.showOptions = false;
      this.searchInvoked = true;
      this.$emit("search");
    },
  },

});
