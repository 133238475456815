
import { defineComponent, PropType } from "@/plugins/vue";

interface FilterOption {
  id: string;
  text: string;
  count: number;
  selected: boolean;
  disabled: boolean;
}

export default defineComponent({
  name: "FilterCheckBox",
  props: {
    /**
     * Items to be shown for filters
     */
    items: {
      type: Array as PropType<FilterOption[]>,
      default: () => [],
    },
  },
  emits: ["selected"],
  data() {
    return {
      initialCount: 5,
      currentCount: 5,
    };
  },
  computed: {
    /**
     * computed property to splice upto maxCount items
     */
    itemsToShow(): FilterOption[] {
      return this.items.slice(0, this.currentCount);
    },
    /**
     * Whether view more button is enabled or not
     */
    viewMoreEnabled(): boolean {
      return this.items.length > this.currentCount;
    },

    /**
     * Whether view less button is enabled or not
     */
    viewLessEnabled(): boolean {
      return this.initialCount < this.currentCount;
    },
  },
  methods: {
    /**
     * Updates the number of items to show
     */
    updateItemsCount(count: number) {
      if (count < 0) this.currentCount = Math.max(this.currentCount + count, this.initialCount);
      else this.currentCount = Math.min(this.currentCount + count, this.items.length);
    },
  },
});
