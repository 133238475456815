/* eslint-disable guard-for-in */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { axiosCallWithHeaders } from "@/plugins/axiosInstances";
import { checkAPIAccess } from "@/helpers/valueAPIBlocker";
import getAlertsAPI from "@/api/alertsAPI";
import getCoreAPI from "@/api/coreAPI";

export default {
  /**
   * Asynchronous function to create a new field name data (tagName) and return its tagId (nameId)
   *
   * @param {*} root0 - commit
   * @param {*} root0.commit - commit: used for committing the mutation APPEND_TAG
   * @param {object} payload - {type, name}: payload for API call, contains tag type and name
   * @returns {object} data - A tagId (nameId) for the tag created if execution is successful
   */
  async addField({ commit }, payload) {
    const api = getAlertsAPI("ADD_FIELD_NAME");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(api.url, { ...payload }, { API_ID: api.id });
    return data;
  },

  /**
   * Asynchronous functionn to update the field tags data and return the response from the API
   *
   * @param {*} root0 - commit
   * @param {*} root0.commit - commit: used for committing the mutation APPEND_TAG
   * @param {object} payload - {tagId, tagText, attach-alias, detach-alias}:
   * payload for API call, contains tagId, changed tag name,
   * aliases to add, aliases to delete to/from the tag data respectively
   * @returns {string} data = 'saved', if execution was successful
   */
  async updateFieldTag({ commit }, payload) {
    const payloadObj = payload;
    if (payload["attach-alias"] !== undefined && payload["attach-alias"].length === 0) delete payloadObj["attach-alias"];
    if (payload["detach-alias"] !== undefined && payload["detach-alias"].length === 0) delete payloadObj["detach-alias"];
    const api = getAlertsAPI("UPDATE_TAG_TEXT");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(api.url, { ...payload }, { API_ID: api.id });
    return data;
  },

  /**
   * Asynchronous function to retrieve the presets data for field names
   *
   * @param {*} root0 - commit
   * @param {*} root0.commit - commit: used for committing the mutation SET_PRESETS for presets
   * @returns {string[]} presetArray - array of nameIDs which are set as preset field
   */
  async readPresets({ commit }) {
    const api = getAlertsAPI("READ_PRESETS");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.get(api.url, { API_ID: api.id });
    commit("SET_PRESETS", data.presets);
  },

  /**
   * Asynchronous function to update presets list data for field names
   *
   * @param {*} root0 - commit
   * @param {*} root0.commit - commit: used for committing the mutation SET_PRESETS for presets
   * @param {object} payload {attach, detach}: payload for API call,
   * contains nameIds to attach and detach from set presets
   * @returns {boolean} true - On successful updation of presets
   */
  async updatePresets({ commit }, payload) {
    const payloadObj = payload;
    if (payload.attach !== undefined && payload.attach.length === 0) delete payloadObj.attach;
    if (payload.detach !== undefined && payload.detach.length === 0) delete payloadObj.detach;
    const api = getAlertsAPI("UPDATE_PRESETS");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(api.url, { ...payload }, { API_ID: api.id });
    commit("SET_PRESETS", data.presets);
    return true;
  },

  /**
   * Asynchronous function to create a new field value (tagValue) and return the nameId and valueId
   *
   * @param {*} root0 - commit
   * @param {*} root0.commit - commit: used for committing the mutation APPEND_TAG
   * @param {object} payload - {nameId, tag: {key: value} }: payload for API call,
   * contains tagNameId and tag info
   * @returns {object} data - Object containing nameId and valueId, if the execution is successful
   */
  async addFieldValue({ commit }, payload) {
    if (!checkAPIAccess(payload.nameId)) throw new Error("Sorry, you don't have access to add value to this field.");
    const api = getAlertsAPI("ADD_FIELD_VALUE");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(api.url, { ...payload }, { API_ID: api.id });
    return data;
  },

  /**
   * Asynchronous function to get the location coordinates of a specific loaction
   *
   * @param {*} param0 - commit
   * @param {string} query - The location for which we need to obtain the location coordinates
   * @returns {object} data - {lat, long} The latitude and longitude
   * of the specific location passed as parameter
   */
  async getLocationCoordinates({ commit }, query) {
    const api = getAlertsAPI("GET_LOCATION_COORDINATES");
    const axiosInstance = await axiosCallWithHeaders();
    if (query.trim() === "") { return null; }
    const { data } = await axiosInstance.get(`${api.url}?value=${query}`, { API_ID: api.id });
    return data;
  },
  async getLocationDetails({ commit }, query) {
    const api = getAlertsAPI("GET_LOCATION_DETAILS");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.get(`${api.url}?placeId=${query}`, { API_ID: api.id });
    return data;
  },
  /**
   * Asynchronous function to get the location suggestions for a specific loaction
   *
   * @param {*} param0 - commit
   * @param {string} query - The input string for which we need to display the location suggestions
   * @returns {object[]} data - List of location suggestions
   * related to the input string passed as parameter
   */
  async getLocationSuggestions({ commit }, queryObject) {
    const api = getAlertsAPI("GET_LOCATION_SUGGESTIONS");
    const axiosInstance = await axiosCallWithHeaders();
    if (queryObject.string.trim() === "") { return null; }
    const { data } = await axiosInstance.get(`${api.url}?string=${queryObject.string}&countryCode=${queryObject.countryCode}`, { API_ID: api.id });
    return data;
  },

  /**
   * Asynchronous function to create core tag: Exam or Organisation
   *
   * @param {object} payload- {tagName, tagType} Object containg tag information
   * @returns {object} data - The tagId of created tag on successful operation
   */
  async createCoreTag({ commit }, payload) {
    const api = getCoreAPI("CORE_ADD_TAG");
    const axiosInstance = await axiosCallWithHeaders();

    const payloadData = payload;
    // If exam code is empty, remove it from payload
    if (!payload.examCode) delete payloadData.examCode;

    const { data } = await axiosInstance.post(api.url, { ...payloadData }, { API_ID: api.id });
    return data;
  },

  /**
   * Asynchronous function to connect and detach parents tags
   *
   * @param {object} payload- {tagName, tagId} Object containing
   * information about the tag and the tags to be attached/detached
   * @returns {object} data- The tagId of the tag
   */
  async attachParent({ commit }, payload) {
    const api = getCoreAPI("CORE_TAG_CONNECT_PARENTS");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(api.url, { ...payload }, { API_ID: api.id });
    return data;
  },

  /**
   * Asynchronous function to update core tags
   *
   * @param {object} payload- {tagId, tagText, attach-aliases, detach-aliases}:
   * Object containing information about the tag and the tags to be attached/detached
   * @returns {string} saved- a string saved is returned on successful execution
   */
  async updateCoreTag({ commit }, payload) {
    const api = getCoreAPI("CORE_UPDATE_TAG");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(api.url, { ...payload }, { API_ID: api.id });
    return data;
  },

  /**
   * Asynchronous function to connect alert tags
   *
   * @param {object} payload- {tagId, tagText, attach-parent, detach-parent, attach-alertTags,
   * detach-alertTags}: Object containing information
   * about the tag and the tags to be attached/detached
   * @returns {object} data- response from API call
   */
  async connectAlertTags({ commit }, payload) {
    const api = getAlertsAPI("CONNECT_TAGS");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(api.url, { ...payload }, { API_ID: api.id });
    return data;
  },

};
