import { toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "mt-1 relative shadow-sm" }
const _hoisted_3 = { class: "absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none" }
const _hoisted_4 = ["disabled", "placeholder"]
const _hoisted_5 = {
  key: 0,
  class: "w-full whitespace-nowrap mt-2 text-sm text-red-600"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    disabled: _ctx.disabled,
    class: _normalizeClass(`${_ctx.disabled ? 'text-grey-5 dark:text-dark-text-1' : ''} md:text-base text-sm`)
  }, [
    _createElementVNode("label", {
      for: "inputVal",
      class: _normalizeClass([_ctx.disabled ? 'text-grey-5 dark:text-slate-1' : 'text-grey-7 dark:text-gray-300', 'block text-sm font-medium'])
    }, [
      _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), { class: "w-5 h-5" }))
        ]),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputVal) = $event)),
          disabled: _ctx.disabled,
          type: "text",
          class: _normalizeClass([_ctx.outlined ? 'border border-grey-4 rounded' : 'border-0 border-b',
                   _ctx.disabled ? 'border-grey-3 dark:border-grey-2' : ( _ctx.errorFound ? 'border-red-300 text-red-900 placeholder-red-300 '
                     + 'focus:outline-none focus:ring-red-500 focus:border-red-500 ' : 'border-grey-4' ),
                   _ctx.icon ? 'px-0 pl-10' : 'px-1',
                   'focus:ring-0 inline w-full m-0 dark:text-dark-text-2 dark:bg-dark-primary-2 dark:border-dark-text-2']),
          autocomplete: "off",
          placeholder: _ctx.$t(_ctx.placeholder),
          onFocusout: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.validateInput && _ctx.validateInput(...args)))
        }, null, 42, _hoisted_4), [
          [
            _vModelText,
            _ctx.inputVal,
            void 0,
            { trim: true }
          ]
        ])
      ])
    ], 2),
    (_ctx.errorFound)
      ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.errorMessage), 1))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}