import displayConsole from "@/helpers/displayConsole";
import {
  CoreTagList, CoreTagListType, CoreTagSuggestion, Event, EventFilter, FieldName, FieldNameSuggestion, FieldValue, FieldValueSuggestion,
  FieldValueWithFieldName, IdData, IdDataType, SubFilterType, TagDetailsPayload,
  TagDetailsResponse, CoreTagWithFields, EventType, Mapped, SpecificTypeEvents, ValidCoreTagType, TagType,
  StaticFilesLang, TagTranslatedStatus, LangCode, NonEnglishLangCode, CountryName,
} from "./interface";
/** ******************************************************************************
 * Worker Exported Methods for Application Usage
 * ******************************************************************************* */
import {
  getLatestEvents, getListData, getStaticTagName, getTagsDetails, getIdData, getTranslatedTags, getTagSuggestions,
  getUserSpecificCoreTagsList, getSubscribedCoreTagsInformation, getSpecificFieldValuesData, getUserSpecificStateList,
  getEventList,
} from "./workerAdapter";

/**
 * Function to get Complete core tag (organisation) object
 *
 * @param {ValidCoreTagType} type - the type of core tag [exams, organisations]
 * @param {string[]} ids - array of ids of core tag
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @returns {Promise<Mapped<CoreTagWithFields>>} the core tag objects corresponding ids
 */
async function getCoreTagData(type: ValidCoreTagType, ids: string[], isAPICallEnabled: boolean): Promise<Mapped<CoreTagWithFields>> {
  const validTypes = ["exams", "organisations"];
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getCoreTagData, must be one of these ${validTypes}`);
  }
  const returnValue = await getIdData(type, ids, null, isAPICallEnabled, !isAPICallEnabled);
  return returnValue as Mapped<CoreTagWithFields>;
}

/**
 * Function to get name from static file according to the id given
 *
 * @param {string} tagType - name of static file
 * @param {string[]} ids - array of ids
 * @returns {Promise<StaticFilesLang[]> } array of objects
 */
async function getTagName(tagType: TagType, ids: string[]): Promise<StaticFilesLang[]> {
  if (!["organisation", "university", "stream", "category", "state", "qualification", "exam"].includes(tagType)) {
    throw new Error(`${tagType} is invalid tagType`);
  }
  const tagName = await getStaticTagName(tagType, ids, true);
  return tagName;
}

/**
 * Helper function to get field name tags from getTranslatedTags function
 *
 * @param {TagTranslatedStatus} tagStatus - Tag translation status: pending or completed
 * @param {LangCode} langCode - language to translate from
 * @param {NonEnglishLangCode} translationLangCode - language to translate to
 * @param {number} start - Position to start fetching translation data from
 * @param {number} count - Number of items to fetch
 * @returns {Promise<Mapped<FieldName>>} field name tags object containing translated tags to translate
 */
async function getTranslatedFieldNames(
  tagStatus: TagTranslatedStatus,
  langCode: LangCode,
  translationLangCode: NonEnglishLangCode,
  start: number,
  count: number,
): Promise<Mapped<FieldName>> {
  const fieldTags = await getTranslatedTags("fieldNames", tagStatus, langCode, translationLangCode, start, count, false);
  return fieldTags as Mapped<FieldName>;
}

/**
 * Helper function to get field values tags from getTranslatedTags function
 *
 * @param {TagTranslatedStatus} tagStatus - Tag translation status: pending or completed
 * @param {LangCode} langCode - language to translate from
 * @param {NonEnglishLangCode} translationLangCode - language to translate to
 * @param {number} start - Position to start fetching translation data from
 * @param {number} count - Number of items to fetch
 * @returns {Promise<Mapped<FieldValueWithFieldName>>} field value tags object containing translated tags to translate
 */
async function getTranslatedFieldValues(
  tagStatus: TagTranslatedStatus,
  langCode: LangCode,
  translationLangCode: NonEnglishLangCode,
  start: number,
  count: number,
): Promise<Mapped<FieldValueWithFieldName>> {
  const fieldValues = await getTranslatedTags("fieldValues", tagStatus, langCode, translationLangCode, start, count, false);
  return fieldValues as Mapped<FieldValueWithFieldName>;
}

/**
 * Helper function to get core tags values from getTranslatedTags function
 *
 * @param {"exams" | "organisations"} coreTagType - Type of core tag exams or organisations
 * @param {TagTranslatedStatus} tagStatus - Tag translation status: pending or completed
 * @param {LangCode} langCode - language to translate from
 * @param {NonEnglishLangCode} translationLangCode - language to translate to
 * @param {number} start - Position to start fetching translation data from
 * @param {number} count - Number of items to fetch
 * @returns {Promise<Mapped<FieldValueWithFieldName>>} core tags object containing translated tags to translate
 */
async function getTranslatedCoreTags(
  coreTagType: "exams" | "organisations",
  tagStatus: TagTranslatedStatus,
  langCode: LangCode,
  translationLangCode: NonEnglishLangCode,
  start: number,
  count: number,
): Promise<CoreTagList> {
  const validTypes = ["exams", "organisations"];
  if (!validTypes.includes(coreTagType)) {
    throw new Error(`Invalid type '${coreTagType}' was passed to getTranslatedTags, must be one of these ${validTypes}`);
  }
  const coreTagValues = await getTranslatedTags(coreTagType, tagStatus, langCode, translationLangCode, start, count, false);
  return coreTagValues as CoreTagList;
}

/**
 * Function to get Complete all connected tags to coretag (organisation) object
 *
 * @param {ValidCoreTagType} type - the type of core tag [exams, organisations]
 * @param {string[]} ids - array of ids of core tag
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @returns {Promise<Mapped<CoreTagWithFields>>} the core tag objects corresponding ids
 */
async function getCoreConnectedTags(type: ValidCoreTagType, ids: string[], isAPICallEnabled: boolean): Promise<Mapped<CoreTagWithFields>> {
  const validTypes = ["exams", "organisations"];
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getCoreConnectedTags, must be one of these ${validTypes}`);
  }
  const returnValue = await getIdData(type, ids, ["examsInfo", "tags"], isAPICallEnabled, !isAPICallEnabled);
  return returnValue as Mapped<CoreTagWithFields>;
}

/**
 * Function to fetch latest events for various filter types
 *
 * @param {EventType} type - Event type - job or admission
 * @param {number} limit - Limit for events (quantity)
 * @returns {Promise<SpecificTypeEvents>} events data
 */
async function getLandingPageEvents(type: EventType, limit: number): Promise<SpecificTypeEvents> {
  const validTypes = ["job", "admission"];
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getLandingPageEvents, must be one of these ${validTypes}`);
  }
  const events = await getLatestEvents(type, [], [], limit, true);
  return events;
}

/**
* Function to fetch latest events for various sub filter types
*
* @param {EventType} type - Type of event: job or admission
* @param {SubFilterType[]} subFilter - Array of filtrations: latestNotifications, latestResults, etc
* @param {number} limit - Number of items to fetch
* @returns {Promise<SpecificTypeEvents>} events - events object array
*/
async function getLandingSpecificEventsList(type: EventType, subFilter: SubFilterType[], limit: number): Promise<SpecificTypeEvents> {
  const validTypes = ["job", "admission"];
  const validSubFilter = ["latestNotifications", "latestAdmitCard", "latestFellowships", "latestWalkInInterview", "latestResults", "latestScholarships",
    "lastDateSoon", "latestInterview"];

  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getLandingSpecifcEventsList, must be one of these ${validTypes}`);
  }

  subFilter.forEach((filterType) => {
    if (!validSubFilter.includes(filterType)) {
      throw new Error(`Invalid subFilter '${filterType}' was passed to getLandingPageEvents, must be one of these ${validSubFilter}`);
    }
  });

  const eventList = await getLatestEvents(type, [], subFilter, limit, true);
  return eventList;
}

/**
 * Function to get suggestions for field names
 *
 * @returns {Promise<Mapped<FieldNameSuggestion>>} suggestions for field names
 */
async function getFieldNameSuggestions(): Promise<Mapped<FieldNameSuggestion>> {
  const suggestionsObject = await getTagSuggestions("fieldNames", null, false);
  return suggestionsObject as Mapped<FieldNameSuggestion>;
}

/**
 * Function to get suggestions for field values
 *
 * @param {string} fieldNameId - Id of fieldnamefor which value suggestions are needed
 * @returns {Promise<Mapped<FieldValueSuggestion>>} suggestions for field values
 */
async function getFieldValueSuggestions(fieldNameId: string): Promise<Mapped<FieldValueSuggestion>> {
  const suggestionsObject = await getTagSuggestions("fieldValues", fieldNameId, false);
  return suggestionsObject as Mapped<FieldValueSuggestion>;
}

/**
 * Function to get suggestions for core tags : exams or organisations
 *
 * @param {"exams" | "organisations"} type - Type of core tag: exams or organisations
 * @returns {Promise<Mapped<CoreTagSuggestion>>} suggestions for core tags
 */
async function getCoreTagSuggestions(type: "exams" | "organisations"): Promise<Mapped<CoreTagSuggestion>> {
  const validTypes = ["exams", "organisations"];
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getCoreTagSuggestions, must be one of these ${validTypes}`);
  }
  const suggestionsObject = await getTagSuggestions(type, null, false);
  return suggestionsObject;
}

/**
* Function to fetch latest events for various filter types like category, organisation, qualification, exam etc
*
* @param {EventType} type - Type of event: job or admission
* @param {EventFilter[]} filter - Specific filters like category and its id
* @param {number} limit - Number of items to fetch
* @returns {Promise<SpecificTypeEvents>} events - events object array
*/
async function getSpecificTemplateEvents(type: EventType, filter: EventFilter[], limit: number): Promise<SpecificTypeEvents> {
  const validTypes = ["job", "admission"];
  const validFilter = ["category", "exam", "organisation", "university", "stream", "qualification", "state"];

  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getSpecificTemplateEvents, must be one of these ${validTypes}`);
  }
  filter.forEach((filterType) => {
    if (!validFilter.includes(filterType.type)) {
      throw new Error(`Invalid filter '${filterType.type}' was passed to getSpecificTemplateEvents, must be one of these ${validFilter}`);
    }
  });
  const eventList = await getLatestEvents(type, filter, [], limit, true);
  return eventList;
}

/**
 * Function to fetch latest events for various filter types and sub-filters
 *
 * @param {EventType} type - Type of event: job or admission
 * @param {EventFilter[]} filter - Specific filters like category and its id
 * @param {SubFilterType[]} subFilter - Array of filtrations: latestNotifications, latestResults, etc
 * @param {number} limit - Number of items to fetch
 * @returns {Promise<SpecificTypeEvents>} events - events object array
 */
async function getTemplateSpecificEventsList(type: EventType, filter: EventFilter[], subFilter: SubFilterType[], limit: number): Promise<SpecificTypeEvents> {
  const validTypes = ["job", "admission"];
  const validFilter = ["category", "exam", "organisation", "university", "stream", "qualification", "state"];
  const validSubFilter = ["latestNotifications", "latestAdmitCard", "latestFellowships", "latestWalkInInterview", "latestResults", "latestScholarships",
    "lastDateSoon", "latestInterview"];

  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getTemplateSpecificEventsList, must be one of these ${validTypes}`);
  }

  filter.forEach((filterType) => {
    if (!validFilter.includes(filterType.type)) {
      throw new Error(`Invalid filter '${filterType.type}' was passed to getTemplateSpecificEventsList, must be one of these ${validFilter}`);
    }
  });

  subFilter.forEach((filterType) => {
    if (!validSubFilter.includes(filterType)) {
      throw new Error(`Invalid subFilter '${filterType}' was passed to getTemplateSpecificEventsList, must be one of these ${validSubFilter}`);
    }
  });

  const eventList = await getLatestEvents(type, filter, subFilter, limit, true);
  return eventList;
}

/**
 * Provides fieldName object corresponding to the ids passed
 *
 * @param {string[]} ids - array of ids for fieldNames
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @returns {Promise<Mapped<FieldName>>} fieldName object
 */
async function getFieldNameData(ids: string[], isAPICallEnabled: boolean): Promise<Mapped<FieldName>> {
  const returnValue = await getIdData("tags", ids, ["id", "lang", "type"], isAPICallEnabled, !isAPICallEnabled);
  return returnValue as Mapped<FieldName>;
}

/**
 * Function gets the events list for admin event list page
 *
 * @param {number} start - Position to start fetching events from
 * @param {number} count - Number of items to fetch
 * @returns {Promise<Event[]>} Object array of events
 */
async function getAdminEventList(start: number, count: number): Promise<Event[]> {
  const keys = ["lastUpdated", "eventId", "title", "title_roman"];
  const adminEventList = await getEventList(start, count, keys, false);
  return adminEventList;
}

/**
 * Function to get a core tag list for admin page
 *
 * @param {CoreTagListType} type - Type of tag: { exam / organisation }
 * @param {number} start - Position to start fetching events or tags from
 * @param {number} count - Number of items to fetch
 * @returns {Promise<CoreTagList>} Object containing admin coreTagList
 */
async function getAdminCoreTagList(type: CoreTagListType, start: number, count: number): Promise<CoreTagList> {
  const validTypes = ["exam", "organisation"];
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getAdminCoreTagList, must be one of these ${validTypes}`);
  }
  const keys = ["id", "type", "lang"];
  const coreTagList = await getListData(type, start, count, null, keys, false);
  return coreTagList as CoreTagList;
}

/**
 * Function to get a core tag list for user page
 *
 * @param {EventType} eventType - Type of event: job or admission
 * @param {CoreTagListType} listType - Type of tag: { exam / organisation }
 * @param {number} start - Position to start fetching events or tags from
 * @param {number} count - Number of items to fetch
 * @returns {Promise<CoreTagList>} coreTagList - Object containing user coreTagList
 */
async function getUserCoreTagList(eventType: EventType, listType: CoreTagListType, start: number, count: number): Promise<CoreTagList> {
  const validTypes = ["exam", "organisation", "university"];
  if (!validTypes.includes(listType)) {
    throw new Error(`Invalid type '${listType}' was passed to getUserCoreTagList, must be one of these ${validTypes}`);
  }
  const keys = ["id", "type", "lang"];
  const coreTagList = await getUserSpecificCoreTagsList(eventType, listType, start, count, keys, true);
  return coreTagList;
}

/**
 * Function to get field name list on admin field name list page
 *
 * @param {number} start - Position to start fetching events from
 * @param {number} count - Number of items to fetch
 * @returns {Promise<Mapped<FieldName>>} Object array of fields
 */
async function getAdminFieldNameList(start: number, count: number): Promise<Mapped<FieldName>> {
  const keys = ["id", "type", "lang"];
  const adminFieldNameList = await getListData("fieldName", start, count, null, keys, false);
  return adminFieldNameList as Mapped<FieldName>;
}

/**
 * Function to get field name list on admin field name list page
 *
 * @param {string} fieldNameId - Id of fieldname which values need to return
 * @param {number} start - Position to start fetching events from
 * @param {number} count - Number of items to fetch
 * @returns {Promise<Mapped<FieldValue>>} Object array of fieldValues
 */
async function getAdminFieldValueList(fieldNameId: string, start: number, count: number): Promise<Mapped<FieldValue>> {
  const keys = ["id", "type", "lang"];
  const fieldValueList = await getListData("fieldValue", start, count, fieldNameId, keys, false);
  return fieldValueList as Mapped<FieldValue>;
}

/**
   * Fetch tags title from store using its id
   *
   * @param {IdDataType} type - events, organisations, exams, tags
   * @param {string[]} ids - eventIds, organisationIds tagNameIds
   * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
   * @return {Promise<IdData>} - dataObj at id with keys ['id', 'lang']
   */
async function getTagTitle(type: IdDataType, ids: string[], isAPICallEnabled: boolean): Promise<IdData> {
  const validTypes = ["exams", "organisations", "tags"];
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getTagTitle, must be one of these ${validTypes}`);
  }
  const result = await getIdData(type, ids, ["id", "lang"], isAPICallEnabled, !isAPICallEnabled);
  return result;
}

/**
 * Function to Get All Tags Details for core tags: exams or organisations
 *
 * @param {ValidCoreTagType} type - type of page ( organisations or exams)
 * @param {TagDetailsPayload} payload - Object of arrays
 *  { examIds : [], organisationIds : [], tagsData : [], parent: [], children: []}
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @returns {Promise<TagDetailsResponse>} object with all data of core tags: organisations or exams
 */
async function getCoreTagsDetails(type: ValidCoreTagType, payload: TagDetailsPayload, isAPICallEnabled: boolean): Promise<TagDetailsResponse> {
  const validTypes = ["exams", "organisations"];
  if (!validTypes.includes(type)) {
    throw new Error(`Invalid type '${type}' was passed to getCoreTagsDetails, must be one of these ${validTypes}`);
  }
  const returnValue = await getTagsDetails(type, payload, isAPICallEnabled, !isAPICallEnabled);
  return returnValue;
}

/**
 * Function to Get All Tags Details for event
 *
 * @param {TagDetailsPayload} payload - Object of arrays
 *  { examIds : [], organisationIds : [], tagsData : [], parent: [], children: []}
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @returns {Promise<TagDetailsResponse>} object with all data of event tags
 */
async function getEventTagsDetails(payload: TagDetailsPayload, isAPICallEnabled: boolean): Promise<TagDetailsResponse> {
  displayConsole("Worker Adapter Connector: Request Received", { ...payload }, "getEventTagsDetails");
  const returnValue = await getTagsDetails("events", payload, isAPICallEnabled, !isAPICallEnabled);
  displayConsole("Worker Adapter Connector: Response Sent", { ...returnValue }, "getEventTagsDetails");
  return returnValue;
}

/**
 * Function to get event IDs data  object
 *
 * @param {string[]} ids - array of ids of events
 * @returns {Promise<Mapped<Event>>} the event objects for corresponding ids
 */
async function getEventIdsData(ids: string[]): Promise<Mapped<Event>> {
  const returnValue = await getIdData("events", ids, ["eventId", "title", "title_roman"], false, true);
  return returnValue as Mapped<Event>;
}

/**
 * Function to get event IDs create and updated timestamps
 *
 * @param {string[]} ids - array of ids of events
 * @returns {Promise<Mapped<Event>>} the event objects for corresponding ids containing timestamps
 */
async function getEventIdsTimestamps(ids: string[]): Promise<Mapped<Event>> {
  const returnValue = await getIdData("events", ids, ["eventId", "created", "lastUpdated"], false, true);
  return returnValue as Mapped<Event>;
}

/**
 * Function to get exam information and the mode for exams: job or admission
 *
 * @param {string[]} examIds - Array of exam Ids for which information is required
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @returns {Promise<CoreTagList>} subscribedExamInformationAndMode - The subscription information for the exams
 */
async function getExamSubscriptionInformation(examIds: string[], isAPICallEnabled: boolean): Promise<CoreTagList> {
  const returnValue = await getSubscribedCoreTagsInformation("exams", examIds, ["id", "lang", "mode"], isAPICallEnabled, true);
  return returnValue;
}

/**
 * Function to fetch location page data from worker thread
 *
 * @param {string} fieldNameId - Id of field name
 * @param {number} start - Start value of list index
 * @param {number} count - Number of items to fetch
 * @returns {Promise<Mapped<FieldValue>>} locationListData to be displayed on location page
 */
async function getLocationPageData(fieldNameId: string, start: number, count: number): Promise<Mapped<FieldValue>> {
  const locationList = await getSpecificFieldValuesData(fieldNameId, start, count, ["id", "lang", "locationInfo"], false);
  return locationList;
}

/**
 * Function to get states list for user side
 *
 * @param {EventType} eventType - Type of event: job or admission
 * @param {number} start - Position to start fetching events or tags from
 * @param {number} count - Number of items to fetch
 * @param {CountryName} country - Name of country
 * @returns {Promise<Mapped<FieldValue>>} coreTagList - Object containing user coreTagList
 */
async function getUserStateList(eventType: EventType, start: number, count: number, country: CountryName = "India"): Promise<Mapped<FieldValue>> {
  const keys = ["id", "lang"];
  const stateList = await getUserSpecificStateList(eventType, start, count, country, keys, true);
  return stateList;
}

export {
  getTagName,
  getCoreTagsDetails, getEventTagsDetails,
  getTranslatedFieldNames, getTranslatedFieldValues, getTranslatedCoreTags,
  getLandingPageEvents, getSpecificTemplateEvents, getTemplateSpecificEventsList, getLandingSpecificEventsList,
  getFieldNameSuggestions, getFieldValueSuggestions, getCoreTagSuggestions,
  getAdminEventList, getUserCoreTagList, getAdminCoreTagList, getAdminFieldNameList, getAdminFieldValueList,
  getCoreTagData, getCoreConnectedTags, getTagTitle, getFieldNameData, getEventIdsData, getEventIdsTimestamps,
  getExamSubscriptionInformation, getLocationPageData, getUserStateList,
};
