import axios from "@/plugins/axios";
import EventBus from "@/helpers/eventBus";
import { getCurrentUser } from "@/plugins/firebase";
import {
  AxiosRequestConfigCustom, User, AxiosError, AxiosInstance, AxiosRequestConfig, AxiosRequestHeaders, AxiosResponse,
} from "@/plugins/interface";

/**
 * Asynchronous function to generate and fetch the API call headers if the
 * firebase token is valid and refresh the firebase token when it has expired
 *
 * @returns {AxiosRequestHeaders} -headers
 */

const fetchHeaders = async (): Promise<AxiosRequestHeaders> => {
  const user: User | null = await getCurrentUser();
  const UUID = user?.uid;
  const token = await user?.getIdToken();
  const headers = {
    "x-fb-token": token,
    "x-fb-uuid": UUID,
  } as AxiosRequestHeaders;
  return headers;
};

/**
 * Request handler used in interceptor for the axios instances
 *
 * @param {AxiosRequestConfig} request - The request information data for the API call
 * @returns {AxiosRequestConfig} request - The request information data for the API call
 */
const requestHandler = (request: AxiosRequestConfig): AxiosRequestConfig => request;

/**
 * Response handler used in interceptor for the axios instances
 *
 * @param {AxiosResponse} response - The response data recieved from the API call
 * @returns {AxiosResponse} response - The response data recieved from the API call
 */
const responseHandler = (response: AxiosResponse): AxiosResponse => response;

/**
 * Error handler used in interceptor for the axios
 * instances and displays error message in case of errors on the basis of statusCode
 *
 * @param {AxiosError} err - The error that ocurred during the API call
 * @returns {Error} Promise - A promise object that is rejected with a given error apiFailed
 */
const errorHandler = (err: AxiosError): Promise<Error> => {
  const error: AxiosError = err;
  const API_ID: string | undefined = error.response ? (error.response.config as AxiosRequestConfigCustom)?.API_ID
    : (error.config as AxiosRequestConfigCustom)?.API_ID || "API";
  const statusCode = error.response ? error.response.status : null;
  const actualErrorBody = error.message;
  let errorMessage = error.message;
  switch (statusCode) {
    case 401:
      errorMessage = "Please login to access this resource";
      break;
    case 404:
      errorMessage = "The requested resource does not exist or has been deleted";
      break;
    default:
      errorMessage = "Something went wrong and request was not completed";
  }
  EventBus.$emit("apiError", API_ID, errorMessage, actualErrorBody);
  const x: Promise<Error> = Promise.reject(Error("apiFailed"));
  return x;
};

/**
 * Asynchronous function to create an axios instance containing headers
 *
 * @returns {AxiosInstance} instance - An axios instance containing headers (promise)
 */
const axiosCallWithHeaders = async (): Promise<AxiosInstance> => {
  const headers = await fetchHeaders();
  const instance = axios.create({
    headers,
  });
  instance.interceptors.request.use(requestHandler, errorHandler);
  instance.interceptors.response.use(responseHandler, errorHandler);
  return instance;
};

/**
 * Asynchronous function to create an axios instance that does not contains headers
 *
 * @returns {AxiosInstance} instance - An axios instance without headers (promise)
 */
const axiosCallWithoutHeaders = async (): Promise<AxiosInstance> => {
  const instance = axios.create({
    // here we are checking if status 403 then it should not throw any error
    validateStatus(status: number) {
      return (status >= 200 && status < 300) || status === 403;
    },
  });
  instance.interceptors.request.use(requestHandler, errorHandler);
  instance.interceptors.response.use(responseHandler, errorHandler);
  return instance;
};

/**
 * Asynchronous function to create an axios instance
 * that requires withCredentials property to be set TRUE
 *
 * @returns {AxiosInstance} instance - An axios instance having
 *  withCredentials property set to TRUE (promise)
 */
const axiosCallWithCredentials = async (): Promise<AxiosInstance> => {
  const instance = axios.create({
    withCredentials: true,
  });
  instance.interceptors.request.use(requestHandler, errorHandler);
  instance.interceptors.response.use(responseHandler, errorHandler);
  return instance;
};

/**
 * Asynchronous function to create an axios instance
 *  that requires withCredentials property to be set FALSE
 *
 * @returns {AxiosInstance} instance - An axios instance having
 * withCredentials property set to FALSE (promise)
 */
const axiosCallWithoutCredentials = async (): Promise<AxiosInstance> => {
  const instance = axios.create({
    withCredentials: false,
  });
  instance.interceptors.request.use(requestHandler, errorHandler);
  instance.interceptors.response.use(responseHandler, errorHandler);
  return instance;
};

/**
 * Asynchronous function to create an axios instance that
 * requires headers and withCredentials property to be set TRUE
 *
 * @returns {AxiosInstance} instance - An axios instance having
 * headers and withCredentials property set to TRUE (promise)
 */
const axiosCallWithHeadersAndCredentials = async (): Promise<AxiosInstance> => {
  const headers = await fetchHeaders();
  const instance = axios.create({
    headers,
    withCredentials: true,
    crossDomain: true,
  } as AxiosRequestConfigCustom);
  instance.interceptors.request.use(requestHandler, errorHandler);
  instance.interceptors.response.use(responseHandler, errorHandler);
  return instance;
};

export {
  axios, axiosCallWithHeaders, axiosCallWithoutHeaders,
  axiosCallWithCredentials, axiosCallWithoutCredentials, axiosCallWithHeadersAndCredentials, fetchHeaders,
};
