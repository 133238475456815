/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import { axiosCallWithoutHeaders } from "@/plugins/axiosInstances";
import { getCurrentUser } from "@/plugins/firebase";
// import locationParser from '@/helpers/locationParser'
import { storeKarma } from "@/plugins/indexedDB";
import getUserAPI from "@/api/userAPI";
import { analyticsSetGoal } from "@/helpers/analyticsEvents";

export default {
  async fetchKarma({ commit, dispatch, getters }) {
    try {
      const user = await getCurrentUser();
      const idTokenResult = await user.getIdTokenResult(true);
      const userKarma = idTokenResult.claims.epf_karma;
      if (!userKarma) {
        // set role for new user
        dispatch("setKarma", { token: idTokenResult.token, uid: idTokenResult.claims.user_id });
      } else {
        // commit
        commit("SET_USER_KARMA", userKarma);
        await storeKarma(userKarma);
        return getters.getUserKarma;
      }
    } catch (error) {
      console.log("an error occurred while fetching user karma", error);
    }
    return null;
  },
  async setKarma({ dispatch }, payload) {
    const api = getUserAPI("USER_SIGN_IN");
    const axiosInstance = await axiosCallWithoutHeaders();
    const { data } = await axiosInstance.post(api.url, {
      API_ID: api.id,
      "fb-token": payload.token,
      "fb-uid": payload.uid,
    });
    if (data.statusCode === 200 && data.body === "passed") {
      // log into analytics and dispatch fetchKarma api
      analyticsSetGoal();
      dispatch("fetchKarma");
    }
  },
  addAllEventIdAndTags(state, data) {
    let eventId;
    let tags;
    for (let i = 0; i < data.length; i += 1) {
      eventId = data[i].eventId;
      tags = data[i].tagsData;
      state.commit("eventDetails/setAllEventTags", { eventId, tags }, { root: true });
    }
  },
};
