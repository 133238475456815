
import { ChevronLeftIcon, ChevronRightIcon } from "@/plugins/solidHeroicon";
import EventBus from "@/helpers/eventBus";
import { defineComponent, PropType } from "@/plugins/vue";
import { RouteLocationRaw } from "@/plugins/interface";
import { changeToSentenceCase } from "@/helpers/validations";
import { analyticsSelectItemFromList } from "@/helpers/analyticsEvents";
// type for prop eventList
interface Item { date: string; title: string; link: string; }

export default defineComponent({
  name: "StaticDataTable",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    /**
     * Headers for the table
     */
    headers: {
      type: Array as PropType<{ text: string; value: string; }[]>,
      default: () => [],
    },

    /**
     * events to be shown in the table
     */
    eventList: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    /**
     * paginationCount of the table
     */
    paginationCount: {
      type: Array as PropType<number[]>,
      default: () => [],
    },
    /**
     * paginationCount of the table
     */
    routeFilter: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    pageNo: 1,
    isRightArrowShow: false,
    itemsSelectOptions: [] as number[],
    noOfItemsSelected: 0,
    startNo: 0,
  }),
  computed: {
    /**
     * True or false based on initial page check to
     * enables or disables the left arrow click of the table
     * @returns {boolean} val - false if first page or else true
     */
    isLeftArrowShow(): boolean {
      return this.pageNo <= 1;
    },

    /**
     * Returns an array of items that has to appear on the
     * current page of table
     * @returns {Array} val - array of items which are to be displayed
     */
    currentPageItems(): Item[] {
      return this.eventList.slice(this.startNo, this.startNo + this.itemsPerPage);
    },

    /**
     * Returns the number of items to be displayed on
     * the in table
     * @returns {number} val - number of items to be displayed
     */
    itemsPerPage(): number {
      return Number(this.noOfItemsSelected);
    },

  },
  watch: {
    /**
     * Update Pagination Route on route change,
     * also enabling the footer on route change
     *
     * @param {object} newRoute - New route value
     * @param {object} oldRoute - Old route value
     */
    $route(newRoute, oldRoute) {
      const newRouteName = newRoute.name.split("_")[0];
      const oldRouteName = oldRoute.name?.split("_")[0];
      if (newRouteName === oldRouteName) {
        EventBus.$emit("dataLoaded", true);
        this.paginationRoute(true);
      }
    },

    /**
     * To watch the Page no. to see if there is anymore data
     * if not then disabling the right click button
     * or else keeping the button enabled to visit the next page
     * Also calculating the start no when page changes
     */
    pageNo() {
      this.calculateStartNo();
      this.isRightArrowShow = (this.pageNo === Math.ceil(this.eventList.length / this.itemsPerPage));
    },

    /**
     * To check if the number of items per page has changed
     * and according to this enabling or disabling the right click arrow button
     * to disable the next page.
     * Also calculating the start no when items per page changes
     * and then redirecting to the required page
     */
    itemsPerPage() {
      this.calculateStartNo();
      this.pageNo = (this.startNo / this.itemsPerPage) + 1;
      this.goToPage(this.pageNo);
      this.isRightArrowShow = (this.pageNo === Math.ceil(this.eventList.length / this.itemsPerPage));
    },
    /**
     * Updates right arrow property if event list is changed
     */
    eventList() {
      this.isRightArrowShow = (this.pageNo === Math.ceil(this.eventList.length / this.noOfItemsSelected));
    },
  },
  /**
   * Setting the itemsSelectOptions and noOfItemsSelected with the pagination recieved as props
   * Also checking the amount of data based on which the right arrow click is enabled or disabled
   * Checking the route to if table information can be found in the route
   */
  created() {
    this.itemsSelectOptions = this.paginationCount;
    if (Object.keys(this.$route.query).length) {
      this.noOfItemsSelected = Number(this.$route.query?.itemsPerPage) > this.paginationCount[0] ? this.paginationCount[1] : this.paginationCount[0];
      this.pageNo = Number(this.$route.query?.pageNo) > 1 ? Number(this.$route.query?.pageNo) : 1;
    } else {
      [this.noOfItemsSelected] = this.paginationCount;
      this.pageNo = 1;
    }
  },
  methods: {
    changeToSentenceCase,
    analyticsSelectItemFromList,
    /**
     * Changes the current page to the page passed
     * as parameter also setting the Tabe parameters in route on page change
     *
     * @param  {number}  page to be set as current page in table
     */
    goToPage(page: number): void {
      if (this.pageExists(page)) {
        this.pageNo = page;
        this.paginationRoute();
      }
    },

    /**
     * Tells whether the the page passed as parameter
     * does exist in table
     *
     * @param {number} page to be checked for validity of the page
     */
    pageExists(page: number): boolean {
      const maxPages = Math.ceil(this.eventList.length / this.itemsPerPage);
      return page <= maxPages && page > 0;
    },

    /**
     * The link of the event which must be visited on click
     *
     * @param {string} link link of event
     */
    redirectLink(link: RouteLocationRaw): void {
      this.$router.push(link);
    },

    /**
     * Function to set the table parameters (pageNo & itemsPerPage) in the route
     *
     * @param {Boolean} routechangeCheck - To check if the function is called when route changes
     */
    paginationRoute(routechangeCheck = false) {
      const url = new URL(document.location.href);
      const params = new URLSearchParams(url.search);
      params.set("itemsPerPage", String(this.itemsPerPage));
      params.set("pageNo", String(this.pageNo));
      if (routechangeCheck && this.routeFilter.trim().length) {
        params.set("filter", this.routeFilter);
      } else {
        params.delete("filter");
      }
      this.$router.replace(`${url.pathname}?${params}`);
    },

    /**
     * Function to calculate the serial no. of the first element
     * on the current page of table
     */
    calculateStartNo(): void {
      let i = 1;
      while (this.eventList.length <= (this.itemsPerPage * (this.pageNo - i))) {
        i += 1;
      }
      this.startNo = this.itemsPerPage * (this.pageNo - i);
    },
    /**
     * Function log select_item event in firbase analyticsLogger
     *
     * @param {object} item - Item object with event information
     *
     */
    logEvent(item: Item) {
      let itemListName = "";
      let itemCategory = "";
      if (this.$route.meta.pageType === "filteredTagListPage") {
        itemListName = `${changeToSentenceCase(this.$route.meta.filterType as string).replace(/-/g, " ")} list`;
        itemCategory = `${changeToSentenceCase(this.$route.meta.filterType as string).replace(/-/g, " ")}`;
      } else {
        itemListName = `${changeToSentenceCase(this.$route.meta.type as string)} list`;
        itemCategory = `${changeToSentenceCase(this.$route.meta.type as string)}`;
      }
      analyticsSelectItemFromList(item.title, itemCategory, itemListName);
    },
  },
});
