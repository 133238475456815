import { filterString } from "@/helpers/dataFormat";
/**
 * Links to understand the new timestampToDate functionality
 * link : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString#performance
 * link : https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat
 */
/**
 * Creating a new date Object
 * @param {number} timestamp - timestamp
 * @returns {date} - return a date date Object
 */
const dateObject = (timestamp: number) => new Date(timestamp);
const dateParameter = { timeZone: "Asia/Kolkata" };
const intl = new Intl.DateTimeFormat("en-GB", dateParameter);
const filters = {
  /**
  * Converts UTC timestamp to date
  *
  * @param {number} timestamp - The UTC timestamp for date value
  * @returns {Date} Date - the locale date string from utc timestamp
  */

  timestampToDate: (timestamp: number): string => {
    const ts = timestamp?.toString().length === 13 ? Number(timestamp) : Number(timestamp) * 1000;
    const rawDate = dateObject(ts);
    const date = intl.format(rawDate);
    return date;
  },

  /**
  * Removes the HTML tags from the string value
  *
  * @param {string} value - String value with html removeHtmlTags
  * @returns {string} String - String with removed html tags
  */
  removeHtmlTags: (value: string): string => value.replace(/(<([^>]+)>)/ig, "").replace(/&nbsp;/g, " "),

  /**
  * Converts input text to Title Case
  *
  * @param {string} value - The string to be case converted
  * @returns {string} value - Title case converted string value
  */
  titleCase: (value: string) => filterString(value),
};
export default filters;
