import { analyticsLogger } from "@/plugins/firebase";

/**
 * Logs firebase analytics event when user view and item
 *
 * @param {object} eventParam - contains page title and page type
 */

/**
 * Logs firebase analytics event when user view and item
 *
 * @param {string} pageTitle - the page title
 * @param {string} pageType - the page type
 */
function analyticsViewEvent(pageTitle: string, pageType: string): void {
  analyticsLogger("view_item", {
    items: [
      {
        item_name: pageTitle,
        item_category: pageType,
      },
    ],
  });
}

/**
 * Logs firebase analytics event when user selects any link item like community and preparation
 *
 * @param {string} name - name of selected item
 */

function analyticsSelectItem(name: string): void {
  analyticsLogger(
    "select_item",
    {
      item_list_id: name,
      item_list_name: name,
    },
  );
}

/**
 * Logs firebase analytics event when user selects any item from the list
 * @param {string} name - event name
 * @param {string} category - event category
 * @param {string} listName - event list name
 */
function analyticsSelectItemFromList(name: string, category: string, listName: string): void {
  analyticsLogger("select_item", {
    items: [
      {
        item_name: name,
        item_category: category,
      },
    ],
    item_list_name: listName,
  });
}
/**
 * Logs firebase analytics event when user view any list of items
 *
 * @param {string} listName - name of the list viewed by the user
 */
function analyticsViewItemList(listName: string): void {
  analyticsLogger("view_item_list", {
    item_list_name: listName,
  });
}
/**
 * Logs firebase analytics event when user signup
 *
 * @param {string} providerId - provider id of AuthProvider
 */
function analyticsSignIn(providerId: string): void {
  analyticsLogger("sign_up", { method: providerId });
}
/**
 * Logs firebase analytics event when user login
 *
 * @param {string} providerId - provider id of AuthProvider
 */
function analyticsLogin(providerId: string): void {
  analyticsLogger("login", { method: providerId });
}
/**
 * Logs firebase analytics event when user subscribe any exam.
 *
 * @param {string} eventId - id of event subscribed.
 */
function analyticsSelectContent(eventId: string): void {
  analyticsLogger("select_content", { content_type: "event", item_id: eventId });
}

/**
 * Logs firebase analytics event when user karma is set in firebase.
 */
function analyticsSetGoal(): void {
  analyticsLogger("goal_completion", { name: "user_karma_set" });
}

export {
  analyticsViewEvent, analyticsSelectItem, analyticsSelectItemFromList, analyticsViewItemList,
  analyticsSignIn, analyticsLogin, analyticsSelectContent, analyticsSetGoal,
};
