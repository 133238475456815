import {
  getResults, getSuggestions, getAdminTagSuggestions, getSpecificTypeEventFilters as getSpecificTypeEventFiltersHelper,
  getSpecificTypeFilteredEvents as getSpecificTypeFilteredEventsHelper,
} from "./searchWorkerAdapter.js";

/**
 * Returns filters for specifc type events
 *
 * @param {string} type - Event type - job or admission
 * @param {object[] | null } filter - specific page : category, exam, etc
 * @param {string[]} subFilter - latest update type
 * @returns {Promise<SpecificTypeEvents>} event filters
 */
async function getSpecificTypeEventFilters(type, filter, subFilter) {
  try {
    const data = await getSpecificTypeEventFiltersHelper(type, filter, subFilter);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Returns specifc type events from filtered using the provided tagIds
 *
 * @param {string[]} tagIds array of tagIds to search in events
 * @param {number} count number of events to return
 * @returns {Promise<any[]>} Array of specific type events
 */
async function getSpecificTypeFilteredEvents(tagIds, count) {
  try {
    const data = await getSpecificTypeFilteredEventsHelper(tagIds, count);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Returns top events and filters for a given tagIds
 *
 * @param {string[]} tagIds array of tagIds to search in events
 * @param {string} eventQuery The string to fuzzy search all over events
 * @returns {Promise<SearchResult[]>} array of events
 */
async function getEvents(tagIds, eventQuery) {
  try {
    const data = await getResults(tagIds, eventQuery, true, false);
    return data.events;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Returns top events and filters for a given tagIds
 *
 * @param {string[]} tagIds array of tagIds to search in events
 * @param {string} eventQuery The string to fuzzy search all over events
 * @returns {Promise<Filters>} event filters
 */
async function getFilters(tagIds, eventQuery) {
  try {
    const data = await getResults(tagIds, eventQuery, false, true);
    return data.filters;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Returns top events and filters for a given tagIds
 *
 * @param {string[]} tagIds array of tagIds to search in events
 * @param {string} eventQuery The string to fuzzy search all over events
 * @returns {Promise<EventsAndFilters>} object containing top evemts and filters
 */
async function getEventsAndFilters(tagIds, eventQuery) {
  try {
    const data = await getResults(tagIds, eventQuery, true, true);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Returns an array of tags matching search query
 * Searches the query in all tags and returns the merged sorted results
 *
 * @param {string} query string to search in tags
 * @returns {Promise<SearchSuggestion[]>} array of top tags matching events
 */
async function getSearchSuggestions(queryParams) {
  try {
    const data = await getSuggestions(queryParams);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Function to get suggestions for core tags
 *
 * @param {string} queryParams - The search input by user
 * @param {string} coreTagType - Type of core tag - exams, organisations
 * @param {number} limit - Number of suggestions to fetch
 * @returns  {Promise<object>} suggestions for a core tags
 */
async function getAdminCoreTagSuggestions(queryParams, coreTagType, limit = 50) {
  try {
    const data = await getAdminTagSuggestions(queryParams, coreTagType, null, limit);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Function to get suggestions for fieldNames
 *
 * @param {string} queryParams - The search input by user
 * @param {number} limit - Number of suggestions to fetch
 * @returns  {Promise<object>} suggestions for fieldnames
 */
async function getAdminFieldNameSuggestions(queryParams, limit = 50) {
  try {
    const data = await getAdminTagSuggestions(queryParams, "fieldNames", null, limit);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}
/**
 * Function to get suggestions for fieldValues
 *
 * @param {string} queryParams - The search input by user
 * @param {string} fieldNameId - Field Name Id for which suggestions are needed
 * @returns {Promise<object>} suggestions for fieldValues
 */
async function getAdminFieldValueSuggestions(queryParams, fieldNameId, limit = 50) {
  try {
    const data = await getAdminTagSuggestions(queryParams, "fieldValues", fieldNameId, limit);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export {
  getEvents, getFilters, getEventsAndFilters, getSearchSuggestions, getAdminCoreTagSuggestions,
  getAdminFieldNameSuggestions, getAdminFieldValueSuggestions, getSpecificTypeEventFilters, getSpecificTypeFilteredEvents,
};
