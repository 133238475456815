
import { ShareIcon } from "@/plugins/solidHeroicon";
import ShareDialog from "@/components/Generic/ShareDialog.vue";
import { defineComponent } from "@/plugins/vue";
import BreadCrumbs from "./BreadCrumbs.vue";

export default defineComponent({
  name: "HeadingSection",
  components: {
    BreadCrumbs,
    ShareIcon,
    ShareDialog,
  },

  props: {
    /**
      Title for page
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * Enable share button
     */
    enableShare: {
      type: Boolean,
      default: false,
    },
    /**
     * check specificPageEventList page
     */
    isSpecificPageEventList: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["headingSectionLoaded"],
  data() {
    return {
      showTitle: false,
      openShare: false,
    };
  },
  methods: {
    /**
     * Function to show title and emit heading section loaded status.
     */
    breadcrumbHandler() {
      this.showTitle = true;
      this.$emit("headingSectionLoaded");
    },
  },
});
