
import { defineComponent } from "@/plugins/vue";

export default defineComponent(
  {
    name: "CircularLoader",
    props: {
    /**
     * Display Size for loader
     */
      size: {
        type: Number,
        default: 12,
      },
      /**
     * Prop to denote whether to show text under loader or not
     */
      showInfo: {
        type: Boolean,
        default: false,
      },
      /**
     * Prop for text title to be shown under loader
     */
      infoTitle: {
        type: String,
        default: "Loading",
      },
      /**
     * Prop for text subtitle to be shown under loader
     */
      infoText: {
        type: String,
        default: "This may take a few seconds, please don't close this page.",
      },
    },
  },
);
