import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 6,
  class: "max-w-full mb-5 mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_home_page_banner = _resolveComponent("home-page-banner")!
  const _component_latest_notifications = _resolveComponent("latest-notifications")!
  const _component_popular_exams = _resolveComponent("popular-exams")!
  const _component_latest_update_template = _resolveComponent("latest-update-template")!
  const _component_top_organisations = _resolveComponent("top-organisations")!
  const _component_top_states = _resolveComponent("top-states")!
  const _component_educational_category = _resolveComponent("educational-category")!
  const _component_other_platforms = _resolveComponent("other-platforms")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_home_page_banner),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_latest_notifications, {
          key: 0,
          class: "w-full",
          "latest-notifications-events": _ctx.latestEvents.latestNotifications
        }, null, 8, ["latest-notifications-events"]))
      : _createCommentVNode("", true),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_popular_exams, {
          key: 1,
          class: "max-w-full mb-5"
        }))
      : _createCommentVNode("", true),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_latest_update_template, {
          key: 2,
          updateslist: _ctx.latestEvents,
          class: "max-w-full mx-auto"
        }, null, 8, ["updateslist"]))
      : _createCommentVNode("", true),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_top_organisations, {
          key: 3,
          class: "max-w-full mb-5 mx-auto"
        }))
      : _createCommentVNode("", true),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_top_states, {
          key: 4,
          class: "max-w-full mb-5 mx-auto"
        }))
      : _createCommentVNode("", true),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_educational_category, {
          key: 5,
          class: "max-w-full mb-5 mx-auto"
        }))
      : _createCommentVNode("", true),
    (_ctx.isShow)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_other_platforms)
        ]))
      : _createCommentVNode("", true)
  ]))
}