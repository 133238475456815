import { openDB, IDBPDatabase, DBSchema } from "idb";

// Database Name Constants
const FIREBASE_DB_NAME: string = "firebaseLocalStorageDb";
const USER_DB_NAME: string = "userLocalStorageDb";
const USER_INFO_STORE_NAME: string = "userInfo";

/**
 * This interface is used in indexedDb.ts file
 */
interface IndexedDBUpgradeOptions {
  upgrade?: (db: IDBPDatabase<DBSchema>) => void;
  blocked?: () => void;
  blocking?: () => void;
  terminated?: () => void;
}

/**
 * Opens the specified database in indexed db
 *
 * @param {string} dbName Name of the database
 * @param {IndexedDBUpgradeOptions} IndexedDBUpgradeOptions: options for creation
 * updation or deletion of db, refer https://github.com/jakearchibald/idb#opendb for details
 *
 * @returns {Promise<IDBDatabase>} a promise that resolves into an IDBDatabase instance of the specified db and rejects if it can't access the db
 * with the corresponding error
*/
const getDBPromise = (dbName: string, options?: IndexedDBUpgradeOptions): Promise<IDBPDatabase | any> => new Promise((resolve, reject) => {
  openDB(dbName, 1, options).then(resolve).catch(reject);
});

/**
 * Function opens and return an instance to Firebase indexed DB that holds firebase auth information
 *
 * @returns {Promise<IDBDatabase>} a promise that resolves into an IDBDatabase instance of the firebase
 * db and rejects if it can't access the db with the corresponding error
*/
const getFirebaseDB = (): Promise<IDBPDatabase> => getDBPromise(FIREBASE_DB_NAME);

/**
 * Function opens and return an instance to user indexed DB that holds additional
 * user information
 *
 * @returns {Promise<IDBDatabase>} a promise that resolves into an IDBDatabase instance of user db
 * and rejects if it can't access the db with the corresponding error
*/
const getUserDB = (): Promise<IDBDatabase | any> => getDBPromise(USER_DB_NAME, {
  upgrade(db) {
    db.createObjectStore(USER_INFO_STORE_NAME as never);
  },
});

interface ReadCreds {
  token: undefined | string;
  uid: undefined | string;
}
/**
 * Function to read Firebase auth credentials from Firebase Indexed DB
 * @return {Promise<ReadCreds>} - return type of function of readCreds
 */
const readCreds = async (): Promise<ReadCreds> => {
  try {
    const storeName = "firebaseLocalStorage";
    const db = await getFirebaseDB();
    const tx = db.transaction(storeName, "readonly");
    const store = tx.objectStore(storeName);
    const allRows = await store.getAll();
    const filteredRows = allRows.filter((obj) => obj.fbase_key.includes("firebase:authUser"));

    // TODO third check
    if (!filteredRows.length) {
      return {
        token: undefined,
        uid: undefined,
      };
    }

    const row = filteredRows[0];
    const token: string = row.value.stsTokenManager.accessToken;
    const { uid } = row.value as { uid: string; };
    return {
      token,
      uid,
    };
  } catch (error: any) {
    throw new Error(error);
  }
};

// Store EPF karma in userInfo datastore of User Indexed DB
const storeKarma = async (karma: number): Promise<void> => {
  await (await getUserDB()).put(USER_INFO_STORE_NAME, karma, "karma");
};

// Read EPF karma in userInfo datastore of User Indexed DB
const readKarma = async (): Promise<number> => {
  const karma: number = await (await getUserDB()).get(USER_INFO_STORE_NAME, "karma");
  return karma || 0;
};

export {
  readCreds, readKarma, storeKarma,
};
