export default {
  /**
   * Getter gets the language code form the state of translation module
   *
   * @param {object} state - Data store for translation module
   * @returns {string} - Value containing language code like 'en','hi'
   */
  getLangCode(state) {
    return state.languageCode;
  },
};
