// Sets Access cookies to upload attachments to S3
// Needed only for admins

import { axiosCallWithHeadersAndCredentials } from "@/plugins/axiosInstances";
import getAlertsAPI from "@/api/alertsAPI";
import { API } from "@/api/interface";
import { AxiosInstanceWithApiId } from "@/plugins/interface";

/**
 * Function to get the cookie
 *
 * @param {string} name - Name of the cookie
 * @returns {string} cookie - The requested cookie
 */
function getCookie(name: string): string {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return "";
}

/**
 * Function to get the cookie's expiry date
 *
 * @param {string} cookieName - Name of cookie
 * @returns {string} cookieExpiryDate - The cookie expiry date
 */
function getCookieExpiryDate(cookieName: string): string {
  const epfCookie = getCookie(cookieName);
  if (epfCookie) {
    const policyCookie = JSON.parse(atob(epfCookie));
    if (policyCookie) return JSON.parse(atob(policyCookie.policy)).expiration;
  }
  return "";
}

/**
 * Function to delete cookies
 */
function deleteCookies(): void {
  const cookieSamePart = "=;expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.exampathfinder.com";
  document.cookie = `EPFA${cookieSamePart}`;
  document.cookie = `UUID${cookieSamePart}`;
  document.cookie = `CloudFront-Key-Pair-Id${cookieSamePart}`;
  document.cookie = `CloudFront-Signature${cookieSamePart}`;
  document.cookie = `CloudFront-Policy${cookieSamePart}`;
  // console.log('delete all cookies')
}

/**
 * Asynchronous function to get the access cookie for file uploadAttachments
 *
 * @returns {boolean} true- on success event
 */
async function getAccess(): Promise<boolean> {
  const api = getAlertsAPI("GET_ACCESS_FOR_UPLOAD") as API;
  const axiosInstance = await axiosCallWithHeadersAndCredentials() as AxiosInstanceWithApiId;
  const data = await axiosInstance.get(api.url, { API_ID: api.id });
  if (data.status === 200) {
    return true;
  }
  return false;
}
/**
 * Asynchronous function to check if the cookie has expired or not
 */
async function checkCookieExpiry() {
  const expiryData = getCookieExpiryDate("EPFA");
  if (!expiryData) return;
  const timeInMiliseconds = new Date(expiryData).getTime();
  const totalTimeDiff = timeInMiliseconds - new Date().getTime();
  const timeInHours = Math.floor(totalTimeDiff / 3600000);
  // Delete cookie before 1 day, or after 10 days
  if (timeInHours <= 24 || timeInHours >= 240) {
    deleteCookies();
    await getAccess();
  }
}

export {
  getCookie, getAccess, checkCookieExpiry,
};
