import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_section = _resolveComponent("heading-section")!
  const _component_latest_updates_list = _resolveComponent("latest-updates-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_heading_section, {
      title: _ctx.listType,
      "enable-share": true,
      "is-specific-page-event-list": false,
      onHeadingSectionLoaded: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showList = true))
    }, null, 8, ["title"]),
    _createElementVNode("div", null, [
      (_ctx.showList)
        ? (_openBlock(), _createBlock(_component_latest_updates_list, {
            key: 0,
            mode: _ctx.mode,
            "list-type": _ctx.listType,
            "is-specific": _ctx.isSpecific,
            search: _ctx.search
          }, null, 8, ["mode", "list-type", "is-specific", "search"]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}