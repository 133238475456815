
import { getTagName } from "@/scripts/workerAdapterConnector";
import { ChevronRightIcon, HomeIcon } from "@/plugins/solidHeroicon";
import { getSeperatedIdAndName } from "@/helpers/routesHandler";
import { changeToSentenceCase, isEmptyObject } from "@/helpers/validations";
import generateSeoSchema from "@/helpers/seoSchema";
import { defineComponent } from "@/plugins/vue";
import type { DataSetSchema } from "@/helpers/interface";
import { NOTIFICATION_PLATFORM_URL } from "@/helpers/constants";
import { camelCaseConversion } from "@/helpers/dataFormat";
import { RouteLocationNormalized, RouteLocationRaw, LocationQuery } from "@/plugins/interface";
import { TagType } from "@/scripts/interface";

type LangCode = "en" | "hi";

interface Link {
  name: string;
  params?: { id?: string | readonly string[]; langCode?: LangCode; latestUpdateType?: string; };
  query?: LocationQuery;
}

interface Breadcrumbs {
  name: string;
  link: Link;
  current?: string;
}

interface DataAndListName {
  en: { aliases?: string[]; string: string; };
  hi: { roman: string; string: string; };
}

interface SeoBreadcrumbs {
  name: string;
  link: string;
}

export default defineComponent({
  name: "BreadCrumbs",
  components: {
    HomeIcon,
    ChevronRightIcon,

  },
  emits: ["breadcrumbsLoaded"],
  data() {
    return {
      routerBreadcrumbs: [] as Breadcrumbs[],
      breadcrumbsLoaded: false,
      staticDataName: {} as DataAndListName,
      filteredTagListName: {} as DataAndListName,
      isStaticDataName: false,
      isFilteredTagListName: false,
      isLandingPage: false,
    };
  },
  computed: {
    /**
     * Computed property to get the current langCode from the store
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed property that returns the page type i.e job or admission
     */
    jobAdmissionPageType(): "job" | "admission" {
      return this.$store.getters["base/getPageType"];
    },
    /**
     * Computed property to get cureent breadcrumb properties
     */
    currentRouteProperties(): Link {
      const currentName = this.$route.name ? String(this.$route.name) : "";
      const query = (Object.values(this.$route.query as LocationQuery)) ? this.$route.query : {};
      const routeObj = {
        name: currentName,
        ...(this.$route.params) && { params: this.$route.params },
        ...(!isEmptyObject(query)) && { query },
      };

      return routeObj;
    },
  },
  /**
     * Applied watch on router to change breadcrumbs value
     *
     * @param {RouteLocationNormalized} newRoute - New route value
     * @param {RouteLocationNormalized} oldRoute - Old route value
     */
  watch: {
    $route(newVal: RouteLocationNormalized, oldVal: RouteLocationNormalized): void {
      const newRouteName = (newVal.name as string).split("_")[0];
      const oldRouteName = (oldVal.name as string)?.split("_")[0];
      if (oldRouteName !== newRouteName) {
        this.isStaticDataName = false;
      }
      this.displayBreadcrumbs(newVal);
    },
    /**
     * Watch routerBreadcrumbs to set SEO schema
     */
    routerBreadcrumbs(): void {
      if (this.breadcrumbsLoaded) {
        this.setSeoSchema();
      }
    },
  },
  created() {
    this.displayBreadcrumbs(this.$route);
  },
  methods: {
    /**
     * watch the previously visited page type and change the home icon route accordingly
     */
    getHomeRoute(): { name: string; } {
      const path = (this.jobAdmissionPageType === "admission")
        ? { name: `admissionsHome_${this.langCode}` } : { name: `jobsHome_${this.langCode}` };
      return path;
    },
    /**
     * Function to filter link for breadcrumb on the basis of page
     *
     * @param {RouteLocationNormalized} currentRoute: The current route object
     *
     */
    displayBreadcrumbs(currentRoute: RouteLocationNormalized): void {
      this.isLandingPage = false;
      this.breadcrumbsLoaded = false;

      const isPageSpecific = this.specificPageTypeBreadCrumb(currentRoute);
      if (!isPageSpecific) {
        // empty array to store the breadcrumbs objects
        const routes = [];

        const allPaths = currentRoute.path.split("/");

        // if the page is event view page
        if (allPaths.includes("event") && allPaths.includes("view")) {
          this.eventViewPageBreadcrumb(currentRoute);
          return;
        }
        // for all other types of pages
        for (let i = 2; i < allPaths.length; i += 1) {
          const trimmedLink = this.$t(camelCaseConversion(allPaths[i]));
          routes.push({ name: trimmedLink, link: this.currentRouteProperties });
        }

        this.breadcrumbsLoaded = true;
        this.routerBreadcrumbs = routes;
        this.$emit("breadcrumbsLoaded");
      }
    },
    /**
     * Function to find is a page is specfic type or not
     *
     * @param {RouteLocationNormalized} currentRoute : the object of currentRoute
     * @returns {Boolean} true if page is specific otherwise false
     */
    specificPageTypeBreadCrumb(currentRoute: RouteLocationNormalized): boolean {
      if (currentRoute.meta.pageType === "landingPage") {
        this.landingPageBreadcrumb(currentRoute);
        return true;
      }
      if (currentRoute.meta.pageType === "latestUpdatePage") {
        this.latestUpdatePageBreadcrumb(currentRoute);
        return true;
      }
      if (currentRoute.meta.pageType === "staticDataTemplatePage") {
        this.staticDataTemplatePageBreadcrumb(currentRoute);
        return true;
      }
      if (currentRoute.meta.pageType === "tagListPage") {
        this.tagListPageBreadcrumb(currentRoute);
        return true;
      }
      if (currentRoute.meta.pageType === "filteredTagListPage") {
        this.filteredTagListPageBreadcrumb(currentRoute);
        return true;
      }
      if (currentRoute.meta.pageType === "userProfilePage") {
        this.userProfilePageBreadcrumb();
        return true;
      }
      if ((currentRoute.meta.minKarma as number) >= 25) {
        this.adminPageBreadcrumbs(currentRoute);
        return true;
      }
      return false;
    },
    /**
     * Function to display breadcrumb for event view page
     *
     * @param {RouteLocationNormalized} currentRoute : the object of currentRoute
     */
    eventViewPageBreadcrumb(currentRoute: RouteLocationNormalized): void {
      const routes = [] as Breadcrumbs[];
      const allPaths = currentRoute.path.split("/");
      const viewIndex = allPaths.indexOf("view");
      const eventViewNameArray = allPaths[viewIndex + 1].split("-");
      eventViewNameArray.shift();
      const eventViewName = eventViewNameArray.join(" ");
      routes.push({ name: `Event - ${eventViewName}`, link: this.currentRouteProperties });
      this.breadcrumbsLoaded = true;
      this.routerBreadcrumbs = routes;
      this.$emit("breadcrumbsLoaded");
    },
    /**
     * Function to display breadcrumb for landing page
     *
     * @param {RouteLocationNormalized} currentRoute : the object of currentRoute
     */
    landingPageBreadcrumb(currentRoute: RouteLocationNormalized): void {
      this.isLandingPage = true;
      this.routeMode(currentRoute);
      this.breadcrumbsLoaded = true;
      this.$emit("breadcrumbsLoaded");
    },
    /**
     * Function to display breadcrumb for latest update page
     *
     * @param {RouteLocationNormalized} currentRoute : the object of currentRoute
     */
    latestUpdatePageBreadcrumb(currentRoute: RouteLocationNormalized): void {
      const routes = [] as Breadcrumbs[];
      this.routeMode(currentRoute);
      const latestLinkName = this.$t(camelCaseConversion(currentRoute.meta.type as string));
      routes.push({ name: latestLinkName, link: this.currentRouteProperties });
      this.breadcrumbsLoaded = true;
      this.routerBreadcrumbs = this.routerBreadcrumbs.concat(routes);
      this.$emit("breadcrumbsLoaded");
    },
    /**
     * Function to display breadcrumb forstatic data template page
     *
     * @param {RouteLocationNormalized} currentRoute : the object of currentRoute
     */
    staticDataTemplatePageBreadcrumb(currentRoute: RouteLocationNormalized): void {
      const routes = [] as Breadcrumbs[];
      this.routeMode(currentRoute);
      // get the event name from the id of the event
      const eventObj = getSeperatedIdAndName(currentRoute.params.id as string) as { id: string; name: string; };
      const eventId = eventObj.id;
      if (!this.isStaticDataName) {
        getTagName(currentRoute.meta.type as TagType, [eventId])
          .then((data) => {
            if (!data[0]) return;
            [this.staticDataName] = data as DataAndListName[];
            routes.push({ name: this.staticDataName[this.langCode]?.string || this.staticDataName.en.string, link: this.currentRouteProperties });
            this.breadcrumbsLoaded = true;
            this.isStaticDataName = true;
            this.routerBreadcrumbs = this.routerBreadcrumbs.concat(routes);
            this.$emit("breadcrumbsLoaded");
          });
      } else {
        routes.push({ name: this.staticDataName[this.langCode]?.string || this.staticDataName.en.string, link: this.currentRouteProperties });
        this.breadcrumbsLoaded = true;
        this.routerBreadcrumbs = this.routerBreadcrumbs.concat(routes);
        this.$emit("breadcrumbsLoaded");
      }
    },
    /**
     * Function to display breadcrumb for tag list page
     *
     * @param {RouteLocationNormalized} currentRoute : the object of currentRoute
     */
    tagListPageBreadcrumb(currentRoute: RouteLocationNormalized): void {
      const routes = [] as Breadcrumbs[];
      this.routeMode(currentRoute);
      const typeName = this.$t(`${currentRoute.meta.type}List`);
      routes.push({ name: typeName, link: this.currentRouteProperties });
      this.breadcrumbsLoaded = true;
      this.routerBreadcrumbs = this.routerBreadcrumbs.concat(routes);
      this.$emit("breadcrumbsLoaded");
    },
    /**
     * Function to display breadcrumb for filtered tag list page
     *
     * @param {RouteLocationNormalized} currentRoute : the object of currentRoute
     */
    filteredTagListPageBreadcrumb(currentRoute: RouteLocationNormalized): void {
      const routes = [] as Breadcrumbs[];
      this.routeMode(currentRoute);
      // get the event name from the id of the event
      // add latest update type in breadcrumb
      const latestLinkName = this.$t(camelCaseConversion(currentRoute.meta.filterType as string));
      routes.push({ name: latestLinkName, link: this.currentRouteProperties });
      if (!this.isFilteredTagListName) {
        const eventObj = getSeperatedIdAndName(currentRoute.params.id as string) as { id: string; name: string; };
        getTagName(currentRoute.meta.type as TagType, [eventObj.id])
          .then((data) => {
            if (!data[0]) return;
            [this.filteredTagListName] = data as DataAndListName[];
            // add filteredTagListName on perfct index
            routes.splice(0, 0, {
              name: this.filteredTagListName[this.langCode]?.string || this.filteredTagListName.en.string,
              link: {
                name: `${currentRoute.meta.mode}sSpecific${changeToSentenceCase(currentRoute.meta.type as string)}Page_${this.langCode}`,
                params: { id: currentRoute.params.id },
              },
            });
            this.breadcrumbsLoaded = true;
            this.isFilteredTagListName = true;
            this.routerBreadcrumbs = this.routerBreadcrumbs.concat(routes);
            this.$emit("breadcrumbsLoaded");
          });
      } else {
        routes.splice(0, 0, {
          name: this.filteredTagListName[this.langCode]?.string || this.filteredTagListName.en.string,
          link: {
            name: `${currentRoute.meta.mode}sSpecific${changeToSentenceCase(currentRoute.meta.type as string)}Page_${this.langCode}`,
            params: { id: currentRoute.params.id },
          },
        });
        this.breadcrumbsLoaded = true;
        this.isFilteredTagListName = true;
        this.routerBreadcrumbs = this.routerBreadcrumbs.concat(routes);
      }
    },
    /**
     * Function to display breadcrumbs on user profile page
     *
    */
    userProfilePageBreadcrumb(): void {
      this.routerBreadcrumbs = [];
      const routeMode = this.$t(camelCaseConversion(this.jobAdmissionPageType));
      const routeName = this.jobAdmissionPageType === "job" ? `jobsHome_${this.langCode}` : `admissionsHome_${this.langCode}`;
      this.routerBreadcrumbs.push({ name: routeMode, link: { name: routeName } });
      const profilePage = this.$t("profile");
      const currentRouteName = this.langCode === "en" ? "userProfile_en" : "userProfile_hi";
      this.routerBreadcrumbs.push({ name: profilePage, link: { name: currentRouteName } });
      this.breadcrumbsLoaded = true;
      this.$emit("breadcrumbsLoaded");
    },
    /**
     * Function to display breadcrumbs on admin edit pages
     *
     * @param {RouteLocationNormalized} currentRoute : the object of the current route
    */
    adminPageBreadcrumbs(currentRoute: RouteLocationNormalized): void {
      const routes = [] as Breadcrumbs[];
      const currentPaths = currentRoute.path.split("/");
      const pageType = this.$t(currentPaths[2]);
      routes.push({ name: pageType, link: this.currentRouteProperties });
      const actionType = this.$t(currentPaths[3]);
      routes.push({ name: actionType, link: this.currentRouteProperties });
      if (currentRoute.params.id) {
        const eventObj = getSeperatedIdAndName(currentRoute.params.id as string) as { id: string; name: string; };
        const eventName = eventObj.name;
        routes.push({ name: eventName, link: this.currentRouteProperties });
      }
      this.routerBreadcrumbs = routes;
      this.breadcrumbsLoaded = true;
      this.$emit("breadcrumbsLoaded");
    },
    /**
     * Function to attach breadcrumb of mode
     *
     * @param {RouteLocationNormalized} currentRoute : the object of currentRoute
     */
    routeMode(currentRoute: RouteLocationNormalized): void {
      this.routerBreadcrumbs = [];
      const routeMode = this.$t(camelCaseConversion(currentRoute.meta.mode as string));
      const routeName = currentRoute.meta.mode === "job" ? `jobsHome_${this.langCode}` : `admissionsHome_${this.langCode}`;
      this.routerBreadcrumbs.push({ name: routeMode, link: { name: routeName } });
    },
    /**
     * Function to set seo schema
     */
    setSeoSchema(): void {
      const routeName = (this.$route.name as string).split("_")[0];
      const breadCrumbList = [] as SeoBreadcrumbs[];
      if (!(routeName === "eventViewDetails" || routeName === "eventView")) {
        this.routerBreadcrumbs.forEach((ele) => {
          breadCrumbList.push({ name: ele.name, link: this.$router.resolve(ele.link as RouteLocationRaw).href });
        });

        // set data for newsArticle schema
        const newsArticle = { id: `${NOTIFICATION_PLATFORM_URL}${this.$route.fullPath}`, title: breadCrumbList[breadCrumbList.length - 1]?.name };

        const data = { breadCrumbList, newsArticle };
        // get schema
        const schema = generateSeoSchema(routeName, data as DataSetSchema);
        // set schema
        this.$meta.script = [
          {
            json: schema,
            type: "application/ld+json",
          },
        ];
      }
    },
  },
});
