

import {
  Dialog as DialogContainer,
  DialogOverlay,
  Menu as MenuContainer,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from "@/plugins/headlessui";

import {
  ChevronDownIcon,
  MenuIcon,
  XIcon,
  PhotographIcon,
  AdjustmentsIcon,
} from "@/plugins/solidHeroicon";

import { defineComponent, PropType } from "@/plugins/vue";
import { changeToSentenceCase } from "@/helpers/validations";
import { authPromise, getCurrentUser } from "@/plugins/firebase";
import { analyticsSelectItem } from "@/helpers/analyticsEvents";
import HeaderThemeSwitcher from "@/components/Header/HeaderThemeSwitcher.vue";
import LangJSON from "@/assets/json/langCodes.json";
import EventBus from "@/helpers/eventBus";
import { getNamedRouteParams, getNamedRoute } from "@/helpers/routesHandler";
import HeaderAlertDialogBox from "@/components/Header/HeaderAlertDialogBox.vue";
import BellIcon from "@/components/Header/BellIcon.vue";
import { RouteLocationRaw } from "@/plugins/interface";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

// Composed type for prop sidebarNavigation
interface SidebarNavigation { name: string; icon: string; pathName: string; admin: boolean; displayName: string;}

// Composed type for prop sidebarNavigation
interface NavigationRoutes { name: string; path: string; }

export default defineComponent({
  name: "BaseAppShellHeader",
  components: {
    DialogContainer,
    DialogOverlay,
    MenuContainer,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
    PhotographIcon,
    HeaderThemeSwitcher,
    AdjustmentsIcon,
    HeaderAlertDialogBox,
    BellIcon,
  },
  props: {
    /**
     * Sidebar navigation list
     */
    sidebarNavigation: {
      type: Array as PropType<SidebarNavigation[]>,
      default: () => [],
    },
  },
  emits: ["toggleDesktopFilter", "toggleMobileFilter", "showDesktopFilter"],
  data() {
    return {
      user: {
        name: "User",
        imageUrl: null,
      } as { name: string | null; imageUrl: string | null; },
      open: false as boolean,
      enabled: false as boolean,
      mobileMenuOpen: false as boolean,
      organisationList: { name: "organisationList", path: "jobsOrganisationList_en" } as NavigationRoutes,
      universityList: { name: "universityList", path: "admissionsUniversityList_en" } as NavigationRoutes,
      admissionsExamList: { name: "examList", path: "admissionsExamList_en" } as NavigationRoutes,
      jobsExamList: { name: "examList", path: "jobsExamList_en" } as NavigationRoutes,
      navigation: [
        { name: "jobAlert", path: "jobsHome_en" },
        { name: "admissionAlert", path: "admissionsHome_en" },
      ] as NavigationRoutes[],
      itemSelected: "homePage" as string,
      isBellIconClicked: false as boolean,
      showFilterButton: false as boolean,
    };
  },
  computed: {
    /**
     * Computed property to check current link
     */
    currentLink(): string {
      const currentName = (this.$route.name as string)?.split("_")[0];
      const currentNavigation = this.navigation.find((nav) => nav?.path?.split("_")[0] === currentName);
      if (currentNavigation) return currentNavigation.name;
      if (this.jobAdmissionPageType === "job") return this.navigation[0].name;
      return this.navigation[1].name;
    },
    /**
     * Computed property to get selected option from the router
     */
    menuSelected(): SidebarNavigation | undefined {
      const routeName = (this.$route.name as string)?.split("_")[0];
      return this.userSidebarItems.find((nav) => nav.pathName === routeName);
    },
    /**
     * computed property get the sidebar items for user only
     */
    userSidebarItems(): SidebarNavigation[] {
      if (this.isUserSignedIn && this.userKarma >= 25) { return this.sidebarNavigation; }
      return this.sidebarNavigation.filter((item) => item.admin === false);
    },
    /**
     * computed property get the user signed in state
     */
    isUserSignedIn(): boolean {
      return this.$store.getters["user/isUserSignedIn"];
    },

    /**
     * computed property to display the language selected by the user
     */
    languageToShow(): "English" | "हिन्दी" {
      return (this.langCode === LangJSON.primaryLangCode
        ? "हिन्दी" : "English");
    },
    /**
     * Gets lang code from the base module in store
     *
     * @returns {string} - lang code
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed property that returns the page type i.e jo or admission
     */
    jobAdmissionPageType(): string {
      return this.$store.getters["base/getPageType"];
    },
    /**
     * Computed property to get the user karma
     */
    userKarma(): number {
      return this.$store.getters["user/getUserKarma"];
    },
    /**
     * Computed property to get the dark Mode
     */
    darkMode(): boolean {
      return this.$store.getters["base/getDarkMode"];
    },
  },

  watch: {
    /**
     * Watch user signed in state to fetch data after login
     */
    isUserSignedIn() {
      this.displayUserInfo();
    },
    /**
     * Watch on jobAdmissionPageType to change organisation list to university list on the basis of job and admission mode
     */
    jobAdmissionPageType(val) {
      this.setHeaderNavigation(val);
      this.itemSelected = this.menuSelected ? this.menuSelected.pathName : "homePage";
    },
    /**
     * Watch on menuSelected to set the itemSelected
     */
    menuSelected() {
      this.itemSelected = this.menuSelected ? this.menuSelected.pathName : "homePage";
    },
  },
  /**
   * Created hook activities
   * Get Current User Data
   * Check route to change organisation list to university list on the basis of job and admission mode
   */
  created() {
    this.displayUserInfo();
    this.setHeaderNavigation(this.jobAdmissionPageType);
    EventBus.$on("setFiterButtonState", (state: boolean) => {
      this.showFilterButton = state;
      this.$emit("showDesktopFilter", state);
    });
  },
  methods: {
    changeToSentenceCase,
    analyticsSelectItem,
    getNamedRoute,
    /**
     * Function to set header navigation items on the basis of current page type
     *
     * @param {string} type - the current page type - job / admission
     */
    setHeaderNavigation(type: string): void {
      if (type === "job") {
        this.navigation.splice(2, 1, this.organisationList);
        this.navigation.splice(3, 1, this.jobsExamList);
      }
      if (type === "admission") {
        this.navigation.splice(2, 1, this.universityList);
        this.navigation.splice(3, 1, this.admissionsExamList);
      }
    },
    /**
     * Function to change the Language on the button click
     *
     * @param {string} nextLangCode : The next lang code set to the websitE
     */
    changeLanguage(nextLangCode: string): void {
      const convertLangCodes = nextLangCode === "English" ? "en" : "hi";
      const { langCodes } = LangJSON;
      const langCodeIdx = langCodes.indexOf(convertLangCodes);
      this.setRouterLang(langCodes[langCodeIdx] as LangCode);
      window.localStorage.setItem("langCode", langCodes[langCodeIdx]);
    },
    /**
     * Function to set the lang code to the router and i18n locale
     *
     * @param {string} langCode: the lang code to be set
     */
    setRouterLang(langCode: LangCode): void {
      this.$i18n.locale = langCode;
      // ? Sets the current language code in store
      this.$store.commit("base/SET_LANGUAGE_CODE", langCode);
      const routeObject = getNamedRouteParams(langCode, this.$route);
      this.$router.push(routeObject);
    },
    /**
     * Function to logout user
     */
    logout(): void {
      this.mobileMenuOpen = false;
      authPromise.then((authModule) => {
        authModule.getAuth().signOut().then(() => {
          EventBus.$emit("dataLoaded", true);
          if (this.$route.meta.minKarma as number > 0) {
            this.$router.push({ name: `jobsHome_${this.langCode}`, params: { langCode: this.langCode } });
          }
        });
      });
    },
    /**
     * Function to show Firebase Ui Dialogue
     */
    openRegistrationBox() {
      this.$store.commit("base/CHANGE_SIGN_IN_BOX_DISPLAY", true);
    },
    /**
     * Function to go on the selected path
     *
     * @param {Object} event : the Object where is all redirection information is stored
     */
    goToPath(event: Event): void {
      const pathName = (event.target as HTMLSelectElement)?.value;
      if ((["dashboard", "userNotifications", "userSubscriptions"].includes(pathName)) && !this.isUserSignedIn) {
        this.$store.commit("base/CHANGE_SIGN_IN_BOX_DISPLAY", true);
        this.itemSelected = this.menuSelected ? this.menuSelected.pathName : "homePage";
        return;
      }
      if (pathName === "homePage") { this.$router.push({ name: pathName }); return; }
      this.$router.push({ name: this.getNamedRoute(this.langCode, pathName), params: { langCode: this.langCode } });
    },
    /**
     * Gets the route link name object which will redirect to that name page
     *
     * @param {string} path - item containing path name
     * @returns {RouteLocationRaw} - route link object
     */
    getRouteLinkObject(path: string): RouteLocationRaw {
      if (!this.langCode) return { name: "", params: { langCode: "en" } };
      return { name: this.getNamedRoute(this.langCode, path), params: { langCode: this.langCode } };
    },
    /**
     * Reads user info from getCurrentUser function and updates
     * user name and image in header
     */
    displayUserInfo() {
      if (!this.isUserSignedIn) return;

      getCurrentUser().then((res) => {
        if (res != null) {
          this.user.name = res.displayName;
          this.user.imageUrl = res.photoURL;
        }
      });
    },
  },
});

