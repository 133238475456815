
import { BookOpenIcon } from "@/plugins/outlineHeroicon";
import { getTagName } from "@/scripts/workerAdapterConnector";
import { sanitizeRoute, getSeperatedIdAndName } from "@/helpers/routesHandler";
import { defineComponent, PropType } from "@/plugins/vue";
import { StaticFilesLang } from "@/scripts/interface";

// interface for getExamRoute function return value
interface ExamRoute {
  name: string;
  params: {
    langCode: string;
    id: string;
  };
}

export default defineComponent({
  name: "ExamCards",
  components: {
    BookOpenIcon,
  },
  props: {
    examIds: {
      type: Array as PropType<string[]>,
      default: null,
    },
  },
  data() {
    return {
      tagNames: [] as StaticFilesLang[],
      isTagNames: false,
    };
  },
  computed: {
    /**
     * Computed property to get the array of exam Ids
     */
    examIdArray(): string[] {
      return this.examIds;
    },

    /**
     * Computed property to get the current langCode from the store
     */
    langCode(): "en" | "hi" {
      return this.$store.getters["base/getLangCode"];
    },

  },
  /**
   * Get the all exam names from ids on created hook
   */
  created() {
    const ids = this.examIds.map((exam) => getSeperatedIdAndName(exam).id);
    getTagName("exam", ids).then((tagNames) => {
      this.tagNames = tagNames;
      this.isTagNames = true;
    });
  },
  methods: {
    /**
     * Function to get the string data
     *
     * @param {Number} index: the index value of tagName
     * @returns {String} the string value inside the tagName
     */
    getStringData(index: number): string {
      if (!this.isTagNames) return "";
      return this.tagNames[index][this.langCode]?.string || this.tagNames[index].en.string || "";
    },
    /**
     * Function to get the specific exam route on the basis of examId
     *
     * @param {String} examId: the id of exam
     * @param {String} examName: the name of exam
     * @returns {ExamRoute} the named route object
     */
    getExamRoute(examId: string, examName: string): ExamRoute {
      // if mode is job
      if (this.$route.meta.mode === "job") {
        return { name: `jobsSpecificExamPage_${this.langCode}`, params: { langCode: this.langCode, id: sanitizeRoute(`${examId}-${examName}`) } };
      } // else mode is admission
      return { name: `admissionsSpecificExamPage_${this.langCode}`, params: { langCode: this.langCode, id: sanitizeRoute(`${examId}-${examName}`) } };
    },
  },
});
