
import { getTemplateSpecificEventsList, getLandingSpecificEventsList, getTagName } from "@/scripts/workerAdapterConnector";
import { getSeperatedIdAndName, getEventRoutePath } from "@/helpers/routesHandler";
import EventBus from "@/helpers/eventBus";
import generateSeoSchema from "@/helpers/seoSchema";
import { getOrganizationSchema } from "@/helpers/schemaHelpers";
import StaticDataTable from "@/components/Generic/StaticDataTable.vue";
import SearchFilter from "@/components/Search/SearchFilter.vue";
import CircularLoader from "@/components/Generic/CircularLoader.vue";
import { defineComponent, PropType } from "@/plugins/vue";
import {
  Dialog as DialogContainer, DialogOverlay, TransitionChild, TransitionRoot,
} from "@/plugins/headlessui";
import { DESKTOP_FILTER_ID, MOBILE_FILTER_ID } from "@/helpers/constants";
import { DataSetSchema } from "@/helpers/interface";
import {
  EventFilter,
  EventType, SpecificEvent, SpecificTypeEvents, SubFilterType, TagType,
} from "@/scripts/interface";
import { getSpecificTypeEventFilters, getSpecificTypeFilteredEvents } from "@/scripts/searchWorkerAdapterConnector.js";

interface FilterOptionObj {
  id: string;
  text: string;
  count: number;
}

interface FilterOption extends FilterOptionObj {
  selected: boolean;
  disabled: boolean;
}

interface SelectedFilters extends FilterOption {
  label: string;
  nameId: string;
}

interface Filters {
  organisations: FilterOptionObj[];
  exams: FilterOptionObj[];
  tags: { id: string; text: string; type: string; values: FilterOption[]; }[];
}

interface FilteredData {
  id: string;
  nameId: string;
  label: string;
}

interface EventData {
  date: string;
  title: { en: string; hi: string; };
  link: { en: string; hi: string; };
}

interface TagData {
  id: string;
  text: string;
  type: string;
  values: FilterOption[];
}

export default defineComponent({
  name: "LatestUpdatesList",
  components: {
    StaticDataTable,
    SearchFilter,
    DialogContainer,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    CircularLoader,
  },
  props: {
    /**
     * Type of list
     */
    listType: {
      type: String as PropType<SubFilterType | "sarkariResults">,
      default: "",
    },
    /**
     * Meta mode
     */
    mode: {
      type: String,
      default: "",
    },
    /**
     * Search string
     */
    search: {
      type: String,
      default: "",
    },
    /**
     * Boolean indicating is specific list
     */
    isSpecific: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    headers: [] as { text: string; value: string; }[],
    eventList: [] as { date: string; title: string; link: string; }[],
    paginationCount: [500, 1000],
    updatedListType: "",
    dataset: false,
    datalist: [] as EventData[],
    loadingFilters: true,
    tags: [] as TagData[],
    organisations: [] as FilterOption[],
    exams: [] as FilterOption[],
    routeFilter: "",
    filterId: "",
    filterTimer: 0,
  }),
  computed: {
    /**
     * Whether search worker is ready or not
     */
    isSearchReady(): boolean {
      return this.$store.getters["base/getSearchState"];
    },
    /**
     * Gets lang code from the store
     *
     * @returns {string} - lang code
     */
    langCode(): "en" | "hi" {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * returns whether to removeOverlay or not
     */
    removeOverlay(): boolean {
      if (!this.$route.query?.filter) return true;
      if (this.$route.query?.filter && !this.loadingFilters) return true;
      return false;
    },
  },
  /**
   * Watch Hook
   * Get latest events data from worker when the listType changes
   */
  watch: {
    /**
     * Called when search worker gets ready
     */
    isSearchReady(val) {
      if (!val) return;
      this.loadFilters();
    },
    /**
     * Afters filters have been loaded from url information, it loads the filtered events
     */
    loadingFilters(val) {
      if (!val && this.isSearchReady && this.routeFilter) {
        const decodedData = this.decodeStringToData(this.routeFilter);
        this.updateFiltersState(decodedData, false, true);
        this.processFilterQuery();
      }
    },
    /**
     * Whenever listType changes, updates the list data by fetching
     * appropriate data from worker
     */
    listType(val) {
      this.eventList = [];
      this.routeFilter = "";
      this.dataset = false;
      this.getEventData();
    },
    /**
     * Watcher hook to change the data in the table when the language changes
     */
    langCode() {
      this.eventList = [] as { date: string; title: string; link: string; }[];
      this.datalist.forEach((eventItem) => {
        this.eventList.push({ date: eventItem.date, title: eventItem.title[this.langCode], link: eventItem.link[this.langCode] });
      });
    },
  },
  /**
   * Created Hook
   * Set the headers of the table
   * Get latest events data from worker
   * Set the data in the table
   */
  created() {
    EventBus.$emit("setFiterButtonState", true);
    this.filterId = window.matchMedia("(max-width: 1024px)").matches ? MOBILE_FILTER_ID : DESKTOP_FILTER_ID;
    this.setHeaderTitle();
    if (!this.$route.query?.filter) this.getEventData();
    this.routeFilter = this.$route.query?.filter ? this.$route.query.filter as string : "";
    if (this.isSearchReady) this.loadFilters();
  },
  methods: {
    /**
     * Loads the data for filter panel from search worker
     */
    loadFilters(): void {
      this.updatedListType = this.listType === "sarkariResults" ? "latestResults" : this.listType;
      const subfilter = [this.updatedListType];

      if (!this.isSpecific) {
        getSpecificTypeEventFilters(this.$route.meta.mode as string, [], subfilter).then((this.updateFilters));
        return;
      }

      const routeId = getSeperatedIdAndName(this.$route.params.id as string).id;
      const filterType = this.$route.meta.type === "university" ? "organisation" : this.$route.meta.type;
      const filters = [{ type: filterType, id: routeId }];

      getSpecificTypeEventFilters(this.$route.meta.mode as string, filters, subfilter).then(this.updateFilters);
    },
    /**
     * It is a post task for loading filters
     * Updates the filters properties after filters have been fetched from search worker
     */
    updateFilters(filters: Filters): void {
      this.organisations = filters.organisations.map((obj) => ({ ...obj, selected: false, disabled: false }));
      this.exams = filters.exams.map((obj) => ({ ...obj, selected: false, disabled: false }));
      this.tags = filters.tags.map((item) => ({ ...item, values: item.values.map((obj) => ({ ...obj, selected: false, disabled: false })) }));
      this.loadingFilters = false;
    },
    /**
     * Function to clear the previous timeout and set a
     * new timeout for making a call to the processFilterQuery function
     */
    filterUpdate(): void {
      clearTimeout(this.filterTimer);
      this.filterTimer = window.setTimeout(this.processFilterQuery, 500);
    },
    /**
     * It creates the payload to send to search worker for getting results
     * according to the selected filters
     */
    processFilterQuery(): void {
      const examFilter = this.exams.filter((exam) => exam.selected).map((item) => ({ ...item, label: "Exam", nameId: "" }));
      const organisationFilter = this.organisations
        .filter((organisation) => organisation.selected).map((item) => ({ ...item, label: "Organisation", nameId: "" }));
      const filterQuery = [...examFilter, ...organisationFilter];
      this.tags.forEach((tag) => {
        tag.values.forEach((value) => {
          if (value.selected) {
            filterQuery.push({
              id: value.id, nameId: tag.id, label: tag.type, text: "", count: 0, selected: false, disabled: false,
            });
          }
        });
      });
      this.setSearchFilter(filterQuery);
      if (!filterQuery.length) {
        this.getEventData();
        return;
      }
      const arr = filterQuery.map((item) => {
        if (item.label === "Organisation" || item.label === "Exam") return item.id;
        return `${item.nameId}-${item.id}`;
      });
      getSpecificTypeFilteredEvents(arr, 2000).then((searchResults) => {
        this.eventList = [];
        this.datalist = [];
        searchResults.forEach((eventItem) => {
          const { date, title, link } = this.retrieveEventData(eventItem);
          this.eventList.push({ date, title: title[this.langCode], link: link[this.langCode] });
          this.datalist.push({ date, title, link });
        });
        this.dataset = true;
        EventBus.$emit("dataLoaded", true);
      });
    },
    getEventRoutePath,

    /**
     * Get Latest Events on basis of page type
     */
    getEventData(): void {
      this.updatedListType = this.listType === "sarkariResults" ? "latestResults" : this.listType;
      const subfilter = [this.updatedListType];
      if (!this.isSpecific) {
        /**
         * Grab Data from eventDataStore,
         * Filter till count param,
         * Fill the latest Event Object from jsonData and return
         *
         * @param {String} eventType - {recuitment/admission} - event mode
         * @param {Number} count - Number Of Items to Send
         */

        getLandingSpecificEventsList(this.$route.meta.mode as EventType, subfilter as SubFilterType[], 2000).then((latestEvents: SpecificTypeEvents) => {
          this.eventList = [];
          this.datalist = [];
          if (this.updatedListType) {
            latestEvents[this.updatedListType as keyof typeof latestEvents].forEach((eventItem) => {
              const { date, title, link } = this.retrieveEventData(eventItem);
              this.eventList.push({ date, title: title[this.langCode], link: link[this.langCode] });
              this.datalist.push({ date, title, link });
            });
          }
          this.dataset = true;
          EventBus.$emit("dataLoaded", true);

          // Emit dataForScroll event to load scroll bar
          this.$nextTick().then(() => EventBus.$emit("dataForScroll"));
        });
      } else {
        /**
       * Retrieve events from latestEventsData based on event type and filter
       * by category id's
       * return Object
       * @param {String} id - id of the category (university, organisation, exam etc)
       * @param {String} eventType - type of event (job / admission)
       * @param {string} pageType - Page from where function was invoked (category, )
       */
        const routeId = getSeperatedIdAndName(this.$route.params.id as string).id;
        const filterType = this.$route.meta.type === "university" ? "organisation" : this.$route.meta.type as string;
        const filters = [{
          type: filterType,
          id: routeId,
        }];

        if (["organisation", "university"].includes(filterType)) {
          getTagName(filterType as TagType, [routeId]).then((tagArray) => {
            if (tagArray[0]) {
              const title = tagArray[0][this.langCode]?.string || tagArray[0].en.string;
              this.setSeoSchema(title);
            }
          });
        }
        getTemplateSpecificEventsList(
          this.$route.meta.mode as EventType,
          filters as EventFilter[],
          subfilter as SubFilterType[],
          2000,
        ).then((specificLatest) => {
          this.eventList = [];
          this.datalist = [];
          (specificLatest[this.updatedListType as keyof typeof specificLatest] as []).forEach((eventItem) => {
            const { date, title, link } = this.retrieveEventData(eventItem);
            this.eventList.push({ date, title: title[this.langCode], link: link[this.langCode] });
            this.datalist.push({ date, title, link });
          });
          this.dataset = true;
          EventBus.$emit("dataLoaded", true);
          // Emit dataForScroll event to load scroll bar
          this.$nextTick().then(() => EventBus.$emit("dataForScroll"));
        });
      }
    },
    /**
     * Function to set the header title of the dataTable according to the type of template viewed
     */
    setHeaderTitle(): void {
      this.updatedListType = this.listType === "sarkariResults" ? "latestResults" : this.listType;
      this.headers = [
        { text: "s.No", value: "sno" },
        { text: "eventTitle", value: "title.en" },
      ];
      if (["lastDateSoon", "latestNotifications", "latestFellowships", "latestScholarships"].includes(this.updatedListType)) {
        this.headers.push({ text: "lastDate", value: "lastUpdated" });
      } else if (["latestAdmitCard"].includes(this.updatedListType)) {
        this.headers.push({ text: "admitCardDate", value: "lastUpdated" });
      } else if (["latestResults"].includes(this.updatedListType)) {
        this.headers.push({ text: "resultDate", value: "lastUpdated" });
      } else if (["latestWalkInInterview", "latestInterview"].includes(this.updatedListType)) {
        this.headers.push({ text: "startDate", value: "lastUpdated" });
      }
    },

    /**
     * Function to set seo schema for organization pages
     */
    async setSeoSchema(title: string): Promise<void> {
      const organization = await getOrganizationSchema(this.$route, title, this.$router);

      // get schema
      const routeName = (this.$route.name as string).split("_")[0];
      const schema = generateSeoSchema(routeName, { organization } as DataSetSchema);
      const mainObj = {} as { [key: string]: object; };

      // filter schema
      schema["@graph"]?.forEach((obj) => { mainObj[obj["@type" as keyof typeof obj] as keyof typeof mainObj] = obj; });
      this.$meta.script[0].json["@graph"].forEach((obj: object) => { mainObj[obj["@type" as keyof typeof obj] as keyof typeof mainObj] = obj; });
      schema["@graph"] = Object.values(mainObj);

      // set schema
      this.$meta.script = [
        {
          json: schema,
          type: "application/ld+json",
        },
      ];
    },
    /**
     * Function to recive an event object which is destructerd and to retrun event data
     *
     * @param {SpecificEvent} event - object which contains event info
     * @returns {EventData} - filters and destructures the event data and sends it back
     */
    retrieveEventData(event: SpecificEvent): EventData {
      if (!event) return { date: "", title: { en: "", hi: "" }, link: { en: "", hi: "" } };
      const date = this.$filters.timestampToDate(event.valueTimestamp);
      const title = {
        en: event.title.en,
        hi: event.title.hi,
      };
      const link = {
        en: this.$router.resolve(getEventRoutePath(event, "en", "eventView", this.$route)).href,
        hi: this.$router.resolve(getEventRoutePath(event, "hi", "eventView", this.$route)).href,
      };
      return { date, title, link };
    },
    /**
     * Updates the filters in url
     * @param {SelectedFilters[]} selectedFilters
     */
    setSearchFilter(selectedFilters: SelectedFilters[]): void {
      const url = new URL(document.location.href);
      const params = new URLSearchParams(url.search);
      const filteredData = selectedFilters.map((item) => {
        if (item.label === "Exam" || item.label === "Organisation") return { id: item.id, label: item.label, nameId: "" };
        return { id: item.id, nameId: item.nameId, label: "" };
      });
      if (!filteredData.length) {
        params.delete("filter");
        this.routeFilter = "";
      } else {
        this.routeFilter = this.encodeDataToString(filteredData);
        params.set("filter", this.routeFilter);
      }
      this.$router.push(`${url.pathname}?${params}`);
    },
    /**
     * Encodes parameter obj to string using btoa
     * @param {FilteredData[]} obj  array of object to encode
     * @returns {string} encoded string
     */
    encodeDataToString(obj: FilteredData[]): string {
      return btoa(JSON.stringify(obj));
    },
    /**
     * Decodes parameter str to obj using atob
     * @param {string} str string to decode
     * @returns {FilteredData[]} decoded array of object
     */
    decodeStringToData(str: string): FilteredData[] {
      return JSON.parse(atob(str));
    },
    /**
     * Updates filters disabled and checked state
     * @param { FilteredData[] } filters array of filters
     * @param {boolean} disabledTags whether to disable tags
     * @param {boolean} checkTags whether to disable tags
     */
    updateFiltersState(filters: FilteredData[], disableTags = true, checkTags = true): void {
      filters.forEach((item) => {
        let foundItem;
        if (item.label === "Organisation") foundItem = this.organisations.find((organisation) => organisation.id === item.id);
        else if (item.label === "Exam") foundItem = this.exams.find((exam) => exam.id === item.id);
        else if (item.label === "Event") return;
        else foundItem = this.tags.find((tag) => tag.id === item.nameId)?.values.find((value) => value.id === item.id);
        if (disableTags && foundItem) foundItem.disabled = true;
        if (checkTags && foundItem) foundItem.selected = true;
      });
    },
  },
});
