
import { getJSONDataIds } from "@/helpers/staticDataParser";
import { getTagName } from "@/scripts/workerAdapterConnector";
import { getHomeRoutePath } from "@/helpers/routesHandler";
import getAssetAPI from "@/api/assetsAPI";
import { defineComponent } from "@/plugins/vue";
import { RouteObject } from "@/helpers/interface";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

interface TagName {
  en: {
    string: string;
  };
  hi: {
    string: string;
    roman: string;
  };
}

export default defineComponent({
  name: "TopOrganisations",
  data() {
    return {
      organisationIds: [] as string[],
      title: "",
      description: "",
      pageType: "",
      staticFileName: "" as "organisation" | "university",
      apiObject: "",
      tagNames: [] as TagName[],
      isTagNames: false,
      iscreated: false,
    };
  },
  computed: {
    /**
     * Gets lang code from base store module
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Gets the path name on basis on the route meta module
     */
    pathName(): string {
      return this.$route.meta.mode === "job" ? "jobsSpecificOrganisationPage_en" : "admissionsSpecificUniversityPage_en";
    },
  },
  watch: {
    /**
     * Watcher to watch for changes in route
     *
     * @param {object} newRoute - the current new route
     * @param {object} oldRoute - the previous route
     */
    $route(newRoute, oldRoute) {
      const oldRouteName = oldRoute.name.split("_")[0];
      const newRouteName = newRoute.name.split("_")[0];
      if (!["jobsHome", "admissionsHome"].includes(newRouteName)) return;
      if (oldRouteName !== newRouteName) {
        this.iscreated = false;
        this.isTagNames = false;
        this.updateTitleAndLink();
      }
    },
  },
  created() {
    this.updateTitleAndLink();
    this.apiObject = getAssetAPI("ACCESS_LOGO")?.url || "";
  },
  methods: {
    /**
    * Function to get the route path of homePage icons
    *
    * @param {string} id - id of the item
    * @param {number} index - index at which the item is present
    * @return {RouteObject} route path
    */
    setHomeRoutePath(id: string, index: number): RouteObject {
      if (!this.tagNames[index]) return { name: "", params: { langCode: this.langCode, id: "" } };
      const path = getHomeRoutePath(this.tagNames, id, index, this.langCode, this.pathName, this.$route);
      return path;
    },
    getTagName,
    /**
     * Function updates the title,page type,and static file name
     */
    updateTitleAndLink(): void {
      this.title = this.$route.meta.mode === "job" ? "organisations" : "institutions";
      this.description = this.$route.meta.mode === "job" ? "topOrganisationsDescription" : "topInstitutionsDescription";
      this.pageType = this.$route.meta.mode === "job" ? "organisation" : "university";
      this.staticFileName = this.$route.meta.mode === "job" ? "organisation" : "university";
      this.organisationIds = getJSONDataIds(this.staticFileName);
      if (!this.iscreated) {
        getTagName(this.staticFileName, [...this.organisationIds]).then((tagNames) => {
          this.tagNames = tagNames as TagName[];
          this.isTagNames = true;
          this.iscreated = true;
        });
      }
    },
    /**
     * Function to get the string data
     *
     * @param {number} index: the index value of tagName
     * @returns {string} the string value inside the tagName
     */
    getStringData(index: number): string {
      if (!this.isTagNames) return "";
      return this.tagNames?.[index]?.[this.langCode]?.string || this.tagNames?.[index]?.en?.string;
    },
  },
});
