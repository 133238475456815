import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_snackbar = _resolveComponent("action-snackbar")!

  return (_openBlock(), _createBlock(_component_action_snackbar, {
    show: _ctx.isVisible,
    icon: _ctx.bellIcon,
    title: _ctx.$t('examPathFinder'),
    message: _ctx.$t('allowNotificationsMessage'),
    "action-text": _ctx.$t(`allowNotifications`),
    onActionSelect: _ctx.requestPermission,
    onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isVisible = false))
  }, null, 8, ["show", "icon", "title", "message", "action-text", "onActionSelect"]))
}