import {
  axiosCallWithHeaders,
  axiosCallWithoutHeaders,
  axiosCallWithCredentials,
} from "@/plugins/axiosInstances";
import getAlertsAPI from "@/api/alertsAPI";
import getAssetAPI from "@/api/assetsAPI";
import { getCookie } from "@/helpers/cookie";
import uuidv4 from "@/plugins/uuid";
import { getCurrentUser } from "@/plugins/firebase";

export default {
  /**
   * Asynchronous function to create a new event
   *
   * @param {*} root0 - commit
   * @param {*} root0.commit - commit: used for committing the mutations
   * @param {object} payload - payload for API call, contains the event data
   * @returns {object} data - An id for the event created if execution is successful
   */
  async addEvent({ commit }, payload) {
    const payloadObj = payload;
    if (payload.title_roman.hi === "") delete payloadObj.title_roman.hi;
    if (payload.title.hi === "") delete payloadObj.title.hi;
    if (payload.description.hi === "") delete payloadObj.description.hi;
    const api = getAlertsAPI("ADD_EVENT");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(
      api.url,
      { ...payload },
      { API_ID: api.id },
    );
    return data;
  },

  /**
   * Asynchronous function to read a specific event data on the basis of route meta and karma
   * (Read original event data or cached event data,
   * decision will be made on the basis of user karma or route meta)
   *
   * @param {*} root0 - commit
   * @param {*} root0.commit - commit: used for committing the mutations
   * @param {*} root0.rootGetters - used to access any of the getters present in store
   * @param {string} eventID - Id of the specific event
   * @param {boolean} mode - Mode recieved from route meta
   * @returns {object} data - The event data object
   */
  async readEvent({ commit, rootGetters }, { eventID, mode }) {
    const api = getAlertsAPI("READ_EVENT");
    const axiosInstance = await axiosCallWithoutHeaders();
    let promiseObject = {};
    const userKarma = rootGetters["user/getUserKarma"];
    if (mode === "edit" || userKarma >= 25) {
      const timeStamp = Date.now();
      promiseObject = axiosInstance.get(
        `${api.url}?eventId=${eventID}&ts=${timeStamp}`,
        { API_ID: api.id },
      );
    } else {
      promiseObject = axiosInstance.get(`${api.url}?eventId=${eventID}`, {
        API_ID: api.id,
      });
    }
    const { data } = await promiseObject;
    return data;
  },

  /**
   * Asynchronous function to update the event data and return the response from the API
   *
   * @param {*} root0 - commit
   * @param {*} root0.commit - commit: used for committing the mutations
   * @param {object} payload - payload for API call, contains event data
   * @returns {string} data = 'saved', if execution was successful
   */
  async updateEvent({ commit }, payload) {
    const payloadObj = payload;
    if (payload.title_roman.hi === "") delete payloadObj.title_roman.hi;
    if (payload.title.hi === "") delete payloadObj.title.hi;
    if (payload.description.hi === "") delete payloadObj.description.hi;
    const api = getAlertsAPI("UPDATE_EVENT");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(
      api.url,
      { ...payload },
      { API_ID: api.id },
    );
    return data;
  },

  /**
   * Asynchronous function to upload attachments
   *
   * @param {*} state - state of store
   * @param {object} payload - {file, isLogo, organisationId} -The payload for upload attachments
   * @returns {object} fileAttachInfo - Information about attached file
   */
  async uploadAttachments({ state }, payload) {
    if (!payload.file) return null;
    const epfCookieInstance = getCookie("EPFA");
    let link;
    let fileAttachInfo;
    if (!epfCookieInstance) {
      throw new Error("Access problem");
    }
    // TODO improve atob below
    const epf = JSON.parse(atob(epfCookieInstance));
    const uuid = uuidv4();
    const nameSplit = payload.file.name.split(".");
    const extension = nameSplit[nameSplit.length - 1];
    if (payload.isLogo) {
      link = `epf_n_attachments/organisation/${payload.orgId}/logo.png`;
    } else {
      link = `epf_n_attachments/${uuid}.${extension}`;
    }
    // store and return fileInfo on success
    if (!payload.isLogo) {
      fileAttachInfo = {
        fileName: payload.file.name,
        fileState: "show",
        fileLink: `${uuid}.${extension}`,
      };
    } else {
      fileAttachInfo = `${payload.orgId}/logo.png`;
    }
    const form = new FormData();
    const names = Object.keys(epf);

    names.forEach((name) => form.append(name, epf[name]));
    const user = await getCurrentUser();
    form.append("key", `public/${link}`);
    form.append("acl", "private");
    // form.append('Content-Type', 'application/json')
    form.append("Cache-Control", "public, max-age=86400");
    form.append("x-amz-meta-uuid", user.uid);
    form.append("file", payload.file);
    const api = getAssetAPI("UPLOAD_FILE_ATTACHMENTS");
    const axiosInstance = await axiosCallWithCredentials();
    const response = await axiosInstance.post(api.url, form, {
      API_ID: api.id,
    });
    return fileAttachInfo;
  },

  /**
   * Asynchronous functionn to add notification of the event
   *
   */
  async appendNotification({ commit }, payload) {
    const api = getAlertsAPI("APPEND_NOTIFICATION");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(
      api.url,
      { ...payload },
      { API_ID: api.id },
    );
    return data;
  },

  /**
   * Asynchronous functionn to Edit notification of the event
   *
   */
  async editNotification({ commit }, payload) {
    const api = getAlertsAPI("EDIT_NOTIFICATION");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(
      api.url,
      { ...payload },
      { API_ID: api.id },
    );
    return data;
  },
  /**
   * Function to subscribe and unsubscribe the event and exam
   *
   * @param {*} param0 - commit
   * @param {object} payload - object of exam and event ids and mode i.e  subscribe and unsubscribe
   * @returns {object} - object of event ids and exam ids
   */
  async subscribeEvent({ commit }, payload) {
    // TODO: Validations will be done later for data keys at the backend
    const api = getAlertsAPI("USER_SUBSCRIPTION");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.get(api.url, { params: payload }, { API_ID: api.id });
    return data;
  },
  /**
   * Function get the subscribed event ids and exam ids by the user
   *
   * @returns {object} - object containing exam ids and event ids
   */
  async fetchSubscriptionList({ state, commit }) {
    state.isSubscribedDataFetched = false;
    const api = getAlertsAPI("USER_SUBSCRIPTION");
    const subscriptionListPayoad = { mode: "list" };
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.get(api.url, { params: subscriptionListPayoad }, { API_ID: api.id });
    commit("SET_SUBSCRIBED_EVENTS", data.body);
    state.isSubscribedDataFetched = true;
  },
};
