import error from "@/views/ErrorPage.vue";
import homePage from "@/views/HomePage.vue";
import latestUpdates from "@/views/LatestUpdates.vue";
import specificPageList from "@/views/SpecificPageList.vue";
import specificPageEventList from "@/views/SpecificPageEventList.vue";
import search from "@/views/SearchPage.vue";
import eventView from "@/views/EventView.vue";
import eventDetails from "@/views/EventViewDetails.vue";
import dashboard from "@/views/Dashboard.vue";
import subscriptionList from "@/views/SubscriptionList.vue";
import user from "@/views/User.vue";
import userProfile from "@/views/UserProfile.vue";
import { redirectToSpecificPath } from "@/helpers/routesHandler";
import RouterViewMetaRefresh from "@/components/Shared/RouterViewMetaRefresh.vue";
import notificationList from "@/views/NotificationList.vue";
import { RouterView } from "@/plugins/vueRouter";

export default [
  // ----------------  404: Not Found Page -------------------
  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
  {
    path: "/:langCode(en)/404",
    name: "notFound_en",
    component: error,
    meta: {
      alwaysRender: true,
      minKarma: 0,
    },
  },

  // ----------------------- Home page -----------------------
  // ? For redirect refer: https://www.digitalocean.com/community/tutorials/vuejs-advanced-vue-routing#redirects
  {
    path: "/",
    name: "homePage",
    meta: {
      minKarma: 0,
    },
    redirect: () => redirectToSpecificPath("jobsHomePage"),
  },

  // --------- Catch All route, redirect to 404:Not Found Page ---------------
  // * regex updated to /:pathMatch(.*)*  -> any match other than defined
  {
    path: "/:pathMatch(.*)*",
    name: "catchAll",
    redirect: () => redirectToSpecificPath("404"),
  },

  // ------------------------ Landing Page(en) ----------------------
  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
  {
    path: "/:langCode(en)",
    name: "landingPage_en",
    component: RouterView,
    meta: {
      minKarma: 0,
    },
    children: [
      // -------------------------- Landing Home Page  ----------------------------------
      {
        path: "",
        name: "landingHome_en",
        meta: {
          minKarma: 25,
        },
        redirect: { name: "jobsHome_en" },
      },

      // * ---------------------------- System Specific Pages --------------------------- *
      // ----------------  Dashboard -------------------
      {
        path: "dashboard",
        name: "dashboard_en",
        meta: {
          minKarma: 1,
        },
        component: dashboard,
      },

      // ----------------  User Profile -------------------
      {
        path: "user-profile",
        name: "user_en",
        component: user,
        alias: ["user"],
        meta: {
          minKarma: 1,
          pageType: "userProfilePage",
        },
        children: [
          {
            path: "",
            name: "userProfile_en",
            component: userProfile,
          },
        ],
      },

      //  ----------------  User Subscriptions -------------------
      {
        path: "subscriptions",
        name: "userSubscriptions_en",
        meta: {
          minKarma: 1,
        },
        component: subscriptionList,
      },
      //  ----------------  User Notifications -------------------
      {
        path: "notifications",
        name: "userNotifications_en",
        meta: {
          minKarma: 1,
        },
        component: notificationList,
      },

      // * ---------------------------- Search Pages --------------------------- *
      // ----------------  Search Page -------------------
      {
        path: "search",
        name: "search_en",
        component: RouterView,
        children: [
          //  ----------------  Search Home Page -------------------
          {
            path: "",
            name: "searchHome_en",
            meta: {
              minKarma: 0,
              pageType: "searchPage",
            },
            component: search,
          },
        ],
      },

      // ? ############################# Specific Landing Pages ###############################
      // * ---------------------------- Jobs Landing Page --------------------------- *
      {
        path: "govt-jobs",
        name: "jobs_en",
        alias: ["recruitment"],
        component: RouterView,
        children: [
          // +++++++++++++++++++++++++++++++++ Jobs Home Page +++++++++++++++++++++++++++++++
          {
            path: "",
            name: "jobsHome_en",
            component: homePage,
            meta: {
              minKarma: 0,
              mode: "job",
              pageType: "landingPage",
            },
          },

          // +++++++++++++++++++++++++++++++++ Jobs Specific Pages +++++++++++++++++++++++++++++++
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
            name: "jobsLatestUpdates_en",
            component: latestUpdates,
            meta: {
              minKarma: 0,
              mode: "job",
              pageType: "latestUpdatePage",
            },
          },

          // +++++++++++++++++++++++++++++++++ Jobs Specific Pages - Static Data +++++++++++++++++++++++++++++++
          // TODO build these pages as per Event Type: job or admission (Institution <=> Organisation)
          // +++++++++++++++++++++++++++++++++ Jobs Organisation Pages +++++++++++++++++++++++++++++++
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "organisation",
            name: "jobsOrganisation_en",
            alias: ["organisations"],
            component: RouterView,
            children: [
              // ------------------------ Jobs Organisation Home Page----------------------
              {
                path: "",
                name: "jobsOrganisationHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsOrganisationList_en" },
              },
              // +++++++++++++++++++++++++++++++++ Jobs Organisation List +++++++++++++++++++++++++++++++
              {
                path: "list",
                name: "jobsOrganisationList_en",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "job",
                  type: "organisation",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Jobs Specific Organisation Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              /**
              * ! :id-:name approach cant be used for schema as it requires the hyphen after id ,
              * ! and it is mandatory, if not provided, user gets redirected to 404 page as schema is not validated.
              * ! SAME for all id pages
              */
              {
                path: ":id",
                name: "jobsSpecificOrganisation_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificOrganisationPage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "organisation",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsOrganisationLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "organisation",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission
          // +++++++++++++++++++++++++++++++++ Jobs Exam Pages +++++++++++++++++++++++++++++++
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "exam",
            name: "jobsExam_en",
            alias: ["exams"],
            component: RouterView,
            children: [
              // ------------------------ Jobs Exam Home Page----------------------
              {
                path: "",
                name: "jobsExamHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsExamList_en" },
              },
              // +++++++++++++++++++++++++++++++++ Jobs Exam List +++++++++++++++++++++++++++++++
              {
                path: "list",
                name: "jobsExamList_en",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "job",
                  type: "exam",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Jobs Specific Exam Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificExam_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificExamPage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "exam",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsExamLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "exam",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission
          // ------------------------ Jobs State Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "state",
            name: "jobsState_en",
            alias: ["states"],
            component: RouterView,
            children: [
              // ------------------------ Jobs State Home Page----------------------
              {
                path: "",
                name: "jobsStateHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsStateList_en" },
              },
              // +++++++++++++++++++++++++++++++++ Jobs State List +++++++++++++++++++++++++++++++
              {
                path: "list",
                name: "jobsStateList_en",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "job",
                  type: "state",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Jobs Specific State Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificState_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificStatePage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "state",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsStateLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "state",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // ------------------------ Jobs Educational Category Pages ----------------------
          {
            path: "qualification",
            name: "jobsQualification_en",
            alias: ["qualifications"],
            component: RouterView,
            children: [
              // ------------------------ Jobs Qualification Home Page----------------------
              {
                path: "",
                name: "jobsQualificationHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsHome_en" },
              },
              // ------------------------ Jobs Specific Educational Category Page ----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificQualification_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificQualificationPage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "qualification",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsQualificationLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "qualification",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // ------------------------ Jobs Specific Category Page ----------------------
          {
            // Suggested path change from category
            path: "exam-category",
            name: "jobsCategory_en",
            alias: ["category"],
            component: RouterView,
            children: [
              // ------------------------ Jobs State Home Page----------------------
              {
                path: "",
                name: "jobsCategoryHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsHome_en" },
              },
              // ------------------------ Jobs Specific Category Page ----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificCategory_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificCategoryPage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "category",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsCategoryLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "category",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

        ],
      },

      // ? ############################# Admissions Specific Pages ###############################
      // * ----------------------------- Admissions Landing Page --------------------------- *
      {
        path: "admissions",
        name: "admissions_en",
        alias: ["admission"],
        component: RouterView,
        children: [
          // +++++++++++++++++++++++++++++++++ Admissions Home Page +++++++++++++++++++++++++++++++
          {
            path: "",
            name: "admissionsHome_en",
            component: homePage,
            meta: {
              minKarma: 0,
              mode: "admission",
              pageType: "landingPage",
            },
          },

          // +++++++++++++++++++++++++++++++++ Admissions Specific Pages +++++++++++++++++++++++++++++++
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-scholarships|last-date-soon|latest-interview)",
            name: "admissionsLatestUpdates_en",
            component: latestUpdates,
            meta: {
              minKarma: 0,
              mode: "admission",
              pageType: "latestUpdatePage",
            },
          },

          // +++++++++++++++++++++++++++++++++ Admissions Specific Pages - Static Data +++++++++++++++++++++++++++++++
          // ------------------------ Admissions Educational Category Pages ----------------------
          {
            path: "qualification",
            name: "admissionsQualification_en",
            alias: ["qualifications"],
            component: RouterView,
            children: [
              // ------------------------ Admissions Qualification Home Page----------------------
              {
                path: "",
                name: "admissionsQualificationHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsHome_en" },
              },
              // ------------------------ Admissions Specific Educational Category Page ----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificQualification_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificQualificationPage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "qualification",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsQualificationLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "qualification",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // ------------------------ Admissions Stream Pages ----------------------
          {
            path: "stream",
            name: "admissionsStream_en",
            alias: ["streams"],
            component: RouterView,
            children: [
              // ------------------------ Admissions Stream Home Page----------------------
              {
                path: "",
                name: "admissionsStreamHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsHome_en" },
              },
              // ------------------------ Admissions Specific Stream Page ---------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificStream_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificStreamPage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "stream",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsStreamLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "stream",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission (University <=> Organisation)
          // ------------------------ Admissions Institution Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            // Name was suggested to be kept as institution instead of university
            path: "institution",
            name: "admissionsUniversity_en",
            alias: ["university", "universities", "institutions"],
            component: RouterView,
            children: [
              // ------------------------ Admissions University Home Page----------------------
              {
                path: "",
                name: "admissionsUniversityHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsUniversityList_en" },
              },
              // +++++++++++++++++++++++++++++++++ Admissions University List +++++++++++++++++++++++++++++++
              {
                path: "list",
                name: "admissionsUniversityList_en",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "admission",
                  type: "university",
                  pageType: "tagListPage",
                },
              },
            ],
          },
          // ------------------------ Admissions Organisation Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            // Name was suggested to be kept as institution instead of university
            path: "organisation",
            name: "admissionsOrganisation_en",
            alias: ["university", "universities", "organisations", "institutions"],
            component: RouterView,
            children: [
              // ------------------------ Admissions Organisation Home Page----------------------
              {
                path: "",
                name: "admissionsOrganisationHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsUniversityList_en" },
              },
              // ------------------------ Admissions Specific Organisation Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificUniversity_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificUniversityPage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "university",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsUniversityLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "university",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission
          // ------------------------ Admissions Exam Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "exam",
            name: "admissionsExam_en",
            alias: ["exams"],
            component: RouterView,
            children: [
              // ------------------------ Admissions Exam Home Page----------------------
              {
                path: "",
                name: "admissionsExamHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsExamList_en" },
              },
              // +++++++++++++++++++++++++++++++++ Admissions Exam List +++++++++++++++++++++++++++++++
              {

                path: "list",
                name: "admissionsExamList_en",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "admission",
                  type: "exam",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Admissions Specific Exam Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificExam_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificExamPage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "exam",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsExamLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "exam",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission
          // ------------------------ Admissions State Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "state",
            name: "admissionsState_en",
            alias: ["states"],
            component: RouterView,
            children: [
              // ------------------------ Admissions State Home Page----------------------
              {
                path: "",
                name: "admissionsStateHome_en",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsStateList_en" },
              },
              // +++++++++++++++++++++++++++++++++ Admissions State List +++++++++++++++++++++++++++++++
              {

                path: "list",
                name: "admissionsStateList_en",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "admission",
                  type: "state",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Admissions Specific Exam Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificState_en",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificStatePage_en",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "state",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|latest-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsStateLatestUpdates_en",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "state",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      // ? ############################# EPF Admin Pages ###############################
      //  ----------------  Admin Location pages -------------------
      {
        path: "location",
        name: "adminLocation_en",
        component: RouterView,
        children: [
          // ------------------------ Admin Location Home ----------------------
          {
            path: "",
            name: "locationHome_en",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "locationList_en" },
          },
          {
            path: "list",
            name: "locationList_en",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/LocationList.vue"),
          },
        ],
      },
      // * ---------------------------- Admin Event Pages --------------------------- *
      {
        path: "event",
        name: "adminEvent_en",
        alias: ["events"],
        component: RouterView,
        children: [
          // ------------------------ Admin Event Home ----------------------
          {
            path: "",
            name: "eventHome_en",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "eventList_en" },
          },
          // ------------------------ Admin Event List ----------------------
          {
            path: "list",
            name: "eventList_en",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/EventList.vue"),
          },
          // * ---------------------------- User and Admin Event View Page --------------------------- *
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "view/:id",
            name: "eventView_en",
            component: eventView,
            meta: {
              minKarma: 0,
            },
            children: [
              {
                path: "",
                name: "eventViewDetails_en",
                meta: {
                  mode: "view",
                },
                component: eventDetails,
              },
            ],
          },
          // ------------------------ Admin Event Create ----------------------
          {
            path: "create",
            name: "eventCreator_en",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdate.vue"),
            children: [
              {
                path: "",
                name: "eventCreate_en",
                meta: {
                  mode: "create",
                },
                component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdateDetails.vue"),
              },
            ],
          },
          {
            path: "check",
            name: "eventCheck_en",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/DuplicateCheck.vue"),
          },
          // ------------------------ Admin Event and Status Update ----------------------
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "edit/:id",
            name: "eventUpdater_en",
            component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdate.vue"),
            meta: {
              minKarma: 25,
            },
            children: [
              {
                path: "",
                name: "eventEdit_en",
                meta: {
                  mode: "edit",
                },
                component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdateDetails.vue"),
              },
              {
                path: "status",
                name: "eventEditStatus_en",
                meta: {
                  mode: "edit",
                },
                component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdateStatus.vue"),
              },
            ],
          },
        ],
      },

      // * ---------------------------- Admin Field Name and Field Value Pages --------------------------- *
      {
        path: "fieldname",
        name: "fieldName_en",
        alias: ["fieldnames"],
        component: RouterView,
        children: [
          // ------------------------ Admin Field Home ----------------------
          {
            path: "",
            name: "fieldHome_en",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "fieldList_en" },
          },
          // ------------------------ Admin Field Name List ----------------------
          {
            path: "list",
            name: "fieldList_en",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/FieldNameList.vue"),
          },
          // ------------------------ Admin Field Name Value List ----------------------
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "view/:id",
            name: "fieldValueList_en",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/FieldNameValueList.vue"),
          },
        ],
      },

      // * ---------------------------- Admin Exams Pages --------------------------- *
      {
        path: "exam",
        name: "adminExam_en",
        alias: ["exams"],
        component: RouterView,
        children: [
          // ------------------------ Admin Exams Home ----------------------
          {
            path: "",
            name: "examHome_en",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "examList_en" },
          },
          // ------------------------ Admin Exams List ----------------------
          {
            path: "list",
            name: "examList_en",
            meta: {
              minKarma: 25,
              tagType: "exam",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTagList.vue"),
          },
          // ------------------------ Admin Exam Create ----------------------
          {
            path: "create",
            name: "examCreate_en",
            meta: {
              minKarma: 50,
              tagType: "exam",
              mode: "create",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTag.vue"),
          },
          // ------------------------ Admin Exam Update ----------------------
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "edit/:id",
            name: "examEdit_en",
            meta: {
              minKarma: 50,
              tagType: "exam",
              mode: "edit",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTag.vue"),
          },
        ],
      },

      // * ---------------------------- Admin Organisation Pages --------------------------- *
      {
        path: "organisation",
        name: "adminOrganisation_en",
        alias: ["organisations"],
        component: RouterView,
        children: [
          // ------------------------ Admin Organisations Home ----------------------
          {
            path: "",
            name: "organisationHome_en",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "organisationList_en" },
          },
          // ------------------------ Admin Organisations List ----------------------
          {
            path: "list",
            name: "organisationList_en",
            meta: {
              minKarma: 25,
              tagType: "organisation",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTagList.vue"),
          },

          // ------------------------ Admin Organisation Create ----------------------
          {
            path: "create",
            name: "organisationCreate_en",
            meta: {
              minKarma: 50,
              tagType: "organisation",
              mode: "create",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTag.vue"),
          },
          // ------------------------ Admin Organisation Update ----------------------
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "edit/:id",
            name: "organisationEdit_en",
            meta: {
              minKarma: 50,
              tagType: "organisation",
              mode: "edit",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTag.vue"),
          },
        ],
      },

      // * ---------------------------- Admin Translation Page --------------------------- *
      {
        path: "translation",
        name: "adminTranslation_en",
        component: RouterView,
        children: [
          // ------------------------ Admin Translation Home ----------------------
          {
            path: "",
            name: "translationHome_en",
            meta: {
              minKarma: 50,
            },
            redirect: { name: "translationList_en" },
          },
          // ------------------------ Admin Translation List ----------------------
          {
            path: "list",
            name: "translationList_en",
            meta: {
              minKarma: 50,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/Translate.vue"),
          },
        ],
      },
    ],
  },
];
