import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "my-4" }
const _hoisted_2 = { class: "py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24" }
const _hoisted_3 = { class: "space-y-8 sm:space-y-12" }
const _hoisted_4 = { class: "space-y-5 sm:mx-auto" }
const _hoisted_5 = { class: "text-3xl sm:text-4xl font-arial dark:text-gray-300" }
const _hoisted_6 = { class: "max-w-4xl mx-auto text-lg sm:text-xl text-gray-700 dark:text-gray-500" }
const _hoisted_7 = { class: "mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6" }
const _hoisted_8 = { class: "flex justify-center items-center space-y-4 my-6 mx-auto h-16 w-16 rounded-full lg:w-32 lg:h-32 bg-secondary-1/50 hover:bg-secondary-1 dark:bg-dark-primary-1" }
const _hoisted_9 = ["src", "alt"]
const _hoisted_10 = { class: "space-y-4" }
const _hoisted_11 = { class: "text-sm font-normal font-arial lg:text-base hover:text-secondary-3 dark:text-gray-400 dark:hover:text-gray-100" }
const _hoisted_12 = { class: "flex justify-center items-center space-y-4 my-6 mx-auto h-16 w-16 rounded-full lg:w-32 lg:h-32 bg-secondary-1/50 hover:bg-secondary-1 dark:bg-dark-primary-1" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "space-y-4" }
const _hoisted_15 = { class: "text-sm font-normal font-arial lg:text-base hover:text-secondary-3 dark:text-gray-400 dark:hover:text-gray-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.$t(`stateWise`)) + " " + _toDisplayString(_ctx.$t(`${_ctx.title}`)), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t(`${_ctx.description}`)), 1)
        ]),
        _createElementVNode("ul", _hoisted_7, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.states, (state, index) => {
            return (_openBlock(), _createElementBlock("li", { key: index }, [
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_router_link, {
                  to: _ctx.getStateRoute(state) 
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: require(`@/assets/images/statesImages/${state.imagePath}`),
                      alt: `${state.lang.en.string} image`,
                      class: "w-16 h-16 lg:w-28 lg:h-28 p-1"
                    }, null, 8, _hoisted_9)
                  ]),
                  _: 2
                }, 1032, ["to"])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(_component_router_link, {
                    to: _ctx.getStateRoute(state) 
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("h3", null, _toDisplayString(state.lang[_ctx.langCode]?.string || state.lang.en.string), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ]))
          }), 128)),
          _createElementVNode("li", null, [
            _createElementVNode("div", _hoisted_12, [
              _createVNode(_component_router_link, {
                to: _ctx.getViewMoreRoute()
              }, {
                default: _withCtx(() => [
                  _createElementVNode("img", {
                    src: require('@/assets/images/statesImages/others.webp'),
                    alt: `Other states image`,
                    class: "w-16 h-16 lg:w-28 lg:h-28 p-1"
                  }, null, 8, _hoisted_13)
                ]),
                _: 1
              }, 8, ["to"])
            ]),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_router_link, {
                  to: _ctx.getViewMoreRoute()
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.$t(`otherStates`)), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}