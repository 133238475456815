
import { CheckCircleIcon, XIcon } from "@/plugins/outlineHeroicon";
import { defineComponent } from "@/plugins/vue";
import {
  FunctionalComponent, HTMLAttributes, PropType, VNodeProps,
} from "@/plugins/interface";

export default defineComponent({
  name: "Snackbar",
  components: {
    CheckCircleIcon,
    XIcon,
  },
  props: {

    /**
     * The Text to be shown in snackbar
     */
    text: {
      type: String,
      default: "Alert",
    },

    /**
     * The icon to be shown in alert
     */
    icon: {
      type: Function as PropType<FunctionalComponent<HTMLAttributes & VNodeProps, {}>>,
      default: CheckCircleIcon,
    },

    /**
     * The type of snackbar error/success
     */
    type: {
      type: String,
      default: "success",
    },

    /**
     * The timeout for the which the snackbar appears
     */
    timeout: {
      type: Number,
      default: 1000,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  watch: {

    /**
     * If show is made true, set the timeout
     */
    show(val: boolean) {
      if (val) {
        setTimeout(this.hideBar, this.timeout);
      }
    },
  },

  /**
   * If show is made at created true, set the timeout
   */
  created() {
    if (this.show) {
      setTimeout(this.hideBar, this.timeout);
    }
  },
  methods: {
    /**
     * Function to hide the snackbar after timeout or close
     */
    hideBar(): void {
      this.show = false;
    },
  },
});
