
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from "@/plugins/headlessui";
import { MoonIcon, SunIcon, DesktopComputerIcon } from "@/plugins/outlineHeroicon";
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  name: "HeaderThemeSwitcher",
  components: {
    Listbox,
    ListboxButton,
    ListboxOptions,
    ListboxOption,
    MoonIcon,
    SunIcon,
    DesktopComputerIcon,
  },
  data() {
    return {
      themes: [
        { name: "light", icon: "SunIcon" },
        { name: "dark", icon: "MoonIcon" },
        { name: "system", icon: "DesktopComputerIcon" },
      ] as { name: string; icon: string; }[],
      selectedMode: { name: "", icon: "" },
    };
  },
  computed: {
    /**
     * The Icon to be displayed in the header
     * for theme
     */
    themeDisplayIcon(): string {
      const isDark = this.$store.getters["base/getDarkMode"];
      return this.themes.filter((theme) => theme.name === (isDark ? "dark" : "light"))[0].icon;
    },
    /**
     * Getting selectedThemeModeName from store to access wherever multiple component is used
     * for changing the active status of theme selected
     */
    selectedThemeModeName(): string {
      return this.$store.getters["base/getSelectedThemeModeName"];
    },
  },
  watch: {
    /**
     * Whenver theme mode is changed it calls updateMode
     */
    selectedMode() {
      this.$store.commit("base/SET_SELECTED_THEME_MODE_NAME", this.selectedMode.name);
      this.updateMode();
    },
  },
  mounted() {
    const localStorageTheme = window.localStorage.getItem("theme");
    if (localStorageTheme) {
      [this.selectedMode] = this.themes.filter((theme) => theme.name === localStorageTheme);
    } else {
      [, , this.selectedMode] = this.themes;
    }
  },

  methods: {
    /**
     * The function checks the mode selected and applies it to the website
     * Setting the Selected theme mode name and dark mode in store
     */

    /**
     * TODO- On switching from mobile view to desktop view frequently, theme switch freezes but works on refresh and system mode.
     */

    updateMode(): void {
      if (this.selectedMode.name === "dark") {
        this.$store.commit("base/SET_DARK_MODE", true);
        this.$store.commit("base/SET_SELECTED_THEME_MODE_NAME", "dark");
      } else if (this.selectedMode?.name === "system") {
        this.$store.commit("base/SET_SELECTED_THEME_MODE_NAME", "system");
        // Check if browser is in darkmode
        if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
          // dark mode
          this.$store.commit("base/SET_DARK_MODE", true);
        } else {
          this.$store.commit("base/SET_DARK_MODE", false);
        }
      } else {
        this.$store.commit("base/SET_DARK_MODE", false);
        this.$store.commit("base/SET_SELECTED_THEME_MODE_NAME", "light");
      }
    },
  },
});
