// importing createApp function that is used to create app instance
import { createApp } from "@/plugins/vue";

//  plugins import
import { ExclamationCircleIcon } from "@/plugins/solidHeroicon";
import EventBus from "@/helpers/eventBus";
import { readCreds, readKarma, storeKarma } from "@/plugins/indexedDB";
import "@/helpers/errorHandlers";
import {
  createMetaManager, useMeta, defaultConfig, MetaSourceProxy,
} from "@/plugins/vueMeta";
import i18n from "@/plugins/i18n";
import filters from "@/helpers/filters";
import googleOneTap from "@/helpers/googleOneTap";
import { searchWorkerAPI } from "@/scripts/searchWorkerAdapter.js";
import { workerAPI } from "@/scripts/workerAdapter";
import registerServiceWorker from "./scripts/registerServiceWorker";
import {
  firebaseAppPromise, authPromise, analyticsLogger,
} from "./plugins/firebase";
import { router, isRouteAllowed } from "./router/index";
import store from "./store/index.js";
import "./assets/tailwind.css";

// Application Imports
import App from "./App.vue";

/**
 * Typescript module augmentation
 *
 * Some plugins install globally available properties to all component instances via app.config.globalProperties.
 * Vue exposes a ComponentCustomProperties interface designed to be augmented via TypeScript module augmentation
 *
 * Refer: https://vuejs.org/guide/typescript/options-api.html#augmenting-global-properties
 */
declare module "vue" {
  interface ComponentCustomProperties {
    $meta: MetaSourceProxy;
    $filters: typeof filters;
  }
}

// Set a promise for workerAPI to initialize and change the worker ready state in store
let jsonPromise = workerAPI.init();
store.commit("base/SET_WORKER_PROMISE", jsonPromise);
jsonPromise.then((isWorkerReady: Boolean) => {
  store.commit("base/SET_WORKER_STATE", isWorkerReady);
  // When local worker is ready, start initializing search worker
  workerAPI.initSearch(searchWorkerAPI).then((searchStatus: Boolean) => {
    store.commit("base/SET_SEARCH_STATE", searchStatus);
  });
});

// Re-Initialize worker every hour
setInterval(() => {
  jsonPromise = workerAPI.init();
  store.commit("base/SET_WORKER_PROMISE", jsonPromise);
  jsonPromise.then((isWorkerReady: Boolean) => {
    store.commit("base/SET_WORKER_STATE", isWorkerReady);
    // When local worker is ready, start syncing search worker
    workerAPI.initSearch(searchWorkerAPI).then((searchStatus: Boolean) => {
      if (searchStatus) store.commit("base/SET_SEARCH_STATE", searchStatus);
      else console.log("Failed to update search store");
    });
  });
}, 3600 * 1000);

interface AuthModule {
  getAuth: Function;
}
/**
 * Uses the Firebase Auth module once it's loaded
 *
 * @param {object} authModule Firebase authModule
 * @param {App} vueApp Vue App instance
 */
function setupAuthentication(authModule: AuthModule, vueApp: any) {
  const app = vueApp;

  // initialize Google One Tap
  const oneTapPromise = googleOneTap.loadScript().then(() => {
    googleOneTap.initialize();
  });

  interface User {
    displayName: string;
    photoURL: string;
    email: string;
    emailVerified: Boolean;
  }
  // run when the user login
  authModule.getAuth().onAuthStateChanged((user: User) => {
    if (user) {
      store.commit("user/SET_USER_DISPLAY_NAME", user.displayName);
      store.commit("user/SET_USER_DISPLAY_IMG", user.photoURL);
      readCreds().then((data) => {
        store.commit("user/UPDATE_IS_AUTH", true);
        return store.commit("user/SET_USER_CREDS", data);
      }).then(() => {
        store.dispatch("user/fetchKarma");
      });

      // Discard User of any privliges, if she/he does not have a verified email
      if (!user.email || !user.emailVerified) store.commit("user/SET_USER_KARMA", 1);
    } else {
      // clear user creds in store and push route to home page
      store.commit("user/UPDATE_IS_AUTH", false);
      store.commit("user/CLEAR_USER_CREDS");
      storeKarma(0);

      // Show Google One Tap prompt
      oneTapPromise.then(() => { googleOneTap.showPrompt(); });
    }
  });

  interface VM {
    _: {
      vnode: {
        type: {
          name: string;
        };
      };
    };
  }
  /**
   * Handler for Vue uncaught errors throughout the platform, Log it to the firebase server,
   * and display the appropriate snackbar message
   *
   * @param {object} err - The error event object containing information about the error
   * @param {object} vm - The vm instance containing information about the vue component
   * @param {object} info - The information regarding the error and where it occurred
   */
  app.config.errorHandler = (err: Object, vm: VM, info: Object) => {
    console.error(err);
    const componentName = vm._.vnode.type.name;
    // analytics
    analyticsLogger(
      "vue_error",
      { description: `Error in ${componentName}: ${err.toString()}\nInfo: ${info}`, fatal: false },
    );
    EventBus.$emit(
      "displayMessage",
      {
        message: "EPF v-error: There has been some error.",
        icon: ExclamationCircleIcon,
        type: "error",
      },
    );
  };

  /**
   * Handler for Vue Runtime Warnings throughout the platform, Log it to the firebase server,
   * and display the appropriate snackbar message
   *
   * @param {string} msg - The error message containing information about the error
   * @param {object} vm - The vm instance containing information about the vue component
   * @param {object} trace - The error trace
   */
  app.config.warnHandler = (msg: String, vm: VM, trace: Object) => {
    if (vm) {
      console.warn(msg);
      const componentName = vm._.vnode.type.name;
      // analytics
      analyticsLogger(
        "vue_warning",
        { description: `Warning in ${componentName}: ${msg}\nTrace: ${trace}`, fatal: false },
      );
    }
  };
}

/**
 * Loads Vue App instance and adds handler to firebase dymnamic imports
 */
function loadVueInstance() {
  // App created using App.vue
  const app = createApp(App);

  // setting app to use store, router, i18n and VueMeta
  app.use(store);
  app.use(router);
  app.use(i18n);

  // setting app to use VueMeta
  // TODO: untill the stable version of vue-meta doesn't come, fix the name property in meta by using default config vue-meta plugin
  // Refer : https://github.com/nuxt/vue-meta/issues/696#issuecomment-878377182
  const metaManager = createMetaManager(false, {
    ...defaultConfig,
    meta: { tag: "meta", nameless: true },
  });
  app.use(metaManager);

  const meta = useMeta(
    {
      title: "Exam Path Finder",
    },
    metaManager,
  ); /**/

  // Vue.prototype replaced with app.config.globalProperties
  app.config.globalProperties.$filters = filters; // Replacing filters with b a global property
  app.config.globalProperties.$meta = meta.meta;
  app.config.globalProperties.$appConfig = app.config;
  app.config.globalProperties.firebase = {};

  firebaseAppPromise.then((firebaseApp) => {
    app.config.globalProperties.firebase.firebaseApp = firebaseApp;
  });

  authPromise.then((authModule) => {
    app.config.globalProperties.firebase = { ...app.config.globalProperties.firebase, ...authModule };
    setupAuthentication(authModule, app);
  });

  // Call for registering service worker when firebase modules are all loaded
  Promise.all([firebaseAppPromise, authPromise]).then((response) => {
    registerServiceWorker();
  });

  // Mounting the app
  app.mount("#app");
  return app;
}
// checks if the routes are allowed or not
if (isRouteAllowed()) {
  loadVueInstance();
} else {
  // Set karma from user indexed db
  readKarma().then((karma) => {
    store.commit("user/SET_USER_KARMA", karma);
    loadVueInstance();
  }).catch((e) => {
    store.commit("user/SET_USER_KARMA", 0);
    loadVueInstance();
  });

  // Set Credentials from firebase indexed db
  readCreds().then((data) => {
    store.commit("user/SET_USER_CREDS", data);
  }).catch(() => {
    store.commit("user/CLEAR_USER_CREDS");
  });
}
