
import { defineComponent } from "@/plugins/vue";
import HeadingSection from "@/components/Generic/HeadingSection.vue";

export default defineComponent({
  name: "User",
  components: {
    HeadingSection,
  },

});
