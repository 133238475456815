
import { defineComponent } from "@/plugins/vue";
import BaseAppShellHeader from "@/components/Base/BaseAppShellHeader.vue";
import BaseAppShellNavigation from "@/components/Base/BaseAppShellNavigation.vue";
import BaseAppShellFooter from "@/components/Base/BaseAppShellFooter.vue";
import EventBus from "@/helpers/eventBus";
import { XIcon, ChevronDoubleUpIcon } from "@/plugins/solidHeroicon";
import type { RouteLocationNormalized } from "@/plugins/interface";
import { MOBILE_FILTER_ID, DESKTOP_FILTER_ID } from "@/helpers/constants";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

const sidebarNavigation = [
  {
    name: "search",
    icon: "search.svg",
    pathName: "searchHome",
    admin: false,
    displayName: "search",
  },
  {
    name: "dashboard",
    icon: "dashboard.svg",
    pathName: "dashboard",
    admin: false,
    displayName: "dashboard",
  },
  {
    name: "alerts",
    icon: "notification.svg",
    pathName: "userNotifications",
    admin: false,
    displayName: "alerts",
  },
  {
    name: "subscriptions",
    icon: "subscription.svg",
    pathName: "userSubscriptions",
    admin: false,
    displayName: "subscribe",
  },

  {
    name: "eventList",
    icon: "eventList.svg",
    pathName: "eventList",
    admin: true,
    displayName: "events",
  },
  {
    name: "fieldList",
    icon: "fieldList.svg",
    pathName: "fieldList",
    admin: true,
    displayName: "Fields",
  },
  {
    name: "organisationList",
    icon: "organisationList.svg",
    pathName: "organisationList",
    admin: true,
    displayName: "orgs",
  },
  {
    name: "examList",
    icon: "examList.svg",
    pathName: "examList",
    admin: true,
    displayName: "Exams",
  },
  {
    name: "translationList",
    icon: "translation.svg",
    pathName: "translationList",
    admin: true,
    displayName: "translate",
  },
  {
    name: "locationList",
    icon: "location.svg",
    pathName: "locationList",
    admin: true,
    displayName: "locations",
  },
];

export default defineComponent({
  name: "BaseAppShell",
  components: {
    BaseAppShellFooter,
    BaseAppShellHeader,
    BaseAppShellNavigation,
    XIcon,
    ChevronDoubleUpIcon,
  },
  setup() {
    return {
      sidebarNavigation,
    };
  },
  data() {
    return {
      footer: false as boolean,
      visible: false as boolean,
      showDesktopFilter: false as boolean,
      showMobileFilter: false as boolean,
      scrollArr: [] as { path: string; scrollPosition: number; }[],
      MOBILE_FILTER_ID,
      DESKTOP_FILTER_ID,
    };
  },

  computed: {
    /**
     * computed property get the user signed in state
     */
    isUserSignedIn(): boolean {
      return this.$store.getters["user/isUserSignedIn"];
    },
    /**
     * Gets lang code from the base module in store
     *
     * @returns {string} - lang code
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed property to get the dark Mode
     *
     * @returns {boolean} - dark Mode
     */
    darkMode(): boolean {
      return this.$store.getters["base/getDarkMode"];
    },
    /**
     * Computed property to check the min Karma
     *
     * @returns {boolean} - return true if User is Admin to set the bottom margin to avoid overlap of add button and snackbar
     */
    isAdmin(): boolean {
      return this.$route.meta.minKarma as number >= 25;
    },
  },

  watch: {
    /**
     * Watcher to watch is user logged out
     *
     * @param {boolean} val - True if user signed in otherwise false
     */
    isUserSignedIn(val: boolean) {
      if (!val && this.$route.meta.minKarma as number > 0) {
        this.$router.push({ name: `jobsHome_${this.langCode}`, params: { langCode: this.langCode } });
      }
    },
    /**
     * Watcher to watch the route object
     * - To scroll to the top of page when page changes
     * To save the scroll position when we came back to previos page, the scroller will return to the same position where
     * we are navigating
     * @param {object} oldRoute - Old route value
     * @param {object} newRoute - New route value
     */
    $route(newRoute: RouteLocationNormalized, oldRoute: RouteLocationNormalized) {
      const newRouteLang: string = (newRoute.name as string).split("_")[1];
      const oldRouteLang: string = (oldRoute.name as string)?.split("_")[1];
      const newRouteName: string = (newRoute.name as string).split("_")[0];
      const oldRouteName: string = (oldRoute.name as string)?.split("_")[0];

      const scrollPosition: number = window.scrollY;
      const scrollObj: { path: string; scrollPosition: number; } = {
        path: `scrollPosition_${oldRoute.fullPath}`,
        scrollPosition,
      };
      this.scrollArr.push(scrollObj);
      const scrollPositionSession: string | null = sessionStorage.getItem("scrollPosition");
      const sessionData: { path: string; scrollPosition: number; }[] = (scrollPositionSession != null) ? JSON.parse(scrollPositionSession) : undefined;
      if (sessionData && sessionData.length > 10) {
        this.scrollArr.splice(0, 1);
      }
      sessionStorage.setItem("scrollPosition", JSON.stringify(this.scrollArr));
      if (oldRouteLang === newRouteLang && newRouteName !== oldRouteName) {
        // Hide Filter Dialog
        this.showDesktopFilter = false;

        // Hide Filter Button
        EventBus.$emit("setFiterButtonState", false);

        // Check if session storage have scrollbar data
        if (sessionData) {
          for (let i = sessionData.length - 1; i >= 0; i -= 1) {
            // Check if the current path scroll bar data are present in session storage
            if (`scrollPosition_${newRoute.fullPath}` === sessionData[i].path) {
              const yAxis = sessionData[i].scrollPosition;
              EventBus.$once("dataForScroll", () => {
                window.scrollTo({ top: yAxis });
              });
              return;
            }
          }
        }
        window.scrollTo({ top: 0 });
      }
    },
  },
  created() {
    /**
     * Listen to EventBus if Data on the page is loaded , then render footer component to reduce CLS score.
     */
    EventBus.$on("dataLoaded", (isShow: boolean) => {
      // hide footer on admin page
      if (this.$route.meta.minKarma as number >= 25 && isShow) this.footer = false;
      else this.footer = isShow;
    });
    /**
     * Listen to reload event for fetching data from session storage
     */
    window.addEventListener("beforeunload", this.getScrollData);
  },
  /**
     * Adding scroll event listener after mounted the app for get scrollTop value
     */
  mounted() {
    window.addEventListener("scroll", this.scrollListener);
  },
  methods: {
    /**
     * Handles the onClick event after user clicking on the return to top button
     */
    scrollTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    /**
     *  Checks the scrollTop value and updating the "visible" state
     *  Setting Scroll position on reload in Session Storage
     */
    scrollListener(): void {
      sessionStorage.setItem("scrollPositionReload", `${window.scrollY}`);
      this.visible = window.scrollY > 150;
    },
    /**
     * Handles the beforeunload event when user refresh the page,
     * Getting scroll position from session storage and setting the scroll position when user refresh the page
     * The data inside the scrollArr gets empty on reload, we are getting all the
     * data from the Session Storage and pushing again inside the scrollArr
     */
    getScrollData(): void {
      const yAxis = sessionStorage.getItem("scrollPositionReload");
      EventBus.$once("dataForScroll", () => {
        if (yAxis != null) {
          window.scrollTo({ top: Number(yAxis) });
        }
      });
      const scrollPositionSession = sessionStorage.getItem("scrollPosition");
      const sessionData = (scrollPositionSession) ? JSON.parse(scrollPositionSession) : undefined;
      if (sessionData) this.scrollArr.push(...sessionData);
    },

  },
});

