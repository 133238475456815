import { Permission } from "@/helpers/interface";

// Import store
const store = require("../store/index.js");

const permissions: Permission = {
  // Event Create and Edit Pages and Event status
  CREATE_EDIT_EVENTS: 25,
  ADD_DYNAMIC_COMPONENT: 50,
  ADD_TITLE: 25,
  ADD_DESCRIPTION: 25,
  UPLOAD_ATTACHMENTS: 25,
  SHOW_HIDE_ATTACHMENTS: 25,
  ADD_ORGANISATION: 25,
  ADD_EXAMS: 25,
  ADD_REMOVE_TAGS: 25,
  ADD_EVENT_BUTTON: 25,
  UPDATE_EVENT_BUTTON: 25,
  SEND_NOTIFICATION_DIALOG: 25,
  PUBLISH_STATUS: 25,
  EDIT_STATUS: 25,

  // Event View Page
  SHOW_EVENT: 0,
  EDIT_EVENT_BUTTON: 25,
  EVENT_TABS: 0,
  SHOW_DESCRIPTION: 0,
  SHOW_ATTACHMENTS: 0,
  DOWNLOAD_ATTACHMENTS: 1,
  SHOW_EVENT_DETAILS: 0,
  VIEW_EVENT_HISTORY: 0,

  // Event list page
  VIEW_EVENT_LIST: 25,
  ADD_EVENT: 25,
  UPDATE_EVENT: 25,
  ADD_CHECK: 25,

  // Field List
  VIEW_FIELD_LIST: 25,
  ADD_EDIT_FIELD: 50,
  ADD_FIELD_ACCESS: 50,
  EDIT_FIELD_ACCESS: 50,
  UPDATE_PRESETS: 50,

  // Values List
  VIEW_VALUES_LIST: 25,
  ADD_EDIT_VALUES: 50,
  ADD_VALUE_ACCESS: 50,
  EDIT_VALUE_ACCESS: 50,

  // Core Tag List: Exams and organisations
  VIEW_CORE_TAG_LIST: 25,
  ADD_CORE_TAG: 50,
  EDIT_CORE_TAG: 50,
  ADD_EDIT_CORE_TAGS: 50,
  ADD_CORE_TAG_NAME: 50,
  ADD_CORE_TAG_ALIASES: 50,
  ADD_LOGO: 50,
  ATTACH_EXAMS: 50,
  ATTACH_PARENTS: 50,
  ATTACH_TAGS: 50,
  SAVE_TAG: 50,

  // Translation List
  VIEW_TRANSLATIONS_PAGE: 50,
  VIEW_TRANSLATIONS_LIST: 50,
  VIEW_TABS: 50,
  CHANGE_TRANSLATION_LANGUAGE: 50,
  ADD_TRANSLATION: 50,
  ADD_ROMAN: 50,
  ADD_ALIASES: 50,
  EDIT_TRANSLATION_DATA: 50,

};

/**
 * Function to check if the user has the minimum karma required to access a particular permission
 *
 * @param {string} permissionKey - The permission that needs to be checked for the user
 * @returns {boolean} true: user has the required karma || false: user does not meet the karma
 * requirements
 */
function renderComponent(permissionKey: string): boolean {
  const userKarma = store.default.getters["user/getUserKarma"] as number;
  if (userKarma >= permissions[permissionKey]) return true;
  return false;
}

export default renderComponent;
