
import EventBus from "@/helpers/eventBus";
import Snackbar from "@/components/Generic/Snackbar.vue";
import { defineComponent } from "@/plugins/vue";
import { FunctionalComponent, HTMLAttributes, VNodeProps } from "@/plugins/interface";

interface SnackMessageType {
  icon: FunctionalComponent<HTMLAttributes & VNodeProps, {}>;
  message: string;
  type: string;
  timeout: number;
}

export default defineComponent({
  name: "BaseSnackbar",
  components: { Snackbar },
  data: () => ({

    snackStack: [] as SnackMessageType[],
  }),
  /**
   * Created life cycle hook
   * Listen for displayMessage event being emitted throughout the platform
   */
  created() {
    EventBus.$on("displayMessage", this.snackHandler);
  },
  methods: {
    /**
     * Function to build the snackStack for snackbars
     * when displayMessage event is fired throughout the platform,
     * and display the appropriate snackbar message for the same
     *
     * @param {SnackMessageType} obj - The object to be used for displaying the snackbar
     */
    snackHandler(obj: SnackMessageType): void {
      this.snackStack.push(obj);
    },
  },
});
