
import {
  Dialog as DialogContainer, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from "@/plugins/headlessui";

import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  components: {
    DialogContainer,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  emits: ["subscribeHandler"],
  data() {
    return {
      closeDialogBox: true,
    };
  },
  computed: {
    /**
     * Computed property to get the dark Mode
     */
    darkMode(): boolean {
      return this.$store.getters["base/getDarkMode"];
    },
  },
  methods: {
    /**
     * Function to set emit handler to subscribe exam
     * DialogContainer box set to close after subscribing exam
     *
     */
    subscribe(value: boolean) {
      this.closeDialogBox = false;
      this.$emit("subscribeHandler", value);
    },
  },
});
