

import {
  User, AuthModule, AuthProvider, UserCredential,
} from "@/plugins/interface";
import {
  authPromise, getCurrentUser, analyticsLogger, FirebaseError,
} from "@/plugins/firebase";
import { displayMessage, Type, Priority } from "@/helpers/emitEventBusEvent";
import AuthProviderButton from "@/components/Shared/AuthProviderButton.vue";
import TextField from "@/components/Generic/TextField.vue";
import Timer from "@/components/Generic/Timer.vue";
import facebookLogo from "@/assets/images/socialMedia/facebook.svg";
import googleLogo from "@/assets/images/socialMedia/google.svg";
import { isFacebookBrowser, rules } from "@/helpers/validations";
import { UserCircleIcon } from "@/plugins/solidHeroicon";
import { defineComponent } from "@/plugins/vue";

// created custom Interface to apply typescript on Refs
interface ValidationFieldRef {
  errorFound: boolean;
  errorMessage: string;
  validateInput: () => void;
}

export default defineComponent({
  name: "UserProfile",
  components: {
    AuthProviderButton,
    TextField,
    UserCircleIcon,
    Timer,
  },
  data() {
    return {
      theme: ["inline-flex h-fit items-center border border-transparent border-grey-3 text-white focus:ring-primary-3",
        "shadow-md text-base font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2"],
      userInfo: {
        userName: "",
        userEmail: "",
        userPhoto: "",
      },
      nameRules: rules.nameRules,
      emailRules: rules.emailRules,
      providers: {
        google: {
          auth: false,
          providerId: "google.com",
          logo: googleLogo,
          disabled: true,
        },
        facebook: {
          auth: false,
          providerId: "facebook.com",
          logo: facebookLogo,
          disabled: true,
        },
      },
      emailVerified: true,
      emailDisabled: true,
      verifyDisabled: false,
      verifyEmailTimeout: 0,
      isFacebookBrowser: false,
    };
  },
  /**
   * Load user profile on mounted hook
   */
  async mounted() {
    this.isFacebookBrowser = isFacebookBrowser();
    const authModule = await authPromise;
    authModule.getAuth().onAuthStateChanged(async (user: User | null) => {
      if (user) {
        await this.loadUserProfile();
        await this.loadProviders();
        if ((this.$route.name as string).startsWith("userProfile")) {
          this.emailVerification();
        }
      }
    });
  },
  methods: {
    /**
     * Function to load profile of user from firebase
     */
    async loadUserProfile() {
      getCurrentUser().then((user: User | null) => {
        if (user) {
          this.userInfo.userName = user.displayName || "";
          this.userInfo.userEmail = user.email || "";
          this.userInfo.userPhoto = user.photoURL || "";
        }
      });
    },

    /**
     * Function to load providers of profile from firebase
     */
    async loadProviders() {
      const authModule: AuthModule = await authPromise;
      const { providerData } = authModule.getAuth().currentUser as User;

      providerData.forEach((data) => {
        const providerName = data.providerId.replace(".com", "");
        if ((this.providers[providerName as keyof typeof this.providers])) this.providers[providerName as keyof typeof this.providers].auth = true;
      });
    },

    /**
     * Function to handle provider to link and unlink Accounts
     *
     * @param {String} providerName: The name of provider
     */
    providerHandler(providerName: string) {
      this.linkProvider(providerName);
    },

    /**
     * Function to link any provider to firebase
     *
     * @param {String} providerName: The name of provider
     */
    async linkProvider(providerName: string) {
      let provider;
      const authModule = await authPromise;
      if (providerName === "google") provider = new authModule.GoogleAuthProvider();
      else if (providerName === "facebook") provider = new authModule.FacebookAuthProvider();

      const user = authModule.getAuth().currentUser;
      // Redirect or provide a popup for linking another auth provider
      if (this.isFacebookBrowser) {
        localStorage.setItem("linkWithRedirect", "true");
        if (user) authModule.linkWithRedirect(user as User, provider as AuthProvider);
        return;
      }
      authModule.linkWithPopup(user as User, provider as AuthProvider)
        .then((credential: UserCredential) => {
          if (credential !== null) {
            this.providers[providerName as keyof typeof this.providers].auth = true;
            if (user) this.userInfo.userName = user.displayName as string;
            displayMessage("Account linked successfully", Priority.MEDIUM, Type.SUCCESS);
          }
        })
        .catch((error: FirebaseError) => {
          this.handleCatchError(error);
        });
    },

    /**
     * Function to update user info
     * If user  update his details then show appropriate message by displayMessage
     * Else if user has not updated his details then show not updated message
     */
    async updateUserInfo() {
      try {
        if ((this.$refs.nameField as ValidationFieldRef).errorFound) return;

        const user = await getCurrentUser();
        const oldName = user?.displayName || "";
        const oldEmail = user?.email || "";

        if ((this.userInfo.userName === oldName) && (this.userInfo.userEmail === oldEmail)) {
          displayMessage("You haven't updated any details", Priority.HIGH, Type.ERROR);
          return;
        }

        if (this.userInfo.userName !== oldName) {
          await this.updateName();
          displayMessage("Name updated successfully", Priority.MEDIUM, Type.SUCCESS);
        }

        if ((this.$refs.emailField as ValidationFieldRef).errorFound) return;

        if (this.userInfo.userEmail !== oldEmail) {
          await this.submitEmail();
          displayMessage("Email updated successfully", Priority.MEDIUM, Type.SUCCESS);
        }

        this.loadUserProfile();
      } catch (error) {
        this.handleCatchError(error as FirebaseError);
      }
    },

    /**
     * Function to upadate name of user
     */
    async updateName() {
      const authModule = await authPromise;
      return authModule.updateProfile(authModule.getAuth().currentUser as User, {
        displayName: this.userInfo.userName,
      });
    },

    /**
     * Function to verify the provided email address
     * If user  save his details then show appropriate message by snackbar
     */
    async verifyEmail() {
      const user = await getCurrentUser();
      const savedEmail = user?.email || "";
      if (this.userInfo.userEmail !== savedEmail) {
        displayMessage("Save email, you haven't saved it yet", Priority.HIGH, Type.ERROR);
        return;
      }
      const authModule = await authPromise;
      const actionCodeSettings = {
        url: window.location.href,
        handleCodeInApp: true,
      };

      // Disable Verify email button for a while to limit frequent request
      if (this.verifyEmailTimeout < 60 * 10) this.verifyEmailTimeout += 60;
      this.verifyDisabled = true;

      try {
        await authModule.sendEmailVerification(authModule.getAuth().currentUser as User, actionCodeSettings);
        displayMessage(`We have sent you an email verification on ${this.userInfo.userEmail.trim()}`, Priority.MEDIUM, Type.SUCCESS);
      } catch (err) {
        if ((err as FirebaseError).message.includes("too-many-requests")) {
          displayMessage("Server Busy, Try again after a while", Priority.MEDIUM, Type.ERROR);
          return;
        }
        throw err;
      }
    },

    /**
     * Updates the email in users account if it doesn't have one
     */
    async submitEmail() {
      const authModule = await authPromise;
      (this.$refs.emailField as ValidationFieldRef).validateInput();

      if (!(this.$refs.emailField as ValidationFieldRef).errorFound) {
        try {
          await authModule.updateEmail(authModule.getAuth().currentUser as User, this.userInfo.userEmail.trim());
        } catch (err) {
          if ((err as FirebaseError).code === "auth/requires-recent-login") {
            const { providerData } = authModule.getAuth().currentUser as User;
            const providerName: string = providerData[0].providerId.replace(".com", "");
            const name = (authModule.getAuth().currentUser as User).displayName;
            const provider = await this.getProvider(providerName);
            displayMessage(
              `Please Sign in to ${name} account on ${this.$filters.titleCase(providerName)} to continue updating email.`,
              Priority.HIGH,
              Type.SUCCESS,
            );
            if (this.isFacebookBrowser) {
              localStorage.setItem("updatedEmail", this.userInfo.userEmail.trim());
              authModule.signInWithRedirect(authModule.getAuth(), provider as AuthProvider);
              return;
            }
            await authModule.signInWithPopup(authModule.getAuth(), provider as AuthProvider);
            await authModule.updateEmail((authModule.getAuth().currentUser as User), this.userInfo.userEmail.trim());
          } else {
            throw err;
          }
        }

        if (!(authModule.getAuth().currentUser as User).emailVerified) {
          await this.verifyEmail();
          this.emailVerified = false;
          this.emailDisabled = false;
        } else {
          this.emailVerified = true;
          this.emailDisabled = true;
        }
      }
    },

    /**
     * Returns the corresponding Provider instance for the providerName passed
     *
     * @param {string} providerName provider name
     */
    async getProvider(providerName: string) {
      const authModule = await authPromise;
      switch (providerName) {
        case "google": return new authModule.GoogleAuthProvider();
        case "facebook": return new authModule.FacebookAuthProvider();
        default: return null;
      }
    },

    /**
     * Function to display email verification message to user
     * for any pending verification
     */
    async emailVerification() {
      const authModule = await authPromise;
      const user = authModule.getAuth().currentUser as User;
      if (!user.email) {
        (this.$refs.emailField as ValidationFieldRef).errorFound = true;
        (this.$refs.emailField as ValidationFieldRef).errorMessage = "Please provide an email for full access!";
        this.emailDisabled = false;
        this.emailVerified = true;
      } else if (!user.emailVerified) {
        (this.$refs.emailField as ValidationFieldRef).errorFound = true;
        (this.$refs.emailField as ValidationFieldRef).errorMessage = "Please verify your email for full access!";
        this.emailDisabled = false;
        this.emailVerified = false;
      } else {
        this.emailDisabled = true;
        this.emailVerified = true;
      }
    },

    /**
     * Handles changes in name Field
     * also enables submit button if required
     *
     * @param {string} newName The name value updated in name field
     */
    handleNameFieldUpdate(newName: string) {
      if ((this.$refs.nameField as ValidationFieldRef).errorFound || newName.trim().toLowerCase() === this.userInfo.userName.trim().toLowerCase()) return;
      this.userInfo.userName = newName.trim().split(/ +/g).map((word) => `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`).join(" ");
    },

    /**
     * Handles changes in email Field
     * also enables submit button if required
     *
     * @param {string} newEmail The name value updated in name field
     */
    handleEmailFieldUpdate(newEmail: string) {
      if ((this.$refs.emailField as ValidationFieldRef).errorFound || newEmail.trim().toLowerCase() === this.userInfo.userEmail.trim().toLowerCase()) return;
      this.userInfo.userEmail = newEmail.trim();
    },

    /**
     * Activates verify email button after firebase timout has expired
     */
    handleVerifyEmailTimeout() {
      this.verifyDisabled = false;
    },

    /**
     * Conditionally handling errors, if user cancels or closes the sign-in pop-up window before
     * authorization then log the error in firebase else
     * display error to the user.
     */
    handleCatchError(error: FirebaseError) {
      if ((error.message === "Firebase: Error (auth/popup-closed-by-user).") || (error.message === "Firebase: Error (auth/cancelled-popup-request).")) {
        analyticsLogger("firebase error", { description: `Error message: ${error.message}`, fatal: false });
      } else {
        analyticsLogger("firebase error", { description: `Error message: ${error.message}`, fatal: false });
        displayMessage(error.message, Priority.HIGH, Type.ERROR);
      }
    },
  },
});
