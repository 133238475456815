import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-16 mt-4 lg:mt-0" }
const _hoisted_2 = { class: "mx-auto sm:py-16 sm:px-6 lg:px-8 py-6" }
const _hoisted_3 = { class: "text-center" }
const _hoisted_4 = { class: "text-4xl text-gray-900 sm:text-5xl sm:tracking-tight font-arial lg:text-main-heading dark:text-gray-200" }
const _hoisted_5 = { class: "max-w-3xl mt-5 mx-auto text-lg sm:text-xl lg:text-2xl text-gray-700 font-normal dark:text-dark-text-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(_ctx.bannerTitle)), 1),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t(_ctx.bannerDescription)), 1)
      ])
    ])
  ]))
}