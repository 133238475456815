
import { getHomeRoutePath } from "@/helpers/routesHandler";
import { getJSONDataIds } from "@/helpers/staticDataParser";
import { getTagName } from "@/scripts/workerAdapterConnector";
import { defineComponent } from "@/plugins/vue";
import { RouteObject } from "@/helpers/interface";
import { StaticFilesLang } from "@/scripts/interface";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

export default defineComponent({
  name: "TopCategory",
  data() {
    return {
      categoryIcons: [
        "upsc.webp",
        "ssc.webp",
        "finance.webp",
        "railways.webp",
        "medical.webp",
        "psu.webp",
        "state-psc.webp",
        "armed-forces.webp",
        "research-dev.webp",
        "education.webp",
        "judiciary.webp",
        "postal.webp",
        "state-government.webp",
        "central-government.webp",
        "others.webp",
      ],
      streamIcons: [
        "engineer.webp",
        "management.webp",
        "law.webp",
        "medical.webp",
        "agriculture.webp",
        "architecture.webp",
        "science.webp",
        "art.webp",
        "education.webp",
        "aviation.webp",
        "it-computer-application.webp",
        "design.webp",
        "animation.webp",
        "pharmacy.webp",
        "mass-communication.webp",
        "business.webp",
        "others.webp",
      ],
      categoryIds: [] as string[],
      staticFileName: "" as "category" | "stream",
      pageType: "",
      title: "",
      description: "",
      iconsArray: [] as string[],
      tagNames: [] as StaticFilesLang[],
      isTagNames: false,
      iscreated: false,
      folderName: "",
    };
  },
  computed: {
    /**
     * Gets lang code from base store module
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Gets the path name on basis on the route meta module
     */
    pathName(): string {
      return this.$route.meta.mode === "job" ? "jobsSpecificCategoryPage_en" : "admissionsSpecificStreamPage_en";
    },
  },
  watch: {
    /**
     * Watcher to watch for changes in route
     *
     * @param {object} newRoute - the current new route
     * @param {object} oldRoute - the previous route
     */
    $route(newRoute, oldRoute) {
      const oldRouteName = oldRoute.name.split("_")[0];
      const newRouteName = newRoute.name.split("_")[0];
      if (!["jobsHome", "admissionsHome"].includes(newRouteName)) return;
      if (oldRouteName !== newRouteName) {
        this.iscreated = false;
        this.isTagNames = false;
        this.updateStaticFileNameAndLink();
      }
    },
  },
  /**
   * Update title and link on created life cycle hook
   */
  created() {
    this.updateStaticFileNameAndLink();
  },
  methods: {
    /**
    * Function to get the route path of homePage icons
    *
    * @param {string} id - id of the item
    * @param {number} index - index at which the item is present
    * @return {RouteObject} route path
    */
    setHomeRoutePath(id: string, index: number): RouteObject {
      if (!this.tagNames[index]) return { name: "", params: { langCode: this.langCode, id: "" } };
      const path = getHomeRoutePath(this.tagNames, id, index, this.langCode, this.pathName, this.$route);
      return path;
    },

    /**
     * Function updates static file name,page type, title,and categoryIds
     */
    updateStaticFileNameAndLink() {
      this.staticFileName = this.$route.meta.mode === "job" ? "category" : "stream";
      this.pageType = this.$route.meta.mode === "job" ? "category" : "stream";
      this.title = this.$route.meta.mode === "job" ? "exams" : "streams";
      this.description = this.$route.meta.mode === "job" ? "popularExamDescription" : "popularStreamDescription";
      this.iconsArray = this.$route.meta.mode === "job" ? this.categoryIcons : this.streamIcons;
      this.folderName = this.$route.meta.mode === "job" ? "popularExams" : "popularStreams";
      this.categoryIds = getJSONDataIds(this.staticFileName);
      if (!this.iscreated) {
        getTagName(this.staticFileName, [...this.categoryIds]).then((tagNames) => {
          this.tagNames = tagNames;
          this.isTagNames = true;
          this.iscreated = true;
        });
      }
    },
    /**
     * Function to get the string data
     *
     * @param {number} index: the index value of tagName
     * @returns {string} the string value inside the tagName
     */
    getStringData(index: number): string {
      if (!this.isTagNames) return "";
      return this.tagNames?.[index]?.[this.langCode]?.string || this.tagNames?.[index]?.en?.string;
    },
  },
});
