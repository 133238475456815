
import { XCircleIcon } from "@/plugins/solidHeroicon";
import googleLogo from "@/assets/images/socialMedia/google.svg";
import facebookLogo from "@/assets/images/socialMedia/facebook.svg";
import AuthProviderButton from "@/components/Shared/AuthProviderButton.vue";
import { authPromise, analyticsLogger } from "@/plugins/firebase";
import { getNamedRoute } from "@/helpers/routesHandler";
import { displayMessage, Type, Priority } from "@/helpers/emitEventBusEvent";
import { isFacebookBrowser, rules } from "@/helpers/validations";
import { analyticsSignIn, analyticsLogin } from "@/helpers/analyticsEvents";
import displayConsole from "@/helpers/displayConsole";
import type {
  AuthModule, AuthProvider, OAuthCredential, UserCredential, FirebaseError, UserCredentialCustom, TokenResponse,
} from "@/plugins/interface";
import { defineComponent } from "@/plugins/vue";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

export default defineComponent({
  name: "BaseAppShellRegistration",
  components: {
    XCircleIcon,
    AuthProviderButton,
  },
  data() {
    return {
      merge: {
        email: "",
        provider: "google",
        logo: googleLogo,
        error: null,
        failedProvider: "",
        accessToken: "",
      },
      state: "provider",
      logos: {
        google: googleLogo,
        facebook: facebookLogo,
      },
      emailRules: rules.emailRules,
      userEmail: "",
      emailVerificationSent: true,
      isFacebookBrowser: false,
    };
  },
  computed: {
    /**
     * Whether Sign In Box should be displayed or not
     */
    getSignInBoxDisplayState(): string {
      return this.$store.getters["base/getSignInBoxDisplayState"];
    },

    /**
     * Computed property to get the current langCode from store
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed property to get the dark Mode
     */
    darkMode(): boolean {
      return this.$store.getters["base/getDarkMode"];
    },
  },

  /**
   * Adds a listener to handle the result of signInWithRedirect
   * if a response is returned the handler signs in the user
   */
  created() {
    authPromise.then((authModule: AuthModule) => {
      authModule.getRedirectResult(authModule.getAuth()).then(async (result: UserCredential | null) => {
        if (!result) return;

        // eslint-disable-next-line no-underscore-dangle
        const { isNewUser } = (result as UserCredentialCustom)._tokenResponse;
        const { providerId } = result;
        if (!providerId) return;
        // analytics
        if (isNewUser) analyticsSignIn(providerId);
        analyticsLogin(providerId);

        const updatedEmail = localStorage.getItem("updatedEmail");
        const mergeInfo = localStorage.getItem("mergeInfo");

        // Email has to be updated
        if (updatedEmail) {
          localStorage.removeItem("updatedEmail");
          const user = authModule.getAuth()?.currentUser;
          if (!user) return;
          await authModule.updateEmail(user, updatedEmail);
          displayMessage("Email updated succesfully", Priority.MEDIUM, Type.SUCCESS);
          return;
        }

        // Requires merging of authentication providers
        if (mergeInfo) {
          await this.mergeAccounts(result, true);

          // Check Email Availability and verification
          await this.setEmailState(result);
          return;
        }

        // If redirect result is of link request
        if (localStorage.getItem("linkWithRedirect")) {
          localStorage.removeItem("linkWithRedirect");
          displayMessage("Account linked succesfully", Priority.MEDIUM, Type.SUCCESS);
        }

        // Auth Provider linked
      }).catch((error: FirebaseError) => {
        this.handleSignInError(error, true);
      });
    });
    this.isFacebookBrowser = isFacebookBrowser();
  },
  methods: {
    /**
     * Method to signIn the user, called when any of the provider login buttons are clicked
     * also merges account credentials if user signs in later with another provider account
     *
     * @param {string} providerName name of the provider to be used to login
     * @param {boolean} requiresMerge whether the sign in requires merge or not
     */
    async signIn(providerName: string, requiresMerge = false): Promise<void> {
      const provider = await this.getProvider(providerName);
      if (!provider) return;
      const authModule = await authPromise;

      if (this.isFacebookBrowser) {
        authModule.signInWithRedirect(authModule.getAuth(), provider);
        return;
      }
      authModule.signInWithPopup(authModule.getAuth(), provider)
        .then(async (result: UserCredential) => {
          // eslint-disable-next-line no-underscore-dangle
          const { isNewUser } = (result as UserCredentialCustom)._tokenResponse;
          const { providerId } = result;
          if (!providerId) return;
          // analytics
          if (isNewUser) analyticsSignIn(providerId);
          analyticsLogin(providerId);
          // Requires merging of authentication providers
          if (requiresMerge) await this.mergeAccounts(result);

          // Check Email Availability and verification
          await this.setEmailState(result);
        }).catch((error: FirebaseError) => {
          this.handleSignInError(error);
        });
    },

    /**
     * Handles Error returned by siginInWithPopUp by either logging it
     * or setting merge state if required
     *
     * @param {object} error returned by siginInWithPopUp
     * @param {boolean} saveToLocalStorage whether to save merge details in localStorage or not
     */
    async handleSignInError(error: FirebaseError, saveToLocalStorage: boolean = false): Promise<void> {
      if (error.message === "Firebase: Error (auth/operation-not-allowed).") {
        displayMessage("This method is not allowed.", Priority.HIGH, Type.ERROR);
      } else if (error.message === "Firebase: Error (auth/account-exists-with-different-credential).") {
        // if email is already linked.
        const { email } = error.customData as { email: string; }; // email account for existing user
        const authModule = await authPromise;

        authModule.fetchSignInMethodsForEmail(authModule.getAuth(), email).then((methods: string[]) => {
          const providerNewName = methods[0].split(".")[0] as keyof typeof this.logos; // fetch exist provider name
          this.state = "mergeRequired";
          this.merge.email = email;
          this.merge.logo = this.logos[providerNewName];
          this.openSignInBox();

          if (providerNewName === "google" && this.isFacebookBrowser) {
            this.state = "webview";
            return;
          }

          /**
           * "!" is a Non-null Assertion Operator
           * It is used when you know that the value can’t be null or undefined
           * ref- https://www.typescriptlang.org/docs/handbook/2/everyday-types.html#non-null-assertion-operator-postfix-
           */
          // eslint-disable-next-line no-underscore-dangle
          const tokenResponse = error.customData!._tokenResponse as TokenResponse;
          [this.merge.failedProvider] = tokenResponse.providerId.split(".");
          this.merge.accessToken = tokenResponse.oauthAccessToken; // return accesstoken

          if (saveToLocalStorage) localStorage.setItem("mergeInfo", JSON.stringify(this.merge));
        });
      } else if ((error.message === "Firebase: Error (auth/popup-closed-by-user).") || (error.message === "Firebase: Error (auth/cancelled-popup-request).")) {
        analyticsLogger("firebase error", { description: `Error message: ${error.message}`, fatal: false });
      } else {
        analyticsLogger("firebase error", { description: `Error message: ${error.message}`, fatal: false });
        displayMessage(error.message, Priority.HIGH, Type.ERROR);
      }
    },

    /**
     * Sets current state based on email verification state of user
     *
     * @param {object} result the login response returned by signInWithPopup
     */
    async setEmailState(result: UserCredential): Promise<void> {
      if (!result.user.email) {
        this.state = "provideEmail";
        this.openSignInBox();
      } else if (!result.user.emailVerified) {
        const actionCodeSettings = {
          url: window.location.href,
          handleCodeInApp: true,
        };
        const authModule = await authPromise;
        const { currentUser } = authModule.getAuth();
        if (!currentUser) return;
        authModule.sendEmailVerification(currentUser, actionCodeSettings).then(() => {
          this.emailVerificationSent = true;
        }).catch((err) => {
          this.emailVerificationSent = false;
          analyticsLogger("firebase error", { description: `Error message: ${err.message}`, fatal: false });
          displayConsole(err, {}, "sendEmailVerification");
        });

        this.state = "verifyEmail";
        this.openSignInBox();
      } else {
        this.closeSignInBox();
      }
    },

    /**
     * Merges accounts with same email
     *
     * @param {object} result the login response returned by signInWithPopup
     *
     * @param {boolean} useLocalStorage whether to read failedProvider and accessToken from localStorage or not
     */
    async mergeAccounts(result: UserCredential, useLocalStorage = false): Promise<void> {
      const { user } = result;

      if (useLocalStorage) {
        const mergeInfo = JSON.parse(localStorage.getItem("mergeInfo") || "{}");
        localStorage.removeItem("mergeInfo");
        this.merge = { ...this.merge, ...mergeInfo };
      }

      const { failedProvider, accessToken } = this.merge; // return accesstoken
      const failedEmail = this.merge.email; // return accesstoken

      const cred = await this.getCredential(failedProvider, accessToken);

      // linking providers using new credentials, previous credentials
      const authModule = await authPromise;

      // If user logged in from a different email. don't merge accounts
      if (user.email !== failedEmail) return;
      if (!cred) return;
      authModule.linkWithCredential(user, cred)
        .then(() => {
          displayMessage("Credentials linked succesfully!", Priority.MEDIUM, Type.SUCCESS);
        })
        .catch((error) => {
          displayMessage(error.message, Priority.HIGH, Type.ERROR);
        });
    },

    /**
     * Returns the corresponding OAuth Credential instance for the providerName passed
     *
     * @param {string} providerName provider name
     * @param {string} accessToken accessToken for login
     * @return {OAuthCredential | null} return type of getCredential
     */
    async getCredential(providerName: string, accessToken: string): Promise<OAuthCredential | null> {
      const authModule = await authPromise;
      switch (providerName) {
        case "google": return authModule.GoogleAuthProvider.credential(accessToken);
        case "facebook": return authModule.FacebookAuthProvider.credential(accessToken);
        default: return null;
      }
    },
    /**
     * Returns the corresponding Provider instance for the providerName passed
     *
     * @param {string} providerName provider name
     * @param {FirebaseApp} app Firebase app instance
     * @return {AuthProvider|null} Return type of getProvider
     */
    async getProvider(providerName: string): Promise<AuthProvider | null> {
      const authModule = await authPromise;
      switch (providerName) {
        case "google": return new authModule.GoogleAuthProvider();
        case "facebook": return new authModule.FacebookAuthProvider();
        default: return null;
      }
    },
    /**
     * Function to close dialogue box
     */
    closeSignInBox() {
      this.$store.commit("base/CHANGE_SIGN_IN_BOX_DISPLAY", false);
      this.state = "provider";
    },
    /**
     * Function to close dialogue box
     */
    openSignInBox() {
      this.$store.commit("base/CHANGE_SIGN_IN_BOX_DISPLAY", true);
    },

    /**
     * Function to redirect the user to profile page
     * for registering email
     */
    redirectToProfile() {
      this.closeSignInBox();
      this.$router.push({ name: getNamedRoute(this.langCode, "userProfile_en"), params: { langCode: this.langCode } });
    },
  },
});
