export default {
  getUserKarma(state) {
    return state.user_karma;
  },
  getSubscriptionList(state) {
    return state.subscriptionList;
  },
  getSearchResultList(state) {
    return state.searchResultList;
  },
  getvisibleResultList(state) {
    return state.visibleResultList;
  },
  getSearchId(state) {
    return state.unsubscribeSearch;
  },
  /**
   * Getter gets the user id
   *
   * @param {*} state - Data store for user module
   * @returns {string} -user id
   */
  getUserId(state) {
    return state.user_uid;
  },
  /**
   * Getter gets the user token
   *
   * @param {*} state - data store for user module
   * @returns {string} - user token
   */
  getUserToken(state) {
    return state.user_token;
  },
  /**
   * Getters return true or false
   * @param {*} state - data store for user module
   * @returns {Boolen} - true or false
   */
  isUserSignedIn(state) {
    return state.user_uid !== undefined;
  },
  /**
   * Getter gets the user displayName
   *
   * @param {*} state - used to access state of user module
   * @returns {String} - user displayName
   */
  getUserDisplayInfo(state) {
    return state.displayName;
  },
  /**
   * Getter gets the user display image
   *
   * @param {*} state - use to access the state of user user module
   * @returns {String} - user image url
   */
  getUserDisplayImg(state) {
    return state.photoURL;
  },
  /**
   * Getter get the authenticated state of the user
   *
   * @param {*} state - used to access state of user module
   * @returns {Boolen} - true or false
   */
  getAuthenticatedState(state) {
    return state.isAuth;
  },
};
