export default {
  /**
   * Getter to get the event from store
   *
   * @param {*} state - state of store
   * @returns {object} event- The event stored in store
   */
  getEvent(state) {
    return state.event;
  },

  /**
   * Getter for file attachments
   *
   * @param {*} state - state of store
   * @returns {object[]} fileAttachments- The file attachments stored in store
   */
  getFileAttachments(state) {
    return state.fileAttachments;
  },

  /**
   * Getter for event type object
   *
   * @param {*} state - state of store
   * @returns {object} eventTypes- The event types and their IDs stored in store
   */
  getEventTypes(state) {
    return state.eventTypes;
  },
  /**
   * Getters for events tags
   * @param {*} state - state of store
   * @returns {Array} eventTags - An array of event tags object
   */
  getEventTags(state) {
    return state.eventTags;
  },
  /**
   * Getters for the subscribed Event Ids
   *
   * @param {*} state - state of the store
   * @returns {string[]} - array of subscribed event ids
   */
  getSubscribedEventIds(state) {
    return state.subscribedEventIds;
  },
  /**
   * Getters for the subscribed exam Ids
   *
   * @param {*} state - state of the store
   * @returns {string[]} - array of subscribed event ids
   */
  getSubscribedExamIds(state) {
    return state.subscribedExamIds;
  },
  /**
   * Getters for the isSubscribedDataFetched
   *
   * @param {*} state - state of the event module
   * @returns {boolean} - boolean if subscribed data is fetched return true otherwise false
   */
  isSubscribedDataFetched(state) {
    return state.isSubscribedDataFetched;
  },
};
