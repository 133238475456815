
import { defineComponent, PropType } from "@/plugins/vue";
import { CalendarIcon, ArrowNarrowLeftIcon, ArrowNarrowRightIcon } from "@/plugins/solidHeroicon";
import EventBus from "@/helpers/eventBus";
import { getEventRoutePath } from "@/helpers/routesHandler";
import { RouteLocationNormalized } from "@/plugins/interface";
import { RouteObject } from "@/helpers/interface";

type LangCode = "en" | "hi";

interface TagsData {
  nameId: string;
  valueId: string;
}

interface SearchResultsData {
  created: number;
  eventId: string;
  examIds: string[];
  fieldIds: string[];
  lastUpdated: number;
  organisationIds: string[];
  tagsData: TagsData[];
  title: {
    en: string;
    hi: string;
  };
  title_roman: {
    hi: string;
  };
}

export default defineComponent({
  name: "SearchResults",
  components: {
    CalendarIcon,
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon,
  },
  props: {
    /**
     * Array of search results
     */
    results: {
      type: Array as PropType<SearchResultsData[]>,
      default: () => [],
    },
    /**
     * encoded filter data from search
     */
    routeFilter: {
      type: String,
      default: "",
    },
    /**
     * encoded query data from search
     */
    routeQuery: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      itemsPerPage: 50,
      pageNo: 1,
      isRightArrowShow: false,
      startNo: 0,
    };
  },
  computed: {
    /**
     * Gets lang code from the base module in Store
     *
     * @returns {LangCode} - langCode
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },

    /**
     * True or false based on initial page check to
     * enables or disables the left arrow click of the table
     * @returns {boolean} val - false if first page or else true
     */
    isLeftArrowShow(): boolean {
      return this.pageNo <= 1;
    },

    /**
     * Returns an array of items that has to appear on the
     * current page of table
     * @returns {SearchResultsData[]} val - array of items which are to be displayed
     */
    currentPageItems(): SearchResultsData[] {
      return this.results.slice(this.startNo, this.startNo + this.itemsPerPage);
    },
  },
  watch: {
    /**
     * Update Pagination Route on route change,
     * also enabling the footer on route change
     *
     * @param {RouteLocationNormalized} newRoute - New route value
     * @param {RouteLocationNormalized} oldRoute - Old route value
     */
    $route(newRoute: RouteLocationNormalized, oldRoute: RouteLocationNormalized) {
      const newRouteName = (newRoute.name as string).split("_")[0];
      const oldRouteName = (oldRoute.name as string)?.split("_")[0];
      if (newRouteName === oldRouteName) {
        EventBus.$emit("dataLoaded", true);
        this.paginationRoute(true);
      }
    },

    /**
     * Watch to check display results have been modified
     */
    results(val) {
      this.isRightArrowShow = (this.pageNo === Math.ceil(val.length / this.itemsPerPage));
      if (Object.keys(this.$route.query).length) {
        this.pageNo = Number(this.$route.query?.pageNo) > 1 ? Number(this.$route.query?.pageNo) : 1;
      } else {
        this.pageNo = 1;
      }
      if (this.pageNo === Math.ceil(this.results.length / this.itemsPerPage)) {
        this.isRightArrowShow = true;
      }
      EventBus.$emit("dataLoaded", true);
    },

    /**
     * To watch the Page no. to see if there is anymore data
     * if not then disabling the right click button
     * or else keeping the button enabled to visit the next page
     * Also calculating the start no when page changes
     */
    pageNo() {
      this.calculateStartNo();
      this.isRightArrowShow = (this.pageNo === Math.ceil(this.results.length / this.itemsPerPage));
    },
  },
  methods: {
    /**
    * Function to get the route path to edit and view events
    *
    * @param {SearchResultsData} item - Current event item
    * @param {string} routeName - Name of route (eventEdit or eventView)
    * @return {RouteObject} RouteObject - url according to current language
    */
    setEventRoutePath(item: SearchResultsData, routename: string): RouteObject {
      if (!item) return { name: "", params: { langCode: this.langCode, id: "" } };
      const path = getEventRoutePath(item, this.langCode, routename, this.$route);
      return path;
    },
    /**
     * Function to set the table parameters (pageNo & itemsPerPage) in the route
     *
     * @param {boolean} routechangeCheck - To check if the function is called when route changes
     */
    paginationRoute(routechangeCheck = false): void {
      const url = new URL(document.location.href);
      const params = new URLSearchParams(url.search);
      params.set("itemsPerPage", String(this.itemsPerPage));
      params.set("pageNo", String(this.pageNo));

      if (routechangeCheck) {
        if (this.routeFilter.trim().length) params.set("filter", this.routeFilter);
        else params.delete("filter");
        if (this.routeQuery.trim().length) params.set("query", this.routeQuery);
        else params.delete("query");
      }

      this.$router.replace(`${url.pathname}?${params}`);
    },

    /**
     * Changes the current page to the page passed
     * as parameter also setting the Tabe parameters in route on page change
     *
     * @param  {number}  page to be set as current page in table
     */
    goToPage(page: number): void {
      if (this.pageExists(page)) {
        this.pageNo = page;
        this.paginationRoute();
      }
    },

    /**
     * Tells whether the the page passed as parameter
     * does exist in table
     *
     * @param {number} page to be checked for validity of the page
     */
    pageExists(page: number): boolean {
      const maxPages = Math.ceil(this.results.length / this.itemsPerPage);
      return page <= maxPages && page > 0;
    },

    /**
     * Function to calculate the serial no. of the first element
     * on the current page of table
     */
    calculateStartNo() {
      let i = 1;
      while (this.results.length <= (this.itemsPerPage * (this.pageNo - i))) {
        i += 1;
      }
      this.startNo = this.itemsPerPage * (this.pageNo - i);
    },
  },
});
