import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mx-10 mt-10 mb-10 font-arial"
}
const _hoisted_2 = { class: "mt-3 grid grid-cols-1 gap-5 sm:gap-6 sm:grid-cols-2 lg:grid-cols-4" }
const _hoisted_3 = { class: "bg-primary-1 ring-1 ring-gray-100 flex-shrink-0 flex items-center justify-center w-16 text-primary-4 text-base font-medium rounded-l-md dark:bg-dark-primary-3 dark:ring-1 dark:ring-dark-text-1" }
const _hoisted_4 = { class: "flex-1 flex items-center justify-between border border- gray-100bg-white rounded-r-md truncate dark:bg-dark-primary-2 dark:border-1 dark:border-dark-text-1" }
const _hoisted_5 = { class: "flex-1 px-4 py-3 text-sm md:text-base truncate" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BookOpenIcon = _resolveComponent("BookOpenIcon")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.examIdArray)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.examIdArray, (examId, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: examId,
              class: "col-span-1 flex shadow-sm rounded-md"
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_BookOpenIcon, { class: "h-6 w-6" })
              ]),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_router_link, {
                    class: "text-black font-medium hover:text-gray-500 hover:dark:text-gray-500 dark:text-dark-text-1",
                    to: _ctx.getExamRoute(examId, _ctx.getStringData(index))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.isTagNames ? _ctx.getStringData(index) : 'Loading...'), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ])
              ])
            ]))
          }), 128))
        ])
      ]))
    : _createCommentVNode("", true)
}