
import { defineComponent, PropType } from "@/plugins/vue";
import { InputValidation } from "@/helpers/interface";

export default defineComponent({
  name: "TextField",
  props: {
    /**
     * Tells whether the TextField is disabled
     * or not
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Label to be displayed on input
     */
    label: {
      type: String,
      default: "",
    },
    /**
     * Placeholder to be shown in input field
     */
    placeholder: {
      type: String,
      default: "enterValue",
    },
    /**
     * Tells whether the input field should be outlined or not
     */
    outlined: {
      type: Boolean,
      default: false,
    },
    /**
     * Array of rules used to verify the entered input
     */
    rules: {
      type: Array as PropType<InputValidation[]>,
      default: () => [],
      required: false,
    },
    /**
     * The input value
     */
    modelValue: {
      type: String,
      default: "",
    },
    /**
     * The icon to be displayed
     */
    icon: {
      type: Function,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      errorFound: false,
      errorMessage: "",
      inputVal: "",
    };
  },
  watch: {
    modelValue(val) {
      this.inputVal = val;
    },
  },
  created() {
    this.inputVal = this.modelValue;
  },
  methods: {
    /**
     * Updates the modelValue prop whne input is cvhanges by user
     * and also validates it using rules prop sets errorFound and errorMessage
     * if required
     */
    validateInput() {
      const entry = this.inputVal;
      this.errorFound = false;
      for (let i = 0; i < this.rules.length; i += 1) {
        const result = this.rules[i](entry);
        if (typeof (result) === "string") {
          this.errorFound = true;
          this.errorMessage = result;
          break;
        }
      }
      this.$emit("update:modelValue", entry);
    },
  },
});
