import { axiosCallWithHeaders } from "@/plugins/axiosInstances";
import getAlertsAPI from "@/api/alertsAPI";

export default {
  /**
   * Function translates the tags and values to particular language like hindi-hi,english-en
   *
   * @param root0
   * @param {object} root0.tagData - value is a object of tag or values
   * @param {string} root0.langCode - A language code like
   * 'hi' or 'en' in which object is being translated
   * @param root0.commit
   * @param root0.tagData.tagData
   * @param root0.tagData.langCode
   * @returns {string} data - string
   */
  async translateData({ commit }, { tagData, langCode }) {
    const payload = {
      tagId: tagData.id,
      langCode,
      tagData: {},
    };
    if (tagData.lang[langCode]?.string) payload.tagData.text = tagData.lang[langCode].string;
    if (tagData.lang[langCode]?.roman) payload.tagData.roman = tagData.lang[langCode].roman;
    payload.tagData.aliases = tagData.lang[langCode]?.aliases || [];
    const api = getAlertsAPI("UPDATE_TAG_TRANSLATION");
    const axiosInstance = await axiosCallWithHeaders();
    const { data } = await axiosInstance.post(api.url, payload, { API_ID: api.id });
    return data;
  },
};
