

import { XIcon } from "@/plugins/solidHeroicon";
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  name: "ActionSnackBar",
  components: {
    XIcon,
  },
  props: {
    persistent: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "",
    },
    message: {
      type: String,
      default: "",
    },
    actionText: {
      type: String,
      default: "",
    },
    icon: {
      type: Function,
      default: null,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["close", "actionSelect"],
  computed: {
    /**
     * Computed property to get the dark Mode
     */
    darkMode(): boolean {
      return this.$store.getters["base/getDarkMode"];
    },
  },
});
