
import { getCovertedKeysToLinks } from "@/helpers/latestUpdateTypeHandler";
import { changeToSentenceCase, camelCaseToDash } from "@/helpers/validations";
import { analyticsSelectItemFromList } from "@/helpers/analyticsEvents";
import { getEventRoutePath } from "@/helpers/routesHandler";
import { defineComponent, PropType } from "@/plugins/vue";
import { SpecificEvent, SpecificTypeEvents } from "@/scripts/interface";
import { RouteLocationRaw } from "@/plugins/interface";

interface EventItem {
  created: number;
  eventId: string;
  lastUpdated: number;
  title: { en: string; hi: string; };
  title_roman: { hi: string; };
  valueTimestamp: number;
}

export default defineComponent({
  name: "LatestUpdatesTemplate",
  components: {

  },
  props: {
    /**
    * List to iterate through
    */
    updateslist: {
      type: Object as PropType <SpecificTypeEvents>,
      default: () => { },
    },
  },
  data() {
    return {
      list: {} as SpecificTypeEvents,
    };
  },
  computed: {
    /**
    * Computed property to get route name of specific SpecificEvent
    */
    getEventRoute(): string {
      return `${this.$route.meta.mode}s${changeToSentenceCase(this.$route.meta.type as string)}LatestUpdates_${this.getLangCode}`;
    },
    /**
  * Computed property to get current Language type
  */
    getLangCode(): "en" | "hi" {
      return this.$store.getters["base/getLangCode"];
    },
  },
  /**
   * watcher to change in list when SpecificTypeEvents prop data has been changed
   */
  watch: {
    updateslist(val) {
      this.list = { ...val };
      this.filterLatestNotificationsEvent();
    },
  },
  created() {
    this.list = { ...this.updateslist };
    this.filterLatestNotificationsEvent();
  },
  methods: {
    getCovertedKeysToLinks,
    camelCaseToDash,
    changeToSentenceCase,

    /**
    * Function to get the route path to edit and view events
    *
    * @param {object} item - Current event item
    * @param {string} routeName - Name of route (eventEdit or eventView)
    * @return {object} RouteObject - url according to current language
    */
    setEventRoutePath(item: EventItem, routename: string) {
      if (!item) return { name: "", params: { langCode: this.getLangCode, id: "" } };
      const path = getEventRoutePath(item, this.getLangCode, routename, this.$route);
      return path;
    },

    /**
  * Method to Log SpecificEvent in firebase analytics when a user selects any item from the latest updates List
  *
  * @param {object} item - Object containing SpecificEvent information
*/
    logEvent(item: SpecificEvent, type: string): void {
      let itemListName = "";
      let itemCategory = "";
      itemListName = `${changeToSentenceCase(type)} list`;
      itemCategory = `${changeToSentenceCase(type)}`;
      analyticsSelectItemFromList(item.title.en, itemCategory, itemListName);
    },
    /**
    *Method to set the name of the route
    *
    * @param {string} route - Name of route
    * @param {string} index- Name of latest template
    * @returns {RouteLocationRaw} -  return object of name route for home page
    */
    getRouteName(routeName: string, index: string): RouteLocationRaw {
      const routeObject = {
        name: routeName,
        params: {
          langCode: this.getLangCode,
          latestUpdateType: `${getCovertedKeysToLinks(this.getLangCode, this.$route.meta.mode as "job" | "admission", camelCaseToDash(index))}`,
        },
      };
      return routeObject;
    },
    /**
    * Function to filter main list by removing empty items and removing latestNotifications on landing pages
    */
    filterLatestNotificationsEvent(): void {
      if (this.$route.name === `jobsHome_${this.getLangCode}` || this.$route.name === `admissionsHome_${this.getLangCode}`) {
        this.list.latestNotifications = [];
      }

      const filteredList = {} as SpecificTypeEvents;

      Object.keys(this.list).forEach((key) => {
        if (Object.keys(this.list[key as keyof SpecificTypeEvents])
          .length) filteredList[key as keyof SpecificTypeEvents] = this.list[key as keyof SpecificTypeEvents];
      });

      this.list = { ...filteredList };
    },
  },
});
