export default {
  /**
   * Updates the template data  in state of template module
   *
   * @param {object} state - Data store of the template module
   * @param {string} payload - payload containing the data to populate the templateData
   */
  UPDATE_TEMPLATE_DATA(state, payload) {
    state.templateData = {
      name: payload.name,
      type: payload.type,
      nameId: payload.nameId,
      value: payload.value,
      valueId: payload.valueId,
    };
  },
};
