import LangJSON from "@/assets/json/langCodes.json";
import store from "@/store/index.js";
import { getConvertedUpdateType } from "@/helpers/latestUpdateTypeHandler";
import type {
  DataObjectOfNameId, EventItem, EventItemData, RouteObject, TagName, LangCode,
} from "@/helpers/interface";
import type { RouteLocationNormalized } from "@/plugins/interface";
import { Lang, StaticFilesLang } from "@/scripts/interface";

/**
 * Function to get the redirected path and set lang code in store
 *
 * @param {string} langCode - the language code received
 * @param {string} pathToRedirectTo - The path to redirect to
 * @returns {string} redirectPath - The path to redirect to based on langCode
 */
function helperForRedirector(langCode: string, pathToRedirectTo: string): string {
  store.commit("base/SET_LANGUAGE_CODE", langCode);
  let path = "";
  let redirectPath = "";
  if (pathToRedirectTo === "jobsHomePage") path = langCode === "en" ? "govt-jobs" : "sarkari-naukri";
  else path = pathToRedirectTo;
  redirectPath = `/${langCode}/${path}`;
  return redirectPath;
}

/**
 * Function to return Home Path to redirect to
 * Preference 1: Pick langCode from local storage, if available
 * Preference 2: Pick langCode from browser language, if available
 * Preference 3: Else, pick langCode from primaryLangCode
 *
 * @param {string} pathToRedirectTo - The path to redirect to
 * @returns {string} path - The page path to redirect to
 */
function redirectToSpecificPath(pathToRedirectTo: string): string {
  // the static JSON File of lang codes
  const { langCodes, primaryLangCode } = LangJSON;

  // if there is any langCode set in the local storage
  const localLangCode = localStorage.getItem("langCode");
  if (localLangCode && langCodes.includes(localLangCode)) return helperForRedirector(localLangCode, pathToRedirectTo);

  // if any language set to the browser
  const browserLanguage = navigator.language.split("-")[0];
  if (langCodes.includes(browserLanguage)) return helperForRedirector(browserLanguage, pathToRedirectTo);

  // if there is no lang code found in local storage and browser, then set the primary code
  return helperForRedirector(primaryLangCode, pathToRedirectTo);
}

/**
 * Function to remove special characters from route path string
 *
 * @param {string} routePathString - The route path string
 * @returns {string} pathWithoutSpecialCharacters
 */
function removeSpecialCharacters(routePathString: string): string {
  if (!routePathString) return "";
  const pathWithoutSpecialCharacters = routePathString.replace(/[^a-zA-Z0-9]/g, " ");
  return pathWithoutSpecialCharacters;
}

/**
 * Function to remove multiple spaces and replace it with hyphens from route path string
 *
 * @param {string} routePathString - The route path string
 * @returns {string} pathWithHyphens - The route path seperated with hyphens
 */
function replaceSpacesWithHyphens(routePathString: string): string {
  if (!routePathString) return "";
  const pathWithHyphens = routePathString.replace(/\s+/g, "-").trim();
  return pathWithHyphens;
}

/**
 * Function to return the sanitized route path string
 *
 * @param {string} routePathString - The route path string
 * @returns {string} sanitized route path
 */
function sanitizeRoute(routePathString: string): string {
  let sanitizedRoute = removeSpecialCharacters(routePathString);
  sanitizedRoute = sanitizedRoute ? sanitizedRoute.trim() : "";
  sanitizedRoute = replaceSpacesWithHyphens(sanitizedRoute);
  return sanitizedRoute;
}

/**
 * Function to return the id and name from Id string
 *
 * @param {string} idString - The Id string passed as parameter
 * @returns {DataObjectOfNameId} object - contains the Id and Name as properties
 */

function getSeperatedIdAndName(idString: string): DataObjectOfNameId {
  const content = idString.split("-");
  const dataObject: DataObjectOfNameId = {
    id: content[0],
    name: content.slice(1).join("-"),
  };
  return dataObject;
}

/**
 * Function to return named route based on language
 *
 * @param {LangCode} langCode - Current lang code
 * @param {string} currentRouteName - Current route name
 * @returns {string} named route for current language
 */
function getNamedRoute(langCode: LangCode, currentRouteName: string): string {
  const routeName = `${currentRouteName.split("_")[0]}_${langCode}`;
  return routeName;
}

/**
 * Function to get the name and params of the route
 *
 * @param {string} langCode - Current lang code
 * @param {RouteLocationNormalized} route - The current route object
 * @returns {RouteObject} - The object containing name and params for route
 */
function getNamedRouteParams(langCode: LangCode, route: RouteLocationNormalized): RouteObject {
  const routeObject = {
    name: getNamedRoute(langCode, route.name as string),
    params: route.params,
  };
  routeObject.params.langCode = langCode;
  if (route.meta.pageType === "latestUpdatePage") {
    if (route.params.latestUpdateType) {
      const convertedName = getConvertedUpdateType(langCode, <"job" | "admission">route.meta.mode, <string>route.meta.type);
      routeObject.params.latestUpdateType = convertedName;
    }
  } else if (route.meta.pageType === "filteredTagListPage") {
    if (route.params.latestUpdateType) {
      const convertedName = getConvertedUpdateType(langCode, <"job" | "admission">route.meta.mode, <string>route.meta.filterType);
      routeObject.params.latestUpdateType = convertedName;
    }
  }
  return routeObject;
}

/**
 * Function to validate the id slug and id present in route
 *
 * @param {string} id - The id of tag or event
 * @param {string} name - The string stored at the given id
 * @param {RouteLocationNormalized} route - The current route object
 * @returns { { isValidated : boolean , idSlug : string } } - {idSlug, isValidated: boolean} The Id slug and validation result
 */
function validateRouteAndFetchSlug(id: string, name: string, route: RouteLocationNormalized): { idSlug: string; isValidated: boolean; } {
  const idSlug = sanitizeRoute(`${id}-${name}`);
  const routeId = route.params.id;
  const result = idSlug === routeId;
  return { idSlug, isValidated: result };
}

/**
 * Function to get the route path of homePage icons
 *
 * @param {TagName[]} array - array of the item
 * @param {string} id - id of the item
 * @param {number} index - index at which the item is present
 * @param {LangCode} langCode - Current lang code
 * @param {string} pathName - Input path name
 * @param {RouteLocationNormalized} route - current route object
 * @return {RouteObject} route path
 */
function getHomeRoutePath(
  array: StaticFilesLang[],
  id: string,
  index: number,
  langCode: LangCode,
  pathName: string,
  route: RouteLocationNormalized,
): RouteObject {
  const title = (LangJSON.romanLangCodes.includes(langCode) && langCode !== "en" && array[index]?.[langCode]?.roman)
    ? array[index][langCode]!.roman : array[index].en.string;

  return {
    name: getNamedRoute(langCode, pathName),
    params: { langCode, id: validateRouteAndFetchSlug(id, title, route).idSlug },
  };
}

/**
 * Function to get the route path to edit and view events
 *
 * @param {EventItem} item - Current event item
 * @param {LangCode} langCode - Current lang code
 * @param {string} routeName - Name of route (eventEdit or eventView)
 * @param {RouteLocationNormalized} route - The current route object
 * @return {RouteObject} RouteObject - url according to current language
 */
function getEventRoutePath(item: EventItem, langCode: LangCode, routeName: string, route: RouteLocationNormalized): RouteObject {
  const pathName = routeName === "eventView" ? `eventView_${langCode}` : `eventEdit_${langCode}`;
  const id = item.eventId;
  const title = (LangJSON.romanLangCodes.includes(langCode) && langCode !== "en" && item?.title_roman?.[langCode])
    ? item.title_roman?.[langCode] : item.title.en;

  return { name: pathName, params: { langCode, id: `${validateRouteAndFetchSlug(id, title, route).idSlug}` } };
}

/**
 * Function to get the route path,
 *
 * @param {EventItemData} item - Current event item
 * @param {LangCode} langCode - Current lang code
 * @param {string} routeName - Name of route
 * @param {RouteLocationNormalized} route - The current route object
 * @return {RouteObject} path - url according to current language
 */
function getRoutePath(item: EventItemData, langCode: LangCode, routeName: string, route: RouteLocationNormalized): RouteObject {
  const { id } = item;
  const title = (langCode !== "en" && LangJSON.romanLangCodes.includes(langCode) && item.lang?.[langCode]?.roman)
    ? item.lang[langCode]!.roman : item.lang.en.string;
  return { name: routeName, params: { langCode, id: `${validateRouteAndFetchSlug(id, <string>title, route).idSlug}` } };
}

/**
 * Function to set the roman title in the route
 *
 * @param {LangData} langObject - langcode object which has langcodes and their respective strings
 * @param {string} id - id of the langcode object
 * @param {LangCode} langCode - Current lang code
 * @param {RouteLocationNormalized} route - The current route object
 */

function setRomanTitle(langObject: Lang, id: string, langCode: LangCode, route: RouteLocationNormalized): void {
  if (route.meta.mode !== "create") {
    const routeName = (langCode !== "en" && langObject?.[langCode]?.roman) ? langObject[langCode]!.roman : langObject.en.string;
    const { idSlug } = validateRouteAndFetchSlug(id, routeName as string, route);
    // eslint-disable-next-line no-restricted-globals
    history.replaceState(history.state, "", idSlug);
  }
}

export {
  setRomanTitle,
  getRoutePath,
  getEventRoutePath,
  getHomeRoutePath,
  redirectToSpecificPath,
  sanitizeRoute,
  removeSpecialCharacters,
  replaceSpacesWithHyphens,
  getSeperatedIdAndName,
  getNamedRoute,
  getNamedRouteParams,
  validateRouteAndFetchSlug,
};
