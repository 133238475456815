import baseSalaryData from "@/assets/json/baseSalary.json";
import getAssetAPI from "@/api/assetsAPI";
import schemas from "@/assets/json/schemas.json";
import { NOTIFICATION_PLATFORM_URL, COMMUNITY_PLATFORM_URL } from "@/helpers/constants";
import { getCoreTagData, getCoreTagsDetails } from "@/scripts/workerAdapterConnector";
import { getSeperatedIdAndName } from "@/helpers/routesHandler";
import { RouteLocationNormalized, Router } from "@/plugins/interface";
import { API } from "@/api/interface";
import type {
  EducationalRequirements, FieldNameAndValue, EventData, CourseSchemaData,
  CoreDataObject, OrganizationSchemaProperties, LandingPageOrganisationSchema, JobPostingValues,
  NewsArticleSchema, CommonValues, CourseSchema, BreadCrumbListSchema, OrganizationSchema, JobPostingSchema,
  CourseValues, DataSetSchema, CourseDetails, JobPostingDetails,
} from "@/helpers/interface";

import { FieldName, FieldValue } from "@/scripts/interface";

const apiObject: API | null = getAssetAPI("ACCESS_LOGO");

// key value pairs for field educationRequirements
const educationalRequirements: EducationalRequirements = {
  Matriculation: "high school",
  Intermediate: "associate degree",
  Graduation: "bachelor degree",
  Diploma: "professional certificate",
  Postgraduation: "postgraduate degree",

};

// key value pairs for field employmentType
const employmentType = {
  Permanent: "FULL_TIME",
  Contractual: "PART_TIME",
};

/**
 * Function to remove the HTML tags from the string
 *
 * @param {string} str - string with html tags
 * @returns {string} - String without html tags
 */
function removeTags(str: string): string {
  let newString = "";
  if (str === "") return "";
  newString = str.toString();
  return newString.replace(/(<([^>]+)>)/ig, "");
}

/**
 * Function to get link of organisation
 *
 * @param { RouteLocationNormalized} route - the current route
 * @param {string} id - the id of organisation
 * @param {Router} router - an object of router to resolve links
 * @returns {string} link of organisation
 */
function getOrganizationLink(route: RouteLocationNormalized, id: string, router: Router): string {
  return typeof route.name === "string" ? router.resolve({ name: route.name, params: { ...route.params, id } }).href : "";
}

/**
 * Function to get highest base salary
 *
 * @param {keyof typeof baseSalaryData} newSalary - A new field Salary
 * @param {number} oldSalary - old salary
 * @return {number} highest salary
 */
function getBaseSalary(newSalary: keyof typeof baseSalaryData, oldSalary?: number): number {
  const updatedSalary = Number(newSalary);
  if (oldSalary) {
    if (!Number.isNaN(updatedSalary)) {
      return oldSalary > updatedSalary ? oldSalary : updatedSalary;
    }
    const baseSalary = Number(baseSalaryData[newSalary]);
    return oldSalary > baseSalary ? oldSalary : baseSalary;
  }
  return updatedSalary || Number(baseSalaryData[newSalary]);
}

/**
 * Function to filter fieldnames and fieldvalues
 *
 * @param {FieldName[]} fieldNames - all field names object
 * @param {FieldValue[]} fieldValues - all field values object
 * @returns {FieldNameAndValue}  filtered names and values
 */
function filterFields(fieldNames: FieldName[], fieldValues: FieldValue[]): FieldNameAndValue {
  const names = fieldNames.map((name) => name?.lang.en.string);
  const values = fieldValues.map((value) => value?.lang.en.string);
  return { names, values };
}

/**
 * Function to getConverted Date
 *
 * @param {Date | number} date - and date instance or a timestamp
 * @returns {string} modified structure of date
 */
function toLocaleString(date: Date | number): string {
  // Reference link to convert date in yyyy-mm-dd format
  // https://stackoverflow.com/a/63490548/17747846
  return new Date(date).toLocaleDateString("en-CA");
}

/**
 * Function to filter common values
 *
 * @param {EventData} data - the event data needed in seo
 * @param {FieldNameAndValue} fields -the object cointains field names and field field
 * @returns {CommonValues} the common values needed in seo
 */
function getCommonValues(data: EventData, fields: FieldNameAndValue): CommonValues {
  const eventData = {} as CommonValues;
  eventData.type = data.eventType;
  eventData.name = data.eventData.title.en;
  eventData.description = removeTags(data.eventData.description.en || "");
  eventData.forum = `${COMMUNITY_PLATFORM_URL}/d/${data.eventData.forumLink}`;
  eventData.orgName = data.organisation[0]?.lang.en.string;
  eventData.orgLogo = `${apiObject?.url}${data.eventData.organisationIds[0]}/logo.png`;
  const indexOfEducation = fields.names.indexOf("Educational Qualification");
  const indexOfWebsite = fields.names.indexOf("Website");
  eventData.orgWebsite = fields.values[indexOfWebsite];
  eventData.education = educationalRequirements[fields.values[indexOfEducation]] || fields.values[indexOfEducation];
  return eventData;
}

/**
 * Function to get filtered jobposting seo values
 *
 * @param {FieldNameAndValue} fields - the object cointains field names and field values
 * @param {EventData} eventObj - the object cointains data about event
 * @returns {JobPostingValues} the jobposting related data
 */
function getJobPostingValues(fields: FieldNameAndValue, eventObj: EventData): JobPostingValues {
  const eventData = {} as JobPostingValues;
  fields.names.forEach((fieldName: string, index: number) => {
    if (["Pay Matrix", "Salary"].includes(fieldName)) {
      const getSalary = getBaseSalary(fields.values[index] as keyof typeof baseSalaryData, eventData.salary);
      eventData.salary = getSalary;
    } else if (fieldName === "Post Type") eventData.employmentType = employmentType[fields.values[index] as keyof typeof employmentType];
    else if (fieldName === "Start Date") eventData.datePosted = toLocaleString(Number(fields.values[index]) * 1000);
    else if (fieldName === "Last date") eventData.validThrough = toLocaleString(Number(fields.values[index]) * 1000);
    else if (fieldName === "Post Name") eventData.title = fields.values[index];
    else if (fieldName === "Vacancy") eventData.totalJobOpenings = fields.values[index];
    else if (fieldName === "Place of Posting/Admission") {
      eventData.address = fields.values[index];
      eventData.addressRegion = eventObj.fieldValues[index]?.locationInfo?.state?.en.string || "";
      eventData.postalCode = eventObj.fieldValues[index]?.locationInfo?.pincode?.en.string || "";
      eventData.addressCountry = eventObj.fieldValues[index]?.locationInfo?.country?.en.string || "";
    }
  });
  return eventData;
}

/**
 * Function to get filtered course seo values
 *
 * @param {FieldNameAndValue} fields - the object cointains field names and field values
 * @returns {CourseValues} the course related data
 */
function getCourseValues(fields: FieldNameAndValue): CourseValues {
  const eventData = {} as CourseValues;
  const indexOfAward = fields.names.indexOf("Educational Credential Awarded");
  eventData.credentialAward = fields.values[indexOfAward];
  return eventData;
}

/**
 * Function to get all filtered values object to set event seo
 *
 * @param {EventData} data - event all data needed in seo
 * @returns {JobPostingDetails | CourseDetails | Error} filtered and needed values
 */
function getEventSchemaDetails(data: EventData): (JobPostingDetails | CourseDetails | Error) {
  const fields = filterFields(data.fieldNames, data.fieldValues);
  const commonValues = getCommonValues(data, fields);
  if (data.eventType === "job") {
    const jobPostingValues = getJobPostingValues(fields, data);
    return ({ ...commonValues, ...jobPostingValues });
  }
  if (data.eventType === "admission") {
    const courseValues = getCourseValues(fields);
    return ({ ...courseValues, ...commonValues });
  }
  throw new Error("Schema: Invalid event type");
}

/**
 * Function to get schema of organization
 *
 * @param {RouteLocationNormalized} route - the object of route
 * @param {string} title - the title of organization
 * @param {Router} router - an object of router to resolve links
 * @returns {Promise<OrganizationSchemaProperties | null>} the organisation schema properties
 */
async function getOrganizationSchema(route: RouteLocationNormalized, title: string, router: Router): Promise<OrganizationSchemaProperties | null> {
  const routeId = getSeperatedIdAndName(route.params.id as string).id;
  const coreTagData = await getCoreTagData("organisations", [routeId], true);
  if (!coreTagData) return null;

  const coreData = coreTagData[routeId as keyof { id: CoreDataObject; }];
  const data = await getCoreTagsDetails("organisations", { parent: coreData.parent, children: coreData.children }, true);

  if (!data) return null;
  // organisation logo
  const imageUrl = `${apiObject?.url}${routeId}/logo.png`;
  const siteLink = NOTIFICATION_PLATFORM_URL;
  // parent organisations
  const parentOrganization = Object.values(data.parent).map((org) => ({
    name: org.lang.en.string,
    link: `${siteLink}${getOrganizationLink(route, org.id, router)}`,
    image: `${apiObject?.url}${org.id}/logo.png`,
  }));
  // child organisations
  const childOrganization = Object.values(data.children).map((org) => ({
    name: org.lang.en.string,
    link: `${siteLink}${getOrganizationLink(route, org.id, router)}`,
    image: `${apiObject?.url}${org.id}/logo.png`,
  }));

  // main data
  const organizationSchema = {
    name: title,
    url: `${siteLink}${getOrganizationLink(route, route.params.id as string, router)}`,
    alternateNames: coreData.lang.en.aliases,
    parentOrganization,
    childOrganization,
    imageUrl,
  };

  return organizationSchema;
}
/**
 * Function to generate seo schema of organisation on landing page
 *
 * @returns {LandingPageOrganisationSchema} - the object of organisation schema for landing page
 */
function organizationLanding(): LandingPageOrganisationSchema {
  const org = JSON.parse(JSON.stringify(schemas.OrganizationLanding));
  return org;
}

/**
 * Function to generate seo schema of newsArticle
 *
 * @param {DataSetSchema} dataObj - which contains the details of newsArticle
 * @returns {NewsArticleSchema} - the object contains schema
 */
function newsArticle(dataObj: DataSetSchema): NewsArticleSchema {
  const news = JSON.parse(JSON.stringify(schemas.NewsArticle));

  if (dataObj.newsArticle) {
    news.mainEntityOfPage["@id"] = dataObj.newsArticle.id;
    news.headline = dataObj.newsArticle.title;
  }

  news.datePublished = toLocaleString(new Date("01/01/2022"));
  news.dateModified = toLocaleString(new Date());
  return news;
}

/**
 * Function to generate seo schema of BreadCrumbList
 *
 * @param {DataSetSchema} data - data for SEO schema
 * @returns {BreadCrumbListSchema} breadCrumbLists- the object contains schema
 */
function breadCrumbList(data: DataSetSchema): BreadCrumbListSchema {
  const breadCrumbLists = JSON.parse(JSON.stringify(schemas.BreadCrumbList));
  breadCrumbLists.itemListElement = [];

  data.breadCrumbList.forEach((item, index) => {
    breadCrumbLists.itemListElement.push({
      "@type": "ListItem",
      position: index + 1,
      name: item.name,
      item: `${NOTIFICATION_PLATFORM_URL}${item.link}`,
    });
  });
  return breadCrumbLists;
}

/**
 * Function to get organisation schema
 *
 * @param {DataSetSchema} data - the data of organization
 * @returns {OrganizationSchema } the schema of organisation
 */
function organization(data: DataSetSchema): OrganizationSchema {
  const organizationSchema = JSON.parse(JSON.stringify(schemas.Organization));
  organizationSchema["@id"] = data.organization?.url;
  organizationSchema.url = data.organization?.url;
  organizationSchema.legalName = data.organization?.name;
  organizationSchema.name = data.organization?.name;
  organizationSchema.alternateName = data.organization?.alternateNames;
  organizationSchema.logo = data.organization?.imageUrl;
  organizationSchema.parentOrganization = data.organization?.parentOrganization.map((org) => {
    const parent = {
      "@type": "Organization",
      "@id": org.link,
      legalName: org.name,
      logo: org.image,
    };
    return parent;
  });
  organizationSchema.subOrganization = data.organization?.childOrganization.map((org) => {
    const child = {
      "@type": "Organization",
      "@id": org.link,
      legalName: org.name,
      logo: org.image,
    };
    return child;
  });
  return organizationSchema;
}

/**
 * Function to get jobposting schema
 *
 * @param {JobPostingDetails} data - data for jobposting schema
 * @returns {JobPostingSchema} the jobposting schema
 */
function jobPosting(data: JobPostingDetails): JobPostingSchema {
  const schema = JSON.parse(JSON.stringify(schemas.JobPosting));
  schema.name = data.name;
  schema.applicationContact.url = data.orgWebsite;
  schema.totalJobOpenings = data.totalJobOpenings;
  schema.datePosted = data.datePosted;
  schema.validThrough = data.validThrough;
  schema.employmentType = data.employmentType || schema.employmentType;
  schema.jobLocation.address.streetAddress = data.address;
  schema.jobLocation.address.addressRegion = data.addressRegion;
  schema.jobLocation.address.postalCode = data.postalCode;
  schema.jobLocation.address.addressCountry = data.addressCountry;
  schema.jobLocation.address.addressLocality = data.address;
  schema.title = data.title || data.name;
  schema.baseSalary.value.value = data.salary;
  schema.employmentUnit.name = data.orgName;
  schema.hiringOrganization.name = data.orgName;
  schema.hiringOrganization.logo = data.orgLogo;
  schema.hiringOrganization.url = data.orgWebsite;
  schema.description = data.description;
  schema.educationRequirements.credentialCategory = data.education;
  schema.sameAs = [data.forum];
  return schema;
}

/**
 * Function to get course schema
 *
 * @param {CourseSchemaData} data - data for course schema
 * @returns {CourseSchema} the course schema
 */
function course(data: CourseSchemaData): CourseSchema {
  const schema = JSON.parse(JSON.stringify(schemas.Course));
  schema.name = data.name;
  schema.educationalCredentialAwarded.credentialCategory.name = data.credentialAward;
  schema.coursePrerequisites = data.education;
  schema.provider.name = data.orgName;
  schema.provider.logo = data.orgLogo;
  schema.provider.url = data.orgWebsite;
  schema.programPrerequisites.credentialCategory = data.education;
  schema.discussionUrl = data.forum;
  schema.description = data.description;
  return schema;
}
export {
  removeTags, organizationLanding, newsArticle, breadCrumbList, organization, getOrganizationSchema, getEventSchemaDetails, jobPosting, course,
};
