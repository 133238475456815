import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center" }
const _hoisted_2 = { class: "flex-shrink-0" }
const _hoisted_3 = { class: "ml-3" }
const _hoisted_4 = { class: "md:text-base text-sm font-medium text-white" }
const _hoisted_5 = { class: "ml-auto pl-3" }
const _hoisted_6 = { class: "-mx-1.5" }
const _hoisted_7 = { class: "sr-only" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_XIcon = _resolveComponent("XIcon")!

  return (_ctx.show)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass([ _ctx.type === 'error' ? 'bg-red-500' : 'bg-green-500', 'z-50 rounded-md p-4 bar mx-auto shadow-md w-fit fixed left-0 right-0 top-2'])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.icon), {
              class: "text-white h-6 w-6",
              "aria-hidden": "true"
            }))
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.text), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("button", {
                type: "button",
                class: _normalizeClass([_ctx.type === 'error' ? 'focus:ring-offset-red-50 focus:ring-red-600 hover:bg-red-400' :
                       'focus:ring-offset-green-50 focus:ring-green-600 hover:bg-green-400',
                     'inline-flex rounded-md p-1.5 text-white focus:outline-none focus:ring-2 focus:ring-offset-2']),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.hideBar && _ctx.hideBar(...args)))
              }, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(`dismiss`)), 1),
                _createVNode(_component_XIcon, {
                  class: "h-5 w-5",
                  "aria-hidden": "true"
                })
              ], 2)
            ])
          ])
        ])
      ], 2))
    : _createCommentVNode("", true)
}