const GOOGLE_ONE_TAP_URL = "https://accounts.google.com/gsi/client";
const PREPARATION_PLATFORM_URL = "https://exampathfinder.com";
const NOTIFICATION_PLATFORM_URL = "https://alert.exampathfinder.com";
const COMMUNITY_PLATFORM_URL = "https://community.exampathfinder.com";

const YOUTUBE_URL = "https://www.youtube.com/c/exampathfinder";
const TWITTER_URL = "https://twitter.com/ExamPathFinder";
const FACEBOOK_URL = "https://www.facebook.com/exampathfinder";
const INSTAGRAM_URL = "https://www.instagram.com/exampathfinder";

const ALERT_JSONS_VERSION = 1;

const GOOGLE_DOWNLOAD_URL = "https://www.google.com/chrome/";
const MOBILE_FILTER_ID = "filter_mobile";
const DESKTOP_FILTER_ID = "filter_desktop";

export {
  GOOGLE_ONE_TAP_URL,
  PREPARATION_PLATFORM_URL,
  NOTIFICATION_PLATFORM_URL,
  COMMUNITY_PLATFORM_URL,
  YOUTUBE_URL,
  TWITTER_URL,
  FACEBOOK_URL,
  INSTAGRAM_URL,
  ALERT_JSONS_VERSION,
  GOOGLE_DOWNLOAD_URL,
  MOBILE_FILTER_ID,
  DESKTOP_FILTER_ID,
};
