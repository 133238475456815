/**
 * Function to display console in production
 *
 * @param {string} message - Message to display
 * @param {any} data - Data to display
 * @param {string} functionName - Function in which display console called
 */
function displayConsole(message?: string, data?: any, functionName?: string) {
  const debugStatus = localStorage.getItem("isDebuggingEnabled");
  const isDebuggingEnabled = debugStatus ? ((debugStatus.toLowerCase() === "true")) : false;
  if (!isDebuggingEnabled) localStorage.setItem("isDebuggingEnabled", "false");
  if (isDebuggingEnabled) console.log(JSON.stringify({ message, functionName }), JSON.stringify(data));
}

export default displayConsole;
