import seoMetaData from "@/assets/json/seoMetaData.json";
import { getTagName } from "@/scripts/workerAdapterConnector";
import { getSeperatedIdAndName } from "@/helpers/routesHandler";
import LangJSON from "@/assets/json/langCodes.json";
import { RouteLocationNormalized } from "@/helpers/interface";
import { NonEnglishLangCode, TagType } from "@/scripts/interface";

interface Meta {
  title: string;
  description: string;
}

/**
 * Function to get the data from the static json
 *
 * @param {RouteLocationNormalized} routerObject the information about current route
 * @returns {Meta} a meta object contains title and description for the page
 */
function latestUpdatePage(routerObject: RouteLocationNormalized): Meta {
  // eslint-disable-next-line max-len
  return seoMetaData.latestUpdateObject?.[`${routerObject.name as string}_${routerObject.params.latestUpdateType}` as keyof typeof seoMetaData.latestUpdateObject];
}

/**
 * Async Function to get the data from dynamic object named staticDataTemplateObject
 *
 * @param {RouteLocationNormalized} routerObject the information about current route
 * @returns {Promise<Meta | null>} a meta object contains title and description for the page
 */
async function staticDataTemplatePage(routerObject: RouteLocationNormalized): Promise<Meta | null> {
  // the name of the event
  const itemId = getSeperatedIdAndName(routerObject.params.id as string).id;
  const eventName = await getTagName(routerObject.meta.type as TagType, [itemId]);
  // if event name is null or undefined return
  if (!eventName[0]) return null;
  // check if the langcode is other than english , if it is seeting roman title or else seeting english title
  let eventNameString = "";
  if (LangJSON.romanLangCodes.includes(routerObject.params.langCode as string)
    && eventName[0]?.[routerObject.params.langCode as NonEnglishLangCode]?.roman) {
    eventNameString = eventName[0][routerObject.params.langCode as NonEnglishLangCode]?.roman || "";
  } else eventNameString = eventName[0].en.string || "";
  return {
    title: seoMetaData.staticDataTemplateObject?.[routerObject.name as keyof
      typeof seoMetaData.staticDataTemplateObject]?.title.replaceAll("/$*{name}*$/", eventNameString),
    description: seoMetaData.staticDataTemplateObject?.[routerObject.name as keyof
      typeof seoMetaData.staticDataTemplateObject]?.description.replaceAll("/$*{name}*$/", eventNameString),
  };
}

/**
 * Async Function to get the data from dynamic object named filteredTagListObject
 *
 * @param {RouteLocationNormalized} routerObject the information about current route
 * @returns {Promise<Meta | null>} a meta object contains title and description for the page
 */
async function filteredTagListPage(routerObject: RouteLocationNormalized): Promise<Meta | null> {
  // the name of the event
  const itemId = getSeperatedIdAndName(routerObject.params.id as string).id;
  const eventName = await getTagName(routerObject.meta.type as TagType, [itemId]);
  // if event name is null or undefined return
  if (!eventName[0]) return null;
  // check if the langcode is other than english , if it is seeting roman title or else seeting english title
  let eventNameString = "";
  if (LangJSON.romanLangCodes.includes(routerObject.params.langCode as string)
    && eventName[0]?.[routerObject.params.langCode as NonEnglishLangCode]?.roman) {
    eventNameString = eventName[0][routerObject.params.langCode as NonEnglishLangCode]!.roman;
  } else eventNameString = eventName[0].en.string || "";

  // TODO REMOVE THIS WHEN COMPLETE META DATA IS RECEIVED AND STORED AS KEY VALUE PAIR
  const TagObject = seoMetaData.filteredTagListObject?.[routerObject.name as keyof typeof seoMetaData.filteredTagListObject];
  if (TagObject?.[routerObject.meta.filterType as keyof typeof TagObject]) {
    return {
      title: TagObject?.[routerObject.meta.filterType as keyof typeof TagObject]?.title.replaceAll("/$*{name}*$/", eventNameString),
      description: TagObject?.[routerObject.meta.filterType as keyof typeof TagObject]?.description.replaceAll("/$*{name}*$/", eventNameString),
    };
  }
  console.log(`Meta Error: Meta data unavailable for specific ${routerObject.meta.type} ${routerObject.meta.filterType}.`);
  return null;
}

/**
 * Function to get the meta data for all types of routes(static and dynamic)
 *
 * @param {Object} routerObject: the object of current router
 * @returns {Promise<Meta>} data: the object of title and description
 */
async function getMeta(routerObject: RouteLocationNormalized): Promise<Meta> {
  let data;
  const { pageType } = routerObject.meta;
  if (pageType === "latestUpdatePage") {
    data = latestUpdatePage(routerObject);
  } else if (routerObject.meta.pageType === "staticDataTemplatePage") { // if the page type is staticDataTemplatePage
    data = await staticDataTemplatePage(routerObject);
  } else if (routerObject.meta.pageType === "filteredTagListPage") { // if the page type is filteredTagListPage
    data = await filteredTagListPage(routerObject);
  } else data = seoMetaData.latestUpdateObject[routerObject.name as keyof typeof seoMetaData.latestUpdateObject];
  if (!data) data = { title: "epf-alert", description: "exampathfinder-alerts" };
  return data;
}

export default getMeta;
