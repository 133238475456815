
import { DownloadIcon } from "@/plugins/outlineHeroicon";
import ActionSnackbar from "@/components/Generic/ActionSnackbar.vue";
import { GOOGLE_DOWNLOAD_URL } from "@/helpers/constants";
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  name: "ChromeInstallSnackbar",
  components: {
    ActionSnackbar,
  },
  data: () => ({
    isVisible: true,
    downloadIcon: DownloadIcon,
  }),

  methods: {
    /**
     * On clicking the downloadIcon redirect to the google page to download google chrome
     */
    installChrome(): void {
      window.open(GOOGLE_DOWNLOAD_URL, "_blank");
    },
  },
});
