import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_section = _resolveComponent("heading-section")!
  const _component_exam_cards = _resolveComponent("exam-cards")!
  const _component_latest_updates_template = _resolveComponent("latest-updates-template")!
  const _component_no_information = _resolveComponent("no-information")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_heading_section, {
      title: _ctx.pageTitle,
      "enable-share": true,
      "is-specific-page-event-list": true
    }, null, 8, ["title"]),
    (_ctx.examIds?.length && _ctx.isShow)
      ? (_openBlock(), _createBlock(_component_exam_cards, {
          key: 0,
          class: "mt-8",
          "exam-ids": _ctx.examIds
        }, null, 8, ["exam-ids"]))
      : _createCommentVNode("", true),
    (_ctx.isShow)
      ? (_openBlock(), _createBlock(_component_latest_updates_template, {
          key: 1,
          class: "my-8",
          updateslist: _ctx.specificLatestUpdates
        }, null, 8, ["updateslist"]))
      : _createCommentVNode("", true),
    (_ctx.noData)
      ? (_openBlock(), _createBlock(_component_no_information, {
          key: 2,
          title: _ctx.$t(`noInformation`),
          message: _ctx.$t(`noInformationMessage`)
        }, null, 8, ["title", "message"]))
      : _createCommentVNode("", true)
  ]))
}