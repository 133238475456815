import { authPromise } from "@/plugins/firebase";
import { GOOGLE_ONE_TAP_URL } from "@/helpers/constants";
import store from "@/store/index.js";
import { analyticsSignIn, analyticsLogin } from "@/helpers/analyticsEvents";
import { Response } from "@/helpers/interface";
import { UserCredential, UserCredentialCustom } from "@/plugins/interface";

declare let google: any;

/**
 * Handler Function for the credential response returned by Google One Tap
 * Creates a Oath Creadential Object and uses it to sign in the user
 *
 * @param {object} response the response returned google for the login
 */

async function signIn(response: Response) {
  const authModule = await authPromise;
  authModule.signInWithCredential(authModule.getAuth(), authModule.GoogleAuthProvider.credential(response.credential)).then((res: UserCredential) => {
    store.commit("base/CHANGE_SIGN_IN_BOX_DISPLAY", false);
    // eslint-disable-next-line no-underscore-dangle
    const { isNewUser } = (res as UserCredentialCustom)._tokenResponse;
    // Fire login / sign up analytics event for one tap login
    const { providerId } = res;
    if (!providerId) return;
    if (isNewUser) analyticsSignIn(providerId);
    analyticsLogin(providerId);
  }).catch((err: Error) => {
    console.log(err);
  });
}

const googleOneTap = {

  /**
   * Loads the Google One Tap Script library
   * @returns {Promise<void>} A void promise that resolves when the library has loaded
   */
  loadScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.defer = true;
      script.async = true;
      script.src = GOOGLE_ONE_TAP_URL;
      document.body.append(script);
      script.addEventListener("load", () => { resolve(); });
    });
  },

  /**
 * Initializes the Google One Tap with client Id and callback function
 * for handling the response, must be called before showing prompt
 */
  initialize: () => {
    google.accounts.id.initialize({
      client_id: process.env.VUE_APP_FIRE_googleClientId,
      callback: signIn,
    });
  },

  /**
   * Function to show Google One Tap prompt
   */
  showPrompt: () => google.accounts.id.prompt(),
};

export default googleOneTap;
