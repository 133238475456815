import { wrap } from "../plugins/comlink";
import { getSearchCacheData, setSearchCacheData } from "./searchWorkerCache.ts";
import displayConsole from "../helpers/displayConsole";

const searchWorkerAPI = wrap(new Worker(new URL("./searchWorker.js", import.meta.url)));

/**
 * Function to fetch cache data from cache for search worker calls
 *
 * @param {string} methodName - Name of calling method
 * @param {object} payloadObject - Payload for API request
 * @returns {object} cachedData - Cached data or null
 */
function getCacheDataFromPayload(methodName, payloadObject) {
  const cacheKey = {
    methodName,
    ...payloadObject,
  };
  // ? Fetch data from cache, if cacheKey exists in cache or return null
  const cachedData = getSearchCacheData(cacheKey);
  return cachedData;
}

/**
 * Function to set cache data for search worker
 *
 * @param {string} methodName - Name of calling method
 * @param {object} payload - Payload for API request
 * @param {object} response - Response for payload
 */
function setCacheDataForPayload(methodName, payload, response) {
  const payloadKey = {
    methodName,
    ...payload,
  };
  setSearchCacheData(payloadKey, response);
}
/**
 * Returns top events and filters for a given tagIds
 *
 * @param {string[]} tagIds array of tagIds to search in events
 * @param {string} eventQuery The string to fuzzy search all over events
 * @param {boolean} includeFilters boolean value to specify whether filters have to be returned or not
 * @param {boolean} includeEvents boolean value to specify whether events have to be returned or not
 * @returns {{events: any[], filters: { organisations, exams, tags }}} object containing top evemts and filters
 */
async function getResults(tagIds, eventQuery, includeEvents, includeFilters) {
  const isCacheEnabled = true;
  try {
    // Fetch data from cache for given Key
    if (isCacheEnabled) {
      const cacheData = getCacheDataFromPayload("getResults", {
        tagIds, eventQuery, includeEvents, includeFilters,
      });
      if (cacheData !== null) {
        displayConsole("Cache: Response Received from Worker Cache", { ...cacheData }, "getResults");
        return cacheData;
      }
    }
    const data = await searchWorkerAPI.getResults(tagIds, eventQuery, includeEvents, includeFilters);

    // Set data into cache if cache is enabled, and return data
    if (isCacheEnabled) {
      setCacheDataForPayload("getResults", {
        tagIds, eventQuery, includeEvents, includeFilters,
      }, data);
    }
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}
/**
 * Returns specifc type events from filtered using the provided tagIds
 *
 * @param {string[]} tagIds array of tagIds to search in events
 * @param {number} count number of events to return
 * @returns {any[]} Array of specific type events
 */
async function getSpecificTypeFilteredEvents(tagIds, count) {
  const isCacheEnabled = false;
  try {
    // Fetch data from cache for given Key
    if (isCacheEnabled) {
      const cacheData = getCacheDataFromPayload("getSpecificTypeFilteredEvents", { tagIds, count });
      if (cacheData !== null) {
        console.log("Cache: Response Received from Worker Cache", { ...cacheData });
        return cacheData;
      }
    }
    const data = await searchWorkerAPI.getSpecificTypeFilteredEvents(tagIds, count);

    // Set data into cache if cache is enabled, and return data
    if (isCacheEnabled) setCacheDataForPayload("getSpecificTypeFilteredEvents", { tagIds, count }, data);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Returns filters for specifc type events
 *
 * @param {string} type - Event type - job or admission
 * @param {object[]} filter - specific page : category, exam, etc
 * @param {string[]} subFilter - latest update type
 * @returns {{ organisations, exams, tags }} events data
 */
async function getSpecificTypeEventFilters(type, filter, subFilter) {
  const isCacheEnabled = false;
  try {
    // Fetch data from cache for given Key
    if (isCacheEnabled) {
      const cacheData = getCacheDataFromPayload("getSpecificTypeEventFilters", {
        type, filter, subFilter,
      });
      if (cacheData !== null) {
        console.log("Cache: Response Received from Worker Cache", { ...cacheData });
        return cacheData;
      }
    }
    const data = await searchWorkerAPI.getSpecificTypeEventFilters(type, filter, subFilter);

    // Set data into cache if cache is enabled, and return data
    if (isCacheEnabled) {
      setCacheDataForPayload("getSpecificTypeEventFilters", {
        type, filter, subFilter,
      }, data);
    }
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Returns an array of tags matching search query
 * Searches the query in all tags and returns the merged sorted results
 *
 * @param {string} query string to search in tags
 * @returns {any[]} array of top tags matching events
 */
async function getSuggestions(query) {
  const isCacheEnabled = false;
  try {
    // Fetch data from cache for given Key
    if (isCacheEnabled) {
      const cacheData = getCacheDataFromPayload("getSuggestions", { query });
      if (cacheData !== null) {
        console.log("Cache: Response Received from Worker Cache", { ...cacheData });
        return cacheData;
      }
    }
    const data = await searchWorkerAPI.getSuggestions(query);

    // Set data into cache if cache is enabled, and return data
    if (isCacheEnabled) setCacheDataForPayload("getSuggestions", { query }, data);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

/**
 * Function to get suggestions for a specific type of tag
 *
 * @param {string} query - The search input by user
 * @param {string} type - Type of tag - fieldNames, exams, organisations, or fieldValues
 * @param {string} fieldNameId - Field Name Id for which suggestions are needed(in case of fieldvalue else null)
 * @param {number} limit - Number of suggestions to fetch
 * @returns {object} suggestions for a specific tag
 */
async function getAdminTagSuggestions(query, type, fieldNameId, limit) {
  const isCacheEnabled = false;
  try {
    // Fetch data from cache for given Key
    if (isCacheEnabled) {
      const cacheData = getCacheDataFromPayload("getAdminTagSuggestions", {
        query, type, fieldNameId, limit,
      });
      if (cacheData !== null) {
        console.log("Cache: Response Received from Worker Cache", { ...cacheData });
        return cacheData;
      }
    }

    const data = await searchWorkerAPI.getTagSuggestions(query, type, fieldNameId, limit);
    // Set data into cache if cache is enabled, and return data
    if (isCacheEnabled) {
      setCacheDataForPayload("getAdminTagSuggestions", {
        query, type, fieldNameId, limit,
      }, data);
    }
    return data;
  } catch (error) {
    console.log(error);
    return null;
  }
}

export {
  searchWorkerAPI, getResults, getSuggestions, getAdminTagSuggestions, getSpecificTypeEventFilters, getSpecificTypeFilteredEvents,
};
