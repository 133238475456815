import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_simple_data_table = _resolveComponent("simple-data-table")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_simple_data_table, {
      class: "w-full",
      headers: _ctx.headers,
      items: _ctx.items,
      "current-items-length": _ctx.currentItemsLength,
      "previous-items-length": _ctx.previousItemsLength,
      "is-static-data": _ctx.isStaticData,
      "enable-pagination-route": _ctx.enablePaginationRoute,
      onPageChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('pageChange', $event)))
    }, _createSlots({ _: 2 }, [
      _renderList(_ctx.$slots, (_, slot) => {
        return {
          name: slot,
          fn: _withCtx((props) => [
            (slot !== 'top')
              ? _renderSlot(_ctx.$slots, slot, _normalizeProps(_mergeProps({ key: 0 }, props)))
              : _createCommentVNode("", true)
          ])
        }
      })
    ]), 1032, ["headers", "items", "current-items-length", "previous-items-length", "is-static-data", "enable-pagination-route"])
  ]))
}