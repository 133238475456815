// Import interface
import type { AssetsAPI, API } from "./interface";

// Import store
const store = require("../store/index.js");

// URLs
const uploadURL: string = "https://ncdn.exampathfinder.com";
const accessURL: string = "https://dyncdn.exampathfinder.com/";

const assetAPI: AssetsAPI = {
  // Upload File Attachments and Logo
  UPLOAD_FILE_ATTACHMENTS: {
    url: uploadURL,
    headers: false,
    karma: 25,
    credentials: true,
    id: "ASSET#1",
  },

  // Access File Attachments
  ACCESS_FILE_ATTACHMENTS: {
    url: `${accessURL}epf_n_attachments/`,
    headers: false,
    karma: 1,
    credentials: false,
    id: "ASSET#2",
  },

  // Access Organisation Logo
  ACCESS_LOGO: {
    url: `${accessURL}epf_n_attachments/organisation/`,
    headers: false,
    karma: 0,
    credentials: false,
    id: "ASSET#3",
  },
};

/**
 * Function to check user karma before calling API and return the API object if the user has the minimum required karma to access the API
 *
 * @param {string} apiKey - The key of the API call whose karma value needs to be checked before access
 * @returns {object} APIObject - Object containing information for the specific API on successfully passing the user karma test
 */
function getAssetAPI(apiKey: keyof AssetsAPI): API | null {
  const userKarma = store.default.getters["user/getUserKarma"] as number;
  if (userKarma >= assetAPI[apiKey].karma) return assetAPI[apiKey];
  return null;
}

export default getAssetAPI;
