import eventData from "@/assets/json/event.json";

export default {
  event: eventData,
  fileAttachments: [],
  eventTypes: {
    recruitmentType: "8lw29cmS",
    stream: "LMGjNRLB",
  },
  eventTags: [],
  eventList: [],
  subscribedEventIds: [],
  subscribedExamIds: [],
  isSubscribedDataFetched: false,
};
