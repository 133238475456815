import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  "wire:loading": "",
  class: "relative flex flex-col items-center justify-center gap-y-4"
}
const _hoisted_2 = {
  key: 0,
  class: "mx-auto text-center text-lg"
}
const _hoisted_3 = { class: "text-gray-700" }
const _hoisted_4 = { class: "text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(`w-${_ctx.size} h-${_ctx.size} border-4 border-t-grey-4 border-primary-4
      dark:border-t-slate-1 dark:border-dark-link-text rounded-full animate-spin`)
    }, null, 2),
    (_ctx.showInfo)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.infoTitle), 1),
          _createElementVNode("h3", _hoisted_4, _toDisplayString(_ctx.infoText), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}