import LangJSON from "@/assets/json/langCodes.json";
import { MetaSourceProxy, Router, RouteLocationNormalized } from "@/plugins/interface";
import { validateRouteAndFetchSlug, getNamedRoute } from "@/helpers/routesHandler";
import { NOTIFICATION_PLATFORM_URL } from "@/helpers/constants";
import socialCardInfo from "@/assets/json/socialCardInfo.json";
import getAssetAPI from "@/api/assetsAPI";
import { getConvertedUpdateType } from "@/helpers/latestUpdateTypeHandler";
import { LangCode, Event } from "@/helpers/interface";

/**
 * Function to generates HTML / Open Graph and Twitter Card meta tags for vue-meta
 *
 * @param {title} - That contains title
 * @param {description} - That contains description
 * @param {imageLink} - That contains imageLink
 * @param {siteLink} - That contains siteLink
 * @return {object[]} - array of object(meta tags) for Open Graph/Facebook and Twitter Card
 */
function metaTagsGenerator(title: string, description: string, imageLink: string, siteLink: string) {
  const meta = [];
  // HTML Tags
  meta.push({ name: "title", content: title });
  // meta.push({ name: 'description', content: description })

  // Open Graph / Facebook
  meta.push({ property: "og:type", content: "website" });
  meta.push({ property: "og:url", content: siteLink });
  meta.push({ property: "og:title", content: title });
  meta.push({ property: "og:description", content: description });
  meta.push({ property: "og:site_name", content: "Exam Path Finder" });
  meta.push({ property: "og:image", content: imageLink || "/assets/favicon/maskable_icon_x48.png" });
  meta.push({ property: "og:image:width", content: 1200 });
  meta.push({ property: "og:image:height", content: 630 });
  meta.push({ property: "og:image:type", content: "image/png" });
  meta.push({ property: "og:image:alt", content: title });
  meta.push({ property: "og:locale", content: "en_IN" });
  meta.push({ property: "og:locale:alternate", content: "hi_IN" });

  // Twitter Card
  meta.push({ name: "twitter:card", content: "summary_large_image" });
  meta.push({ name: "twitter:site", content: siteLink });
  meta.push({ name: "twitter:title", content: title });
  meta.push({ name: "twitter:description", content: description });
  meta.push({ name: "twitter:image", content: imageLink || "/assets/favicon/maskable_icon_x48.png" });
  meta.push({ name: "twitter:image:alt", content: title });

  return meta;
}

function metaTagSlugRedirection(slugInfo: { matchFound: boolean; newSlug: string; }) {
  const meta = [] as {
    "http-equiv": string;
    content: string;
  }[];
  // conditional redirection to newSLug
  if (!slugInfo.matchFound) {
    meta.push({ "http-equiv": "refresh", content: `0; url=${slugInfo.newSlug}` });
  }
  return meta;
}
/**
 * Checks if roman is present in event object or not
 *
 * @param {string} langCode - language code like en,hi
 * @param {object} event - event information object
 * @returns {bollean} - true or false
 */
function checkRomanLangCode(langCode: string, event: Event) {
  const isRomanLangCode = LangJSON.romanLangCodes.includes(langCode);
  if (isRomanLangCode) {
    if (!("title_roman" in event)) return false;
    if (langCode in event.title_roman) return true;
    return false;
  }
  return false;
}
/**
 * Generate the alternate language link tag
 *
 * @param {object} event - event information object
 * @param {string} eventId - event id
 * @returns {object}  alternateLinkTags array of object
 */
function generateAlternateLinkTags(this: any, event: Event, eventId: string, route: RouteLocationNormalized) {
  const eventTitleLanguages = Object.keys(event.title);
  eventTitleLanguages.splice(eventTitleLanguages.indexOf("en"), 1);
  const siteLink = NOTIFICATION_PLATFORM_URL;
  let eventTitle = validateRouteAndFetchSlug(eventId, event.title.en, route) as { idSlug: string; isValidated: boolean; };
  const defaultRoute = this.$router.resolve({
    name: getNamedRoute("en", route.name as string),
    params: { langCode: "en", id: eventTitle.idSlug },
  });
  const alternateLinkTags = [{ rel: "alternate", hreflang: "x-default", href: `${siteLink}${defaultRoute.path}` }];
  const linkObj = { rel: "", hreflang: "", href: "" };
  for (let i = 0; i < eventTitleLanguages.length; i += 1) {
    linkObj.rel = "alternate";
    const routeObj = this.$router.resolve({
      name: getNamedRoute(eventTitleLanguages[i] as LangCode, route.name as string),
      params: { langCode: eventTitleLanguages[i], id: eventTitle.idSlug },
    });
    if (!(checkRomanLangCode(eventTitleLanguages[i], event))) {
      linkObj.hreflang = eventTitleLanguages[i];
      linkObj.href = `${siteLink}${routeObj.path}`;
    } else {
      const key = eventTitleLanguages[i] as keyof typeof event.title_roman;
      eventTitle = validateRouteAndFetchSlug(eventId, event.title_roman[key], route) as { idSlug: string; isValidated: boolean; };
      const routeObjLink = this.$router.resolve({
        name: getNamedRoute(eventTitleLanguages[i] as LangCode, route.name as string),
        params: { langCode: eventTitleLanguages[i], id: eventTitle.idSlug },
      });
      linkObj.hreflang = eventTitleLanguages[i];
      linkObj.href = `${siteLink}${routeObjLink.path}`;
    }
    alternateLinkTags.push(linkObj);
  }
  return alternateLinkTags;
}
/**
 * Function validates the rourte and redirects to the correct route
 *
 * @param {string} id - id of the item
 * @param {string} tagName - name of the item
 * @param {object} route - object containing route info
 * @param {object} meta - meta object
 */
function validateRouteAndRefresh(id: string, tagName: string, route: RouteLocationNormalized, meta: MetaSourceProxy) {
  const slugObj = validateRouteAndFetchSlug(id, tagName, route) as { isValidated: boolean; idSlug: string; };
  // if the link is not correct as eventSlug then redirect to correct page.
  if (!slugObj.isValidated) {
    const matchFound = slugObj.isValidated;
    const newSlug = slugObj.idSlug;
    const metaSlug = metaTagSlugRedirection({ matchFound, newSlug });
    // eslint-disable-next-line no-param-reassign
    meta.meta = metaSlug;
  }
}
/**
 *
 * Function to set the canonical tag of the current page
 *
 * @param {object} route - current route Object
 * @param {object} router - global property of router
 * @returns {{rel: string; href:string; hreflang:string;}} canonical tag
 */
function createCanonicalTag(route: RouteLocationNormalized, router: Router) {
  const siteLink = NOTIFICATION_PLATFORM_URL;
  if (route.name && typeof route.name === "string") {
    const routeObjParams = {
      name: getNamedRoute("en", route.name),
      params: { ...route.params, langCode: "en" },
    };
    const routeObj = router.resolve(routeObjParams);
    const canonicalTag = { rel: "canonical", href: `${siteLink}${routeObj.path}` };
    return canonicalTag;
  }
  return null;
}
/**
 * Function to get latest update type name for social card meta data
 *
 * @param {string} langCode - current lang code
 * @param {string} mode - Mode: job or admission
 * @param {string} type - latest update type (for ex: latest-results, latest-notifications etc.)
 * @returns {string}  return converted latest update type according to lang code
 */
function getLatestUpdateType(langCode: "en" | "hi", mode: "job" | "admission", type: string): string {
  if (langCode === "hi") { return getConvertedUpdateType("en", mode, type); }
  return type;
}

/**
 * Function to get update data for social card info (basically for replace string)
 *
 * @param {object} route - current route Object
 * @param {object} info - that contains title, description, and imageLink
 * @return {object} - return updated social card meta data (info)
 */
function getUpdateInfo(routeObj: RouteLocationNormalized, info: { title: string; description: string; imageLink: string; }) {
  const apiObject = getAssetAPI("ACCESS_LOGO");
  let imageLink = "";
  const name = (routeObj.params.id as string).split("-").slice(1).join(" ");

  if (["university", "organisation"].includes(routeObj.meta.type as string) && apiObject) {
    imageLink = `${apiObject.url}${(routeObj.params.id as string).split("-")[0]}/logo.png`;
  }
  return {
    imageLink,
    title: info.title.replaceAll("/$*{name}*$/", name),
    description: info.description.replaceAll("/$*{name}*$/", name),
  };
}
/**
 * Function to return social media card meta data
 *
 * @param {object} routeObj - current route Object
 * @return {object[]} - meta tags of social media card
 */
function setSocialCardInfo(routeObj: RouteLocationNormalized) {
  const { pageType, mode } = routeObj.meta as { pageType: keyof typeof socialCardInfo; mode: "job" | "admission"; };
  const objectSocialCardInfo = socialCardInfo[pageType];
  const routeName = (routeObj.name as string).split("_")[0] as keyof typeof objectSocialCardInfo;
  const { langCode } = routeObj.params as { langCode: "en" | "hi"; };
  let updatedType: string;
  let info = {} as { title: string; description: string; imageLink: string; };
  // If pageType is undefined then the routeName is pass in switch case
  switch (pageType || routeName) {
    case "landingPage":
      info = socialCardInfo[pageType][routeName];
      break;
    case "tagListPage":
      info = socialCardInfo[pageType][routeName];
      break;
    case "latestUpdatePage":
      updatedType = getLatestUpdateType(langCode, mode, routeObj.meta.type as string) as string;
      info = socialCardInfo[pageType][routeName][updatedType];
      break;
    case "staticDataTemplatePage":
      info = socialCardInfo[pageType][routeName];
      info = { ...getUpdateInfo(routeObj, info) };
      break;
    case "filteredTagListPage":
      updatedType = getLatestUpdateType(langCode, mode, routeObj.meta.filterType as string) as string;
      info = socialCardInfo[pageType][routeName][updatedType];
      info = { ...getUpdateInfo(routeObj, info) };
      break;
    case "searchPage":
      info = socialCardInfo[pageType] as { title: string; description: string; imageLink: string; };
      break;
    default:
    // In this case `default case` will not be executed any time
  }
  const siteLink = `${NOTIFICATION_PLATFORM_URL}${routeObj.path}`;
  const { title, description } = info;
  let { imageLink } = info;
  imageLink = encodeURI(`https://napi.exampathfinder.com/card/?title=${title}&img_url=${imageLink}&exams=`);
  // Generates metatags of (open Graph, twitter )
  const metaTags = metaTagsGenerator(title, description, imageLink, siteLink);
  return metaTags;
}

export {
  metaTagsGenerator, metaTagSlugRedirection, generateAlternateLinkTags, validateRouteAndRefresh, createCanonicalTag, setSocialCardInfo,
};
