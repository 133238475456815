/**
 * Function to test whether the given input data string is in camelCase format or not
 *
 * @param {string} testString - The string under test for camelCase format
 * @returns {boolean} True: String is in camelCase format | False: String is not in camelCase format
 */
function isCamelCase(testString: string): boolean {
  if (testString) {
    return !(/[\s]/g).test(testString) && !(/^[A-Z]/).test(testString);
  }
  return false;
}
/**
 * Function to convert the given input string data into camelCase format and return it
 *
 * @param {string} testString - The string to be converted to camalCase format
 * @returns {string} The converted input data string in camelCase format
 */
function camelize(testString: string): string {
  let camelizedString = "";
  if (testString) {
    camelizedString = testString.trim();
    const testPattern = /(^\S+|\b\S+)/g;
    if (!isCamelCase(testString)) {
      camelizedString = testString.toLowerCase();
      camelizedString = testString.replace(testPattern, (word) => word[0]
        .toUpperCase() + word.substring(1));
      camelizedString = testString[0].toLowerCase() + testString.substring(1);
      camelizedString = testString.replace(/\s/g, "");
      return camelizedString;
    }
  }
  return camelizedString;
}
/**
 * Function to test whether the given input string data is in PascalCase format or not
 *
 * @param {string} testString - The string under test for PascalCase format
 * @returns {boolean} True: String is in PascalCase format | False: String is not in PascalCase
 * format
 */
function isPascalCase(testString: string): boolean {
  if (testString) {
    return !(/[\s]/g).test(testString) && !(/^[a-z]/).test(testString) && (/[a-z]/g).test(testString);
  }
  return false;
}
/**
 * Function to convert the given input string data into PascalCase format and return it
 *
 * @param {string} testString - The string to be converted to PascalCase format
 * @returns {string} The converted input data string in PascalCase format
 */
function pascalize(testString: string): string {
  let pascalizeString = "";
  if (testString) {
    if (!isPascalCase(testString)) {
      pascalizeString = camelize(testString);
      return pascalizeString[0].toUpperCase() + pascalizeString.substring(1);
    }
  }
  return pascalizeString;
}
/**
 * Function to check whether the character passed to the function is a digit or not
 *
 * @param {string} character - The character from the string under test for being a digit
 * @returns {boolean} True: Character is a digit | False: Character is not a digit
 */
function isDigit(character: string): boolean {
  const num = character.charCodeAt(0);
  if (num >= 48 && num <= 57) {
    return true;
  }
  return false;
}

/**
 * Function to check whether the character passed to the function is an uppercase alphabet or not
 *
 * @param {string} character - The character from the string under test for being an uppercase
 * alphabet
 * @returns {boolean} True: Character is an uppercase letter | False: Character is not an uppercase
 *  letter
 */
function isUpper(character: string): boolean {
  const num = character.charCodeAt(0);
  if (num >= 65 && num <= 90) {
    return true;
  }
  return false;
}

/**
 * Function to convert the character passed to the function into its uppercase form
 *
 * @param {string} character - The character from the string to be converted to its uppercase form
 * @returns {string} The given character converted to its uppercase form
 */
function toUpper(character: string): string {
  return character.toUpperCase();
  // return null;
}

/**
 * Function to convert the input string data into Title Case format which is used as the display
 *  format for displaying stored data
 *
 * @param {string} testString - The string to be converted to Title Case format
 * @returns {string} newString: which is the Title Case format converted string from testString
 */
function filterString(testString: string): string {
  let newString = "";
  let stringTest = testString;
  // Trim string for spaces

  if (stringTest) {
    stringTest = stringTest.trim();
  }
  newString += toUpper(stringTest[0]);
  for (let i = 1; i < stringTest.length; i += 1) {
    if (isUpper(stringTest[i]) || isDigit(stringTest[i])) {
      const len = newString.length;
      // Remove '.',"'" from set
      const charArr = ["(", "-", ",", ")", "/"];
      if (!charArr.includes(newString[len - 1])) {
        newString += " ";
      }
      newString += stringTest[i];
    } else if (stringTest[i] === "-") {
      // Space before and after
      newString += " ";
      newString += stringTest[i];
      newString += " ";
    } else if (stringTest[i] === "(") {
      // Space before
      newString += " ";
      newString += stringTest[i];
    } else if (stringTest[i] === "," || stringTest[i] === ")") {
      // Space after
      newString += stringTest[i];
      newString += " ";
    } else {
      // No space
      newString += stringTest[i];
    }
  }
  newString = newString.replace(/ [ ]+/g, " ");
  // Regex for testing spaces between digits
  const testDigitSpace = /([\d]+)([\s])([\d]+)/g;
  while (testDigitSpace.test(newString)) {
    newString = newString.replace(testDigitSpace, "$1$3");
  }
  return newString.trim();
}
/**
     * Function to removing hyphen (-) and convert the string in camel case conversion
     *
     * @param {String} string : link string which needs to convert in camelcase
     * @returns {String} camel case string
     *
     */
function camelCaseConversion(string: string): string {
  // split the string on hyphen (-) basis
  if (string) {
    let modifiedLinkNames = string.split("-").filter(Boolean);
    modifiedLinkNames = modifiedLinkNames.map((item, index) => {
      if (index !== 0) {
        if (!(Number.isNaN(item[0]))) {
          return item[0].toUpperCase() + item.slice(1);
        }
      }
      return item;
    });
    return modifiedLinkNames.join("");
  }
  return "";
}

export {
  filterString, camelize, pascalize, camelCaseConversion,
};
