export default {
  "usefulLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" useful links"])},
  "importantLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" important links"])},
  "quickLinks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" quick links"])},
  "lorem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam."])},
  "latestNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Notification"])},
  "admitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Admit Card"])},
  "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Results"])},
  "walkInInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Walk-In Interview"])},
  "fellowshipApprenticeship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Fellowship / Apprenticeship"])},
  "sitemap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sitemap"])},
  "aboutUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "pricingAndRefundPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing and Refund Policy"])},
  "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disclaimer"])},
  "termsAndConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms and Conditions"])},
  "privacyPolicy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
  "communityForum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community Forum"])},
  "preparationPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation Platform"])},
  "rightReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inc. All rights reserved."])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
  "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
  "eventList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event List"])},
  "fieldList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field List"])},
  "organisationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation List"])},
  "examList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam List"])},
  "translationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation List"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "collegeAdmissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["College Admissions"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community"])},
  "preparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preparation"])},
  "selectItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Item"])},
  "openMainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open main menu"])},
  "signIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
  "viewNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Notification"])},
  "signOut": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Out"])},
  "closeMainMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close main menu"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
  "yourProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Profile"])},
  "openUserMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Open user menu"])},
  "darkMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark Mode"])},
  "viewMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View More"])},
  "viewLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View Less"])},
  "lastDateSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Date Soon"])},
  "exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Category"])},
  "streams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streams"])},
  "popular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular"])},
  "latestNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Notifications"])},
  "latestAdmitCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Admit Card"])},
  "latestWalkInInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Walk In Interview"])},
  "latestResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Results"])},
  "top": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
  "organisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisations"])},
  "institutions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institutions"])},
  "stateWise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State wise"])},
  "admission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions"])},
  "job": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])},
  "jobByQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs by Qualification"])},
  "admissionByQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admissions by Qualification"])},
  "communityPlatform": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Community Platform"])},
  "connectWithOtherPlatforms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stay Connected to Other Platforms"])},
  "goBackHome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back home"])},
  "pageNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page Not Found"])},
  "pageNotFoundMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please check the URL in the address bar and try again."])},
  "eventStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Status"])},
  "createdEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Created Event"])},
  "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe"])},
  "unsubscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe"])},
  "forum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuss"])},
  "editEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Event"])},
  "eventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Information"])},
  "eventDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Description"])},
  "attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments"])},
  "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
  "eventHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event History"])},
  "continueWithProviders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue with providers"])},
  "signUpTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By signing up, you agree to our Terms of Use and Privacy Policy."])},
  "examPathFinder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Path Finder"])},
  "attachmentsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachments List"])},
  "similarResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Similar Results"])},
  "fieldInputRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field input is required!"])},
  "noDataFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found!"])},
  "rowsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rows per page"])},
  "dismiss": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dismiss"])},
  "visitNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visit Now"])},
  "lastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Updated"])},
  "noResultFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No result Found"])},
  "noDataAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" No Data Available "])},
  "s.No": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S.No"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
  "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])},
  "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["of"])},
  "notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
  "notificationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Subscribe some events to get Notifications for that event..."])},
  "subscribedOn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed on"])},
  "light": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Light"])},
  "dark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dark"])},
  "system": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System"])},
  "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
  "postBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post By"])},
  "lastUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Update"])},
  "eventTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Title"])},
  "newEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Event"])},
  "vidyamantra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vidyamantra"])},
  "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
  "phoneNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone Number"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "notificationPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification Preference"])},
  "linkAccounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Link Accounts"])},
  "signInWithEPF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in faster with one click by integrating one of your accounts below with your EPF account"])},
  "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Name"])},
  "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
  "subscribedEvents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Events"])},
  "subscribedSearches": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Searches"])},
  "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List"])},
  "searchPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is search page"])},
  "latestPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Post"])},
  "popularPost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular Post"])},
  "fieldValuesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Values List"])},
  "fieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
  "examPageText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is exam page"])},
  "dashboardMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please subscribe events to see notifications"])},
  "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "showingPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing Page"])},
  "latestDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Date"])},
  "latestFellowships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Fellowship / Apprenticeship"])},
  "popularExamDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Providing you here, all the notifications and updates about the government exams for jobs across India. You can select your desired sector and get all the information of the jobs in that field."])},
  "popularStreamDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here you will get all the notifications of admission exams, admit cards, results, and many more notifications, to help you get a job in your desirable stream. "])},
  "topOrganisationsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An enormous range of organisations to select from. We are providing you all the job alerts and notifications here to get a government job in your favourite organisation."])},
  "topInstitutionsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the best suitable institution for your career from our vast list of top institutions and colleges and get informed about all the notifications and updates about them. "])},
  "otherPlatformsDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our purpose is to give you overall exam preparation experience by our other two platforms."])},
  "communityPlatformDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our discussion platform is where you can ask questions, share your answers and connect with people with similar interests."])},
  "preparationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Practice, learn, and achieve your goal through our free mock tests,practice papers and analysis report of your performance for all Government exams."])},
  "jobByQualificationDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose your educational qualifications and get the list of jobs suitable for you. We are providing you the informations about the best jobs to fit in, as per your education. "])},
  "stateWiseJobDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Here are all the details and alerts of jobs on the basis of your choice of the states. Select your favourite place and get the job notifications to get posted there."])},
  "stateWiseAdmissionDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can visit here to get the notifications and updates of admissions in colleges and universities of your state or interested state."])},
  "admissionByQualificatonDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get all the information for your upcoming course admissions based on your previous qualifications. Select your qualification and get all the available options for your further education."])},
  "jobAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Alert"])},
  "admissionAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admission Alert"])},
  "universityList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution List"])},
  "university": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution"])},
  "translation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translation"])},
  "fieldname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
  "stateList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["State List"])},
  "pageNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page no"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event"])},
  "fieldType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Type"])},
  "aliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aliases"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
  "logo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo"])},
  "organisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Name"])},
  "examName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Name"])},
  "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Showing"])},
  "Fields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
  "values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Values"])},
  "Exams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exams"])},
  "Exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
  "Organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tags"])},
  "roman": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Romans"])},
  "tagName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tag Name"])},
  "FieldName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
  "pendingCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending/Completed"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "addexam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Exam"])},
  "addorganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Organisation"])},
  "addField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Field"])},
  "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
  "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Range"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
  "saveEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Edit"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
  "enterValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter Value"])},
  "createField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Field"])},
  "editField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Field"])},
  "addAliases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Aliases"])},
  "fieldInputIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field input is required"])},
  "fieldInputCanNotBeABlankString": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field input can not be a blank string"])},
  "fieldInputCanNotContainAnApostrophe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field input can not contain an apostrophe ( ' )"])},
  "onlyDigitsAndDecimalNumbersAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only digits and decimal numbers allowed"])},
  "onlyDigitsAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only digits allowed"])},
  "onlyAlphabetsDigitsAndSpacesAllowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Only alphabets, digits, and spaces allowed"])},
  "allowedSpecialCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allowed special characters: (  )  .  -  / , "])},
  "createOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Organisation"])},
  "editOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Organisation"])},
  "createExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Exam"])},
  "editExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Exam"])},
  "examCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam Code"])},
  "childrenTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Children Tags:"])},
  "noChildrenTags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No children tags"])},
  "attachExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Exam:"])},
  "organisationLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Logo"])},
  "uploadOrganisationLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Organisation Logo"])},
  "attachOrganisationParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Organisation Parent"])},
  "attachExamParent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Exam Parent"])},
  "saveOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Organisation"])},
  "saveExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save Exam"])},
  "attachFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attach Fields"])},
  "pleaseEnterYourExamCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your exam code"])},
  "pleaseEnterYourExamName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your exam name"])},
  "pleaseEnterYourOrganisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your organisation name"])},
  "fieldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Value"])},
  "minimumValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Value"])},
  "maximumValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximum Value"])},
  "pickDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick Date"])},
  "addLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Location"])},
  "duplicateField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Field"])},
  "deleteField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete Field"])},
  "addNumberValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Number Value"])},
  "addEventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Event Information"])},
  "editEventInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Event Information"])},
  "eventStatusInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Status Information"])},
  "statusTitleEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Title (In English)"])},
  "statusTitleEnPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Status Title (In English)"])},
  "statusTitleHi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Title (In Hindi)"])},
  "statusTitleHiPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Status Title (In Hindi)"])},
  "statusMessageEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Message (In English)"])},
  "statusMessageEnPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Status message (In English)"])},
  "statusMessageHi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status Message (In Hindi)"])},
  "statusMessageHiPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Status message (In Hindi)"])},
  "publish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PUBLISH"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
  "publishChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PUBLISH CHANGES"])},
  "eventDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Details"])},
  "uploadAttachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload Attachments"])},
  "eventTitleEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Title (In English)"])},
  "eventTitleHi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Title (In Hindi)"])},
  "eventTitleRm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Title (In Hindi Roman)"])},
  "eventTitleEnPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the event title (In English)"])},
  "eventTitleHiPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the event title (In Hindi)"])},
  "eventTitleRmPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the event title (In Hindi Roman)"])},
  "noFileUploadedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No files have been uploaded for this event"])},
  "uploadFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload a file"])},
  "dragAndDropFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or drag and drop"])},
  "eventDescirptionEn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Description (In English)"])},
  "eventDescirptionHi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Description (In Hindi)"])},
  "eventDescirptionEnPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Event Description (In English)"])},
  "eventDescirptionHiPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter the Event Description (In Hindi)"])},
  "pleaseChooseOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose an organisation"])},
  "pleaseSelectExams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please select exams"])},
  "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
  "sendNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Notification?"])},
  "sendNotificationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to send this change as a Notification ?"])},
  "confirmRelocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Relocation"])},
  "confirmRelocationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to proceed? Changes made will be lost!"])},
  "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value"])},
  "valueType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value Type"])},
  "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])},
  "addValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Value"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View"])},
  "latestScholarships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Scholarship / Fellowship / Apprenticeship"])},
  "addEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Event"])},
  "updateEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Event"])},
  "exam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exam"])},
  "comingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coming Soon"])},
  "pwaVersionUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version Update"])},
  "pwaUpdateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New version is available. Please refresh."])},
  "refreshPWA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refresh"])},
  "verifyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verify Email"])},
  "sarkariResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Results"])},
  "noInternetConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Internet Connection"])},
  "offline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offline"])},
  "offlineMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You are offline, please connect to a working internet connection"])},
  "installApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install app"])},
  "installMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get the EPF app, for instant access to all alerts."])},
  "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select Country"])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscriptions"])},
  "eventLastUpdated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Last Updated"])},
  "eventCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Event Created"])},
  "lastDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last Date"])},
  "admitCardDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admit Card Date"])},
  "resultDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Result Date"])},
  "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Date"])},
  "latestInterview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Interview"])},
  "updatingSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Updating Soon ... "])},
  "scholarships": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Scholarship"])},
  "interview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Interview"])},
  "noInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No New Information"])},
  "noInformationMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No new updates available for this section at the moment."])},
  "subscriptionList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription List"])},
  "subscribedExams": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed Exams"])},
  "locationList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location List"])},
  "otherStates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other States"])},
  "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
  "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
  "twitter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Twitter"])},
  "gmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gmail"])},
  "copyToClipboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy to Clipboard"])},
  "subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribed"])},
  "unsubscribeEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe Event"])},
  "unsubscribeExam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unsubscribe Exam"])},
  "recentUpdates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Updates"])},
  "installChrome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Install Chrome"])},
  "installChromeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notification display is not supported on your browser. Please install chrome to see notification."])},
  "alertDialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your notifications live here"])},
  "alertDialogDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe to your favorite exams to get notified about their latest updates"])},
  "orgs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orgs"])},
  "translate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Translate"])},
  "locations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locations"])},
  "EPFSearchPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPF search page"])},
  "allowNotifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow notifications"])},
  "allowNotificationsMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allow Notifications to get updates at the latest."])},
  "bannerHeadingOnJobAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs at a glance"])},
  "bannerDescriptionOnJobAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get speedy alerts on all types of Govt. Jobs for Central Govt., State Govt. and autonomous bodies"])},
  "bannerHeadingOnAdmissionAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explore Admission opportunities"])},
  "bannerDescriptionOnAdmissionAlert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get alerts on subscribed admissions including State, UT’s and Central Govt. Universities  "])},
  "exploreCommunity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discuss, suggest and ask your queries"])},
  "explorePreparation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excel your preparation with our free mock test"])},
  "duplicateCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate Check"])},
  "createEvent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Event"])},
  "noResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Results Found"])},
  "noResultMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, No matching results found for your query."])},
  "initSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initializing search, please wait..."])},
  "fetchResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please wait, fetching search results..."])},
  "filterResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Loading filtered results, please wait..."])},
  "loadingFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loading Filters..."])}
}