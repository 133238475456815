import { analyticsLogger } from "@/plugins/firebase";
import EventBus from "@/helpers/eventBus";
import { ExclamationCircleIcon } from "@/plugins/solidHeroicon";

/**
 * Event listener for tracking JavaScript errors throughout the platform,
 Log it to the firebase server,
 * and display appropriate snackbar message
 *
 * @param {object} e - The error event object containing information about the error
 */
window.addEventListener("error", (e) => {
  console.error(e.message);
  // analytics
  analyticsLogger("windows_error", { description: `Error message: ${e.message}`, fatal: false });
  EventBus.$emit("displayMessage", {
    message: "EPF error: An error occurred.",
    icon: ExclamationCircleIcon,
    type: "error",
  });
});

/**
 * Event listener for tracking Unhandled Rejections throughout the platform,
   Log it to the firebase server,
 * and display appropriate snackbar message
 *
 * @param {object} event - The error event object containing information about the error
 */
window.addEventListener("unhandledrejection", (event) => {
  console.error(event.reason);
  if (event.reason.message === "apiFailed") return;
  analyticsLogger("windows_warning", { description: event.reason, fatal: true });
  EventBus.$emit("displayMessage", {
    message: "EPF unhandled rejection:Please refresh your window.",
    icon: ExclamationCircleIcon,
    type: "error",
  });
});

/**
 * Error Handler for tracking API errors throughout the platform, Log it to the firebase server,
 * and display appropriate snackbar message
 *
 * @param {string} apiId - The ID of API
 * @param {string} userMessage - The error message to display to the user
 * @param {Error} error - Error Message is sent to error
 */
EventBus.$on("apiError", (apiId: string, userMessage: string, error: Error) => {
  analyticsLogger("exception", { description: `Error : ${apiId}: ${error}`, fatal: false });
  EventBus.$emit(
    "displayMessage",
    {
      message: `Error ${apiId}: ${userMessage}`,
      icon: ExclamationCircleIcon,
      type: "error",
    },
  );
});
