
import { defineComponent } from "@/plugins/vue";
import renderComponent from "@/helpers/componentKarma";
import { Event } from "@/scripts/interface";

interface EventNotificationType {
  message: {
    en: string;
    hi: string;
  };
  timestamp: number;
  title: {
    en: string;
    hi: string;
  };
}

interface NotificationType {
  [key: string]: EventNotificationType;
}
interface EventType extends Event {
  component: string;
  component_id: string;
  createdby: string;
  description: { en: string; hi: string; };
  fileAttachments: { fileLink: string; fileName: string; fileState: string; }[];
  forumLink: string;
  notifications: NotificationType;
  tags: { nameId: string; valueId: string; }[];
}

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";
export default defineComponent({
  name: "EventStatusLog",
  emits: ["readNotification"],
  data: () => ({
    headers: [
      {
        text: "status", value: "title", sortable: true, hidden: false,
      },
      {
        text: "description", value: "message", sortable: true, hidden: true,
      },
      {
        text: "date", value: "timestamp", sortable: false, hidden: false,
      },
    ],
    loading: false,
    mode: "",
  }),
  computed: {
    /**
     * Fetch the sorted event notifications (ascending order of timestamps)
     *
     * @returns {EventNotificationType[]} - sorted event notifications
     */
    sortedNotifications(): EventNotificationType[] {
      return Object.values(this.getEventNotification).sort((a, b) => a.timestamp - b.timestamp);
    },

    /**
     * Fetch the event notifications
     *
     * @returns {NotificationType} event notification
     */
    getEventNotification(): NotificationType {
      return this.getEvent ? this.getEvent.notifications : {};
    },

    /**
     * Function to fetch event from store
     *
     * @returns {EventType} - current event
     */
    getEvent(): EventType {
      return this.$store.getters["events/getEvent"];
    },
    /**
     * Gets lang code from the base module in Store
     *
     * @returns {"en"|"hi"} - langCode
     */
    langCode(): "en" | "hi" {
      return this.$store.getters["base/getLangCode"];
    },
  },
  methods: {
    renderComponent,
    /**
     * to send existing notification data to parent component and scroll page to top
     * @param {Object} - existing notification data
     */
    editNotification(notificationInfo: EventNotificationType): void {
      this.$emit("readNotification", notificationInfo);
    },
  },
});
