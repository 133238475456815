
import { defineComponent } from "@/plugins/vue";
import LatestNotifications from "@/components/HomePage/HomePageLatestNotifications.vue";
import TopOrganisations from "@/components/HomePage/HomePageOrganisations.vue";
import PopularExams from "@/components/HomePage/HomePageCategory.vue";
import EducationalCategory from "@/components/HomePage/HomePageQualifications.vue";
import TopStates from "@/components/HomePage/HomePageStates.vue";
import OtherPlatforms from "@/components/HomePage/HomePageOtherPlatforms.vue";
import LatestUpdateTemplate from "@/components/Shared/LatestUpdates/LatestUpdatesTemplate.vue";
import { getLandingPageEvents } from "@/scripts/workerAdapterConnector";
import generateSeoSchema from "@/helpers/seoSchema";
import EventBus from "@/helpers/eventBus";
import HomePageBanner from "@/components/HomePage/HomePageBanner.vue";
import { RouteLocationNormalized } from "@/plugins/interface";
import { DataSetSchema } from "@/helpers/interface";
import { EventType, SpecificTypeEvents } from "@/scripts/interface";

export default defineComponent({
  name: "HomePage",
  components: {
    LatestNotifications,
    TopOrganisations,
    PopularExams,
    EducationalCategory,
    TopStates,
    OtherPlatforms,
    LatestUpdateTemplate,
    HomePageBanner,
  },
  data() {
    return {
      isShow: false,
      latestEvents: {} as SpecificTypeEvents,
    };
  },
  watch: {
    /**
     * Watcher to watch for changes in route
     *
     * @param {object} newRoute - the current new route
     * @param {object} oldRoute - the previous route
     */
    $route(newRoute: RouteLocationNormalized, oldRoute: RouteLocationNormalized) {
      const oldRouteName = (oldRoute.name as string).split("_")[0];
      const newRouteName = (newRoute.name as string).split("_")[0];
      if (!["jobsHome", "admissionsHome"].includes(newRouteName)) return;
      if (oldRouteName !== newRouteName) this.setLatestEvents();
    },
  },
  /**
   * Fetch latest event on created hook
   */
  created() {
    this.setLatestEvents();
  },
  methods: {
    /**
     * Function to get landing page events for both job or admission based on route mode
     */
    setLatestEvents() {
      (getLandingPageEvents(this.$route.meta.mode as EventType, 10)).then((events) => {
        this.latestEvents = events;
        this.isShow = true;
        // Emit dataLoaded event to load footer
        EventBus.$emit("dataLoaded", true);

        // Emit dataForScroll event to load scroll bar
        this.$nextTick().then(() => EventBus.$emit("dataForScroll"));
      });
      this.setSeoSchema();
    },

    /**
     * Function to set seo schema
     */
    setSeoSchema() {
      const routeName = (this.$route.name as string).split("_")[0];
      // get schema
      const schema = generateSeoSchema(routeName as string, {} as DataSetSchema);

      // set schema
      this.$meta.script = [
        {
          json: schema,
          type: "application/ld+json",
        },
      ];
    },
  },
});
