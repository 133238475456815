
import { Disclosure, DisclosureButton, DisclosurePanel } from "@/plugins/headlessui";
import { ChevronDownIcon } from "@/plugins/outlineHeroicon";
import FilterComboBox from "@/components/Shared/FilterHelpers/FilterComboBox.vue";
import FilterCheckBox from "@/components/Shared/FilterHelpers/FilterCheckboxes.vue";
import { defineComponent, PropType } from "@/plugins/vue";

interface FilterOption {
  id: string;
  text: string;
  count: number;
  selected: boolean;
  disabled: boolean;
}

export default defineComponent({
  name: "FilterBar",
  components: {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    ChevronDownIcon,
    FilterComboBox,
    FilterCheckBox,
  },
  props: {
    /**
     * expand the filters initially or not
     */
    openInitial: {
      type: Boolean,
      default: false,
    },
    /**
     * Type of filter
     */
    type: {
      type: String,
      required: true,
    },
    /**
     * Filtration field to be displayed
     */
    text: {
      type: String,
      required: true,
    },
    /**
     * List of options for the filteration field
     */
    options: {
      type: Array as PropType<FilterOption[]>,
      default: () => [],
    },
  },
  emits: ["attach", "detach"],
  data: () => ({
    expand: false,
  }),
  watch: {
    /**
     * Watcher to watch options and expand the options list if item is ticked
     */
    options() {
      this.expand = !!this.options.find((item) => item.selected);
    },
  },
  /**
   * Created life cycle hook, used for setting the expand property of filter if any FilterOption in them is ticked
   */
  created() {
    this.expand = this.openInitial || !!this.options.find((item) => item.selected);
  },
  methods: {
    /**
     * Function to attach or detach filter
     *
     * @param {object} item - the object needs to attach or detach
     */
    selectItem(item: FilterOption) {
      if (item.selected) this.$emit("attach", item);
      else this.$emit("detach", item);
    },
  },
});
