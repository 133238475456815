/* eslint-disable no-shadow */
import type { FunctionalComponent, HTMLAttributes, VNodeProps } from "vue";
import EventBus from "@/helpers/eventBus";
import { InformationCircleIcon, CheckCircleIcon } from "@/plugins/outlineHeroicon";

type Icon = FunctionalComponent<HTMLAttributes & VNodeProps>;

enum Priority {
  HIGH = 4000,
  MEDIUM = 3000,
  LOW = 2000,
}

enum Type {
  ERROR = "error",
  SUCCESS = "success",
}

/**
 * Display snackbar using event bus emit
 *
 * @param {string} message - The messsage to be displayed in snackbar
 * @param {Priority} priority - The priority of displaying snackbar HIGH/MEDIUM/LOW
 * @param {Type} type - The type of displaying snackbar SUCCESS/ERROR
 */
function displayMessage(message: string, priority: Priority, type: Type) {
  // Setting icon based on the message type
  const icon: Icon = type === Type.ERROR ? InformationCircleIcon : CheckCircleIcon;

  // Fire Event in Event Bus
  EventBus.$emit("displayMessage", {
    message, type, icon, timeout: priority,
  });
}

export { displayMessage, Priority, Type };
