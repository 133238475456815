
import { defineComponent, PropType } from "@/plugins/vue";
import SimpleDataTable from "./SimpleDataTable.vue";
// type for prop items items
type Item = object;

export default defineComponent({
  name: "DataTable",
  components: {
    SimpleDataTable,
  },
  props: {
    /**
     * Headers for the table
     */
    headers: {
      type: Array as PropType<{ text: string; value: string; sortable?: boolean; }[]>,
      default: () => [],
    },
    /**
     * Items to be shown in the table
     */
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    /**
     * Title for the table
     */
    title: {
      type: String as PropType<string>,
      default: "DataTable",
    },
    /**
     * Total number of current items
     */
    currentItemsLength: {
      type: Number as PropType<number>,
      default: 0,
    },
    /**
     * Total number of items before update in items
     */
    previousItemsLength: {
      type: Number as PropType<number>,
      default: 0,
    },
    /**
     * To check for LatestUpdatesList page
     */
    isStaticData: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /**
     * To Check if Route Pagination is allowed or not
     */
    enablePaginationRoute: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ["pageChange"],
});
