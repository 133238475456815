import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "flex mr-5 items-center justify-center h-6 w-6 rounded-md text-white"
}
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    type: "button",
    class: _normalizeClass([_ctx.disabled ? 'opacity-50' : 'opacity-100',
             'inline-flex items-center mt-4 px-4 py-2 border border-transparent border-grey-3 shadow-md text-base font-medium',
             'bg-white text-black rounded-md border-grey-3 w-full hover:text-primary-4 hover:bg-primary-2 dark:bg-dark-primary-3 dark:hover:bg-gray-300',
             'dark:text-dark-text-1 dark:hover:text-black'])
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.icon,
            alt: _ctx.text + ' Image',
            fetchpriority: "low",
            loading: "lazy",
            decoding: "async"
          }, null, 8, _hoisted_3)
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.text), 1)
  ], 10, _hoisted_1))
}