
import EventStatusLog from "@/components/Shared/EventStatusLog.vue";
import renderComponent from "@/helpers/componentKarma";
import getAssetAPI from "@/api/assetsAPI";
import { PaperClipIcon } from "@/plugins/solidHeroicon";
import { getEventTagsDetails, getEventIdsTimestamps } from "@/scripts/workerAdapterConnector";
import generateSeoSchema from "@/helpers/seoSchema";
import EventBus from "@/helpers/eventBus";
import FileViewer from "@/components/Generic/FileViewer.vue";
import displayConsole from "@/helpers/displayConsole";
import { getEventSchemaDetails } from "@/helpers/schemaHelpers";
import { isMobileDevice } from "@/helpers/validations";
import { defineComponent } from "@/plugins/vue";
import {
  FieldName, EventData, FieldValueForEventView, FieldValue,
} from "@/scripts/interface";

interface APIExtendedobject {
  url: string;
  headers: boolean;
  karma: number;
  credentials: Boolean;
  id: String;
}
interface filterFileAttachmentsInterface {
  fileState: string;
  fileName: string;
  fileLink: string;
}

interface Lang {
  en: {
    aliases: string[];
    string: string;
    text: string;
  };
  hi?: {
    aliases: string[];
    string: string;
    roman: string;
    text: string;
  };
}
interface TitleInfo {
  id: string;
  lang: Lang;
}

export default defineComponent({
  name: "EventViewDetails",
  components: {
    EventStatusLog,
    PaperClipIcon,
    FileViewer,
  },
  data: () => ({
    overlay: false,
    previewURL: "",
    previewFileName: "",
    apiObject: {} as APIExtendedobject,
    dataSet: false,
    attachedOrganisation: [] as TitleInfo[],
    attachedExam: [] as TitleInfo[],
    fieldNames: [] as FieldName[],
    fieldValues: [] as FieldValue[],
    websiteFieldID: "gaZ53yRq",
    creationTime: 0,
    updateTime: 0,
  }),
  computed: {
    /**
     * Computed Property to fetch User Karma Score from the store
     */
    userKarma(): number {
      return this.$store.getters["user/getUserKarma"];
    },
    /**
     * Computed property which gets the eventData object from the eventDetails module
     */
    eventData(): EventData {
      return this.$store.getters["events/getEvent"];
    },
    /**
     * Gets lang code from the store
     *
     * @returns {string} - lang code
     */
    langCode(): "en" | "hi" {
      return this.$store.getters["base/getLangCode"];
    },
  },
  watch: {
    userKarma() {
      this.apiObject = getAssetAPI("ACCESS_FILE_ATTACHMENTS") as APIExtendedobject;
    },
    // Watch Event Data to set Event Details if not set on created
    eventData() {
      this.getEventIdsTimestamps([this.eventData.component]).then((data) => {
        const timestamps = data?.[this.eventData.component];
        this.creationTime = timestamps?.created || timestamps?.lastUpdated;
        this.updateTime = timestamps?.lastUpdated;
      });
      this.setEventDetails();
    },
  },
  /**
   * Created Life Cycle Hook to fetch the API for accessing attachments
   */
  created() {
    this.apiObject = getAssetAPI("ACCESS_FILE_ATTACHMENTS") as APIExtendedobject;
    if (this.eventData.component) {
      this.getEventIdsTimestamps([this.eventData.component]).then((data) => {
        const timestamps = data?.[this.eventData.component];
        this.creationTime = timestamps?.created || timestamps?.lastUpdated;
        this.updateTime = timestamps?.lastUpdated;
      });
    }
    this.setEventDetails();
  },

  methods: {
    isMobileDevice,
    getEventIdsTimestamps,
    // Unused functionality for file preview component, will be used later
    /**
     * Fetches the file icon corresponding to the the type of file
     * @param {string} url The URL of the file for which the icon is to be fetched
     * @returns {string} a constant string representing a MDI for the file
     */
    // getIcon(url) {
    //   if (url === "") return;
    //   const mimeType = mime.getType(url);
    //   if (mimeType.includes("image")) return mdiImage;
    //   if (mimeType.includes("pdf")) return mdiFilePdfBox;
    //   return mdiFileImage;
    // },
    /**
     * Makes the file preview visible
     * by making overlay true and setting the previewURL
     * @param {string} fileLink The fileLink of the file for which the preview is to be shown
     * @param {string} fileName The file name of the file for which the preview is to be shown
     */
    showPreview(fileLink: string, fileName: string) {
      if (!this.apiObject) {
        this.openRegistrationBox();
        return;
      }
      this.previewURL = `${this.apiObject.url}${fileLink}`;
      this.overlay = true;
      this.previewFileName = fileName;
    },

    /**
     * Downloads the file with path fileLink
     *
     * @param {string} fileLink the path of file
     */
    downloadFile(fileLink: string) {
      if (!this.apiObject) {
        this.openRegistrationBox();
        return;
      }
      document.location.href = `${this.apiObject.url}${fileLink}`;
    },
    /**
     * Function to show Firebase Ui Dialogue
     */
    openRegistrationBox() {
      this.$store.commit("base/CHANGE_SIGN_IN_BOX_DISPLAY", true);
    },
    renderComponent,
    /**
     * Function to display only those files to the user having their fileState set to 'show'
     */
    filterFileAttachments(fileAttachments: filterFileAttachmentsInterface[]) {
      const filteredFileAttachment = [];
      for (let i = 0; i < fileAttachments.length; i += 1) {
        if (fileAttachments[i].fileState === "show") {
          filteredFileAttachment.push(fileAttachments[i]);
        }
      }
      return filteredFileAttachment;
    },
    // Set Event Details (fieldNames, fieldValiues, exams, organisation)
    setEventDetails() {
      let payload = {
        organisationIds: this.eventData.organisationIds,
        examIds: this.eventData.examIds,
        tagsData: this.eventData.tags,
      };
      payload = JSON.parse(JSON.stringify(payload));
      const isPayloadValid = Object.values(payload).some((value) => value.length !== 0);
      if (!isPayloadValid) return;
      displayConsole("Event View: Payload Sent to worker", { ...payload }, "setEventDetails");
      /**
      * Function to Get Specific Tags Details( organisations, fieldNames, fieldValues, exams)
      * Case 1
      * if type is all - payload = Object of arrays - payload { examIds : [], organisationIds : [], tagsData : []}
      * @returns {Object[]} object with  all data of exams , organisations fieldNames, fieldValues
      *
      * Case 2
      * if type  is specific (exams / organisations / fieldNames / fieldValues)
      * payload is Array of type in this case (examIds[] / organisations[] / ...)
      * @returns {Object[]} Array of Objects of (type)
      *
      * @param {string} type -
      * @param {Object[]} payload -
      */
      getEventTagsDetails(payload, false).then((data) => {
        this.attachedOrganisation = Object.values(data.organisations || {} as TitleInfo);
        this.attachedExam = Object.values(data.exams || {} as TitleInfo);
        this.fieldNames = [];
        this.fieldValues = [];
        payload.tagsData.forEach((tag) => {
          if (data.fieldNames?.[tag.nameId]) {
            this.fieldNames.push(data.fieldNames[tag.nameId]);
            this.fieldValues.push(data.fieldValues[tag.valueId]);
          }
        });
        displayConsole(
          "Event View: Data Received from worker",
          {
            fieldNames: JSON.parse(JSON.stringify(this.fieldNames)),
            fieldValues: JSON.parse(JSON.stringify(this.fieldValues)),
          },
          "setEventDetails",
        );
        this.updateSEOSchema();
        this.dataSet = true;

        // Emit dataLoaded event to load footer
        EventBus.$emit("dataLoaded", true);
        // Emit dataForScroll event to load scroll bar
        this.$nextTick().then(() => EventBus.$emit("dataForScroll"));
      });
    },

    /**
     * Funtion to resolve link of home routes
     *
     * @param {string} name - the name of route
     * @returns {string} the resolved href link
     */
    resolveHomeRouterLink(name: string) {
      return this.$router.resolve({ name: `${name}_${this.langCode}`, params: { langCode: this.langCode } }).href;
    },

    /**
     * Function gets the seo schema for event
     */
    updateSEOSchema() {
      const eventNameIdFound = this.eventData.tags.find((tag) => tag.nameId === "8lw29cmS");
      const eventType = eventNameIdFound ? "job" : "admission";

      // breadcrumb object for seo schema
      const homeRouteBreadcrumb = eventType === "job"
        ? { name: "Jobs", link: this.resolveHomeRouterLink("jobsHome") }
        : { name: "Admissions", link: this.resolveHomeRouterLink("admissionsHome") };
      const eventRouteBreadcumb = { name: `Event - ${this.eventData.title.en}`, link: this.$route.fullPath };
      const breadCrumbList = [];
      breadCrumbList.push(homeRouteBreadcrumb);
      breadCrumbList.push(eventRouteBreadcumb);

      // all data which needs in seo
      const eventObj = JSON.parse(JSON.stringify({
        eventType, eventData: this.eventData, fieldNames: this.fieldNames, fieldValues: this.fieldValues, organisation: this.attachedOrganisation,
      }));

      // get filtered data for schema
      const eventSchema = JSON.parse(JSON.stringify(getEventSchemaDetails(eventObj)));
      const routeName = (this.$route.name as string).split("_")[0];

      // generate seo schema object
      const schemaData = JSON.parse(JSON.stringify(generateSeoSchema(routeName, { eventSchema, breadCrumbList })));

      // set seo schema
      this.$meta.script = [
        {
          json: schemaData,
          type: "application/ld+json",
        },
      ];
    },
    /**
     * Generates the location meta details string
     *
     * @param {FieldValueForEventView} locationObj - location meta obejct
     */
    generateLocationDetailsString(locationObj: FieldValueForEventView) {
      const locationMetaObj = {
        pincode: "",
        state: "",
        country: "",
      };
      locationMetaObj.state = locationObj.locationInfo?.state.en.string || "";
      locationMetaObj.pincode = locationObj.locationInfo?.pincode.en.string || "";
      locationMetaObj.country = locationObj.locationInfo?.country.en.string || "";
      const locationString = Object.values(locationMetaObj).filter(Boolean).join(", ");
      return `${locationObj.lang.en.string}${`, ${locationString}`}`;
    },
  },
});
