/* eslint-disable prefer-rest-params */
/** ****************************************************************************************************************
 * Worker Access Methods
 ***************************************************************************************************************** */
import { getCacheData, setCacheData } from "./workerCache";
import { workerAPIType } from "./worker";
import {
  CoreTagList, CoreTagListType, EventFilter, EventType, FieldValue, IdData, IdDataType,
  ItemsRequiredCount, LangCode, ListData, ListType, Mapped, NonEnglishLangCode, SpecificTypeEvents, StaticFilesLang,
  SubFilterType, Suggestion, SuggestionsType, TagDetailsPayload, Keys, ValidCoreTagType, WorkerPayLoadType,
  TagDetailsResponse, TagDetailsType, TagTranslatedStatus, TagType, TranslatedTags, TranslatedTagsType, Event, CountryName,
} from "./interface";

/** ******************************************************************************************************* */
// ! For Back End use, uncomment this
// import workerAPI from "./worker";

// const wrap = () => { };
// const fetchHeaders = async () => { };
// const workerSwitcher = async (methodName: string, payload: any, argumentsArr: IArguments) => ({});
// const displayConsoles = (message: string, data: any, functionName: string) => { }

// ! For Front End use, uncomment this
import { wrap } from "../plugins/comlink";
import { fetchHeaders } from "../plugins/axiosInstances";
import workerSwitcher from "./workerSwitch";
import displayConsoles from "../helpers/displayConsole";

const workerAPI = wrap<workerAPIType>(new Worker(new URL("./worker", import.meta.url)));
/** ********************************************************************************************************* */

// * Boolean for enabling display of consoles for worker
const displayErrorInConsole = true;
const displayPerformanceMetric = true;

/**
 * Function to fetch cache data from cache for worker calls
 *
 * @param {string} methodName - Name of calling method
 * @param {any} payloadObject - Payload for API request
 * @returns {any} cachedData - Cached data or null
 */
function getCacheDataFromPayload(methodName: string, payloadObject: any): any {
  const cacheKey = {
    methodName,
    ...payloadObject,
  };
  // ? Fetch data from cache, if cacheKey exists in cache or return null
  const cachedData = getCacheData(cacheKey);
  return cachedData;
}

/**
 * Function to set cache data for worker
 *
 * @param {string} methodName - Name of calling method
 * @param {any} payload - Payload for API request
 * @param {any} response - Response for payload
 */
function setCacheDataForPayload(methodName: string, payload: any, response: any): void {
  const payloadKey = {
    methodName,
    ...payload,
  };
  setCacheData(payloadKey, response);
}

/**
 * Function for display error messages for API call
 *
 * @param {string} apiId - Id of API
 * @param {object} error - Error response from API
 * @param {boolean} displayConsole - Whether to display console or not
 */
function printErrorMessage(apiId: string, error: unknown, displayConsole: boolean): void {
  if (displayConsole) console.log(`${apiId}: ${(error as Error).message}`);
}

/**
 * Function to test performance and print console
 *
 * @param {string} name - function name
 * @param {any} returnValue - Data returned from worker
 * @param {number} count - number of items or json type
 * @param {boolean} displayConsole - Whether to display console or not
 */
async function printConsole(name: string, returnValue: any, count: ItemsRequiredCount, displayConsole: boolean): Promise<void> {
  if (!displayConsole) return;
  const data = await returnValue;
  const size = new TextEncoder().encode(JSON.stringify(data)).length;
  const kiloBytes = size / 1024;
  console.log(name, " | ", kiloBytes, "KB | ", count);
}

/**
 * Function to handle data requests from application and return response
 *
 * @param {string} adapterMethod - Name of worker adapter method
 * @param {string} workerMethod - Name of worker method
 * @param {any} serverPayload - Payload for server request
 * @param {any[]} argumentsArray - Params passed to worker adapter method
 * @param {boolean} isCacheEnabled - Boolean indicating if cache is enabled
 * @returns {Promise<any>} data - Response for request sent
 */
async function getDataForApplication(
  adapterMethod: string,
  workerMethod: keyof workerAPIType,
  serverPayload: any,
  argumentsArray: IArguments,
  isCacheEnabled: boolean,
): Promise<any> {
  displayConsoles("Worker Adapter: Request Received", serverPayload, workerMethod);
  // * Fetch data from cache for given Key
  if (isCacheEnabled) {
    const cacheData = getCacheDataFromPayload(adapterMethod, serverPayload);
    if (cacheData !== null) {
      displayConsoles("Cache: Response Received from Worker Cache", { ...cacheData }, adapterMethod);
      return cacheData;
    }
  }

  // * Worker switch: Return data from server or null in case local worker is loaded
  let data = await workerSwitcher(adapterMethod, serverPayload, argumentsArray);
  if (Object.keys(data).length) displayConsoles("Server worker: Response Received", { ...data }, adapterMethod);
  else {
    const workerPayload = [...argumentsArray];
    // ! Removing last param from workerPayload araguments array (isUserPage) as not required for worker method call
    workerPayload.pop();
    // ? Insert header in workerPayload arguments array before isAPICallEnabled param for functions that take that variable as a parameter
    if (["getTagsDetails", "getIdData", "getSubscribedCoreTagsInformation"].includes(adapterMethod)) {
      const headers = await fetchHeaders();
      workerPayload.splice(workerPayload.length - 1, 0, headers);
    }

    // worker methods mutated since, it is not possible to check the validity of parameters statically
    data = await (workerAPI![workerMethod] as WorkerPayLoadType)(...workerPayload);
    displayConsoles("Local worker: Response Received", { ...data }, workerMethod);
  }

  // * Set data into cache if cache is enabled, and return data
  if (isCacheEnabled) setCacheDataForPayload(adapterMethod, serverPayload, data);
  return data;
}

/**
 * Function to fetch latest events for various filter types and sub-filters
 *
 * @param {EventType} type - Event type - job or admission
 * @param {EventFilter[]} filter - specific page : category, exam, etc
 * @param {SubFilterType[]} subFilter - latest update type
 * @param {number} limit - Limit for events (quantity)
 * @param {boolean} isUserPage - Value denoting if the function call is from a User Page
 * @returns {Promise<SpecificTypeEvents>} events data
 */
async function getLatestEvents(type: EventType, filter: EventFilter[], subFilter: SubFilterType[], count: number, isUserPage: boolean):
  Promise<SpecificTypeEvents> {
  const isCacheEnabled = true;
  try {
    const specificLatestEvents = getDataForApplication("getLatestEvents", "getSpecificTypeEvents", {
      type, filter, subFilter, count, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getLatestEvents", specificLatestEvents, count, displayPerformanceMetric);
    return specificLatestEvents;
  } catch (error) {
    printErrorMessage("WEB#1", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to get a specific type data - (tags or events) list according to the count provided
 *
 * @param {ListType} dataType - Type of tag: { exam / organisation or university / fieldName/ fieldValue / event }
 * @param {number} start - Position to start fetching events or tags from
 * @param {ItemsRequiredCount} count - Number of items to fetch or all-If need to fetch all tags or events
 * @param {string | null} fieldNameId - The id of fieldName whose values need to be fetched
 * @param {Keys} keys - Array of keys to be present in data
 * @param {boolean} isUserPage - Value denoting if the function call is from a User Page
 * @returns {Promise<ListData>} Object containing specific type data from data store
 */
async function getListData(
  dataType: ListType | "event",
  start: number,
  count: ItemsRequiredCount,
  fieldNameId: string | null,
  keys: Keys,
  isUserPage: boolean,
):
  Promise<ListData | Event[]> {
  const isCacheEnabled = false;
  try {
    const listData = getDataForApplication("getListData", "getListData", {
      dataType, start, count, fieldNameId, keys, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getListData", listData, count, displayPerformanceMetric);
    return listData;
  } catch (error) {
    printErrorMessage("WEB#2", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to get name from static file according to the id given
 *
 * @param {TagType} tagType - tag type
 * @param {string[]} ids - array of Ids
 * @returns {Promise<StaticFilesLang[]>} array of title information for tags
 */
async function getStaticTagName(tagType: TagType, ids: string[], isUserPage: boolean): Promise<StaticFilesLang[]> {
  const isCacheEnabled = false;
  try {
    const tagNames = getDataForApplication("getStaticTagName", "getTagName", {
      tagType, ids, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getStaticTagName", tagNames, ids.length, displayPerformanceMetric);
    return tagNames;
  } catch (error) {
    printErrorMessage("WEB#3", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to Get All Tags Details( organisations, exams, events)
 *
 * @param {TagDetailsType} type - type of page ( organisations, exams, events)
 * @param {TagDetailsPayload} payload - tag details payload
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @param {boolean} isUserPage - Value denoting if the function call is from a User Page
 * @returns {Promise<TagDetailsResponse>} object with all data of organisations, exams, events
 */
async function getTagsDetails(type: TagDetailsType, payload: TagDetailsPayload, isAPICallEnabled: boolean, isUserPage: boolean): Promise<TagDetailsResponse> {
  const isCacheEnabled = true;
  try {
    const tagDetails = getDataForApplication("getTagsDetails", "getTagsDetails", {
      type, ...payload, isAPICallEnabled, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getTagsDetails", tagDetails, 0, displayPerformanceMetric);
    return tagDetails;
  } catch (error) {
    printErrorMessage("WEB#4", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Fetch tags data from store using its id
 *
 * @param {IdDataType} type - events, organisations, exams, tags
 * @param {string[]} ids - eventIds, organisationIds, examIds, tagNameIds,
 * @param {Keys} keys - Array of keys to be in data
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @param {boolean} isUserPage - Value denoting if the function call is from a User Page
 * @returns {Promise<IdData>} - dataObj at id
 */
async function getIdData(type: IdDataType, ids: string[], keys: Keys, isAPICallEnabled: boolean, isUserPage: boolean): Promise<IdData> {
  const isCacheEnabled = true;
  try {
    const tagsData = getDataForApplication("getIdData", "getIdData", {
      type, ids, keys, isAPICallEnabled, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getIdData", tagsData, -1, displayPerformanceMetric);
    return tagsData;
  } catch (error) {
    printErrorMessage("WEB#5", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to get translated tags or tags to translate based on type and translation status
 *
 * @param {TranslatedTagsType} tagType - Type of tag: fieldNames, fieldValues, organisations, exams
 * @param {TagTranslatedStatus} tagStatus - Tag translation status: pending or completed
 * @param {LangCode} langCode - language to translate from
 * @param {NonEnglishLangCode} translationLangCode - language to translate to
 * @param {number} start - Position to start fetching translation data from
 * @param {number} count - Number of items to fetch
 * @param {boolean} isUserPage - Value denoting if the function call is from a User Page
 * @returns {Promise<TranslatedTags>} tags object containing translated tags or tags to translate
 */
async function getTranslatedTags(
  tagType: TranslatedTagsType,
  tagStatus: TagTranslatedStatus,
  langCode: LangCode,
  translationLangCode: NonEnglishLangCode,
  start: number,
  count: number,
  isUserPage: boolean,
): Promise<TranslatedTags> {
  const isCacheEnabled = true;
  try {
    const translationTags = getDataForApplication("getTranslatedTags", "getTranslatedTags", {
      tagType, tagStatus, langCode, translationLangCode, start, count, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getTranslatedTags", translationTags, -1, displayPerformanceMetric);
    return translationTags;
  } catch (error) {
    printErrorMessage("WEB#6", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to get suggestions for a specific type of tag
 *
 * @param {SuggestionsType} type - Type of tag - fieldNames, exams, organisations, or fieldValues
 * @param {string | null} fieldNameId - Field Name Id for which suggestions are needed
 * @param {boolean} isUserPage - Value denoting if the function call is from a User Page
 * @returns {Promise<Mapped<Suggestion>>} suggestions for a specific tag
 */
async function getTagSuggestions(type: SuggestionsType, fieldNameId: string | null, isUserPage: boolean): Promise<Mapped<Suggestion>> {
  const isCacheEnabled = true;
  try {
    const suggestions = getDataForApplication("getTagSuggestions", "getTagSuggestions", {
      type, fieldNameId, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getTagSuggestions", suggestions, -1, displayPerformanceMetric);
    return suggestions;
  } catch (error) {
    printErrorMessage("WEB#7", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to get specific user core tag list based on event type and core tag type
 *
 * @param {EventType} eventType - Type of event: job or admission
 * @param {CoreTagListType} listType - Type of tag: { exam / organisation or university /state }
 * @param {number} start - Position to start fetching tags from
 * @param {number} count - Number of items to fetch
 * @param {Keys} keys - Array of keys to be present in tags data
 * @param {boolean} isUserPage - Value denoting if the function call is from a User Page
 * @returns {Promise<CoreTagList>} Object containing specific type tag data from data store as per requirements
 */
async function getUserSpecificCoreTagsList(
  eventType: EventType,
  listType: CoreTagListType,
  start: number,
  count: number,
  keys: Keys,
  isUserPage: boolean,
): Promise<CoreTagList> {
  const isCacheEnabled = true;
  try {
    const listData = getDataForApplication("getUserSpecificCoreTagsList", "getUserSpecificCoreTagsList", {
      eventType, listType, start, count, keys, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getUserSpecificCoreTagsList", listData, count, displayPerformanceMetric);
    return listData;
  } catch (error) {
    printErrorMessage("WEB#8", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to get core tag information and the mode for core tag: job or admission
 *
 * @param {ValidCoreTagType} coreTagType - Type of core tag: exam or organisation
 * @param {string[]} coreTagIds - Array of exam Ids for which information is required
 * @param {Keys} keys - Array of keys to be present in tags data
 * @param {boolean} isAPICallEnabled - Boolean indicating whether to call API or not
 * @param {boolean} isUserPage - Value denoting if the function call is from a User Page
 * @returns {Promise<CoreTagList>} subscribedExamInformation - The subscription information for the exams
 */
async function getSubscribedCoreTagsInformation(
  coreTagType: ValidCoreTagType,
  coreTagIds: string[],
  keys: Keys,
  isAPICallEnabled: boolean,
  isUserPage: boolean,
): Promise<CoreTagList> {
  const isCacheEnabled = true;
  try {
    const tagInformation = getDataForApplication("getSubscribedCoreTagsInformation", "getSubscribedCoreTagsInformation", {
      coreTagType, coreTagIds, keys, isAPICallEnabled, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getSubscribedCoreTagsInformation", tagInformation, -1, displayPerformanceMetric);
    return tagInformation;
  } catch (error) {
    printErrorMessage("WEB#9", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to fetch values data (limited) for a specific field name
 *
 * @param {string} fieldNameId - Id of field name
 * @param {number} start - Starting index
 * @param {ItemsRequiredCount} count - Number of items to fetch
 * @param {Keys} keys - Keys to be present in return data
 * @returns {Promise<Mapped<FieldValue>>} location page list
 */
async function getSpecificFieldValuesData(fieldNameId: string, start: number, count: number, keys: Keys, isUserPage: boolean): Promise<Mapped<FieldValue>> {
  const isCacheEnabled = true;
  try {
    const locationData = getDataForApplication("getSpecificFieldValuesData", "getSpecificFieldValuesData", {
      fieldNameId, start, count, keys, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getSpecificFieldValuesData", locationData, count, displayPerformanceMetric);
    return locationData;
  } catch (error) {
    printErrorMessage("WEB#10", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to fetch mode specific state list for user to displaye event templates and event lists
 *
 * @param {EventType} eventType - Mode: job or admission
 * @param {number} start - Starting index
 * @param {number} count - Number of items to fetch
 * @param {string} country - country for which data is required
 * @param {Keys} keys - Keys to be present in return data
 * @returns {Promise<Mapped<FieldValue>>} state list for user
 */
async function getUserSpecificStateList(
  eventType: EventType,
  start: number,
  count: number,
  country: CountryName,
  keys: Keys,
  isUserPage: boolean,
): Promise<Mapped<FieldValue>> {
  const isCacheEnabled = true;
  try {
    const stateList = getDataForApplication("getUserSpecificStateList", "getUserSpecificStateList", {
      eventType, start, count, country, keys, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getUserSpecificStateList", stateList, count, displayPerformanceMetric);
    return stateList;
  } catch (error) {
    printErrorMessage("WEB#11", error, displayErrorInConsole);
    throw error;
  }
}

/**
 * Function to get Event list sorted by creation time according to the count provided
 *
 * @param {number} start - Position to start fetching events or tags from
 * @param {ItemsRequiredCount} count - Number of items to fetch or all-If need to fetch all tags or
 * @param {Keys} keys - Array of keys to be present in data
 * @returns {Promise<Event[]>} - Array of events
 */
async function getEventList(start: number, count: ItemsRequiredCount, keys: Keys, isUserPage: boolean): Promise<Event[]> {
  const isCacheEnabled = false;
  try {
    const listData = getDataForApplication("getEventList", "getEventList", {
      start, count, keys, isUserPage,
    }, arguments, isCacheEnabled);
    printConsole("getEventList", listData, count, displayPerformanceMetric);
    return listData;
  } catch (error) {
    printErrorMessage("WEB#12", error, displayErrorInConsole);
    throw error;
  }
}

export {
  workerAPI, getLatestEvents, getListData, getStaticTagName, getTagsDetails, getIdData, getTranslatedTags, getTagSuggestions,
  getUserSpecificCoreTagsList, getSubscribedCoreTagsInformation, getSpecificFieldValuesData, getUserSpecificStateList,
  getEventList,
};
