export default {
  /**
   * Getter for display state of the modal
   *
   * @param {*} state - The state of base store module
   * @returns {boolean} Display status of sign in box
   */
  getSignInBoxDisplayState(state) {
    return state.showSignInBox;
  },

  /**
   * Getter for dark mode display state
   *
   * @param {*} state - The state of base store module
   * @returns {boolean} status of dark mode display
   */
  getDarkMode(state) {
    return state.darkMode;
  },

  /**
   * Getter for theme Selected Theme Mode Name
   *
   * @param {*} state - The state of base store module
   * @returns {string} selectedThemeModeName - the current theme selected name
   */
  getSelectedThemeModeName(state) {
    return state.selectedThemeModeName;
  },

  /**
   * Getter for current language code
   *
   * @param {*} state - The state of base store module
   * @returns {string} langCode - the current language code
   */
  getLangCode(state) {
    return state.langCode;
  },

  /**
   * Getter for state of worker: ready or not ready
   *
   * @param {*} state - The state of base store module
   * @returns {boolean} isWorkerReady - Value indicating if the worker is ready or not
   */
  getWorkerState(state) {
    return state.isWorkerReady;
  },

  /**
   * Getter for json promise for worker
   *
   * @param {*} state - The state of base store module
   * @returns {Promise} workerPromise - Promise for worker jsons
   */
  getWorkerPromise(state) {
    return state.jsonPromise;
  },

  /**
   * Getter for is pwa update available boolean
   *
   * @param {*} state - The state of base store module
   * @returns {boolean} isPWAUpdateAvailable - PWA app update available or not
   */
  getPWAUpdateStatus(state) {
    return state.isPWAUpdateAvailable;
  },
  /**
   * Getters to get page page
   *
   * @param {*} state - The state of base store module
   * @returns {string} - job or admission
   */
  getPageType(state) {
    return state.jobAdmissionPageType;
  },

  /**
   * Getter for state of search functionality: ready or not ready
   *
   * @param {*} state - The state of base store module
   * @returns {boolean} isWorkerReady - Value indicating if the search is ready or not
   */
  getSearchState(state) {
    return state.isSearchReady;
  },
};
