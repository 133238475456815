export default {
  /**
   * Getter gets the template data form the state of template module
   *
   * @param {object} state - Data store for template module
   * @returns {object} - Contains data about the template Data which is stored in the template modules
   */
  getTemplateData(state) {
    return state.templateData;
  },
};
