const latestUpdatesKey = {

  "latest-notifications": {
    en: "latest-notifications",
    hi: "latest-notifications",
  },
  "latest-admit-card": {
    en: "latest-admit-card",
    hi: "latest-admit-card",
  },
  "latest-results": {
    en: "latest-results",
    hi: "sarkari-results",
  },
  "latest-fellowships": {
    en: "latest-fellowships",
    hi: "latest-fellowships",
  },
  "latest-walk-in-interview": {
    en: "latest-walk-in-interview",
    hi: "latest-walk-in-interview",
  },
  "last-date-soon": {
    en: "last-date-soon",
    hi: "last-date-soon",
  },
  "latest-scholarships": {
    en: "latest-scholarships",
    hi: "latest-scholarships",
  },
  "sarkari-results": {
    en: "latest-results",
    hi: "sarkari-results",
  },
  "latest-interview": {
    en: "latest-interview",
    hi: "latest-interview",
  },
};
/**
* Function returns the latest udpate key
*
* @param {string} key - latest update key
* @param {string} langCode - lang code
* @returns {string} latest update key value as per lang code.
*/
function getLatestUpdatesKey(key: keyof typeof latestUpdatesKey, langCode: "en" | "hi") {
  return latestUpdatesKey[key][langCode];
}
export type LatestUpdatesKey = keyof typeof latestUpdatesKey;
export default getLatestUpdatesKey;
