import store from "@/store/index.js";
import EventBus from "@/helpers/eventBus";
import {
  ActionCodeSettings, Auth, AuthCredential, AuthProvider, FacebookAuthProvider, GoogleAuthProvider, PopupRedirectResolver, User, UserCredential,
  OAuthCredential, UserInfo,
} from "firebase/auth";
import { Analytics } from "firebase/analytics";
import { FirebaseApp, FirebaseError } from "firebase/app";

/**
 * Type for firebaseProperty
 */
export interface AuthModule {
  "getAuth": (app?: FirebaseApp | undefined) => Auth;
  "signInWithPopup": (auth: Auth, provider: AuthProvider, resolver?: PopupRedirectResolver | undefined) => Promise<UserCredential>;
  "signInWithRedirect": (auth: Auth, provider: AuthProvider, resolver?: PopupRedirectResolver | undefined) => Promise<never>;
  "GoogleAuthProvider": typeof GoogleAuthProvider;
  "FacebookAuthProvider": typeof FacebookAuthProvider;
  "fetchSignInMethodsForEmail": (auth: Auth, email: string) => Promise<string[]>;
  "linkWithCredential": (user: User, credential: AuthCredential) => Promise<UserCredential>;
  "linkWithRedirect": (user: User, provider: AuthProvider, resolver?: PopupRedirectResolver | undefined) => Promise<never>;
  "getRedirectResult": (auth: Auth, resolver?: PopupRedirectResolver | undefined) => Promise<UserCredential | null>;
  "linkWithPopup": (user: User, provider: AuthProvider, resolver?: PopupRedirectResolver | undefined) => Promise<UserCredential>;
  "createUserWithEmailAndPassword": (auth: Auth, email: string, password: string) => Promise<UserCredential>;
  "signInWithEmailAndPassword": (auth: Auth, email: string, password: string) => Promise<UserCredential>;
  "signInWithCredential": (auth: Auth, credential: AuthCredential) => Promise<UserCredential>;
  "signInWithCustomToken": (auth: Auth, customToken: string) => Promise<UserCredential>;
  "sendPasswordResetEmail": (auth: Auth, email: string, actionCodeSettings?: ActionCodeSettings | undefined) => Promise<void>;
  "sendEmailVerification": (user: User, actionCodeSettings?: ActionCodeSettings | null | undefined) => Promise<void>;
  "updateProfile": (user: User, { displayName, photoURL }: {
    displayName?: string | null | undefined;
    photoURL?: string | null | undefined;
  }) => Promise<void>;
  "updateEmail": (user: User, newEmail: string) => Promise<void>;
  "reauthenticateWithCredential": (user: User, credential: AuthCredential) => Promise<UserCredential>;
}

/**
 * Type for analyticsModule
 */
export interface AnalyticsModule {
  "getAnalytics": (app?: FirebaseApp | undefined) => Analytics;
  "logEvent": (analytics: Analytics, logType: string, logObject: { description: string; fatal: boolean; }) => void;
}

// Firebase Configuration Options
const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIRE_apiKey,
  authDomain: process.env.VUE_APP_FIRE_authDomain,
  databaseURL: process.env.VUE_APP_FIRE_databaseURL,
  projectId: process.env.VUE_APP_FIRE_projectId,
  storageBucket: process.env.VUE_APP_FIRE_storageBucket,
  messagingSenderId: process.env.VUE_APP_FIRE_messagingSenderId,
  appId: process.env.VUE_APP_FIRE_appId,
  measurementId: process.env.VUE_APP_FIRE_measurementId,
};

const firebaseAppPromise = new Promise((resolve, reject) => {
  EventBus.$once("dataLoaded", () => {
    import(/* webpackChunkName: "firebase_module" */"firebase/app").then((module) => {
      // Initialize firebase
      const app = module.initializeApp(firebaseConfig);
      resolve(app);
    }).catch(reject);
  });
});

const authPromise: Promise<AuthModule> = new Promise((resolve, reject) => {
  EventBus.$once("dataLoaded", () => {
    import(/* webpackChunkName: "firebase_module" */"firebase/auth").then(async (module) => {
      const firebaseProperty: AuthModule = {
        getAuth: module.getAuth,
        signInWithPopup: module.signInWithPopup,
        signInWithRedirect: module.signInWithRedirect,
        GoogleAuthProvider: module.GoogleAuthProvider,
        FacebookAuthProvider: module.FacebookAuthProvider,
        fetchSignInMethodsForEmail: module.fetchSignInMethodsForEmail,
        linkWithCredential: module.linkWithCredential,
        linkWithRedirect: module.linkWithRedirect,
        getRedirectResult: module.getRedirectResult,
        linkWithPopup: module.linkWithPopup,
        createUserWithEmailAndPassword: module.createUserWithEmailAndPassword,
        signInWithEmailAndPassword: module.signInWithEmailAndPassword,
        signInWithCredential: module.signInWithCredential,
        signInWithCustomToken: module.signInWithCustomToken,
        sendPasswordResetEmail: module.sendPasswordResetEmail,
        sendEmailVerification: module.sendEmailVerification,
        updateProfile: module.updateProfile,
        updateEmail: module.updateEmail,
        reauthenticateWithCredential: module.reauthenticateWithCredential,
      };
      await firebaseAppPromise;
      resolve(firebaseProperty);
    }).catch(reject);
  });
});

const analyticsPromise: Promise<AnalyticsModule> = new Promise((resolve, reject) => {
  EventBus.$once("dataLoaded", () => {
    import(/* webpackChunkName: "firebase_module" */"firebase/analytics").then(async (module) => {
      await firebaseAppPromise;
      const analyticsModule = (({ getAnalytics, logEvent }) => ({ getAnalytics, logEvent }))(module);
      resolve(analyticsModule);
    }).catch(reject);
  });
});

/**
 *
 * @logs production events in google analytics
 * refer : https://firebase.google.com/docs/analytics/events?platform=web#web-version-9_1
 * Refer the above link to know more about the syntax of firebase version 9
*/
function analyticsLogger(logType: string, logObject: any) {
  analyticsPromise.then((analyticsModule) => {
    const AnalyticsModules = analyticsModule;
    AnalyticsModules.logEvent(AnalyticsModules.getAnalytics(), logType, logObject);
  }).catch((err) => {
    console.log("Analytics couldn't be loaded", err);
  });
}

/**
 * Function gets the user token
 *
 * @returns {string} - user token
 */
async function getUserToken(): Promise<string> {
  const authModule = await authPromise;
  if (store.getters["user/getAuthenticatedState"]) {
    const user = await authModule?.getAuth()?.currentUser?.getIdToken();
  }
  return store.getters["user/getUserToken"];
}
/**
 * Fucntion get the user id
 *
 *  @returns {String} - return the user id
 */
async function getUserId(): Promise<string | undefined> {
  const authModule = await authPromise;
  if (store.getters["user/getAuthenticatedState"]) {
    return authModule.getAuth()?.currentUser?.uid;
  }
  return store.getters["user/getUserId"];
}
/**
 * Function gets the user data asynchronously
 *
 * @returns {Object} - user data
 */
let userLoaded = false;
async function getCurrentUser(): Promise<User | null> {
  const authModule = await authPromise;
  return new Promise((resolveUser, rejectUser) => {
    if (userLoaded) {
      resolveUser(authModule.getAuth().currentUser);
      return;
    }
    const unsubscribe = authModule.getAuth().onAuthStateChanged((user) => {
      userLoaded = true;
      unsubscribe();
      resolveUser(user);
    }, rejectUser);
  });
}

export {
  analyticsLogger,
  authPromise,
  firebaseAppPromise,
  getCurrentUser,
  getUserId,
  getUserToken,
};

export type {
  ActionCodeSettings,
  Auth, AuthCredential, AuthProvider, FacebookAuthProvider, GoogleAuthProvider, PopupRedirectResolver, User, UserCredential,
  OAuthCredential, FirebaseApp, Analytics, UserInfo,
};
export { FirebaseError };
