
import DataTable from "@/components/Generic/DataTable.vue";
import { getEventRoutePath, getRoutePath } from "@/helpers/routesHandler";
import { getEventIdsData, getExamSubscriptionInformation } from "@/scripts/workerAdapterConnector";
import EventBus from "@/helpers/eventBus";
import { displayMessage, Type, Priority } from "@/helpers/emitEventBusEvent";
import {
  Dialog as DialogContainer, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot,
} from "@/plugins/headlessui";
import { pascalize } from "@/helpers/dataFormat";
import { defineComponent } from "@/plugins/vue";
import { EventItemData, RouteObject } from "@/helpers/interface";

interface ItemType {
  eventId?: string;
  title?: {
    en: string;
    hi: string;
  };
  title_roman?: {
    hi: string;
  };
  id?: string;
  lang?: {
    en: {
      aliases: string[];
      string: string;
    };
  };
  mode?: string;
}

interface EventType {
  eventId: string;
  title: {
    en: string;
    hi: string;
  };
  title_roman?: {
    hi: string;
  };
}

interface ExamType {
  id: string;
  lang: {
    en: {
      aliases: string[];
      string: string;
    };
  };
  mode: string;
}

interface EventItem {
  created: number;
  eventId: string;
  lastUpdated: number;
  title: { en: string; hi: string; };
  title_roman: { hi: string; };
  valueTimestamp: number;
}
export default defineComponent({
  name: "SubscriptionListView",
  components: {
    DataTable,
    DialogContainer,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },
  props: {
    /**
     *  To check current subscription list (event subscription list or exam subscription list)
     */
    mode: {
      type: String,
      default: "event",
    },
  },
  /**
   * Data
   */
  data() {
    return {
      dataSet: false,
      eventItems: [] as EventType[],
      examItems: [] as ExamType[],
      headers: [
        { text: "s.No", value: "sno" },
        { text: "name", value: "title" },
        { text: "action", value: "action" },
      ],
      enablePaginationRoute: false,
      openDialog: false,
      unsubscribeName: "" as string | undefined,
      unsubscribeId: "" as string | undefined,
    };
  },
  computed: {
    /**
     * Gets language code from the base module in store
     *
     * @returns {"hi"| "en"} - current language code
     */
    langCode(): "hi" | "en" {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Gets all subscribed examIds from the event module in store
     *
     * @returns {string[]} - current examIDs
     */
    examIds(): string[] {
      return this.$store.getters["events/getSubscribedExamIds"];
    },
    /**
     * Gets all subscribed eventIds from the event module in store
     *
     * @returns {string[]} - current eventIds
     */
    eventIds(): string[] {
      return this.$store.getters["events/getSubscribedEventIds"];
    },
    /**
     * Computed property to get the dark Mode
     */
    darkMode(): boolean {
      return this.$store.getters["base/getDarkMode"];
    },
  },
  watch: {
    /**
     * Watch eventIds to update subscribed event IDs
     */
    eventIds() {
      this.getSubscriptionList();
    },
    /**
     * Watch examIds to update subscribed exam IDs
     */
    examIds() {
      this.getSubscriptionList();
    },
  },
  mounted() {
    if (this.$store.getters["events/isSubscribedDataFetched"]) {
      this.getSubscriptionList();
    }
  },
  methods: {
    pascalize,
    /**
    * Function to get the route path to edit and view events
    *
    * @param {EventItem} item - Current event item
    * @param {string} routeName - Name of route (eventEdit or eventView)
    * @return {RouteObject} RouteObject - url according to current language
    */
    setEventRoutePath(item: EventItem, routename: string): RouteObject {
      if (!item) return { name: "", params: { langCode: this.langCode, id: "" } };
      const path = getEventRoutePath(item, this.langCode, routename, this.$route);
      return path;
    },

    /**
     * Function to get all events or exams subscription list,
     */
    async getSubscriptionList(): Promise<void> {
      // get all details of subscription list IDs of event or exam
      const result = await getEventIdsData([...this.eventIds]);
      const result1 = await getExamSubscriptionInformation([...this.examIds], false);
      this.eventItems = result ? Object.values(result) : [];
      this.examItems = result1 ? Object.values(result1) : [];
      this.dataSet = true;
      // emit dataLoaded event to load footer
      EventBus.$emit("dataLoaded", true);
      // Emit dataForScroll event to load scroll bar
      this.$nextTick().then(() => EventBus.$emit("dataForScroll"));
    },

    /**
     * Function to open dialog box on subscribed button cklick to conform unsubscribe
     *
     * @param ItemType - Custom interface for ItemType, the item which clicked for unsubscribe
     */
    openUnsubscribeDialog(item: ItemType) {
      if (this.mode === "event") {
        this.unsubscribeName = item.title?.[this.langCode] || item.title?.en;
        this.unsubscribeId = item.eventId;
      }
      if (this.mode === "exam") {
        this.unsubscribeName = item.lang?.[this.langCode as keyof typeof item.lang]?.string || item.lang?.en.string;
        this.unsubscribeId = item.id;
      }

      this.openDialog = true;
    },

    /**
     * Function to unsubscribe current event or exam
     */
    unsubscribeHandler() {
      const id = this.unsubscribeId;
      const payload = {
        mode: "unsubscribe",
        data: JSON.stringify(this.mode === "event" ? { eventIds: [id], examIds: [] } : { eventIds: [], examIds: [id] }),
      };
      this.$store.dispatch("events/subscribeEvent", payload).then((data) => {
        this.$store.commit("events/SET_SUBSCRIBED_EVENTS", data.body);
        displayMessage(`${this.pascalize(this.mode)}
         ${payload.mode}d successfully`, Priority.MEDIUM, Type.SUCCESS);
      });
      this.openDialog = false;
    },

    /**
     * Function to get the route path for exam page
     *
     * @param {EventItemData} interface Borrowed from HelperInterface- subscribed exam object
     * @return {RouteObject} path - url according to current language
     */
    getExamRoutePath(item: EventItemData): RouteObject {
      if (!item) return { name: "", params: { langCode: this.langCode, id: "" } };
      const routeName = `${item.mode}sSpecificExamPage_${this.langCode}`;
      const path = getRoutePath(item, this.langCode, routeName, this.$route);
      return path;
    },
  },
});
