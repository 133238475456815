
import { getStatesAndUTs, getState } from "@/helpers/staticDataParser";
import { getNamedRoute, validateRouteAndFetchSlug } from "@/helpers/routesHandler";
import { defineComponent } from "@/plugins/vue";
import type { RouteParams } from "@/plugins/interface";
import { State } from "@/helpers/interface";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

/**
 *  RouteObject url according to current language
 */
interface RouteObject {
  name: string;
  params: RouteParams;
}

export default defineComponent({
  name: "TopStates",
  data() {
    return {
      states: [] as State[],
      stateIds: [] as string[],
    };
  },
  computed: {
    /**
     * Gets lang code from base store module

     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed property to get title according route mode
     */
    title(): string {
      return this.$route.meta.mode === "job" ? "job" : "admission";
    },
    /**
     * Computed property to get description according route mode
     */
    description(): string {
      return this.$route.meta.mode === "job" ? "stateWiseJobDescription" : "stateWiseAdmissionDescription";
    },
  },
  /**
   * On created life cycle hook Fetch state data from worker
   */
  created() {
    Object.values(getStatesAndUTs("India")).forEach((item) => {
      if (item.imagePath) this.stateIds.push(item.id);
    });
    const x = this.stateIds.map((stateId) => getState("India", stateId));
    this.states = x;
  },
  methods: {
    getNamedRoute,

    /**
     * Gets route path using getNamedRoute function
     *
     * @param {string} stateItem - State object
     * @return {RouteObject} route path
     */
    getStateRoute(state: State): RouteObject {
      const title = this.langCode === "en" ? state.lang.en.string : state.lang.hi.roman;
      const { idSlug } = validateRouteAndFetchSlug(state.id, title, this.$route);
      const pathName = this.$route.meta.mode === "job" ? "jobsSpecificStatePage_en" : "admissionsSpecificStatePage_en";
      return { name: getNamedRoute(this.langCode, pathName), params: { langCode: this.langCode, id: idSlug } };
    },

    /**
     * Function to get Route of view More
     *
     * @returns {RouteObject} the named route object
     */
    getViewMoreRoute(): RouteObject {
      const pathName = (this.$route.meta.mode === "job") ? "jobsStateList_en" : "admissionsStateList_en";
      return { name: getNamedRoute(this.langCode, pathName), params: { langCode: this.langCode } };
    },
  },
});
