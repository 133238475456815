
import { Popover, PopoverButton, PopoverPanel } from "@/plugins/headlessui";
import { AnnotationIcon } from "@/plugins/solidHeroicon";
import BellIcon from "@/components/Header/BellIcon.vue";
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  components: {
    Popover,
    PopoverButton,
    PopoverPanel,
    AnnotationIcon,
    BellIcon,
  },
  data() {
    return {
      notifications: [] as { name: string; description: string; }[],
    };
  },
  computed: {
    /**
     * Computed property to get current Language type
     */
    langCode(): "en" | "hi" {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed property to get the dark Mode
     */
    darkMode(): boolean {
      return this.$store.getters["base/getDarkMode"];
    },
  },
});
