import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_section = _resolveComponent("heading-section")!
  const _component_data_table = _resolveComponent("data-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_heading_section, {
      title: _ctx.$t('notifications')
    }, null, 8, ["title"]),
    _createVNode(_component_data_table, { headers: _ctx.headers }, null, 8, ["headers"])
  ], 64))
}