

import { defineComponent } from "@/plugins/vue";

/**
 * timerReturnObj is an interface to deal with return value of computed Property { timeObj }
 */
interface timerReturnObj {
  hours: number;
  minutes: number;
  seconds: number;
}

export default defineComponent({
  name: "Timer",
  props: {
    /**
     * The prefix to be attached to timer
     */
    prefix: {
      type: String,
      default: "",
    },
    /**
     * The suffix to be attached to the timer
     */
    suffix: {
      type: String,
      default: "",
    },
    /**
     * The timeout for timer
     */
    timeout: {
      type: Number,
      default: 0,
    },
  },
  emits: ["timeUp"],
  data: () => ({
    seconds: 0,
  }),
  computed: {
    /**
     * Returns a string with hours, minutes and second seprated by colon
     */
    timerString(): String {
      if (this.timeObj.hours) return `${this.timeObj.hours} : ${this.timeObj.minutes} : ${this.timeObj.seconds}`;
      return `${this.timeObj.minutes} : ${this.timeObj.seconds}`;
    },
    /**
     * A computed property which gives the timeout in parsed form
     * {hours, minutes, seconds}
     */
    timeObj(): timerReturnObj {
      let minutes = Math.floor(this.seconds / 60);
      const seconds = this.seconds % 60;
      const hours = Math.floor(minutes / 60);
      minutes %= 60;

      return { hours, minutes, seconds };
    },
  },
  watch: {
    /**
     * Updates seconds whenever timeout is updated
     */
    timeout(val) {
      this.seconds = val;
    },
  },
  /**
   * Initialises seconds variable and starts a interval timer that breaks once seconds becomes 0
   */
  created() {
    this.seconds = this.timeout;
    const intervalId = setInterval(() => {
      if (this.seconds) {
        this.seconds -= 1;
      } else {
        clearInterval(intervalId);
        this.$emit("timeUp");
      }
    }, 1000);
  },
});
