
import { defineComponent } from "@/plugins/vue";
import HeadingSection from "@/components/Generic/HeadingSection.vue";
import DataTable from "@/components/Generic/DataTable.vue";

export default defineComponent({
  name: "NotificationList",
  components: { HeadingSection, DataTable },
  data() {
    return {
      headers: [
        { text: "s.No", value: "sno" },
        { text: "recentUpdates", value: "string" },
        { text: "date", value: "date" },
      ],
    };
  },
});
