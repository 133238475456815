import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_snackbar = _resolveComponent("action-snackbar")!

  return (_openBlock(), _createBlock(_component_action_snackbar, {
    show: _ctx.isPWAUpdateAvailable,
    title: _ctx.$t('pwaVersionUpdate'),
    message: _ctx.$t('pwaUpdateMessage'),
    "action-text": _ctx.$t('refreshPWA'),
    icon: _ctx.refreshIcon,
    persistent: "",
    onActionSelect: _ctx.refresh
  }, null, 8, ["show", "title", "message", "action-text", "icon", "onActionSelect"]))
}