export default {
  showSignInBox: false,
  darkMode: false,
  langCode: "en",
  isWorkerReady: false,
  isSearchReady: false,
  jsonPromise: undefined,
  isPWAUpdateAvailable: false,
  jobAdmissionPageType: "",
  selectedThemeModeName: "",
};
