export default {
  /**
   * Getter for fetching the preset tags from the tags module in store
   *
   * @param {object} state - Data store for storing tags data
   * @returns {string []} state.presets - Object containing the list of preset fields
   */
  getPresets(state) {
    return state.presets;
  },
};
