import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "py-12 bg-white font-normal dark:bg-dark-primary-1" }
const _hoisted_2 = { class: "text-center" }
const _hoisted_3 = { class: "mt-2 text-3xl text-black dark:text-gray-300 sm:text-4xl font-arial" }
const _hoisted_4 = { class: "mt-6 max-w-2xl text-lg sm:text-xl text-gray-700 dark:text-gray-500 mx-auto" }
const _hoisted_5 = { class: "mt-3 lg:mx-40 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-2" }
const _hoisted_6 = ["href", "onClick"]
const _hoisted_7 = { class: "absolute bg-secondary-4 rounded-md p-3 dark:bg-dark-primary-3" }
const _hoisted_8 = { class: "text-2xl md:text-3xl ml-16 my-4 text-gray-900 truncate font-arial dark:text-gray-300" }
const _hoisted_9 = { class: "ml-16 pb-6 flex items-baseline sm:pb-7" }
const _hoisted_10 = { class: "text-xs md:text-sm font-medium text-gray-700 dark:text-gray-400" }
const _hoisted_11 = { class: "absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6 dark:bg-dark-primary-2 dark:border-t dark:border-dark-text-2" }
const _hoisted_12 = { class: "text-sm dark:text-dark-text-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t(`connectWithOtherPlatforms`)), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t(`otherPlatformsDescription`)), 1)
        ])
      ])
    ]),
    _createElementVNode("dl", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherPlatformLinks, (link) => {
        return (_openBlock(), _createElementBlock("div", {
          key: link.title,
          class: "relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden dark:bg-dark-primary-2"
        }, [
          _createElementVNode("a", {
            href: link.href,
            class: "text-primary-3",
            onClick: ($event: any) => (_ctx.analyticsSelectItem(_ctx.changeToSentenceCase(link.title)))
          }, [
            _createElementVNode("dt", null, [
              _createElementVNode("div", _hoisted_7, [
                (_openBlock(), _createBlock(_resolveDynamicComponent(link.icon), {
                  class: "h-6 w-6 text-white dark:text-dark-text-1",
                  "aria-hidden": "true"
                }))
              ]),
              _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t(`${link.title}`)), 1)
            ]),
            _createElementVNode("dd", _hoisted_9, [
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t(`${link.description}`)), 1),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, _toDisplayString(link.title ==='communityPlatform'? _ctx.$t('exploreCommunity'): _ctx.$t('explorePreparation')), 1)
              ])
            ])
          ], 8, _hoisted_6)
        ]))
      }), 128))
    ])
  ], 64))
}