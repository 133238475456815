import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "h-full fixed right-0 flex pointer-events-none items-end w-full max-w-sm z-10" }
const _hoisted_2 = { class: "w-full pointer-events-none space-y-6 p-4 sm:p-6 flex-col items-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_update_snackbar = _resolveComponent("app-update-snackbar")!
  const _component_app_install_snackbar = _resolveComponent("app-install-snackbar")!
  const _component_chrome_install_snackbar = _resolveComponent("chrome-install-snackbar")!
  const _component_allow_notification_snackbar = _resolveComponent("allow-notification-snackbar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_app_update_snackbar),
      _createVNode(_component_app_install_snackbar),
      (_ctx.showChromeSnackBar)
        ? (_openBlock(), _createBlock(_component_chrome_install_snackbar, { key: 0 }))
        : (_ctx.showAllowNotificationSnackbar)
          ? (_openBlock(), _createBlock(_component_allow_notification_snackbar, { key: 1 }))
          : _createCommentVNode("", true)
    ])
  ]))
}