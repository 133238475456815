import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden" }
const _hoisted_2 = { class: "py-4 pl-4 text-base bg-white border-b-2 border-gray-200 dark:border-dark-primary-3 dark:bg-dark-primary-2 dark:text-dark-text-1" }
const _hoisted_3 = {
  key: 0,
  class: "relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8 dark:bg-dark-primary-2 dark:text-dark-text-1"
}
const _hoisted_4 = { class: "ml-4 flex" }
const _hoisted_5 = { class: "pl-4 text-left text-sm text-gray-700" }
const _hoisted_6 = { class: "space-y-6 sm:flex text-primary-4 justify-center bg-white sm:space-y-0 sm:space-x-10 sm:px-8" }
const _hoisted_7 = {
  key: 1,
  class: "h-96 bg-white bg-opacity-90 block items-center dark:bg-dark-primary-2"
}
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "text-center text-gray-500 font-bold" }
const _hoisted_10 = { class: "max-w-sm mx-auto text-sm text-center text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bell_icon = _resolveComponent("bell-icon")!
  const _component_PopoverButton = _resolveComponent("PopoverButton")!
  const _component_AnnotationIcon = _resolveComponent("AnnotationIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PopoverPanel = _resolveComponent("PopoverPanel")!
  const _component_Popover = _resolveComponent("Popover")!

  return (_openBlock(), _createBlock(_component_Popover, { class: "relative" }, {
    default: _withCtx(({ open }) => [
      _createVNode(_component_PopoverButton, { class: "bg-white inline-flex items-center focus:outline focus:outline-white dark:focus:outline-dark-primary-2" }, {
        default: _withCtx(() => [
          _createVNode(_component_bell_icon, {
            "open-notification-dialog": open ? true : false
          }, null, 8, ["open-notification-dialog"])
        ]),
        _: 2
      }, 1024),
      _createVNode(_Transition, {
        "enter-active-class": "transition ease-out duration-200",
        "enter-from-class": "opacity-0 translate-y-1",
        "enter-to-class": "opacity-100 translate-y-0",
        "leave-active-class": "transition ease-in duration-150",
        "leave-from-class": "opacity-100 translate-y-0",
        "leave-to-class": "opacity-0 translate-y-1"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass([_ctx.darkMode ? 'dark' : ''])
          }, [
            _createVNode(_component_PopoverPanel, { class: "absolute right-0 -left-[150px] z-10 transform -translate-x-1/2 mt-4 px-2 w-screen max-w-md sm:px-0" }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('notifications')), 1)
                  ]),
                  (_ctx.notifications.length)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (item) => {
                          return (_openBlock(), _createElementBlock("div", {
                            key: item.name,
                            class: "flex items-start rounded-lg"
                          }, [
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_AnnotationIcon, { class: "h-6 w-6 text-blue-400" }),
                              _createVNode(_component_PopoverButton, null, {
                                default: _withCtx(() => [
                                  _createElementVNode("p", _hoisted_5, _toDisplayString(item.description), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]))
                        }), 128)),
                        _createElementVNode("div", _hoisted_6, [
                          _createVNode(_component_router_link, {
                            to: { name: `userNotifications_${_ctx.langCode}`, params: { langCode: _ctx.langCode}}
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_PopoverButton, { class: "w-28 rounded-md h-10 bg-primary-1 text-primary-4 hover:bg-primary-4 hover:text-primary-1" }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('viewMore')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          }, 8, ["to"])
                        ])
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                        _createElementVNode("img", {
                          src: require('@/assets/BellIcon.webp'),
                          alt: "BellIcon",
                          class: "mx-auto pt-16"
                        }, null, 8, _hoisted_8),
                        _createElementVNode("div", null, [
                          _createElementVNode("h1", _hoisted_9, _toDisplayString(_ctx.$t('alertDialogTitle')), 1),
                          _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('alertDialogDescription')), 1)
                        ])
                      ]))
                ])
              ]),
              _: 1
            })
          ], 2)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}