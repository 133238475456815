import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  "aria-label": "Sidebar",
  class: "hidden lg:block fixed top-16 lg:flex-shrink-0 lg:bg-gray-800 w-22 h-full dark:bg-dark-primary-2"
}
const _hoisted_2 = { class: "relative flex flex-col p-3 space-y-5 w-full font-medium" }
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "text-grey-3 text-xs flex justify-center dark:text-dark-text-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ToolTip = _resolveComponent("ToolTip")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.userSidebarItems, (item) => {
        return (_openBlock(), _createBlock(_component_ToolTip, {
          key: item.name,
          title: _ctx.$t(`${item.name}`)
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: _ctx.getNavigationRouteName(item.pathName),
              class: _normalizeClass([_ctx.selected === item.pathName ? 'bg-gray-900 text-black' : 'text-gray-400 hover:bg-gray-700',
                     ' inline-flex items-center justify-center h-12 w-full rounded-lg']),
              "aria-current": _ctx.selected === item.pathName ? 'page' : undefined,
              onClick: ($event: any) => (_ctx.clickSidebar(item))
            }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  src: require(`@/assets/images/sidebarIcons/${item.icon}`),
                  class: "h-6 flex justify-center",
                  alt: `${item.displayName} image`,
                  "aria-hidden": "true"
                }, null, 8, _hoisted_3)
              ]),
              _: 2
            }, 1032, ["to", "class", "aria-current", "onClick"]),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(`${item.displayName}`)), 1)
          ]),
          _: 2
        }, 1032, ["title"]))
      }), 128))
    ])
  ]))
}