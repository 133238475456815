
import { defineComponent } from "@/plugins/vue";
import HeadingSection from "@/components/Generic/HeadingSection.vue";
import EventBus from "@/helpers/eventBus";

export default defineComponent({
  name: "Dashboard",
  components: { HeadingSection },
  created() {
    // Emit dataForScroll event to load scroll bar
    this.$nextTick().then(() => EventBus.$emit("dataForScroll"));
  },
});
