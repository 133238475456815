
import { defineComponent } from "@/plugins/vue";
import { getCookie, getAccess, checkCookieExpiry } from "@/helpers/cookie";
import BaseAppShellRegistration from "@/components/Base/BaseAppShellRegistration.vue";
import getMeta from "@/helpers/getMetaData";
import BaseSnackbar from "@/components/Base/BaseSnackbar.vue";
import LangCodes from "@/assets/json/langCodes.json";
import { getNamedRoute, sanitizeRoute } from "@/helpers/routesHandler";
import ActionPanel from "@/components/Generic/ActionPanel.vue";
import { NOTIFICATION_PLATFORM_URL } from "@/helpers/constants";
import getLatestUpdatesKey, { LatestUpdatesKey } from "@/router/routeKeyMap";
import { getTagName } from "@/scripts/workerAdapterConnector";
import { RouteLocationNormalized } from "@/plugins/interface";
import displayConsole from "@/helpers/displayConsole";
import BaseAppShell from "./components/Base/BaseAppShell.vue";
import { createCanonicalTag, setSocialCardInfo } from "./helpers/metaTags";
import { TagType } from "./scripts/interface";

// Interface for static tag name
interface TagName {
  id: string;
  en: {
    string: string;
  };
  hi: {
    string: string;
    roman: string;
  };
}

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

export default defineComponent({
  name: "App",
  components: {
    BaseAppShell,
    BaseAppShellRegistration,
    BaseSnackbar,
    ActionPanel,
  },
  computed: {
    /**
     * Computed Propety to fetch User Karma Score from the store
     */
    userKarma(): number {
      return this.$store.getters["user/getUserKarma"];
    },
    /**
     * Computed property to fetch user signed in state
     */
    isUserSignedIn(): boolean {
      return this.$store.getters["user/isUserSignedIn"];
    },
    /**
     * Computed property to fetch darkMode value from store
     */
    darkMode(): boolean {
      return this.$store.getters["base/getDarkMode"];
    },
    /**
     * Gets lang code from the base store
     *
     * @returns {string} lang code
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed property that returns the page type i.e job or admission
     */
    jobAdmissionPageType(): string {
      return this.$store.getters["base/getPageType"];
    },
    /**
     * Function gets the subscribed event ids
     *
     * @returns {array} - subscribed event ids
     */
    subscribedEventIds(): string[] {
      return this.$store.getters["events/getSubscribedEventIds"];
    },
    /**
     * Function gets the subscribed exam ids
     *
     * @returns {array} - subscribed exam ids
     */
    subscribedExamIds(): string[] {
      return this.$store.getters["events/getSubscribedExamIds"];
    },
  },
  watch: {
    /**
     * Watcher to watch user karma property
     *
     * @param {number} val - The updated karma value
     */
    userKarma(val: number): void {
      this.userKarmaDataHandler(val, "User karma watcher");
    },
    /**
     * Watcher to watch subscribed event ids
     * and send updates subscribed event ids to service Worker
     */
    subscribedEventIds(): void {
      this.sendNotificationIds();
    },
    /**
     * Watcher to watch subscribed exam ids
     * and send updates subscribed exam ids to service Worker
     */
    subscribedExamIds(): void {
      this.sendNotificationIds();
    },
    /**
     * Watcher to watch the route object
     * - Change the meta title and description according to the page
     * - Set langCode in store
     *
     * @param {object} newRoute - New route value
     * @param {object} oldRoute - Old route value
     */
    $route(newRoute: RouteLocationNormalized, oldRoute: RouteLocationNormalized) {
      this.generateMetaTags(newRoute);
      const newRouteName = newRoute.name as string;
      const oldRouteName = oldRoute.name as string;
      if (newRouteName.split("_")[0] !== oldRouteName?.split("_")[0]) {
        // get Meta is an async function
        getMeta(newRoute).then((metaData) => {
          this.$meta.title = metaData?.title;
          this.$meta.description = metaData?.description;
        });
        // set social card meta data for social card template preview
        this.setSocialCardMeta();
      }
      // setting the Language code for static data on page open or reload
      this.setLangCode(newRoute);
      this.$meta.htmlAttrs = { ...this.$meta.htmlAttrs, lang: this.langCode };
      if (this.$route.meta.mode === "job" || this.$route.meta.mode === "admission") {
        this.$store.commit("base/UPDATE_PAGE_TYPE", this.$route.meta.mode);
      }
    },
  },

  /**
   * Created life cycle hook
   * Handle Notifications
   * Listen to swUpdate event and trigger updateAvailable function
   */
  async created() {
    this.setLangCode(this.$route);
    document.addEventListener("swUpdated", this.updateAvailable);
    this.setJobAdmissionPageType();
    this.userKarmaDataHandler(this.userKarma, "App created");
  },

  methods: {
    /**
     * Setting up the jobAdmissionPageType so that this page type can be used to set the appropriate header , footer and breadcrumb
     */
    setJobAdmissionPageType(): void {
      // Function to save the jobAdmissionPageType to the localStorage , just before the page reloads
      const saveJobAdmissionType = () => {
        window.localStorage.setItem("categoryType", this.jobAdmissionPageType);
      };
      this.checkJobAdmissionPageTypes();
      window.addEventListener("beforeunload", saveJobAdmissionType);
    },
    /**
     * Function to check the localStorage and set the jobAdmissionPageType
     */
    checkJobAdmissionPageTypes() {
      const data = window.localStorage.getItem("categoryType");
      this.$store.commit("base/UPDATE_PAGE_TYPE", data);
    },

    /**
     * Function to check the value of userKarma, and get the EPFA cookie if it has expired
     * only when the User Karma exceeds minimum required karma score
     */
    checkAndFetchCookie() {
      if (this.userKarma >= 25) {
        const isCookiePresent = getCookie("EPFA");
        if (!isCookiePresent) {
          getAccess();
        } else {
          checkCookieExpiry();
        }
      }
    },

    /**
     * Function to set the current language code
     *
     * @param {RouteLocationNormalized} route - the current route object
     */
    setLangCode(route: RouteLocationNormalized) {
      const { langCode } = route.params;
      // ! Do we need to set this on created or on route change
      this.$i18n.locale = langCode as LangCode;
      // ? Sets the current language code in store
      this.$store.commit("base/SET_LANGUAGE_CODE", langCode);
    },
    /**
     * Function generates the meta link tags for the particular route path
     *
     * @param {RouteLocationNormalized} route - route object with all route information
     */
    generateMetaTags(route: RouteLocationNormalized) {
      const notValidRouteNames = ["organisationEdit", "examEdit", "eventViewDetails", "eventView", "eventEdit", "eventEditStatus"];
      const routeName = route.name as string;
      const canonicalLink = createCanonicalTag(route, this.$router);
      if (notValidRouteNames.includes(routeName.split("_")[0])) return;
      if (route.meta.pageType === "filteredTagListPage" || route.meta.pageType === "staticDataTemplatePage") {
        const itemId = route.params.id as string;
        const splitItemId = itemId.split("-")[0];
        const staticFileType = route.meta.type as TagType;
        getTagName(staticFileType, [splitItemId]).then((tagNameObj) => {
          if (tagNameObj[0]) {
            const metaLinkTags = this.createAlternateLinkObj(route, tagNameObj as TagName[], itemId);
            if (canonicalLink) metaLinkTags.push(canonicalLink);
            this.$meta.link = metaLinkTags;
          }
        });
      } else {
        const metaLinkTags = this.createAlternateLinkObj(route, [], "");
        if (canonicalLink) metaLinkTags.push(canonicalLink);
        this.$meta.link = metaLinkTags;
      }
    },
    /**
     * Function generates the alternate language link object
     *
     * @param {object} route - route information object
     * @param {object} tagNameObj - object with tag name information
     * @param {string} itemId - link of the site
     * @returns {object[]} alternateLinkTags
     */
    createAlternateLinkObj(route: RouteLocationNormalized, tagNameObj: TagName[], itemId: string): object[] {
      const defaultRoute = tagNameObj && itemId ? this.getRouteObj(route, "en", tagNameObj, itemId) : this.getRouteObj(route, "en", [], "");
      const siteLink = NOTIFICATION_PLATFORM_URL;
      const alternateLinkTags = [{ rel: "alternate", hreflang: "x-default", href: `${siteLink}${defaultRoute.path}` }];
      const validLangCodes = [...LangCodes.langCodes] as LangCode[];
      validLangCodes.splice(validLangCodes.indexOf("en"), 1);
      validLangCodes.forEach((langCode) => {
        const routeObj = tagNameObj && itemId ? this.getRouteObj(route, langCode, tagNameObj, itemId) : this.getRouteObj(route, langCode, [], "");
        const linkObj = {
          rel: "alternate",
          hreflang: langCode,
          href: `${siteLink}${routeObj.path}`,
        };
        alternateLinkTags.push(linkObj);
      });
      return alternateLinkTags;
    },
    /**
     * Function gets the routes object according to different Language
     *
     * @param {object} route - route Object
     * @param {string} langCode - lang code like en, hi
     * @param {object[]} tagNames - tag name object with lang objects
     * @param {string} itemId - id of the item
     * @returns {RouteLocationNormalized} routeObject - Object containing route information
     */
    getRouteObj(route: RouteLocationNormalized, langCode: LangCode, tagNames: TagName[], itemId: string): RouteLocationNormalized {
      // Route object interface
      interface RouteObject {
        name: string;
        params: {
          id?: string;
          langCode: LangCode;
          latestUpdateType?: string;
        };
        meta?: {
          type: string;
          filterType: string;
        };
      }
      const routeObjParams: RouteObject = {
        name: getNamedRoute(langCode, route.name as string),
        params: { langCode },
      };
      if (route.meta.pageType === "staticDataTemplatePage") {
        let tagNameObj = langCode === "en" ? sanitizeRoute(tagNames[0].en.string) : sanitizeRoute(tagNames[0]?.[langCode]?.roman);
        if (!tagNameObj) tagNameObj = sanitizeRoute(tagNames[0].en.string);
        routeObjParams.params = { langCode, id: `${itemId}-${tagNameObj}` };
      }
      if (route.meta.pageType === "latestUpdatePage") {
        const latestUpdateListType = getLatestUpdatesKey(route.meta.type as LatestUpdatesKey, langCode);
        routeObjParams.params = { langCode, latestUpdateType: latestUpdateListType };
      }
      if (route.meta.pageType === "filteredTagListPage") {
        let tagNameObj = langCode === "en" ? sanitizeRoute(tagNames[0].en.string) : sanitizeRoute(tagNames[0]?.[langCode]?.roman);
        if (!tagNameObj) tagNameObj = sanitizeRoute(tagNames[0].en.string);
        const filterType = getLatestUpdatesKey(route.meta.filterType as LatestUpdatesKey, langCode);
        routeObjParams.params = { langCode, id: `${itemId}-${tagNameObj}`, latestUpdateType: filterType };
      }
      const routeObj = this.$router.resolve(routeObjParams);
      return routeObj;
    },
    /**
     * Function is used to show the snackbar to user to refresh when the new update is avaliable.
     */
    updateAvailable() {
      this.$store.commit("base/SET_PWA_UPDATE_STATUS", true);
    },
    /**
     * Function to get the user subscribed event list
     */
    getUserSubscriptionList() {
      this.$store.dispatch("events/fetchSubscriptionList");
    },
    /**
     * Function to send the event and exam ids to the service worker to generate notification whenever there is an update
     */
    sendNotificationIds() {
      if (Notification?.permission !== "granted") return;
      navigator.serviceWorker.controller?.postMessage({
        eventIds: [...this.subscribedEventIds],
        examIds: [...this.subscribedExamIds],
        actionType: "sendNotificationIds",
      });
    },
    /**
     * Function to add social card meta data to specific pages
     */
    setSocialCardMeta(): void {
      const pageTypes = ["landingPage", "latestUpdatePage", "staticDataTemplatePage", "filteredTagListPage", "tagListPage"];
      const routeNames = ["searchHome"];
      const currentPageType = this.$route.meta.pageType as string;
      const currentRouteName = this.$route.name as string;
      if (pageTypes.includes(currentPageType) || routeNames.includes(currentRouteName.split("_")[0])) {
        const metaTags = setSocialCardInfo(this.$route);
        this.$meta.meta = metaTags;
      }
    },
    /**
     * Function to call checkAndFetchCookie and getUserSubscriptionList on specific Karma
     *
     * @param {number} karma - Current karma value
     * @param {string} message - Message to be displayed with current karma value
     */
    userKarmaDataHandler(karma: number, message: string): void {
      displayConsole("User Karma : ", karma, message);
      if (karma) {
        this.checkAndFetchCookie();
        this.getUserSubscriptionList();
      }
    },
  },
});
