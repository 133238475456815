
import { CheckIcon } from "@/plugins/solidHeroicon";
import { defineComponent, PropType } from "@/plugins/vue";
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from "@/plugins/headlessui";

interface FilterOption {
  id: string;
  text: string;
  count: number;
  selected: boolean;
  disabled: boolean;
}

export default defineComponent({
  name: "FilterComboBox",
  components: {
    Combobox,
    ComboboxButton,
    ComboboxInput,
    ComboboxOption,
    ComboboxOptions,
    CheckIcon,
  },
  props: {
    /**
     * Items to be shown in conbobox
     */
    dataItems: {
      type: Array as PropType<FilterOption[]>,
      default: () => [],
    },
    /**
     * placeholder
     */
    placeholder: {
      type: String,
      default: "",
    },
  },
  emits: ["selected"],
  data() {
    return {
      query: "",
    };
  },
  computed: {
    /**
     * Filtered people to be shown in combobox as per query
     */
    filteredItems(): FilterOption[] {
      return this.query === "" ? this.dataItems : this.dataItems.filter((item) => item.text.toLowerCase().includes(this.query.toLowerCase())).splice(0, 30);
    },
  },
});
