import eventData from "@/assets/json/event.json";

export default {
  /**
   * Mutation to set the event data and file attachments
   *
   * @param {object} state - Current state of the store
   * @param {object} root0 - {data} - data: Data of the event
   * @param {object} root0.data - Data of event
   */
  SET_EVENT(state, data) {
    state.event = data;
    state.fileAttachments = data.fileAttachments;
  },

  /**
   * Mutation to clear the event data from store
   *
   * @param {object} state - Current state of the store
   */
  RESET_EVENT(state) {
    state.event = eventData;
  },

  /**
   * Updates the the fileAttachments
   *
   * @param {*} state - data store for the event details module used to access the fileAttachments
   * @param {object} payload - file object
   */
  UPDATE_FILE_ATTACHMENTS(state, payload) {
    state.fileAttachments.push(payload);
  },

  /**
   * Clears the file fileAttachments
   *
   * @param {*} state - used to access the fileAttachments in events module
   */
  CLEAR_FILE_ATTACHMENTS(state) {
    state.fileAttachments = [];
  },

  /**
  * Function updates the fileAttachments object state which can be hide or show
  *
  * @param {*} state - Used to access the fileAttachments in events module
  * @param {object} payload - fileAttachments whose display state needs to be updated: show or hide
  */
  UPDATE_FILE_STATE(state, { attachmentlink, mode }) {
    const index = state.fileAttachments.findIndex((e) => e.fileLink === attachmentlink);
    if (index >= 0) {
      state.fileAttachments[index].fileState = mode;
    }
  },

  /**
   * Mutation to set the event types in state
   *
   * @param {*} state - state of store
   * @param {string} recruitmentType - The recruitmentType event type's Id
   * @param {string} stream - The stream event type's Id
   */
  SET_EVENT_TYPE_IDS(state, { recruitmentType, stream }) {
    state.eventTypes.recruitmentType = recruitmentType;
    state.eventTypes.stream = stream;
  },

  /**
   * Mutation to reset the event types in state
   *
   * @param {*} state - state of store
   */
  RESET_EVENT_TYPE_IDS(state) {
    state.eventTypes = {};
  },
  /**
   * Mutation to append a new notification to existing update notification log in the store
   *
   * @param {string, object} - notificationId , notificationData
   */
  APPEND_NOTIFICATION(state, { tagId, data }) {
    // ? Vue.set is not required in Vue 3, it is reactive and changes are tracked through proxies(https://stackoverflow.com/a/66304421)
    state.event.notifications[tagId] = data;
  },
  /**
   * Mutation to set the event tags in state
   * @param {*} state - state of store
   * @param {Array} tag- Array of event tags object
   */
  SET_EVENT_TAGS(state, tag) {
    state.eventTags = tag;
  },
  /**
   * Mutation reset the event tags in state
   *
   * @param {*} state - state of store
   */
  RESET_EVENT_TAGS(state) {
    state.eventTags = [];
  },
  /**
    * Sets the subscribed event ids and exam ids
    *
    * @param {*} state - state of store
    * @param {object} payload - event ids and exam ids object
    */
  SET_SUBSCRIBED_EVENTS(state, payload) {
    state.subscribedEventIds = payload.eventIds;
    state.subscribedExamIds = payload.examIds;
  },
  /**
   * Clears the list of subscribed eventIds and examIds
   *
   * @param {*} state - state of store
   */
  CLEAR_SUBSCRIBED_EVENTS(state) {
    state.subscribedEventIds = [];
    state.subscribedExamIds = [];
  },
};
