
import ShareDialog from "@/components/Generic/ShareDialog.vue";
import renderComponent from "@/helpers/componentKarma";
import {
  metaTagsGenerator, metaTagSlugRedirection, generateAlternateLinkTags, createCanonicalTag,
} from "@/helpers/metaTags";
import getAssetAPI from "@/api/assetsAPI";
import LangJSON from "@/assets/json/langCodes.json";
import { PhotographIcon } from "@/plugins/solidHeroicon";
import HeadingSection from "@/components/Generic/HeadingSection.vue";
import {
  sanitizeRoute, validateRouteAndFetchSlug, getNamedRoute, getSeperatedIdAndName,
} from "@/helpers/routesHandler";
import { COMMUNITY_PLATFORM_URL, NOTIFICATION_PLATFORM_URL } from "@/helpers/constants";
import displayConsole from "@/helpers/displayConsole";
import NoInternet from "@/components/Shared/NoInternet.vue";
import { displayMessage, Type, Priority } from "@/helpers/emitEventBusEvent";
import { getExamSubscriptionInformation, getTagTitle } from "@/scripts/workerAdapterConnector";
import { analyticsSelectContent, analyticsSelectItem } from "@/helpers/analyticsEvents";
import SubscriptionDialogBox from "@/components/Subscription/SubscriptionDialogBox.vue";
import EventViewDetails from "@/views/EventViewDetails.vue";
import { defineComponent } from "@/plugins/vue";
import type {
  Event, LangCode, Notifications, NotificationData, RouteLocationNormalized,
} from "@/helpers/interface";
import { API } from "@/api/interface";

interface EventDescription {
  en: string;
  hi: string;
}

interface MetaTag {
  name?: string;
  property?: string;
  content: string | number;
}

interface LinkTag {
  rel: string;
  hreflang?: string;
  href: string;
}

interface EventSlugStatus {
  matchFound: boolean;
  newSlug: string;
}

interface ExamData {
  id: string;
  lang: {
    en: {
      aliases: string[];
      string: string;
    };
    hi: {
      aliases: string[];
      string: string;
    };
  };
  mode: string;
}

export default defineComponent({
  name: "EventView",
  components: {
    EventViewDetails,
    PhotographIcon,
    HeadingSection,
    NoInternet,
    SubscriptionDialogBox,
    ShareDialog,
  },
  data: () => ({
    apiObject: null as API | null,
    isEventSubscribed: false,
    eventId: "",
    examIds: [] as string[],
    selectedExamIds: [] as string[],
    attachExams: [] as ExamData[],
    forumLink: "",
    notificationArray: [] as NotificationData[],
    metaTags: [] as MetaTag[],
    altImageValue: "",
    eventSlug: "",
    eventTitle: "",
    metaDescription: {
      en: "EPF Event Description",
      hi: "EPF Naukri / Pariksha vivaran",
    },
    linkTags: [] as LinkTag[],
    isUserOffline: false,
    openShare: false,
    currentEventUrl: "",
    showSubscribeDialogBox: false,
    showSubscribeSpinner: false,
  }),
  computed: {
    /**
    * User Karma Score Property
    *
    * @returns {number} User Karma Score fetched from the Store
    */
    userKarma(): number {
      return this.$store.getters["user/getUserKarma"];
    },
    /**
     * Computed property to get eventData from the events module
     *
     * @returns {Event} object - eventDetails object
     */
    eventData(): Event {
      return this.$store.getters["events/getEvent"];
    },
    /**
     * Gets language code from the base module in store
     *
     * @returns {LangCode} - current language code
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Function gets the subscribed event ids
     *
     * @returns {string[]} - subscribed event ids
     */
    subscribedEventIds(): string[] {
      return this.$store.getters["events/getSubscribedEventIds"];
    },
    /**
     * Function gets the subscribed exam ids
     *
     * @returns {string[]} - subscribed exam ids
     */
    subscribedExamIds(): string[] {
      return this.$store.getters["events/getSubscribedExamIds"];
    },
  },
  watch: {
    /**
     * Watcher to watch for change in route and set roman title and meta info
     *
     * @param {RouteLocationNormalized} current - current route
     */
    $route(current: RouteLocationNormalized) {
      if (!(current.name as string).startsWith("eventView")) return;
      this.setRomanTitle(this.eventData);
      this.setMetaData(this.eventTitle, this.metaDescription, [], []);
    },
    /**
     * Watcher for the change in the subscribed event ids
     */
    subscribedEventIds(val: string[]) {
      this.isEventSubscribed = val.includes(this.eventId);
    },
    /**
     * Watch for changes in the user karma
     */
    userKarma(val: number) {
      if (!val) {
        this.isEventSubscribed = false;
        this.$store.commit("events/CLEAR_SUBSCRIBED_EVENTS");
      }
    },

  },
  /**
   * Created life cycle hook
   * In this hook we are dispatching the getEventData function which will get the eventData object
   * and store it in the events module
   */
  created() {
    this.currentEventUrl = `${NOTIFICATION_PLATFORM_URL}${this.$route.fullPath}`;
    this.eventSlug = this.$route.params.id as string;
    const eventID = getSeperatedIdAndName(this.eventSlug).id;
    this.eventId = eventID;
    this.apiObject = getAssetAPI("ACCESS_LOGO");
    this.isEventSubscribed = this.subscribedEventIds.includes(this.eventId);
    const payload = { eventID: this.eventId, mode: this.$route.meta.mode };
    if (this.eventData.component === this.eventId) this.eventDataHandler(this.eventData);
    else {
      this.$store.dispatch("events/readEvent", payload).then((event) => {
        this.eventDataHandler(event);
      }).catch((e) => {
        // ? Refer: https://developer.mozilla.org/en-US/docs/web/api/navigator/online
        if (!window.navigator.onLine) this.isUserOffline = true;
        else {
          this.isUserOffline = false;
          displayConsole(`readEvent Failed for langCode ${this.langCode} at ${new Date().toLocaleString()}`, e, "Page not found redirection error");
          this.$router.push({ name: `notFound_${this.langCode}`, params: { langCode: this.langCode } });
        }
      });
    }
  },
  /**
   * unmounted hook which resets the event in events module
   */
  unmounted() {
    this.$store.commit("events/RESET_EVENT");
  },
  methods: {
    renderComponent,
    generateAlternateLinkTags,

    // Set Metadata for events
    setMetaData(title: string, description: EventDescription, metaTags: MetaTag[], linkTags: LinkTag[]) {
      this.$meta.title = title;
      this.$meta.description = `${this.$filters.removeHtmlTags(description[this.langCode].split("</p>").join(" ")).slice(0, 200)}... `;
      if (linkTags.length) this.$meta.link = linkTags;
      if (metaTags.length) this.$meta.meta = metaTags;
    },
    /**
     * Function to match slugName of events
     *
     * @param {string} urlSlug - url slug
     * @param {string} eventId - Id of event
     * @param {string} eventName - name of event
     * @returns {EventSlugStatus} Object containing boolean and newslug
     */
    matchEventSlugName(urlSlug: string, eventId: string, eventName: string): EventSlugStatus {
      const slugObject = validateRouteAndFetchSlug(eventId, eventName, this.$route);
      const newSlug = slugObject.idSlug;
      let matchFound = false;
      if (this.$route.params.id) {
        matchFound = slugObject.isValidated;
        if (!matchFound) return { matchFound, newSlug };
      }
      matchFound = sanitizeRoute(urlSlug) === newSlug;
      return { matchFound, newSlug };
    },

    /**
     * Function to open the generated Forum link in a new browser tab using window method
     */
    redirect() {
      const url = this.forumLink;
      analyticsSelectItem("Community Forum");
      window.open(url, "_blank");
    },

    /**
     *
     * Function creates a notification array and sort it descending order
     * @param {Notifications} notification - object with nested notification object
     */
    sortNotifications(notification: Notifications) {
      const notificationArray = Object.values(notification);
      notificationArray.sort((a, b) => (b.timestamp) - (a.timestamp));
      this.notificationArray = notificationArray;
    },

    /**
     * Function to set event and its information, and sort notifications
     *
     * @param {Event} event - The object containing event data
     */
    setEventInformation(event: Event) {
      this.$store.commit("events/SET_EVENT", event);
      const forumURL = `${COMMUNITY_PLATFORM_URL}/d/`;
      this.forumLink = event.forumLink ? forumURL + event.forumLink : COMMUNITY_PLATFORM_URL;
      this.sortNotifications(this.eventData.notifications);
    },
    /**
     * Updates the HTML meta tags
     */
    async updateMetaTags() {
      const siteLink = window.location.href.split("?")[0];
      const eventLogo = `${this.apiObject?.url}${this.eventData.organisationIds[0]}/logo.png`;
      const title = this.eventData.title[this.langCode];
      const description = this.stripHtml(this.eventData.description[this.langCode]);

      let exams = "";
      if (this.eventData.examIds.length) {
        const examsTitle = [] as string[];
        const result = await getTagTitle("exams", [...this.eventData.examIds], false);
        const examsData = Object.values(result);
        examsData.length = 2;
        examsData.forEach((exam) => {
          if (exam?.lang?.en?.string) examsTitle.push(exam.lang.en.string);
        });
        exams = examsTitle.join(", ");
      }
      const titleEn = `${this.eventData.title.en.substr(0, 60).trim()}...`;
      let imageLink = `https://napi.exampathfinder.com/card/?title=${titleEn}&img_url=${eventLogo}&exams=${exams}`;
      imageLink = encodeURI(imageLink);

      const metaTags = metaTagsGenerator(title, description, imageLink, siteLink);
      this.metaTags = [...this.metaTags, ...metaTags];
    },

    /**
     * Function to filterout all description about event from html element string
     *
     * @param {string} html - html element as string
     * @returns {string} return description of event
     */
    stripHtml(html: string): string { // TODO move to generic helper function
      const tmp = document.createElement("DIV");
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || "";
    },
    /**
     * Sets the roman title
     *
     * @param {Event} event - object with all evnet information
     */
    setRomanTitle(event: Event) {
      const { langCode } = this;
      if (LangJSON.romanLangCodes.includes(langCode) && "title_roman" in event && langCode in event.title_roman) {
        const romanTitle = event.title_roman[langCode as "hi"].replace(/\s/g, "-");
        this.setEventTitleAndSlug(romanTitle);
      } else this.setEventTitleAndSlug(event.title.en);
    },
    /**
     * Set the event title and slug
     *
     * @param {string} title - The title of event
     */
    setEventTitleAndSlug(title: string) {
      const { idSlug } = validateRouteAndFetchSlug(this.eventId, title, this.$route);
      // eslint-disable-next-line no-restricted-globals
      history.replaceState(history.state, "", idSlug);
      this.eventSlug = idSlug;
      this.eventTitle = title;
    },
    /**
     * Function to get the edit event link
     */
    getEventEditLink() {
      if (!this.langCode) return null;
      const { id } = getSeperatedIdAndName(this.eventId);
      const idSlug = sanitizeRoute(`${id}-${this.eventTitle}`);
      const path = {
        name: getNamedRoute(this.langCode, "eventEdit_en"),
        params: { langCode: this.langCode, id: idSlug },
      };
      return path;
    },

    /**
     * Function to set all event info and event meta info
     *
     * @param {Event} event - event Object
     */
    async eventDataHandler(event: Event) {
      displayConsole("Event View: Read event", event, "eventDataHandler");
      this.setRomanTitle(event);
      this.metaDescription = event.description;
      this.examIds = event.examIds;
      const slugMatchResponse = this.matchEventSlugName(this.eventSlug, this.eventId, this.eventTitle);
      const metaSlugTag = metaTagSlugRedirection(slugMatchResponse);
      this.metaTags = metaSlugTag;
      this.setEventInformation(event);
      await this.updateMetaTags();
      this.linkTags = this.generateAlternateLinkTags(event, this.eventId, this.$route);
      const canonicalLink = createCanonicalTag(this.$route, this.$router);
      if (canonicalLink) this.linkTags.push(canonicalLink);
      this.setMetaData(this.eventTitle, this.metaDescription, this.metaTags, this.linkTags);
      // Getting attached exam data(ids and title) of current event
      getExamSubscriptionInformation([...this.examIds], false).then((data) => { this.attachExams = Object.values(data); });
    },
    /**
     * Function to subscribe the user for this event
     */
    subscribe() {
      if (!this.$store.getters["user/isUserSignedIn"]) this.$store.commit("base/CHANGE_SIGN_IN_BOX_DISPLAY", true);
      else if (this.examIds.length && !this.isEventSubscribed) {
        this.showSubscribeDialogBox = true;
        this.showSubscribeSpinner = true;
      } else {
        this.showSubscribeSpinner = true;
        this.showSubscribeDialogBox = false;
        this.selectedExamIds = [];
        this.subscriptionHandler(false);
      }
    },
    /**
     * Function handels the user subscription
     */
    subscriptionHandler(value: boolean) {
      if (!this.isEventSubscribed && value) {
        this.attachExams.forEach((element, index) => {
          if (!this.subscribedExamIds.includes(element.id)
          && (this.$refs.input as HTMLInputElement[])[index].checked) this.selectedExamIds.push(element.id);
        });
      }
      const payload = {
        mode: this.isEventSubscribed ? "unsubscribe" : "subscribe",
        data: JSON.stringify({ eventIds: [this.eventId], examIds: this.selectedExamIds }),
      };
      this.$store.dispatch("events/subscribeEvent", payload).then((data) => {
        this.isEventSubscribed = !this.isEventSubscribed;
        if (this.isEventSubscribed) analyticsSelectContent(this.eventId);
        this.$store.commit("events/SET_SUBSCRIBED_EVENTS", data.body);
        this.showSubscribeSpinner = false;
        displayMessage(`Event ${payload.mode}d Successfully`, Priority.MEDIUM, Type.SUCCESS);
      });
    },
  },
});
