// Import interface
import type { API, CoreAPI } from "./interface";

// Import store
const store = require("../store/index.js");

// Base URLs
const baseURL = "https://api.exampathfinder.com/data/";

const coreAPI: CoreAPI = {
  // Core Tags
  CORE_ADD_TAG: {
    url: `${baseURL}admin/tag/add`,
    headers: true,
    karma: 50,
    credentials: false,
    id: "CORE#1",
  },

  CORE_UPDATE_TAG: {
    url: `${baseURL}admin/tag/update`,
    headers: true,
    karma: 50,
    credentials: false,
    id: "CORE#2",
  },

  CORE_TAG_CONNECT_PARENTS: {
    url: `${baseURL}admin/tag/connect`,
    headers: true,
    karma: 50,
    credentials: false,
    id: "CORE#3",
  },

  CORE_READ_TAG: {
    url: `${baseURL}admin/tag/view/single`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "CORE#4",
  },
};

/**
 * Function to check user karma before calling API and return the API object if the user has the minimum required karma to access the API
 *
 * @param {string} apiKey - The key of the API call whose karma value needs to be checked before access
 * @returns {object} APIObject - Object containing information for the specific API on successfully passing the user karma test
 */
function getCoreAPI(apiKey: keyof CoreAPI): API | null {
  const userKarma = store.default.getters["user/getUserKarma"] as number;
  if (userKarma >= coreAPI[apiKey].karma) return coreAPI[apiKey];
  return null;
}

export default getCoreAPI;
