<template>
  <svg
    :style="{width: '24px', height: '24px', 'background-color' : `${darkMode ? '#111111' : 'white'}`, }"
    viewBox="0 0 24 24"
  >
    <path
      :fill="[darkMode ? '#999999' : '#000000']"
      :d="[openNotificationDialog ?
        ['M21,19V20H3V19L5,17V11C5,7.9 7.03,5.17 10,4.29C10,4.19 10,4.1 10,4A2,2 0 0,1 12,2A2,2 0 0,1 14,4C14,4.1 14,4.19 14,4.29C16.97',
         '5.17 19,7.9 19,11V17L21,19M14,21A2,2 0 0,1 12,23A2,2 0 0,1 10,21']
        : ['M10 21H14C14 22.1 13.1 23 12 23S10 22.1 10 21M21 19V20H3V19L5 17V11C5 7.9 7 5.2 10 4.3V4C10 2.9 10.9 2 12 2S14 2.9 14 4V4.3C17',
           '5.2 19 7.9 19 11V17L21 19M17 11C17 8.2 14.8 6 12 6S7 8.2 7 11V18H17V11Z']]"
    />
  </svg>
</template>
<script>

export default {
  props: {
    /**
     * Prop to change bellIcon in outline and solid
     */
    openNotificationDialog: {
      type: Boolean,
      defalt: false,
    },
  },
  computed: {
    /**
     * Computed property to get the dark Mode
     */
    darkMode() {
      return this.$store.getters["base/getDarkMode"];
    },
  },
};
</script>
