
import { defineComponent } from "@/plugins/vue";
import {
  Dialog as DialogBox, DialogOverlay, TransitionChild, TransitionRoot,
} from "@/plugins/headlessui";
import { NOTIFICATION_PLATFORM_URL } from "@/helpers/constants";
import { displayMessage, Type, Priority } from "@/helpers/emitEventBusEvent";
import { DuplicateIcon } from "@/plugins/outlineHeroicon";
/**
 * Navigator extended to ExtendedNavigator for defining the type of canShare
 * canShare returns boolean value and tells where accessing navigation.share() is possible or not
 */
interface ExtendedNavigator extends Navigator {
  canShare: (shareData?: ShareData) => boolean;
}

export default defineComponent({
  name: "ShareDialog",
  components: {
    DialogBox, DialogOverlay, TransitionChild, TransitionRoot, DuplicateIcon,
  },
  props: {
    /**
     * Title shared with url
     */
    title: {
      type: String,
      default: "",
    },
    /**
     * Trigger to share the current url
     */
    sharePage: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["closeShare"],
  data: () => ({
    openShare: false,
    socialIcons: [
      "facebook",
      "gmail",
      "twitter",
      "whatsapp",
      "telegram",
    ],
  }),
  computed: {
    /**
     * Computed current url property
     */
    currentUrl() {
      return `${NOTIFICATION_PLATFORM_URL}${this.$route.fullPath}`;
    },
    /**
     * Computed property to get the dark Mode
     */
    darkMode() {
      return this.$store.getters["base/getDarkMode"];
    },
  },
  watch: {
    /**
     * Watcher on share page prop to open share dialog
     */
    sharePage(val) {
      if (val) this.shareEvent();
    },
  },
  methods: {
    /**
     * Function to share event on social media platforms and copy link to clipboard
     *
     * @param {string} socialName - the name of platform
     */
    shareEventSocial(socialName: string) {
      let shareLink = "";

      switch (socialName) {
        case "facebook":
          shareLink = `https://www.facebook.com/sharer/sharer.php?u=${this.currentUrl}&quote=${this.title}`;
          break;

        case "twitter":
          shareLink = `https://twitter.com/intent/tweet?url=${this.currentUrl}&text=${this.title}`;
          break;

        case "gmail":
          shareLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=&su=${this.title} Notification&body=${this.currentUrl}&ui=2&tf=1&pli=1`;
          break;

        case "whatsapp":
          shareLink = `https://wa.me/?text=${this.title} ${this.currentUrl}`;
          break;

        case "telegram":
          shareLink = `https://t.me/share/url?url=${this.currentUrl}&text=${this.title}`;
          break;

        default:
          navigator.clipboard.writeText(this.currentUrl);
          displayMessage("Url copied succesfully", Priority.MEDIUM, Type.SUCCESS);
      }

      if (shareLink) window.open(shareLink, "_blank");

      this.closeShareDialog();
    },

    /**
     * Function to close the share dialog box
     */
    closeShareDialog() {
      this.openShare = false;
      this.$emit("closeShare");
    },

    /**
     * Function to share event on mobile devices via mdn webshare api
     */
    shareEvent() {
      const shareData = {
        title: "Exam Path Finder Alerts",
        text: this.title,
        url: this.currentUrl,
      };
      const extendedNavigator = navigator as ExtendedNavigator;
      if (extendedNavigator.canShare && extendedNavigator.canShare(shareData)) {
        navigator.share(shareData).then(() => {
          this.closeShareDialog();
        }).catch((error) => {
          this.closeShareDialog();
          console.log("Sharing failed", error);
        });
      } else this.openShare = true;
    },

  },
});
