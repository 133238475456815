import TopOrganisation from "@/assets/workerJson/TopOrganisations.json";
import TopUniversities from "@/assets/workerJson/TopUniversities.json";
import TopQualifications from "@/assets/workerJson/TopQualifications.json";
import TopStreams from "@/assets/workerJson/TopStreams.json";
import TopCategory from "@/assets/workerJson/TopCategory.json";
import TopStates from "@/assets/workerJson/TopStates.json";
import { State, StatesAndUnions } from "@/helpers/interface";

// static file object
const staticFiles = {
  organisation: TopOrganisation,
  university: TopUniversities,
  stream: TopStreams,
  category: TopCategory,
  state: TopStates,
  qualification: TopQualifications,
};

type CountryName = keyof typeof TopStates;
type StaticFileName = keyof typeof staticFiles;

/**
 * Function to get static file names json data ids
 *
 * @param {string} staticFileName - Name of static file
 * @returns {string[]} json object store for the staticFileName
 */
function getJSONDataIds(staticFileName: keyof typeof staticFiles): string[] {
  return Object.keys(staticFiles[staticFileName]);
}

/**
 * Function to get exam ids
 *
 * @param {string} id - Id of tag for which examIds are to be fetched
 * @param {string} staticFileName - Name of static file
 * @returns {string[] or undefined}- Exam ids connected to tag
 *
 */

function getExamIds(id: string, staticFileName: string): string[] | undefined {
  const category = staticFiles[staticFileName as keyof typeof staticFiles];
  const key = id as keyof typeof category;
  const data: State = category[key];
  return data?.examIds;
}

/**
 * Function to get States and UTs
 *
 * @param {CountryName} country Country name
 * @returns {StatesAndUnions} state and union territories
 */
function getStatesAndUTs(country: CountryName): StatesAndUnions {
  return staticFiles.state[country];
}

/**
 * Function to get state using country name and state id
 *
 * @param country - Country name
 * @param stateId - State id
 * @returns State of the respective state id in the given country name
 */
function getState(country: CountryName, stateId: string): State {
  return getStatesAndUTs(country)[stateId];
}

export {
  getJSONDataIds, getExamIds, getStatesAndUTs, getState,
};

export type {
  StaticFileName, CountryName,
};
