
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  name: "Tooltip",
  props: {
    title: {
      type: String,
      default: "",
    },
  },
});
