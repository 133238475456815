/* eslint-disable no-console */

import { register } from "register-service-worker";
import { isChrome } from "../helpers/validations";

// Sync JSON files every hour
async function periodicSync() {
  if (!isChrome()) return;
  const status = await navigator.permissions.query({
    name: "periodic-background-sync" as PermissionName,
  });
  if (status.state === "granted") {
    // Periodic background sync can be used.
    const registration = await navigator.serviceWorker.ready;
    if ("periodicSync" in registration) {
      try {
        await registration.periodicSync.register("json-sync", {
          // An interval of one hour
          minInterval: 60 * 60 * 1000,
        });
        console.log("Periodic background sync can be used.");
      } catch (error) {
        // Periodic background sync cannot be used.
        console.log("Periodic background sync cannot be used.");
      }
    }
  } else {
    // Periodic background sync cannot be used.
    console.log("Periodic background sync cannot be used.");
  }
}

/**
* Function to register service worker in production mode
*/
function registerServiceWorker() {
  if (process.env.NODE_ENV === "production") {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log("App is being served from cache by a service worker.");
      },
      registered(registration) {
        console.log("Service worker has been registered.");

        periodicSync();

        // Routinely check for app updates by testing for a new service worker.
        setInterval(() => {
          registration.update();
        }, 1000 * 60 * 60); // hourly checks
      },
      cached() {
        console.log("Content has been cached for offline use.");
      },
      updatefound() {
        // We are deleting all service worker cache on every build.
        // This is done because apart from ususal build, our JSON files may also change.
        // If our JSON files stablise, we may not have to clear run time table
        // caches.delete("epf-install-time-alert");
        caches.delete("epf-run-time-alert");
        console.log("Old content cleared. New content is downloading.");
      },
      updated() {
        console.log("New content is available; please refresh.");
        // Add a custom event and dispatch it.
        // Used to display of a 'refresh' banner following a service worker update.
        document.dispatchEvent(
          new CustomEvent("swUpdated"),
        );
      },
      offline() {
        console.log("No internet connection found. App is running in offline mode.");
      },
      error(error) {
        console.error("Error during service worker registration:", error);
      },
    });
  }
}

export default registerServiceWorker;
