export default {
  event: {
    eventId: "",
    eventStatus: "This is event status",
    eventMessage: "This is event message",
    timeStamp: "23th Nov 2020",
  },
  eventArr: [{
    eventId: "123", eventStatus: "This is status", eventMessage: "This is a message", timeStamp: "23th Nov 2020",
  }],
  user_karma: 0,
  user_uid: undefined,
  user_token: undefined,
  subscriptionList: [],
  searchResultList: [],
  visibleResultList: [],
  unsubscribeSearch: "",
  isAuth: false,
  displayName: "User",
  photoURL: null,
};
