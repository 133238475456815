
import { defineComponent, PropType } from "@/plugins/vue";
import { DocumentTextIcon } from "@/plugins/outlineHeroicon";
import EventBus from "@/helpers/eventBus";

export default defineComponent({
  name: "NoInformation",
  components: {
    DocumentTextIcon,
  },
  props: {
    // Prop to set title
    title: {
      type: String as PropType <string>,
      default: "No data available",
    },
    // Prop to set message
    message: {
      type: String as PropType <string>,
      default: "No data available at the moment, Please try again later",
    },
  },
  /**
   * Added an emit to show footer
   */
  created() {
    EventBus.$emit("dataLoaded", true);
  },
});
