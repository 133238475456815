
import { defineComponent } from "@/plugins/vue";
import EventBus from "@/helpers/eventBus";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";
export default defineComponent({
  name: "NoInternet",
  computed: {
    /**
     * Gets lang code from base store module
     *
     * @returns {string} - current lang code
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
  },
  /**
   * Mounted life cycle hook
   * Fires event for displaying footer
   */
  mounted() {
    EventBus.$emit("dataLoaded", true);
  },
});
