import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { class: "flex items-center mx-3 px-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ListboxButton = _resolveComponent("ListboxButton")!
  const _component_ListboxOption = _resolveComponent("ListboxOption")!
  const _component_ListboxOptions = _resolveComponent("ListboxOptions")!
  const _component_Listbox = _resolveComponent("Listbox")!

  return (_openBlock(), _createBlock(_component_Listbox, {
    modelValue: _ctx.selectedMode,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedMode) = $event))
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_ListboxButton, { class: "" }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.themeDisplayIcon), {
              class: "text-black dark:text-dark-text-1 dark:rounded dark:p-1 w-7 h-7",
              "aria-hidden": "true"
            }))
          ]),
          _: 1
        }),
        _createVNode(_Transition, {
          "leave-active-class": "transition duration-100 ease-in",
          "leave-from-class": "opacity-100",
          "leave-to-class": "opacity-0"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ListboxOptions, { class: "absolute z-50 xs:top-3/4 top-20 lg:right-16 xs:right-8 bg-white rounded-lg ring-1 ring-slate-900/10 shadow-lg overflow-hidden w-36 py-2 text-sm text-slate-700 dark:bg-dark-table-background dark:ring-0 dark:highlight-white/5 dark:text-dark-text-1 mt-4 dark:bg-dark-primary-1" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.themes, (theme) => {
                  return (_openBlock(), _createBlock(_component_ListboxOption, {
                    key: theme.name,
                    value: theme,
                    as: "template"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("li", {
                        class: _normalizeClass([
                _ctx.selectedThemeModeName === theme.name ? 'text-primary-3' :
                'text-grey-7', 'font-medium text-base dark:hover:text-white px-3 py-1 my-1 flex items-center cursor-pointer dark:hover:bg-dark-primary-3'
              ])
                      }, [
                        (_openBlock(), _createBlock(_resolveDynamicComponent(theme.icon), {
                          class: "w-5 mx-2",
                          "aria-hidden": "true"
                        })),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t(`${theme.name}`)), 1)
                      ], 2)
                    ]),
                    _: 2
                  }, 1032, ["value"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}