
import { defineComponent } from "@/plugins/vue";
import EventBus from "@/helpers/eventBus";

type LangCode = "en" | "hi";

export default defineComponent({
  name: "ErrorPage",
  computed: {
    /**
     * Gets lang code from the base store
     *
     * @returns {string} lang code
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
  },
  /**
   * Added an emit to show footer and set meta properties to noindex the error page
   */
  mounted() {
    EventBus.$emit("dataLoaded", true);
    this.$meta.meta = [{ name: "robots", content: "noindex, nofollow" }];
    this.$router.replace({ name: `notFound_${this.langCode}`, params: { langCode: this.langCode } });
  },
  /**
   * remove noindex meta on unmounted hook of the Component
   */
  unmounted() {
    this.$meta.meta = [];
  },
});
