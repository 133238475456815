import i18n from "@/plugins/i18n";
import {
  ValidationFieldRef, TagPair, NavigatorUA, advanceAttachFieldReturnValue, filerTypeCustomObject, ArrayOfValidation,
} from "@/helpers/interface";

// @ts-ignore
const { t } = i18n.global;
const rules: ArrayOfValidation = {
  // Text type field rules
  fieldTextRules: [
    (v) => !!v || t("fieldInputIsRequired"),
    (v) => !/^[ ]+$/g.test(v) || t("fieldInputCanNotBeABlankString"),
    (v) => !/[']/g.test(v) || t("fieldInputCanNotContainAnApostrophe"),
  ],
  /**
   * Rules for email used in firebase
   */
  emailRules: [
    (v) => !!v || t("fieldInputIsRequired"),
    (v) => !/^[ ]+$/g.test(v) || "Field input can not be a blank string",
    (v) => !v || /^\w+([.-]?\w+)@\w+([.-]?\w+)(\.\w{2,3})+$/.test(v) || "E-mail must be valid",
  ],

  /**
   * Rules for name used in firebase
   */
  nameRules: [
    (v) => !!v || "Name is required",
    (v) => (v && v.length >= 5) || "Name must be greater than 5 characters",
    (v) => v.trim() !== "" || "Name can't be a blank string",
  ],
  // Mandatory field rule
  mandatoryFieldRule: [
    // Type, Date, Location fields, Event name
    (v) => !!v || t("fieldInputIsRequired"),
  ],

  // Number type field rules
  numberValueRules: [
    (v) => {
      if (!v) {
        return t("fieldInputIsRequired");
      }
      // Allow digits as well as decimal numbers in field
      if (!/^\d+(\.\d+)?$/g.test(v)) {
        return t("onlyDigitsAndDecimalNumbersAllowed");
      }
      return true;
    },
  ],

  // Range type field rules
  rangeValueRules: [
    (v) => {
      if (!v) {
        return t("fieldInputIsRequired");
      }
      // Restricts decimal numbers and allows only digits
      if (!/^\d+$/.test(v)) {
        return t("onlyDigitsAllowed");
      }
      return true;
    },
  ],

  // Event name rules
  eventNameRules: [
    (v) => !/^[ ]+$/g.test(v) || t("fieldInputCanNotBeABlankString"),
  ],

  // Apostrophe rule
  apostropheRule: [
    (v) => !/[']/g.test(v) || t("fieldInputCanNotContainAnApostrophe"),
  ],

  // For exams: No special characters rules
  noSpecialCharRules: [
    (v) => !/^[ ]+$/g.test(v) || t("fieldInputCanNotBeABlankString"),
    (v) => /^[a-z0-9 ]+$/gi.test(v) || t("onlyAlphabetsDigitsAndSpacesAllowed"),
  ],

  // Allow special characters
  specialCharRules: [
    (v) => !/^[ ]+$/g.test(v) || t("fieldInputCanNotBeABlankString"),
    (v) => /^[a-z0-9 ().,-/]+$/gi.test(v) || t("allowedSpecialCharacters"),
  ],
};

// Validation methods
/**
 * Function to check if the name and value of a tag are same
 *
 * @param {string} name - The field's name
 * @param {string} value - The field's value
 * @returns {boolean} true: if name and value are same || false: if name and value are different
 */
function isNameAndValueSame(name: string, value: string): boolean {
  const modifiedName = name ? name.trim() : "";
  const modifiedValue = value ? value.trim() : "";
  return modifiedName.toLowerCase() === modifiedValue.toLowerCase();
}

/**
 * Function to check if there exists a copy of the value in the valuesArray
 *
 * @param {Array} valuesArray- Array of values to be checked
 * @returns {boolean} true: if value found in valuesArray ||
 * false: if value is not present in valuesArray
 */
function isValuePresent(value: string, valuesArray: string[]): boolean {
  return valuesArray.includes(value);
}

/**
 * Function to check if there exists a blank value in the valuesArray
 *
 * @param {Array} valuesArray- Array of values to be checked
 * @returns {boolean} true: if blank value is found || false: if blank values are not found
 */
function isBlankValuePresent(valuesArray: string[]): boolean {
  return valuesArray.some((value) => value.length === 0);
}

/**
 * Function to check if duplicate field name-field value pairs exist and return them
 *
 * @param {object[]} tags - The array containing the nameId and valueId of the chosen fields
 * @returns {object} duplicates- {tags, result}- The duplicate tags and their Validation result
 */
function duplicateTagPairFound(tags: TagPair[]): { tags: TagPair[]; result: boolean; } {
  const tagPairs = tags.map((item) => `${item.nameId}-${item.valueId}`);
  const duplicatePairs = tagPairs.reduce((acc: TagPair[], el: string, i, arr) => {
    if (arr.indexOf(el) !== i) acc.push(tags[i]);
    return acc;
  }, [] as TagPair[]);
  if (duplicatePairs.length) return { tags: duplicatePairs, result: true };
  return { tags: [], result: false };
}

/**
 * Function to check if the entered range value is valid or not
 *
 * @param {number} minValue - The minimum value input for range
 * @param {number} maxValue - The maximum value input for range
 * @returns {boolean} true: If range is valid || false: If range is invalid
 */
function isValidRange(minValue: number, maxValue: number): boolean {
  return !(Number(minValue) > Number(maxValue));
}

/**
 * Function to check the presence of a character in the given string
 *
 * @param {string} character - The character to check in the given string
 * @param {string} testString - The string which is needed to be checked
 * @returns {boolean} true: If character is present || false: If character is not present
 */
function isCharacterPresent(character: string, testString: string): boolean {
  return testString.includes(character);
}

/**
 * Function to check if the given string is empty or not
 *
 * @param {string} testString - The string which is needed to be checked
 * @returns {boolean} true: If string is empty || false: If character is not empty
 */
function isEmptyString(testString: string): boolean {
  return (testString === "");
}

/**
 * Function to check if the array is empty or not
 *
 * @param {array} testArray - The array whose length is needed to be checked
 * @returns {boolean} true: If array is empty || false: If array is not empty
 */
function isEmptyArray<T>(testArray: T[]): boolean {
  return !(testArray.length);
}

/**
 * Function to check if an object is empty or not
 *
 * @param {object} testObject - The objetc which needs to be checked
 * @returns {boolean} true if object is empty || false if object is not empty
 */
function isEmptyObject(testObject: object): boolean {
  return (Object.keys(testObject).length === 0);
}
/**
 * Function to check if both mandatory fields are present: job type and Stream
 *
 * @param {array} eventTypeIds - The mandatory field's nameIds
 * @param {string[]} attachedFields - The fields attached to the event or core tag
 * @returns {boolean} true: If both mandatory fields are present ||
 * false: If only one mandatory field is present
 */
function isBothMandatoryFieldsPresent(eventTypeIds: string[], attachedFields: string[]): boolean {
  return (
    attachedFields.includes(eventTypeIds[0])
    && attachedFields.includes(eventTypeIds[1])
  );
}

/**
 * Function to check if both mandatory fields are absent: job type and Stream
 *
 * @param {array} eventTypeIds - The mandatory field's nameIds
 * @param {string[]} attachedFields - The fields attached to the event or core tag
 * @returns {boolean} true: If both mandatory fields are absent ||
 * false: If only one mandatory field is absent
 */
function isBothMandatoryFieldsAbsent(eventTypeIds: string[], attachedFields: string[]): boolean {
  return !(
    attachedFields.includes(eventTypeIds[0])
    || attachedFields.includes(eventTypeIds[1])
  );
}

/**
 * Function to check the presence of null valueID in the tagsArray
 *
 * @param {object[]} tagsArray - The fields attached to the event or core tag
 * @returns {boolean} true: If null valueId is found || false: If null valueId is not found
 */
function isNullValueIdPresent(tagsArray: TagPair[]): { index: number; result: boolean; } {
  const index = tagsArray.findIndex((tag) => !tag.valueId === true);
  return { index, result: (index !== -1) };
}

/**
 * Function to check the size of asset and check if it is within the size limit
 *
 * @param {file} asset - the asset whose size needs to be checked (logo)
 * @param {number} maxSize - the maximum recommended size for asset
 * @returns {boolean} true: If asset exceeds size limit || false: If asset is within size limit
 */

function isMaxSizeExceeded(asset: File, maxSize: number): boolean {
  return (asset.size >= maxSize);
}

/**
 * Function to check the presence of identical parent and childs in a core tag
 * @param {array} parentIds - List of parentIds for a core tag
 * @param {array} childIds - List of childIds for a core tag
 * @returns {boolean} true: If identicals are present || false: If identicals are not present
 */
function isIdenticalParentChildPresent(parentIds: string[], childIds: string[]): boolean {
  return (parentIds.some((item) => childIds.includes(item)));
}

/**
 * Function to test if the object is not null and undefined
 *
 * @param {object} testObject - The object to be tested for undefined and null
 * @returns {boolean} true: if testObject is not undefined and not null false: otherwise
 */
function isNotUndefinedAndNull(testObject: Object): boolean {
  return (testObject !== undefined && testObject !== null);
}

/**
 * Function to replace all the whitespace characters with hyphen
 *
 * @param {string} eventId - The ID of event
 * @param {string} eventTitle - The title of event
 * @returns {string} encodeURIComponent(url) - The new url with all whitespace characters replaced
 */
function getEncodedURL(eventId: string, eventTitle: string): string {
  let parsedEventName = eventTitle.replace(/[^a-zA-Z0-9]/g, " ");
  parsedEventName = parsedEventName.replace(/\s+/g, "-").trim();
  const url = `${eventId}-${parsedEventName.replace(/\s/g, "-")}`;
  return encodeURIComponent(url);
}

/**
 * Split camelcase string and add hyphen rather than space
 *
 * @param {string} str - camelcase string
 * @returns {string} str- hyphen added string
 */
function camelCaseToDash(str: string): string {
  return str.replace(/([a-zA-Z])(?=[A-Z])/g, "$1-").toLowerCase();
}
/**
 * Converts string to Sentence Case
 *
 * @param {string} str - String
 * @returns {string} str - string converted to Sentence Case
 */
function changeToSentenceCase(str: string): string {
  if (!str) return "";
  const result = str.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
}
/* Converts UTC timestamp to date
*
* @param {number} timestamp - The UTC timestamp for date value
* @returns {string} - the locale date string from utc timestamp
*/
function timestampToDate(timestamp: number): string {
  return new Date(timestamp * 1000).toLocaleDateString();
}

/**
* Function to validate textfield, autocomplete and multicomplete components
* @param {Object} refs : The whole object of refs of any page
* @returns {Boolean} true if all fields validate without error. otherwise false.
*/

function validateRefs(refs: Record<string, ValidationFieldRef>): boolean {
  const keys = Object.keys(refs);
  for (let i = 0; i < keys.length; i += 1) {
    if (Object.prototype.hasOwnProperty.call(refs[keys[i]], "errorFound")) {
      refs[keys[i]].validateInput();
      if (refs[keys[i]].errorFound === true) {
        return false;
      }
    }
  }
  return true;
}

/**
 * Function to valid string exist in array
 *
 * @param {string[]} validArr - array of string
 * @param {string} strCheck check string exist in array
 * @param {string} message - error message to show on error occured
 * @returns {boolean} true if string exist otherwise false
 */
function validArray(validArr: string[], strCheck: string, message: string): boolean {
  if (!validArr.includes(strCheck)) {
    return false;
  }
  return true;
}

/**
 * Function to validate the workerAdapterConnector params
 *
 * @param {string} functionName - The name of function who called workerValidations
 * @param {string} type - mode of page like job / admission
 * @param {string} coreType - type of page like exams / organisations
 * @param {object[]} filter - the array of object which includes id and type (category, exam, organisation)
 * @param {string[]} subFilter - the array of sub filters like latestNotifications, latestAdmitCard
 * @returns {boolean} true if all are validated otherwise false
 */
function workerValidations(functionName: string, type: string, coreType: string, filter: filerTypeCustomObject[], subFilter: string[]): boolean {
  const validTypes = ["job", "admission"];
  const validCoreTypes = ["exams", "organisations"];
  const validFilters = ["category", "exam", "organisation", "university", "stream", "qualification", "state"];
  const validSubFilters = ["latestNotifications", "latestAdmitCard", "latestFellowships", "latestWalkInInterview", "latestResults", "lastestScholarships",
    "lastDateSoon"];
  let isValidated = true;

  if (isNotUndefinedAndNull(type)) {
    const errorMessage = `Invalid type '${type}' was passed to ${functionName}, must be one of these ${validTypes}`;
    if (!validArray(validTypes, type, errorMessage)) isValidated = false;
  }

  if (isNotUndefinedAndNull(coreType)) {
    const errorMessage = `Invalid coreType '${coreType}' was passed to ${functionName}, must be one of these ${validCoreTypes}`;
    if (!validArray(validCoreTypes, coreType, errorMessage)) isValidated = false;
  }

  if (isNotUndefinedAndNull(filter) && filter.length !== 0) {
    filter.forEach((filterType) => {
      const errorMessage = `Invalid filter '${filterType.type}' was passed to ${functionName}, must be one of these ${validFilters}`;
      if (!validArray(validFilters, filterType.type, errorMessage)) isValidated = false;
    });
  }

  if (isNotUndefinedAndNull(subFilter) && subFilter.length !== 0) {
    subFilter.forEach((filterType) => {
      const errorMessage = `Invalid subfilter ${filterType} was passed to ${functionName}, must be one of these ${validSubFilters}`;
      if (!validArray(validSubFilters, filterType, errorMessage)) isValidated = false;
    });
  }

  return isValidated;
}

/**
 * Function to validate the attach field components on the basis of duplicate tags and null value tags
 *
 * @param {object[]} tagsArray - array of attach fields object
 * @returns {{ nullValue: any, index: number, validated: boolean,duplicate: any }} the object which contains validated and string (the name of duplicate tag or null tag)
 */

function advancedAttachFieldValidation(tagsArray: TagPair[]): advanceAttachFieldReturnValue {
  if (!isEmptyArray(tagsArray)) {
    const duplicates = duplicateTagPairFound(tagsArray);
    const duplicateTags = duplicates.tags;
    if (duplicates.result) {
      const fieldPair = `${duplicateTags[0].name} - ${duplicateTags[0].value} `;
      return { duplicate: fieldPair, validated: false };
    }
    const nullValueFound = isNullValueIdPresent(tagsArray);
    if (nullValueFound.result) {
      const nullValueIndex = nullValueFound.index;
      const fieldName = tagsArray[nullValueIndex].name;
      return { nullValue: fieldName, index: nullValueIndex, validated: false };
    }
  }
  return { validated: true };
}
/**
 * Function to test whether a string is all in lower case
 *
 * @param {string} str - the string to test
 * @returns {boolean} true if all characters are in lower case otherwise false
 */
function isLowerCase(str: string): boolean {
  const regex = /^[^A-Z]*$/g;
  return regex.test(str);
}
/**
 * Function tests whether the browser being used is chrome
 */

// To access chrome and opr in TypeScript
declare const window: Window &
  typeof globalThis & {
    chrome: any;
    opr: any;
  };
function isChrome() {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== "undefined";
  const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
  const isIOSChrome = winNav.userAgent.match("CriOS");

  if (isIOSChrome) return true;

  if (
    isChromium !== null
    && typeof isChromium !== "undefined"
    && vendorName === "Google Inc."
    && isOpera === false
    && isIEedge === false
  ) return true;

  return false;
}

/**
 * Returns Whether the website is being viewed by a mobile device or not
 * refer https://javascript.plainenglish.io/how-to-detect-a-mobile-device-with-javascript-1c26e0002b31#:~:text=Use%20Agent%20Detection&text=To%20get%20the%20user%20agent,userAgent%20property.&text=We%20check%20for%20all%20the,web%20app%20with%20the%20regex.
 * @returns {boolean} whether the current user agent is of a mobile device or not
 */
function isMobileDevice(): boolean {
  return (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)
    || (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test((navigator as NavigatorUA).userAgentData?.platform)));
}

// Refer https://developers.facebook.com/docs/sharing/best-practices for Facebook App User Agent info
function isFacebookBrowser(): boolean {
  return /FB_IAB\/FB4A|FBAN\/FBIOS|FBAV/i.test(navigator.userAgent);
}

export {
  rules,
  isLowerCase,
  isNameAndValueSame,
  isValuePresent,
  isBlankValuePresent,
  duplicateTagPairFound,
  isValidRange,
  isCharacterPresent,
  isEmptyString,
  isEmptyArray,
  isEmptyObject,
  isBothMandatoryFieldsAbsent,
  isBothMandatoryFieldsPresent,
  isNullValueIdPresent,
  isMaxSizeExceeded,
  isIdenticalParentChildPresent,
  isNotUndefinedAndNull,
  getEncodedURL,
  camelCaseToDash,
  changeToSentenceCase,
  timestampToDate,
  validateRefs,
  workerValidations,
  advancedAttachFieldValidation,
  isChrome,
  isMobileDevice,
  isFacebookBrowser,
};
