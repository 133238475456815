
import { ChevronLeftIcon, ChevronRightIcon } from "@/plugins/solidHeroicon";
import EventBus from "@/helpers/eventBus";
import { defineComponent, PropType } from "@/plugins/vue";

// type for prop items items
type Item = object;

export default defineComponent({
  name: "SimpleDataTable",
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  props: {
    /**
     * Headers for the table
     */
    headers: {
      type: Array as PropType<{ text: string; value: keyof Item | string; sortable?: boolean; }[]>,
      default: () => [],
    },

    /**
     * Items to be shown in the table
     */
    items: {
      type: Array as PropType<Item[]>,
      default: () => [],
    },
    /**
     * Total number of current items
     */
    currentItemsLength: {
      type: Number as PropType<number>,
      default: 0,
    },
    /**
     * Total number of items before update in items
     */
    previousItemsLength: {
      type: Number as PropType<number>,
      default: 0,
    },
    /**
     * To check data is called from LatestUpdatesList page
     */
    isStaticData: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    /**
     * To Check if Route Pagination is allowed or not
     */
    enablePaginationRoute: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  emits: ["pageChange"],
  data() {
    return {
      pageNo: 1,
      itemsSelectOptions: [25, 100],
      noOfItemsSelected: 25,
      isRightArrowShow: false,
      oldNoOfIitems: 0,
      newNoOfIitems: 0,
      isTotalItems: false,
      initialized: false,
      previousStartNo: 0,
    };
  },
  computed: {

    /**
     * True or false based on initial page check to
     * enables or disables the left arrow click of the table
     * @returns {boolean} val - false if first page or else true
     */
    isLeftArrowShow(): boolean {
      return this.pageNo <= 1;
    },
    /**
     * Returns the serial no. of the first element
     * on the current page of table
     * @returns {number} val - serial number of the first element
     */
    startNo(): number {
      return this.itemsPerPage * (this.pageNo - 1);
    },

    /**
     * Returns an array of items that has to appear on the
     * current page of table
     * @returns {array} val - array of items which are to be displayed
     */
    currentPageItems(): Item[] {
      return this.items.slice(this.startNo, this.startNo + this.itemsPerPage);
    },

    /**
     * Returns the number of items to be displayed on
     * the in table
     * @returns {number} val - number of items to be displayed
     */
    itemsPerPage(): number {
      return Number(this.noOfItemsSelected);
    },

  },
  watch: {

    /**
     * To confirm we do not have total number of items
     */
    $route(newRoute, oldRoute) {
      const newRouteName = newRoute.name.split("_")[0];
      const oldRouteName = oldRoute.name?.split("_")[0];
      if (newRouteName !== oldRouteName) {
        this.isTotalItems = false;
      } else {
        this.checkPaginationEnabled();
        EventBus.$emit("dataLoaded", true);
      }
    },
    /**
     * To checked data is from LatestUpdatesList,
     * Right arrow disabled according to condition
     */
    isStaticData() {
      if (this.isStaticData && this.pageNo === Math.ceil(this.items.length / this.itemsPerPage)) {
        this.isRightArrowShow = true;
      }
    },
    /**
     * To confirm we are at last page
     */
    oldNoOfIitems() {
      if (this.oldNoOfIitems === this.newNoOfIitems) {
        this.isRightArrowShow = true;
        this.isTotalItems = true;
      }
    },
    /**
     * To watch the Page no. to see if there is anymore data
     * if not then disabling the right click button
     * or else keeping the button enabled to visit the next page
     */
    pageNo() {
      this.checkPaginationEnabled();
      this.goToPage(this.pageNo);
      this.rightArrowCheck();
    },
    /**
     * Reevaluating the oldNoOfIitems and newNoOfIitems when currentItemsLength changes
     */
    currentItemsLength() {
      this.oldNoOfIitems = this.previousItemsLength;
      this.newNoOfIitems = this.currentItemsLength;
    },
    /**
     * Reevaluating the oldNoOfIitems and newNoOfIitems when previousItemsLength changes
     */
    previousItemsLength() {
      this.oldNoOfIitems = this.previousItemsLength;
      this.newNoOfIitems = this.currentItemsLength;
    },
    /**
     * To check if the number of items per page has changed
     * and according to this enabling or disabling rhw right click arrow button
     * to disbale the next page
     */
    itemsPerPage() {
      this.checkPaginationEnabled();
      if (!this.initialized) {
        this.pageNo = Math.ceil((this.startNo + 1) / this.itemsPerPage);
        this.initialized = true;
      } else {
        this.pageNo = Math.ceil((this.previousStartNo + 1) / this.itemsPerPage);
      }
      this.rightArrowCheck();
    },
    items() {
      this.goToPage(1);
    },
    /**
     * Watching the startNo and storing its previous state value
     */
    startNo(newStartNo, oldStartNo) {
      this.previousStartNo = oldStartNo;
    },
    /**
     * Watching the currentPageItems and going back one page
     * if the displayed array (currentPageItems) is empty then prevent to set page negative value
     */
    currentPageItems() {
      if (!this.currentPageItems.length && this.pageNo !== 1) {
        this.pageNo -= 1;
      }
    },
  },
  /**
   * Initially setting the oldNoOfIitems and newNoOfIitems
   * Check amount of data to enable and disable the right arrow click
   * Set the itemsSelectOptions and noOfItemsSelected if paginationCount is passed as props
   */
  created() {
    if (Object.keys(this.$route.query).length && this.enablePaginationRoute) {
      this.noOfItemsSelected = Number(this.$route.query?.itemsPerPage) > this.itemsSelectOptions[0] ? this.itemsSelectOptions[1] : this.itemsSelectOptions[0];
      this.pageNo = Number(this.$route.query?.pageNo) > 1 ? Number(this.$route.query.pageNo) : 1;
    }
    this.checkPaginationEnabled();
    if (this.currentItemsLength) {
      this.oldNoOfIitems = this.previousItemsLength;
      this.newNoOfIitems = this.currentItemsLength;
    }

    if (this.isStaticData && this.pageNo === Math.ceil(this.items.length / this.itemsPerPage)) {
      this.isRightArrowShow = true;
    }
  },
  methods: {

    /**
     * Changes the current page to the page passed
     * as parameter
     * @param {number}  page to be set as current page in table
     * If user switch to one tab to another tab then page number 1 will be set as current page
     */
    goToPage(page: number): void {
      if (this.pageExists(page)) {
        this.pageNo = page;
      }
    },

    /**
     * Tells whether the the page passed as parameter
     * does exist in table
     * @param {number}  page to be checked for validity
     */
    pageExists(page: number): boolean {
      if (page === 1) return true;
      const maxPages = Math.ceil(this.items.length / this.itemsPerPage);
      return page <= maxPages && page > 0;
    },
    /**
     * Function to set the table parameters (pageNo & itemsPerPage) in the route
     */
    paginationRoute(): void {
      const url = new URL(document.location.href);
      const params = new URLSearchParams(url.search);
      params.set("itemsPerPage", String(this.itemsPerPage));
      params.set("pageNo", String(this.pageNo));
      this.$emit("pageChange", { startNo: this.startNo, itemCount: this.currentPageItems.length });
      this.$router.replace(`${url.pathname}?${params}`);
    },
    /**
     * Check right arrow status to see if it is enabled or disabled
     */
    rightArrowCheck(): void {
      this.isRightArrowShow = (this.isStaticData || this.isTotalItems) && this.pageNo === Math.ceil(this.items.length / this.itemsPerPage);
    },
    /**
     * Function to check if pagination is enabled or not
     * and run code accordingly
     */
    checkPaginationEnabled(): void {
      if (this.enablePaginationRoute) {
        this.paginationRoute();
      } else {
        this.$emit("pageChange", { startNo: this.startNo, itemCount: this.currentPageItems.length });
      }
    },

    /**
     * Function to check which propeties should hidden on small SET_SUBSCRIBED_EVENTS
     *
     * @param {string} value - header value of the Data
     * @returns {boolean} true if value matches otherwise false
     */
    hiddenOnSmallScreen(value: string): boolean {
      return ["type", "aliases", "createdBy", "lastUpdated", "sno", "logo", "roman", "state", "pinCode", "country"].includes(value);
    },

    /**
     * Function to return vale inside object
     *
     * @param {Item} obj - object in which we want to access value
     * @param {string} key - key of object
     * @returns {string} value of object
     */
    rowValue(obj: Item, key: string): string {
      return obj[key as keyof Item] as string;
    },
  },
});
