import type { CourseSchemaData, DataSetSchema, MainSeoSchema } from "@/helpers/interface";
import {
  organizationLanding, newsArticle, breadCrumbList, organization, jobPosting, course,
} from "./schemaHelpers";

const schemasName = ["NewsArticle", "BreadCrumbList"];
/**
  * Schemas for all pages
  */
const schemaData = {
  jobsHome: ["OrganizationLanding", "NewsArticle"],
  admissionsHome: ["OrganizationLanding", "NewsArticle"],
  jobsOrganisationList: schemasName,
  admissionsExamList: schemasName,
  jobsExamList: schemasName,
  admissionsUniversityList: schemasName,
  jobsLatestUpdates: schemasName,
  admissionsLatestUpdates: schemasName,
  jobsSpecificCategoryPage: schemasName,
  jobsCategoryLatestUpdates: schemasName,
  admissionsSpecificExamPage: schemasName,
  admissionsExamLatestUpdates: schemasName,
  jobsSpecificExamPage: schemasName,
  jobsExamLatestUpdates: schemasName,
  jobsSpecificOrganisationPage: [...schemasName, "Organization"],
  jobsOrganisationLatestUpdates: [...schemasName, "Organization"],
  jobsSpecificQualificationPage: schemasName,
  jobsQualificationLatestUpdates: schemasName,
  admissionsSpecificStreamPage: schemasName,
  admissionsStreamLatestUpdates: schemasName,
  admissionsSpecificUniversityPage: [...schemasName, "Organization"],
  admissionsUniversityLatestUpdates: [...schemasName, "Organization"],
  admissionsSpecificQualificationPage: schemasName,
  admissionsQualificationLatestUpdates: schemasName,
  searchHome: ["BreadCrumbList"],
  jobsSpecificStatePage: schemasName,
  jobsStateLatestUpdates: schemasName,
  jobsStateList: schemasName,
  admissionsSpecificStatePage: schemasName,
  admissionsStateLatestUpdates: schemasName,
  admissionsStateList: schemasName,
};

/**
 * Function to generate Seo schema for all user pages
 *
 * @param {string} routeName - route name of current page
 * @param {DataSetSchema} dataObj - the all data which needs to be set in schemas
 * @returns {MainSeoSchema} mainSchema - a combined schema object for all types of schemas
 */
function generateSeoSchema(routeName: string, dataObj: DataSetSchema): MainSeoSchema {
  const mainSchema: MainSeoSchema = { "@context": "https://schema.org" };
  const allSchemas = schemaData[routeName as keyof typeof schemaData];
  const arrayOfSchemas = [];
  if (allSchemas) {
    for (let i = 0; i < allSchemas.length; i += 1) {
      if (allSchemas[i] === "OrganizationLanding") {
        arrayOfSchemas.push(organizationLanding());
      } else if (allSchemas[i] === "NewsArticle") {
        arrayOfSchemas.push(newsArticle(dataObj));
      } else if (allSchemas[i] === "BreadCrumbList") {
        if (dataObj.breadCrumbList) { arrayOfSchemas.push(breadCrumbList(dataObj)); }
      } else if (allSchemas[i] === "Organization") {
        if (dataObj.organization) { arrayOfSchemas.push(organization(dataObj)); }
      }
    }
  }
  if (["eventViewDetails", "eventView"].includes(routeName)) {
    arrayOfSchemas.push(breadCrumbList(dataObj));
    const eventType = dataObj?.eventSchema?.type;
    if (eventType === "job") arrayOfSchemas.push(jobPosting(dataObj.eventSchema));
    else if (eventType === "admission") arrayOfSchemas.push(course(dataObj.eventSchema as CourseSchemaData));
  }
  mainSchema["@graph"] = arrayOfSchemas;

  return mainSchema;
}

export default generateSeoSchema;
