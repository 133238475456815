
import {
  DownloadIcon, ArrowLeftIcon, PlusIcon, MinusIcon,
} from "@/plugins/solidHeroicon";
import { defineComponent } from "@/plugins/vue";

const SCALE_AMOUNT = 0.1;

export default defineComponent({

  name: "ImageViewer",
  components: {
    ArrowLeftIcon,
    DownloadIcon,
    PlusIcon,
    MinusIcon,
  },
  props: {
    /**
     * The url/source of image
     */
    src: {
      type: String,
      default: "",
    },

    /**
     * The title for the image
     */
    title: {
      type: String,
      default: "",
    },
  },
  emits: ["close", "download"],
  data() {
    return {
      scale: 1,
    };
  },
  methods: {
    /**
     * Scales up the image(zoom-in image) till a certain limit
     */
    scaleUp() {
      if (this.scale <= 2) {
        this.scale += SCALE_AMOUNT;
        const rect: DOMRect = (this.$refs.img as HTMLImageElement).getBoundingClientRect();
        const { height } = rect;
        (this.$refs.img as HTMLImageElement).style.transform = `scale(${this.scale}, ${this.scale})`;
        const newHeight = height * this.scale;
        const heightDiff = (newHeight - height) / 4;
        (this.$refs.img as HTMLImageElement).style.transform += `translateY(${heightDiff}px)`;
      }
    },
    /**
     * Scales down the image(zoom-out image) till a certain limit
     */
    scaleDown() {
      if (this.scale > 0.2) {
        this.scale -= SCALE_AMOUNT;
        (this.$refs.img as HTMLImageElement).style.transform = `scale(${this.scale}, ${this.scale})`;
        const rect: DOMRect = (this.$refs.img as HTMLImageElement).getBoundingClientRect();
        const { height } = rect;
        const newHeight = height * this.scale;
        const heightDiff = (newHeight - height) / 4;
        (this.$refs.img as HTMLImageElement).style.transform += `translateY(${heightDiff}px)`;
      }
    },
  },
});
