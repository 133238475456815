// Import interface
import type { AlertsAPI, API } from "./interface";

// Import store
const store = require("../store/index.js");

// Base URL
const baseURL: string = "https://api.exampathfinder.com/n/";

const alertsAPI: AlertsAPI = {
  // Tags
  ADD_FIELD_NAME: {
    url: `${baseURL}admin/tag/add-fieldname`,
    headers: true,
    karma: 50,
    credentials: false,
    id: "ALERTS#1",
  },

  ADD_FIELD_VALUE: {
    url: `${baseURL}admin/tag/add-fieldvalue2`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#2",
  },

  UPDATE_TAG_TEXT: {
    url: `${baseURL}admin/tag/update-field`,
    headers: true,
    karma: 50,
    credentials: false,
    id: "ALERTS#3",
  },

  UPDATE_TAG_TRANSLATION: {
    url: `${baseURL}admin/tag/translation`,
    headers: true,
    karma: 50,
    credentials: false,
    id: "ALERTS#4",
  },

  READ_TAG_ID_VALUES: {
    url: `${baseURL}admin/tag/read/values-list`,
    headers: true,
    karma: 0,
    credentials: false,
    id: "ALERTS#5",
  },

  READ_TAG_ID_DATA: {
    url: `${baseURL}admin/tag/read/single`,
    headers: true,
    karma: 0,
    credentials: false,
    id: "ALERTS#6",
  },

  CONNECT_TAGS: {
    url: `${baseURL}admin/tag/connect`,
    headers: true,
    karma: 50,
    credentials: false,
    id: "ALERTS#7",
  },

  READ_CONNECTED_TAGS: {
    url: `${baseURL}admin/tag/read/connect`,
    headers: true,
    karma: 0,
    credentials: false,
    id: "ALERTS#8",
  },

  // Location
  GET_LOCATION_COORDINATES: {
    url: `${baseURL}admin/location/coordinates`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#9",
  },

  GET_LOCATION_SUGGESTIONS: {
    url: `${baseURL}admin/location/suggestions`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#10",
  },

  // Events
  ADD_EVENT: {
    url: `${baseURL}admin/event/add`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#11",
  },

  UPDATE_EVENT: {
    url: `${baseURL}admin/event/update`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#12",
  },

  READ_EVENT: {
    url: `${baseURL}user/event-read`,
    headers: false,
    karma: 0,
    credentials: false,
    id: "ALERTS#13",
  },

  // Get access for Uploading Files
  GET_ACCESS_FOR_UPLOAD: {
    url: `${baseURL}admin/getAccess`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#14",
  },

  // Notifications
  APPEND_NOTIFICATION: {
    url: `${baseURL}admin/notification/append`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#15",
  },

  EDIT_NOTIFICATION: {
    url: `${baseURL}admin/notification/minor-edit`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#16",
  },

  // Presets
  UPDATE_PRESETS: {
    url: `${baseURL}admin/preset/add`,
    headers: true,
    karma: 50,
    credentials: false,
    id: "ALERTS#17",
  },

  READ_PRESETS: {
    url: `${baseURL}admin/preset/read`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#18",
  },
  USER_SUBSCRIPTION: {
    url: `${baseURL}user/subscription`,
    headers: true,
    karma: 1,
    credentials: false,
    id: "ALERTS#19",
  },
  GET_LOCATION_DETAILS: {
    url: `${baseURL}admin/location/details`,
    headers: true,
    karma: 25,
    credentials: false,
    id: "ALERTS#20",
  },
};

/**
 * Function to check user karma before calling API and return the API object if the user has the minimum required karma to access the API
 *
 * @param {string} apiKey - The key of the API call whose karma value needs to be checked before access
 * @returns {object} APIObject - Object containing information for the specific API on successfully passing the user karma test
 */
function getAlertsAPI(apiKey: keyof AlertsAPI): API | null {
  const userKarma = store.default.getters["user/getUserKarma"] as number;
  if (userKarma >= alertsAPI[apiKey].karma) {
    return alertsAPI[apiKey];
  }
  return null;
}

export default getAlertsAPI;
