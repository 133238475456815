
import { RefreshIcon } from "@/plugins/outlineHeroicon";
import ActionSnackbar from "@/components/Generic/ActionSnackbar.vue";
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  name: "AppUpdateSnackBar",
  components: {
    ActionSnackbar,
  },
  data: () => ({
    refreshIcon: RefreshIcon,
  }),
  computed: {
    /**
     * Propety to get PWA update status
     */
    isPWAUpdateAvailable(): boolean {
      return this.$store.getters["base/getPWAUpdateStatus"];
    },
  },
  methods: {
    /**
     * Function to refresh application
     */
    refresh(): void {
      window.location.reload();
      this.hide();
    },
    /**
     * Function to hide the PWA snackbar
     */
    hide(): void {
      this.$store.commit("base/SET_PWA_UPDATE_STATUS", false);
    },
  },
});
