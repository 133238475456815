export default {
  /**
   * Mutation to set the display state of sign in box
   *
   * @param {*} state - The state of base store module
   * @param {boolean} value - the state of sign in box
   */
  CHANGE_SIGN_IN_BOX_DISPLAY(state, value) {
    state.showSignInBox = value;
  },

  /**
   * Mutation to set the state of dark mode
   *
   * @param {*} state - The state of base store module
   * @param {boolean} value - the state of dark mode
   */
  SET_DARK_MODE(state, value) {
    state.darkMode = value;
  },

  /**
   * Mutation to set the state of selected theme mode name
   *
   * @param {*} state - The state of base store module
   * @returns {string} selectedThemeModeName - the current theme selected name
   */
  SET_SELECTED_THEME_MODE_NAME(state, value) {
    state.selectedThemeModeName = value || "system";
    window.localStorage.setItem("theme", value);
  },

  /**
   * Mutation to set the current language code
   *
   * @param {*} state - The state of base store module
   * @param {string} langCode - the langCode to set
   */
  SET_LANGUAGE_CODE(state, langCode) {
    state.langCode = langCode;
  },

  /**
   * Mutation to set whether the worker is ready or not for receiving calls
   *
   * @param {*} state - The state of base store module
   * @param {boolean} workerState - Value indicating if the worker is ready or not
   */
  SET_WORKER_STATE(state, workerState) {
    state.isWorkerReady = workerState;
  },

  /**
   * Mutation to set the json promise for worker
   *
   * @param {*} state - The state of base store module
   * @param {Promise} workerPromise - Promise for worker jsons
   */
  SET_WORKER_PROMISE(state, workerPromise) {
    state.jsonPromise = workerPromise;
  },

  /**
   * Mutation to set whether the worker is ready or not for receiving calls
   *
   * @param {*} state - The state of base store module
   * @param {boolean} isUpdateAvailable - Value indicating if pwa update available
   */
  SET_PWA_UPDATE_STATUS(state, isUpdateAvailable) {
    state.isPWAUpdateAvailable = isUpdateAvailable;
  },
  /**
   * Mutation  to set the page type i.e job or admission
   *
   * @param {*} state - The state of the base store module
   * @param {string} payload - job or admission
   */
  UPDATE_PAGE_TYPE(state, payload) {
    if (payload) {
      state.jobAdmissionPageType = payload;
    }
  },

  /**
   * Mutation to set whether the search functionality is ready or not for receiving calls
   *
   * @param {*} state - The state of base store module
   * @param {boolean} searchState - Value indicating if the search is ready or not
   */
  SET_SEARCH_STATE(state, searchState) {
    state.isSearchReady = searchState;
  },
};
