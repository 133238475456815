export default {
  /**
   * Updates the language code in state of translation module
   *
   * @param {object} state - Data store of the translation module
   * @param {string} payload - A language code such as 'hi','en'
   */
  UPDATE_LANG_CODE(state, payload) {
    const stateObj = state;
    stateObj.languageCode = payload;
  },
};
