import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Snackbar = _resolveComponent("Snackbar")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.snackStack, (item, index) => {
      return (_openBlock(), _createBlock(_component_Snackbar, {
        key: index,
        icon: item.icon,
        type: item.type,
        text: item.message,
        timeout: item.timeout
      }, null, 8, ["icon", "type", "text", "timeout"]))
    }), 128))
  ]))
}