import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "flex dark:bg-dark-primary-1",
  "aria-label": "Breadcrumb"
}
const _hoisted_3 = { class: "flex items-center space-x-4" }
const _hoisted_4 = { class: "sr-only" }
const _hoisted_5 = { class: "flex items-center" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HomeIcon = _resolveComponent("HomeIcon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_ctx.breadcrumbsLoaded)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("nav", _hoisted_2, [
          _createElementVNode("ol", _hoisted_3, [
            _createElementVNode("li", null, [
              _createElementVNode("div", null, [
                _createVNode(_component_router_link, {
                  to: _ctx.getHomeRoute(),
                  class: "text-gray-700 hover:text-gray-900 dark:text-dark-text-1 dark:hover:text-gray-500"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_HomeIcon, {
                      class: "flex-shrink-0 h-5 w-5",
                      "aria-hidden": "true"
                    }),
                    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t(`home`)), 1)
                  ]),
                  _: 1
                }, 8, ["to"])
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.routerBreadcrumbs, (breadcrumb) => {
              return (_openBlock(), _createElementBlock("li", {
                key: breadcrumb.name
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_ChevronRightIcon, {
                    class: "flex-shrink-0 h-5 w-5 text-gray-700 dark:text-dark-text-1",
                    "aria-hidden": "true"
                  }),
                  _createVNode(_component_router_link, {
                    to: breadcrumb.link,
                    class: "ml-4 text-sm md:text-base font-medium text-gray-700 hover:text-gray-900 dark:text-dark-text-1 dark:hover:text-gray-500",
                    "aria-current": breadcrumb.current ? 'breadcrumb' : undefined
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isLandingPage)
                        ? (_openBlock(), _createElementBlock("h1", _hoisted_6, _toDisplayString(breadcrumb.name), 1))
                        : (breadcrumb.name.length < 25)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(breadcrumb.name), 1))
                          : (_openBlock(), _createElementBlock("abbr", {
                              key: 2,
                              title: breadcrumb.name,
                              class: "border-none no-underline"
                            }, _toDisplayString(breadcrumb.name.substring(0, 25)) + " ...", 9, _hoisted_8))
                    ]),
                    _: 2
                  }, 1032, ["to", "aria-current"])
                ])
              ]))
            }), 128))
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}