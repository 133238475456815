import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-fill h-full" }
const _hoisted_2 = { class: "h-[5%] inline-flex relative w-full bg-zinc-800" }
const _hoisted_3 = { class: "inline-flex items-center h-full sm:w-1/2 xsm:w-24 xs:w-36" }
const _hoisted_4 = { class: "text-white font-normal text-lg xsm:truncate" }
const _hoisted_5 = { class: "mx-auto h-full w-fit absolute right-0 inline-block" }
const _hoisted_6 = { class: "bg-zinc-900 w-full h-[95%] margin-0" }
const _hoisted_7 = { class: "h-full w-full overflow-auto p-4 flex justify-center items-center" }
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_arrow_left_icon = _resolveComponent("arrow-left-icon")!
  const _component_download_icon = _resolveComponent("download-icon")!
  const _component_plus_icon = _resolveComponent("plus-icon")!
  const _component_minus_icon = _resolveComponent("minus-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "w-12 h-full bg-black inline-block left-0 hover:bg-opacity-60",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_arrow_left_icon, { class: "w-5 h-5 text-white mx-auto" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.title), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "h-full hover:bg-opacity-60 hover:bg-white xsm:w-8 xs:w-12",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('download')))
        }, [
          _createVNode(_component_download_icon, { class: "w-5 h-5 text-white mx-auto" })
        ]),
        _createElementVNode("button", {
          class: "h-full hover:bg-opacity-60 hover:bg-white xsm:w-8 xs:w-12",
          onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.scaleUp && _ctx.scaleUp(...args)))
        }, [
          _createVNode(_component_plus_icon, { class: "w-5 h-5 text-white mx-auto" })
        ]),
        _createElementVNode("button", {
          class: "h-full hover:bg-opacity-60 hover:bg-white xsm:w-8 xs:w-12",
          onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.scaleDown && _ctx.scaleDown(...args)))
        }, [
          _createVNode(_component_minus_icon, { class: "w-5 h-5 text-white mx-auto" })
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("img", {
          ref: "img",
          src: _ctx.src,
          alt: "Image Not Found",
          class: "z-30 object-fit max-h-full max-w-full"
        }, null, 8, _hoisted_8)
      ])
    ])
  ]))
}