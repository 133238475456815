import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "w-full my-4 mb-5" }
const _hoisted_2 = { class: "flex flex-wrap gap-y-16 md:gap-y-12 lg:mx-8 md:mx-6 mx-0 justify-center" }
const _hoisted_3 = { class: "p-2 group w-full sm:py-6 space-y-2 h-full" }
const _hoisted_4 = { class: "text-2xl md:text-3xl text-grey-8 dark:text-gray-300 font-arial" }
const _hoisted_5 = { class: "flex flex-col mt-6 pt-2 px-2 shadow rounded-lg h-full bg-white dark:bg-dark-primary-2" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "list-disc" }
const _hoisted_8 = { class: "line-clamp line-clamp-2 xl:line-clamp-1 h-10 xl:h-5 sm:h-12" }
const _hoisted_9 = { class: "flex justify-center items-end pb-4 h-full font-normal" }
const _hoisted_10 = {
  type: "button",
  class: "inline-flex px-3 sm:px-5 py-2 md:px-6 border border-transparent bg-primary-1 text-primary-4 hover:bg-primary-4 hover:text-white rounded-md shadow-sm text-xs md:text-sm dark:bg-dark-primary-3 dark:text-dark-text-2 hover:dark:text-gray-300 hover:dark:bg-grey-8"
}
const _hoisted_11 = {
  type: "button",
  class: "inline-flex px-3 sm:px-5 py-2 md:px-6 border border-transparent bg-primary-1 text-primary-4 hover:bg-primary-4 hover:text-white rounded-md shadow-sm text-xs md:text-sm dark:bg-dark-primary-3 dark:text-dark-text-2 hover:dark:text-gray-300"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (items, index) => {
        return (_openBlock(), _createElementBlock("div", {
          key: index,
          class: "lg:w-1/2 w-full md:px-2 lg:px-4"
        }, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", null, [
              _createElementVNode("h2", _hoisted_4, _toDisplayString(_ctx.$t(`${index}`)), 1)
            ]),
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(items.slice(0, 10), (item) => {
                return (_openBlock(), _createElementBlock("dl", {
                  key: item.eventId,
                  class: "p-2"
                }, [
                  _createVNode(_component_router_link, {
                    to: _ctx.setEventRoutePath(item,'eventView')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("dt", {
                        class: "w-full px-3 text-sm md:text-base font-medium sm:max-w-none sm:pl-6 text-primary-3 hover:text-primary-5 dark:text-dark-text-1 dark:hover:text-gray-500",
                        onClick: ($event: any) => (_ctx.logEvent(item,index) )
                      }, [
                        _createElementVNode("ul", _hoisted_7, [
                          _createElementVNode("li", null, [
                            _createElementVNode("span", _hoisted_8, _toDisplayString(item.title[_ctx.getLangCode]), 1)
                          ])
                        ])
                      ], 8, _hoisted_6)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]))
              }), 128)),
              _createElementVNode("div", _hoisted_9, [
                (_ctx.$route.name === `jobsHome_${_ctx.getLangCode}` || _ctx.$route.name === `admissionsHome_${_ctx.getLangCode}`)
                  ? (_openBlock(), _createBlock(_component_router_link, {
                      key: 0,
                      to: _ctx.getRouteName(`${_ctx.$route.meta.mode}sLatestUpdates_${_ctx.getLangCode}`, index)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", _hoisted_10, _toDisplayString(_ctx.$t(`viewMore`)), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
                  : (_openBlock(), _createBlock(_component_router_link, {
                      key: 1,
                      to: _ctx.getRouteName(_ctx.getEventRoute, index)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("button", _hoisted_11, _toDisplayString(_ctx.$t(`viewMore`)), 1)
                      ]),
                      _: 2
                    }, 1032, ["to"]))
              ])
            ])
          ])
        ]))
      }), 128))
    ])
  ]))
}