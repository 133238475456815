// import store
import store from "@/store/index.js";
import { axiosCallWithoutCredentials } from "@/plugins/axiosInstances";

import { AxiosInstanceWithApiId } from "@/plugins/interface";
import { ServerResponse } from "./interface";

// * Boolean indicating whether to use server worker or not
const isDataFetchFromServerAllowed = true;

/**
 * Created a proxy to get the updated value of worker state every time.
 *
 * ? Refer: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Proxy
 */
const proxyTarget = {
  isWorkerReady: false,
};
const handler = {
  get() {
    return store.getters["base/getWorkerState"];
  },
};
const proxy = new Proxy(proxyTarget, handler);

// * Value indicating maximum retry count and time for server requests
const maxRetryCount = 3;
const maxRequestProcessTime = 7000;

/**
 * Function to fetch data from server worker
 *
 * @param {string} base64Payload - The payload for request
 * @param {number} count - request count for api call
 * @returns {Promise<ServerResponse>} response - Data response from api call
 */
async function fetchDataFromServer(base64Payload: string, count: number): Promise<ServerResponse> {
  let success = false;

  // ? Uncomment when testing jsonWorker
  // const api = { id: "WORKER#1", url: "http://127.0.0.1:8080/" };
  const api = { id: "WORKER#1", url: "https://napi.exampathfinder.com/" };

  const axiosInstance = await axiosCallWithoutCredentials();

  // Add controller instance for aborting requests
  const controller = new AbortController();

  // Make data fetch request to napi.exampathfinder.com
  const response = (axiosInstance as AxiosInstanceWithApiId).get(`${api.url}?payload=${base64Payload}`, {
    API_ID: api.id,
    signal: controller.signal,
  });

  // Add a timeout to abort ongoing request if it is not completed within the alloted time
  setTimeout(() => {
    if (!success) controller.abort();
  }, maxRequestProcessTime);

  // Handle promise and return response
  return response.then((result) => {
    success = true;
    const { data, status } = result;
    if (status !== 200) throw (new Error("Request for fetching data failed"));
    return { data, success, count };
  }).catch((err) => {
    success = false;
    console.log("Request process time is longer than expected.\n", err);
    return { data: null, success, count };
  });
}

/**
 * Function to load worker and update the worker state
 *
 * @returns {Promise<boolean>} Worker ready state
 */
async function setWorkerReadyState(): Promise<boolean> {
  if (!proxy.isWorkerReady) {
    const isJSONLoaded = await store.getters["base/getWorkerPromise"];
    store.commit("base/SET_WORKER_STATE", isJSONLoaded);
    return isJSONLoaded;
  }
  return proxy.isWorkerReady;
}

/**
 * Function to generate base64 payload string for a given method and payload data
 *
 * @param {string} methodName - Name of exported function from web worker
 * @param {any[]} argumentsArray - Arguments array for function call
 * @returns {string} base64Payload - base64 encoded payload string
 */
function generateBase64Payload(methodName: string, argumentsArray: IArguments): string {
  const payload = {
    method: methodName,
    params: [...argumentsArray],
  };
  // ! disabled isAPICallEnabled param in payload
  if (methodName === "getTagsDetails") payload.params[2] = false;
  if (["getIdData", "getSubscribedCoreTagsInformation"].includes(methodName)) payload.params[3] = false;

  // * Refer: https://stackoverflow.com/questions/38134200/base64-encode-a-javascript-object
  const base64Payload = btoa(JSON.stringify(payload));
  return base64Payload;
}

/**
 * Function to handle the retry logic for failed requests
 *
 * @param {string} base64Payload - The payload for request
 * @param {number} count - request count for api call
 * @returns {Promise<ServerResponse>} response - Data response from api call
 */
async function handleRequestFailure(base64Payload: string, count: number): Promise<ServerResponse> {
  if (count <= maxRetryCount) {
    const response = await fetchDataFromServer(base64Payload, count);
    if (response.success) return response;
    return handleRequestFailure(base64Payload, count + 1);
  }
  return { data: null, success: false, count };
}

/**
 * Function to decide which worker to call: server or local
 *
 * @param {string} methodName - Name of exported function from web worker
 * @param {any} payloadObject - Payload for API request
 * @param {any[]} argumentsArray - Arguments array for function call
 * @returns {Promise<any>} data - Response for payload
 */
async function workerSwitcher(methodName: string, payloadObject: any, argumentsArray: IArguments): Promise<any> {
  try {
    const isCallFromUserSidePage = payloadObject.isUserPage;
    let isLocalWorkerReady;

    // Let local worker load for admin pages
    if (!isCallFromUserSidePage) isLocalWorkerReady = await setWorkerReadyState();
    isLocalWorkerReady = proxy.isWorkerReady;
    const isCallToServerNeeded = !isLocalWorkerReady;

    // Request server and fetch response
    if (isCallToServerNeeded && isDataFetchFromServerAllowed) {
      const base64Payload = generateBase64Payload(methodName, argumentsArray);
      const response = await fetchDataFromServer(base64Payload, 0);

      // Request completed successfully
      if (response.success) return response.data;

      // Handle request failure
      if (!response.success) {
        const count = 1;
        const result = await handleRequestFailure(base64Payload, count);
        if (result.success) { return response.data; }
      }
    }

    // Server failed to return response
    // ? Initialize the local worker and Return, and Call local worker if code reaches here
    if (!isLocalWorkerReady) await setWorkerReadyState();

    return {};
  } catch (error) {
    console.log("SWITCH#1: ", (error as Error).message);
    return {};
  }
}

export default workerSwitcher;
