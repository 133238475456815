

import { defineComponent, PropType } from "@/plugins/vue";
import FilterBar from "@/components/Shared/FilterHelpers/FilterBar.vue";
import CircularLoader from "../Generic/CircularLoader.vue";

interface FilterOption {
  id: string;
  text: string;
  count: number;
  selected: boolean;
  disabled: boolean;
}
interface TagData {
  id: string;
  text: string;
  type: string;
  values: FilterOption[];
}

interface AllFilters extends FilterOption {
  label: string;
  ref: FilterOption;
}

export default defineComponent({
  name: "SearchFilter",
  components: {
    FilterBar,
    CircularLoader,
  },
  props: {
    /**
     * Filteration field values to be displayed
     */
    tags: {
      type: Array as PropType<TagData[]>,
      default: () => [],
    },
    /**
     * Filtration exams to be displayed
     */
    exams: {
      type: Array as PropType<FilterOption[]>,
      default: () => [],
    },
    /**
     * Filtration organisations to be displayed
     */
    organisations: {
      type: Array as PropType<FilterOption[]>,
      default: () => [],
    },
    /**
     * Status of filter
     */
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["queryUpdated"],
  data: () => ({
    allFilters: [] as AllFilters[],
  }),
  watch: {
    /**
     * Watcher to watch the state of filter and update the filter options
     *
     * @param {boolean} val - filter loaded or not
     */
    loading(val: boolean) {
      if (val) return;
      this.allFilters.length = 0;

      this.tags.forEach((tag) => {
        const { values } = tag;
        const selectedValues = values.filter((value) => value.selected);
        selectedValues.forEach((value) => { this.addFilter(value, tag.text, values, false); });
      });

      const selectedExams = this.exams.filter((exam) => exam.selected);
      selectedExams.forEach((value) => { this.addFilter(value, "Exam", this.exams, false); });

      const selectedOrganisations = this.organisations.filter((organisation) => organisation.selected);
      selectedOrganisations.forEach((value) => { this.addFilter(value, "Organisation", this.organisations, false); });
    },
  },
  methods: {
    /**
     * Function to Add a selected filter
     *
     * @param {FilterOption} data - the data property which filter applied by user
     * @param {string} type - the type of property
     * @param {FilterOption[]} values - Array of data to be filtered
     * @param {boolean} updateQuery - whether to emit a change in filter query to parent
     */
    addFilter(data: FilterOption, label: string, values: FilterOption[], updateQuery = true): void {
      this.allFilters.push({ ...data, ref: data, label });

      if (updateQuery) this.$emit("queryUpdated");

      // Reorder the selected option to the top
      const idx = values.indexOf(data);
      values.splice(idx, 1);
      values.splice(0, 0, data);
    },
    /**
     * Remove a selected filter
     *
     * @param {FilterOption} data - the data property which filter removed by user
     */
    removeFilter(data: FilterOption) {
      const itemIndex = this.allFilters.findIndex((val) => val.id === data.id);
      this.allFilters.splice(itemIndex, 1);
      this.$emit("queryUpdated");
    },
    /**
     * Clears all filters
     */
    clearFilters(): void {
      this.allFilters.forEach((filter) => {
        const filterObject = filter;
        if (!filterObject.disabled) filterObject.ref.selected = false;
      });
      this.allFilters = this.allFilters.filter((filter) => filter.disabled);
      this.$emit("queryUpdated");
    },
  },
});
