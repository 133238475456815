// import store;
const store = require("@/store/index.js");

// Restricted fields (Limited values)
export const restrictedFields = {
  iJg6EsMk: "Post Type",
  "8lw29cmS": "Recruitment Type",
  "3PvVhPBF": "Pay Matrix",
  OwIM1KAN: "Application mode",
  Fcm0skbv: "Age Relaxation Type",
  ScNU9SmO: "Quota/Reservation",
  eEPkh9p7: "Educational Qualification",
  MgHVVPYR: "Application Fee",
  Sd1YZBDu: "Organisation Type",
  LyLh6cz8: "Interview",
  Lz1uzMxS: "Age Limit",
  jw58ZOFp: "Physical Test",
  MI8XYecF: "Group",
  "74X1HhtR": "Admission Type",
  "5GH9iXBb": "Educational Credential Awarded",
  LMGjNRLB: "Stream",
  VIPwQsKv: "Event Category",
  pBL58aed: "Work Experience",
  HXKXXgut: "Examination Center",
  Bv2ZOMzp: "Examination Mode",
};

// Users allowed to use API for adding value to restricted fields
export const allowedUsers = {
  CtMq3M6ISiPzLqTVCdE2sQcVtb72: "suraj.sharma@vidyamantra.com",
  LXOGLbbVWNWowuT9ZDubRxFevUx1: "sagorika.rai@vidyamantra.com",
  KdTy4ROZDhUF7PLqz77hlGxY5Y32: "akanksha.mishra@vidyamantra.com",
  zNromQsRuFMkHbI66wyU3rN75FH3: "pallavi.bharti@vidyamantra.com",
  UxuyMci6N1Z5ymIf4UZvYqccpQ12: "sakshi.mishra@vidyamantra.com ",

};

/**
 * Function to check whether user is allowed to access API or not
 *
 * @param {string} nameId - Id of field name
 * @returns {boolean} is api access allowed or not
 */
export function checkAPIAccess(nameId: string): boolean {
  const isFieldRestricted = Object.keys(restrictedFields).includes(nameId);
  if (isFieldRestricted) {
    const currentUserID = store.default.getters["user/getUserId"];
    const isAccessAllowed = Object.keys(allowedUsers).includes(currentUserID);
    return isAccessAllowed;
  }
  return true;
}
