
import { getJSONDataIds } from "@/helpers/staticDataParser";
import { getTagName } from "@/scripts/workerAdapterConnector";
import { getHomeRoutePath } from "@/helpers/routesHandler";
import { defineComponent } from "@/plugins/vue";
import { RouteObject } from "@/helpers/interface";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

interface TagName {
  en: {
    string: string;
  };
  hi: {
    string: string;
    roman: string;
  };
}

export default defineComponent({
  name: "TopQualifications",
  data() {
    return {
      title: "",
      description: "",
      qualificationsIconData: [
        "below-matriculation.webp",
        "matriculation.webp",
        "diploma.webp",
        "iti.webp",
        "intermediate.webp",
        "graduation.webp",
        "post-graduation.webp",
        "doctorate.webp",
        "post-doctorate.webp",
        "ca-cms.webp",
      ],
      qualificationsIds: [] as string[],
      tagNames: [] as TagName[],
      isTagNames: false,
    };
  },
  computed: {
    /**
     * Gets lang code from base store module
     *
     * @returns {LangCode} - current lang code
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Gets the path name on basis on the route meta module
     *
     * @returns {string} path name of the route
     */
    pathName(): string {
      return this.$route.meta.mode === "job" ? "jobsSpecificQualificationPage_en" : "admissionsSpecificQualificationPage_en";
    },
  },
  /**
   * Watch the change in the route
   */
  watch: {
    $route() {
      this.updatedtitleAndLink();
    },
  },
  created() {
    /**
   * Update title,description and link on created life cycle hook
   */
    this.updatedtitleAndLink();
    this.qualificationsIds = getJSONDataIds("qualification");
    getTagName("qualification", [...this.qualificationsIds]).then((tagNames) => {
      this.isTagNames = true;
      this.tagNames = tagNames as TagName[];
    });
  },
  methods: {
    /**
    * Function to get the route path of homePage icons
    *
    * @param {string} id - id of the item
    * @param {number} index - index at which the item is present
    * @return {RouteObject} route path
    */
    setHomeRoutePath(id: string, index: number): RouteObject {
      if (!this.tagNames[index]) return { name: "", params: { langCode: this.langCode, id: "" } };
      const path = getHomeRoutePath(this.tagNames, id, index, this.langCode, this.pathName, this.$route);
      return path;
    },

    /**
     * Function to updates the title and description
     */
    updatedtitleAndLink(): void {
      this.title = this.$route.meta.mode === "job" ? "jobByQualification" : "admissionByQualification";
      this.description = this.$route.meta.mode === "job" ? "jobByQualificationDescription" : "admissionByQualificatonDescription";
    },
    /**
     * Function to get the string data
     *
     * @param {number} index: the index value of tagName
     * @returns {string} the string value inside the tagName
     */
    getStringData(index: number): string {
      if (!this.isTagNames) return "";
      return this.tagNames?.[index]?.[this.langCode]?.string || this.tagNames?.[index]?.en?.string;
    },
  },
});
