
import { UserGroupIcon, LibraryIcon } from "@/plugins/outlineHeroicon";
import { analyticsSelectItem } from "@/helpers/analyticsEvents";
import { changeToSentenceCase } from "@/helpers/validations";
import { PREPARATION_PLATFORM_URL, COMMUNITY_PLATFORM_URL } from "@/helpers/constants";
import { defineComponent } from "@/plugins/vue";

export default defineComponent({
  name: "OtherPlatforms",
  data() {
    return {
      otherPlatformLinks: [
        {
          title: "communityPlatform",
          href: COMMUNITY_PLATFORM_URL,
          icon: UserGroupIcon,
          description: "communityPlatformDescription",
        },
        {
          title: "preparation",
          href: PREPARATION_PLATFORM_URL,
          icon: LibraryIcon,
          description: "preparationDescription",
        },
      ],
    };
  },
  methods: {
    analyticsSelectItem,
    changeToSentenceCase,
  },
});
