// Importing emitter
const emitter = require("tiny-emitter/instance");

// Mapped emitter api EventBus object
// Added arguments ...args type as any[] from the tiny-emitter/index.d.ts library typescript file inside node_modules
const EventBus = {
  $on: (...args: any[]) => emitter.on(...args),
  $once: (...args: any[]) => emitter.once(...args),
  $off: (...args: any[]) => emitter.off(...args),
  $emit: (...args: any[]) => emitter.emit(...args),
};

// Exported EventBus
export default EventBus;
