
import dataTable from "@/components/Generic/DataTable.vue";
import { getUserCoreTagList, getUserStateList } from "@/scripts/workerAdapterConnector";
import HeadingSection from "@/components/Generic/HeadingSection.vue";
import { analyticsSelectItemFromList, analyticsViewItemList } from "@/helpers/analyticsEvents";
import { getRoutePath } from "@/helpers/routesHandler";
import EventBus from "@/helpers/eventBus";
import { defineComponent } from "@/plugins/vue";
import { LangCode, EventItemData, RouteObject } from "@/helpers/interface";
import {
  EventType, CoreTagListType, CoreTagList, Mapped, FieldValue,
} from "@/scripts/interface";

type ValidListType = "organisation" | "exam" | "state" | "university";

export default defineComponent({
  name: "SpecificPageList",
  components: {
    dataTable,
    HeadingSection,
  },
  data() {
    return {
      dataSet: false,
      pageMode: "" as EventType,
      tagType: "" as ValidListType,
      list: [] as EventItemData[],
      headers: [
        { text: "s.No", value: "sno" },
        { text: "name", value: "string" },
      ],
      tagsDataLength: null,
      validListTypes: ["organisation", "exam", "state", "university"],
      currentItemsLength: 0,
      previousItemsLength: 0,
      tagsDataStartCheck: 0,
      tagsDataEndCheck: 0,
      enablePaginationRoute: true,
    };
  },
  computed: {
    /**
     * Gets language code from the base module in store
     *
     * @returns {CurrentLangCode} - current language code
     */
    langCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
  },
  watch: {
    /**
     * Watcher to watch the route object
     * Set the page data only when the page changes and not when the language changes
     *
     * @param {object} newRoute - New route value
     * @param {object} oldRoute - Old route value
     */
    $route(newRoute, oldRoute) {
      const newRouteName = newRoute.name.split("_")[0];
      const oldRouteName = oldRoute.name?.split("_")[0];
      if (newRouteName !== oldRouteName) {
        this.pageMode = this.$route.meta.mode as EventType;
        this.tagType = this.$route.meta.type as ValidListType;
        if (this.validListTypes.includes(this.tagType) && this.$route.meta.pageType === "tagListPage") {
          this.dataSet = false;
          this.setTagsList(0, 100);
          this.tagsDataStartCheck = 0;
          this.tagsDataEndCheck = 0;
          this.resetPaginationRoute();
          analyticsViewItemList(`${this.tagType} list`);
        }
      }
    },
    /**
    *  Watch tagsDataStartCheck to append data in start of the table
    *
    * @param {number} val- Change in start Value
    */
    tagsDataStartCheck(val: number) {
      if (val <= (Number(this.$route.query.itemsPerPage) * (Number(this.$route.query.pageNo) - 1)) && val !== 0) {
        this.appendStartTags(val - 100, 100);
      }
    },
    /**
    *  Watch tagsDataEndCheck to append data in end of the table
    *
    * @param {number} val- Change in end Value
    */
    tagsDataEndCheck(val: number) {
      if (val >= this.list.length) {
        this.appendEndTags(val, 100);
      }
    },
  },
  /**
   * Life cycle hook to get value of route mode and route type in variables
   * Life cycle hook to call function to set length of list
   */
  created() {
    this.pageMode = this.$route.meta.mode as EventType;
    this.tagType = this.$route.meta.type as ValidListType;
    if (Object.keys(this.$route.query).length) {
      const rowsPerPage = Number(this.$route.query.itemsPerPage) > 25 ? 100 : 25;
      const pageNo = Number(this.$route.query?.pageNo) > 1 ? Number(this.$route.query?.pageNo) : 1;
      const retriveDataCordinates = Math.floor((rowsPerPage * (pageNo - 1)) / 100) * 100;
      this.setTagsList(retriveDataCordinates, 100);
    } else {
      this.setTagsList(0, 100);
    }
    analyticsViewItemList(`${this.$route.meta.type} list`);
  },
  methods: {
    /**
    * Function to get data list of length 100 from a specific starting point,
    * If no data is found at that starting point then changing the starting point to default
    * and returning the data from worker
    *
    * @param {number} start- value from which table will start
    * @param {number} count- no of item to show in table
    */
    setTagsList(start: number, count: number): void {
      let fetchedDataPromise: Promise<CoreTagList> | Promise<Mapped<FieldValue>>;
      if (["exam", "organisation", "university"].includes(this.tagType)) {
        fetchedDataPromise = getUserCoreTagList(this.pageMode, this.tagType as CoreTagListType, start, count);
      } else if (this.tagType === "state") {
        fetchedDataPromise = getUserStateList(this.pageMode, start, count, "India");
      }
      fetchedDataPromise!.then((tags) => {
        if (Object.values(tags).length === 0 && start !== 0) {
          this.resetPaginationRoute();
          this.setTagsList(0, 100);
        } else {
          if (start === 0) {
            this.list = Object.values(tags);
          } else {
            const dummy = [...this.list];
            for (let i = 1; i <= (start - this.list.length); i += 1) {
              dummy.push({
                id: "",
                lang: {
                  en: { aliases: [], string: "", text: "" },
                  hi: {
                    aliases: [], roman: "", string: "", text: "",
                  },
                },
                type: "",
              });
            }
            this.list = dummy.concat(Object.values(tags));
          }
          this.dataSet = true;
          this.currentItemsLength = this.list.length;
          EventBus.$emit("dataLoaded", true);
          // Emit dataForScroll event to load scroll bar
          this.$nextTick().then(() => EventBus.$emit("dataForScroll"));
        }
      });
    },
    /**
    * Function to Update tagsDataLength to track users page
    *
    * @param {number} startNo- start index value of data table
    * @param {number} itemCount- total count of items
    */
    setPageLength(startNo: number, itemCount: number): void {
      this.tagsDataStartCheck = startNo;
      this.tagsDataEndCheck = startNo + itemCount;
    },
    /**
    * Function to append data to tags list once user reachess start page
    *
    * @param {number} start- take last value of table
    * @param {number} count- next no of item to add in table
    */
    appendStartTags(start: number, count: number): void {
      if (start >= 0) {
        let fetchedDataPromise: Promise<CoreTagList> | Promise<Mapped<FieldValue>>;
        if (["exam", "organisation", "university"].includes(this.tagType)) {
          fetchedDataPromise = getUserCoreTagList(this.pageMode, this.tagType as CoreTagListType, start, count);
        } else if (this.tagType === "state") {
          fetchedDataPromise = getUserStateList(this.pageMode, start, count, "India");
        }
        fetchedDataPromise!.then((tags) => {
          this.list.splice(start, count, ...(Object.values(tags)));
        });
      }
    },
    /**
    * Function to append data to tags list once user reachess last page
    *
    * @param {number} start- take last value of table
    * @param {number} count- next no of item to add in table
    */
    appendEndTags(start: number, count: number): void {
      let fetchedDataPromise: Promise<CoreTagList> | Promise<Mapped<FieldValue>>;
      if (["exam", "organisation", "university"].includes(this.tagType)) {
        fetchedDataPromise = getUserCoreTagList(this.pageMode, this.tagType as CoreTagListType, start, count);
      } else if (this.tagType === "state") {
        fetchedDataPromise = getUserStateList(this.pageMode, start, count, "India");
      }
      fetchedDataPromise!.then((tags) => {
        this.previousItemsLength = this.currentItemsLength;
        this.list.splice(start, 0, ...(Object.values(tags)));
        this.currentItemsLength = this.list.length;
      });
    },
    /**
     * Function log select_item event in firbase analyticsLogger
     *
     * @param {EventItemData} item - Item object with event information
     *
     */
    logEvent(item: EventItemData): void {
      analyticsSelectItemFromList(item.lang.en.string, this.$route.meta.type as string, `${this.$route.meta.type} list`);
    },
    /**
     * Function to get the specific tag link
     *
     * @param {EventItemData} item - specific tag item
     * @returns {RouteObject} path - the resolved path
     */
    getSpecificTagLink(item: EventItemData): RouteObject {
      if (!item) return { name: "", params: { langCode: this.langCode, id: "" } };
      const listType = this.tagType[0].toUpperCase() + this.tagType.substring(1);
      const routeName = `${this.pageMode}sSpecific${listType}Page_${this.langCode}`;
      const path = getRoutePath(item, this.langCode, routeName, this.$route);
      return path;
    },
    /**
     * Function to set the table parameters (pageNo & itemsPerPage) in the route
     */
    resetPaginationRoute(): void {
      const url = new URL(document.location.href);
      const params = new URLSearchParams(url.search);
      params.set("pageNo", "1");
      this.$router.replace(`${url.pathname}?${params}`);
    },
  },
});
