export default {
  /**
   * Function sets the user karma
   *
   * @param {*} state - use to access the state of user module
   * @param {Number} payload - user karma
   */
  SET_USER_KARMA(state, payload) {
    const stateObj = state;
    stateObj.user_karma = payload;
  },
  setSubscriptionList(state, payload) {
    const stateObj = state;
    stateObj.subscriptionList = payload;
  },
  setSearchResultList(state, payload) {
    const stateObj = state;
    stateObj.searchResultList = payload;
  },
  setVisibleResultList(state, payload) {
    const stateObj = state;
    stateObj.visibleResultList = payload;
  },
  setSearchId(state, payload) {
    const stateObj = state;
    stateObj.unsubscribeSearch = payload;
  },
  clearSearchResultList(state) {
    const stateObj = state;
    stateObj.searchResultList = [];
  },
  /**
   * Function sets the user credentials
   *
   * @param {*} state - use to access state of user module
   * @param {*} payload - user credentials
   */
  SET_USER_CREDS(state, payload) {
    const stateObj = state;
    stateObj.user_token = payload.token;
    stateObj.user_uid = payload.uid;
  },
  /**
   * Function clears the user credentials
   * @param {*} state - use to access the state for user module
   */
  CLEAR_USER_CREDS(state) {
    const stateObj = state;
    stateObj.user_karma = 0;
    stateObj.user_token = undefined;
    stateObj.user_uid = undefined;
  },
  /**
  * Function sets the user displayName
  *
  * @param {*} state - use to access the state of user module
  * @param {String} payload - user display name
  */
  SET_USER_DISPLAY_NAME(state, payload) {
    const stateObj = state;
    stateObj.displayName = payload;
  },
  /**
   * Function sets the user display image
   *
   * @param {*} state - use to access the state of user module
   * @param {String} payload - url of user display image
   */
  SET_USER_DISPLAY_IMG(state, payload) {
    const stateObj = state;
    stateObj.photoURL = payload;
  },
  /**
   * Function updates the auth state
   *
   * @param {*} state - use to access the state of user module
   * @param {boolean} isAuth - true if user logged in otherwise false
   */
  UPDATE_IS_AUTH(state, isAuth) {
    const stateObj = state;
    stateObj.isAuth = isAuth;
  },
};
