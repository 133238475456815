
import { analyticsSelectItemFromList } from "@/helpers/analyticsEvents";
import { RouteObject } from "@/helpers/interface";
import { getEventRoutePath } from "@/helpers/routesHandler";
import { defineComponent, PropType } from "@/plugins/vue";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";

interface latestNotification {
  created: number;
  eventId: string;
  lastUpdated: number;
  title: {
    en: string;
    hi: string;
  };
  title_roman: {
    hi: string;
  };
  valueTimestamp: number;
}

export default defineComponent({
  name: "LatestNotifications",
  props: {
    /**
     * The array of object
     */
    latestNotificationsEvents: {
      type: Array as PropType<latestNotification[]>,
      default: () => [],
    },
  },
  data() {
    return {
      eventDisplayCount: 5,
    };
  },
  computed: {
    /**
     * Computed property to get route name for a event
     */
    getPathName(): string {
      return `${this.$route.meta.mode}sLatestUpdates_${this.getLangCode}`;
    },
    /**
     * Computed property to get current Language type
    */
    getLangCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed array of latestNotifications to display
     */
    displayNotifications(): latestNotification[] {
      return this.latestNotificationsEvents?.slice(0, this.eventDisplayCount);
    },
  },
  methods: {
    /**
    * Function to get the route path to edit and view events
    *
    * @param {latestNotification} item - Current event item
    * @param {string} routeName - Name of route (eventEdit or eventView)
    * @return {RouteObject} RouteObject - url according to current language
    */
    setEventRoutePath(item: latestNotification, routename: string): RouteObject {
      if (!item) return { name: "", params: { langCode: this.getLangCode, id: "" } };
      const path = getEventRoutePath(item, this.getLangCode, routename, this.$route);
      return path;
    },
    /**
     * Method to Logs Event in firebase analytics when user select an item from the latest notifications  List
     *
     * @param {object} item - object containing event information
     */
    logEvent(item: latestNotification) {
      analyticsSelectItemFromList(item.title.en, "Latest Notifications", "Latest Notifications List");
    },
  },
});
