
import { getTagName } from "@/scripts/workerAdapterConnector";
import { getSeperatedIdAndName, validateRouteAndFetchSlug, getNamedRoute } from "@/helpers/routesHandler";
import { metaTagSlugRedirection } from "@/helpers/metaTags";
import LangJSON from "@/assets/json/langCodes.json";
import displayConsole from "@/helpers/displayConsole";
import { defineComponent } from "@/plugins/vue";
import { NonEnglishLangCode, TagType } from "@/scripts/interface";

export default defineComponent({
  name: "RouterViewMetaRefresh",
  computed: {
    /**
     * Gets lang code from the base module in the store
     *
     * @returns {string} - lang code
     */
    langCode(): "en" | "hi" {
      return this.$store.getters["base/getLangCode"];
    },
  },
  /**
  * In this hook we will validate the route and refresh if route is not valid
  */
  created() {
    this.validateAndMetaRefresh();
  },
  methods: {
    /**
    * Function validates the url and refresh the page with correct url if its invalid
    */
    validateAndMetaRefresh() {
      const routeId = getSeperatedIdAndName(this.$route.params.id as string).id;
      getTagName(this.$route.meta.type as TagType, [routeId]).then((tagName) => {
        if (!tagName[0]) {
          displayConsole(
            `getTagName Failed for langCode ${this.langCode} at ${new Date().toLocaleString()}`,
            "tagName[0] undefined",
            "Page not found redirection error",
          );
          this.$router.push({ name: `notFound_${this.langCode}`, params: { langCode: this.langCode } });
          return;
        }
        let routeName = "";
        if (LangJSON.romanLangCodes.includes(this.langCode) && tagName[0]?.[this.langCode as NonEnglishLangCode]?.roman) {
          routeName = tagName[0][this.langCode as NonEnglishLangCode]!.roman;
        } else routeName = tagName?.[0].en.string;
        const eventSlugObject = validateRouteAndFetchSlug(routeId, routeName, this.$route);
        // if the link is not correct as eventSlugObject then redirect to correct page.
        if (!eventSlugObject.isValidated) {
          const routeObject = this.$router.resolve({
            name: getNamedRoute(this.langCode, this.$route.name as string),
            params: { langCode: this.langCode, id: eventSlugObject.idSlug },
          });
          const matchFound = eventSlugObject.isValidated;
          const newSlug = routeObject.path;
          const metaSlug = metaTagSlugRedirection({ matchFound, newSlug });
          this.$meta.meta = metaSlug;
        }
      }).catch((err) => {
        displayConsole(`getTagName Failed for langCode ${this.langCode} at ${new Date().toLocaleString()}`, err, "Page not found redirection error");
        this.$router.push({ name: `notFound_${this.langCode}`, params: { langCode: this.langCode } });
      });
    },
  },
});
