import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vModelSelect as _vModelSelect, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "max-w-full mx-auto px-4 sm:px-6 lg:px-8" }
const _hoisted_2 = { class: "-mx-4 mt-8 shadow ring-1 ring-black ring-opacity-5 dark:ring-dark-primary-3 sm:-mx-6 md:mx-0 md:rounded-lg" }
const _hoisted_3 = { class: "min-w-full divide-y divide-gray-300 dark:divide-dark-primary-3" }
const _hoisted_4 = { class: "font-arial" }
const _hoisted_5 = { class: "divide-y divide-gray-200 dark:divide-dark-primary-3 bg-white dark:bg-dark-primary-2 font-medium" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = ["colspan"]
const _hoisted_8 = {
  key: 0,
  class: "text-gray-700 dark:text-dark-text-2 font-medium"
}
const _hoisted_9 = {
  key: 1,
  class: "dark:text-dark-text-2"
}
const _hoisted_10 = ["colspan"]
const _hoisted_11 = { class: "flex justify-end items-center font-medium px-4 text-sm dark:text-dark-text-1" }
const _hoisted_12 = { class: "hidden xs:block" }
const _hoisted_13 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronLeftIcon = _resolveComponent("ChevronLeftIcon")!
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("table", _hoisted_3, [
          _createElementVNode("thead", _hoisted_4, [
            _createElementVNode("tr", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                return (_openBlock(), _createElementBlock("th", {
                  key: header.value,
                  class: _normalizeClass([[_ctx.hiddenOnSmallScreen(header.value) ? 'hidden sm:table-cell' : ''], "sticky md:top-16 top-16 z-10 bg-gray-50 dark:bg-dark-primary-3 px-6 py-3.5 text-left text-sm text-gray-900 dark:text-gray-400 lg:table-cell uppercase tracking-wider"]),
                  scope: "col"
                }, _toDisplayString(_ctx.$t(`${header.text}`)), 3))
              }), 128))
            ])
          ]),
          _createElementVNode("tbody", _hoisted_5, [
            (!_ctx.items.length)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_6, [
                  _createElementVNode("td", {
                    colspan: _ctx.headers.length,
                    class: "px-6 py-4 text-center dark:text-dark-text-1"
                  }, _toDisplayString(_ctx.$t(`noDataFound`)), 9, _hoisted_7)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.currentPageItems, (row, index) => {
              return (_openBlock(), _createElementBlock("tr", { key: index }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header) => {
                  return (_openBlock(), _createElementBlock("td", {
                    key: header.value,
                    class: _normalizeClass([_ctx.hiddenOnSmallScreen(header.value) ? 'hidden sm:table-cell' : '', 'px-6 py-4'])
                  }, [
                    _renderSlot(_ctx.$slots, `item.${header.value}`, {
                      item: row,
                      index: index
                    }, () => [
                      (header.value == 'sno')
                        ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.startNo + index + 1), 1))
                        : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.rowValue(row, header.value)), 1))
                    ])
                  ], 2))
                }), 128))
              ]))
            }), 128)),
            _createElementVNode("tr", null, [
              _createElementVNode("td", {
                colspan: `${_ctx.headers.length}`
              }, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.$t(`rowsPerPage`)), 1),
                  _withDirectives(_createElementVNode("select", {
                    id: "location",
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.noOfItemsSelected) = $event)),
                    name: "location",
                    class: "mt-1 mx-5 w-fit border-2 pl-3 pr-10 py-2 dark:text-dark-text-2 text-xs border-grey-3 dark:bg-dark-primary-2 dark:border-grey-7 sm:text-sm rounded-md cursor-pointer focus:ring-0",
                    "aria-label": "pageItems"
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.itemsSelectOptions, (i) => {
                      return (_openBlock(), _createElementBlock("option", { key: i }, _toDisplayString(i), 1))
                    }), 128))
                  ], 512), [
                    [_vModelSelect, _ctx.noOfItemsSelected]
                  ]),
                  _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.$t(`pageNo`) + ': ' + _ctx.pageNo), 1),
                  _createVNode(_component_ChevronLeftIcon, {
                    class: _normalizeClass([_ctx.isLeftArrowShow ? 'opacity-30': '', 'h-3 w-3 sm:h-5 sm:w-5 mx-2 sm:mx-5 cursor-pointer']),
                    "aria-hidden": "true",
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToPage(_ctx.pageNo - 1)))
                  }, null, 8, ["class"]),
                  _createVNode(_component_ChevronRightIcon, {
                    class: _normalizeClass([_ctx.isRightArrowShow || !_ctx.items.length ? 'opacity-30': '', 'h-3 w-3 sm:h-5 sm:w-5 mx-2 sm:mx-5 cursor-pointer']),
                    "aria-hidden": "true",
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.goToPage(_ctx.pageNo + 1)))
                  }, null, 8, ["class"])
                ])
              ], 8, _hoisted_10)
            ])
          ])
        ])
      ])
    ])
  ]))
}