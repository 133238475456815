
import ToolTip from "@/components/Generic/ToolTip.vue";
import { getNamedRoute } from "@/helpers/routesHandler";
import { defineComponent, PropType } from "@/plugins/vue";
import { RouteLocationRaw } from "@/plugins/interface";

// Composed type for langCode, add more valid langCodes if required
type LangCode = "en" | "hi";
interface SidebarNavigation { name: string; icon: string; pathName: string; admin: boolean; displayName: string; }

export default defineComponent({
  name: "BaseAppShellNavigation",
  components: { ToolTip },
  props: {
    sidebarNavigation: {
      type: Array as PropType<SidebarNavigation[]>,
      default: () => [],
    },
  },
  data() {
    return {
      selected: "" as string,
    };
  },
  computed: {
    /**
     * computed property to get current language type
     */
    getLangCode(): LangCode {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * computed property get the karma score of user
     */
    userKarma(): number {
      return this.$store.getters["user/getUserKarma"];
    },
    /**
     * computed property get the user signed in state
     */
    isUserSignedIn(): boolean {
      return this.$store.getters["user/isUserSignedIn"];
    },
    /**
     * computed property get the sidebar items for user only
     */
    userSidebarItems(): SidebarNavigation[] {
      if (this.isUserSignedIn && this.userKarma >= 25) {
        return this.sidebarNavigation;
      } return this.sidebarNavigation.filter((item) => item.admin === false);
    },
  },
  /**
   * Watching the pathName of route and update the selected property
   */
  watch: {
    $route(newRoute) {
      const newRouteName = newRoute.name?.split("_")[0];
      this.selected = newRouteName;
    },
  },
  methods: {
    /**
     * Method to get encoded route name of navigate link according to current language
     *
     * @param {string} path - Route name of navigation link
     * @returns {RouteLocationRaw} - Return the route name according to current language
     */
    getNavigationRouteName(pathName: string): RouteLocationRaw {
      if (!this.getLangCode) return { name: "", params: { langCode: "en" } };
      const routeObject = {
        name: getNamedRoute(this.getLangCode, pathName),
        params: { langCode: this.getLangCode },
      };
      return routeObject;
    },
    /**
     * Function to pop a sign in box on dashboard, alert and subscription click if user sign out
     *
     * @param {object} clickedRoute - The route where user wants to go
     */
    clickSidebar(clickedRoute: SidebarNavigation): void {
      const routeName = clickedRoute.name;
      const routesName = ["dashboard", "alerts", "subscriptions"];
      if (routesName.includes(routeName) && !this.isUserSignedIn) {
        this.$store.commit("base/CHANGE_SIGN_IN_BOX_DISPLAY", true);
      }
    },
  },
});
