

import { defineComponent, PropType } from "@/plugins/vue";

export default defineComponent({
  name: "AuthProviderButton",
  props: {

    /**
     * The Text to be displayed on button
     */
    text: {
      type: String as PropType <string>,
      default: "Sign In",
    },

    /**
     * Used for img src attribute
     * import the image for a dynamic link
     */
    icon: {
      type: String as PropType <string>,
      default: "",
    },

    /**
     * Prop to disable button conditionally
     */
    disabled: {
      type: Boolean as PropType <Boolean>,
      default: false,
    },
  },
});
