import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_section = _resolveComponent("heading-section")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_data_table = _resolveComponent("data-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.dataSet)
      ? (_openBlock(), _createBlock(_component_heading_section, {
          key: 0,
          title: `${_ctx.tagType}List`,
          "enable-share": true
        }, null, 8, ["title"]))
      : _createCommentVNode("", true),
    (_ctx.dataSet)
      ? (_openBlock(), _createBlock(_component_data_table, {
          key: 1,
          headers: _ctx.headers,
          items: _ctx.list,
          "current-items-length": _ctx.currentItemsLength,
          "previous-items-length": _ctx.previousItemsLength,
          "enable-pagination-route": _ctx.enablePaginationRoute,
          onPageChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setPageLength($event.startNo, $event.itemCount)))
        }, {
          [`item.string`]: _withCtx(({ item }) => [
            _createVNode(_component_router_link, {
              to: _ctx.getSpecificTagLink(item)
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", {
                  class: "text-primary-3 hover:text-primary-5 dark:text-dark-text-1 line-clamp line-clamp-3 sm:line-clamp-2 md:line-clamp-1 dark:hover:text-gray-500",
                  onClick: ($event: any) => (_ctx.logEvent(item))
                }, _toDisplayString(item.lang[_ctx.langCode]?.string||item.lang.en.string), 9, _hoisted_1)
              ]),
              _: 2
            }, 1032, ["to"])
          ]),
          _: 2
        }, 1032, ["headers", "items", "current-items-length", "previous-items-length", "enable-pagination-route"]))
      : _createCommentVNode("", true)
  ], 64))
}