
import { DownloadIcon } from "@/plugins/outlineHeroicon";
import ActionSnackbar from "@/components/Generic/ActionSnackbar.vue";
import { isChrome } from "@/helpers/validations";
import { defineComponent } from "@/plugins/vue";

/**
 * Extending Event interface for prompt function because Event interface does not contain prompt type
 * ref- https://stackoverflow.com/questions/51503754/typescript-type-beforeinstallpromptevent
 */
interface EventPrompt extends Event {
  prompt(): Promise<void>;
}

export default defineComponent({
  name: "AppInstallSnackbar",
  components: {
    ActionSnackbar,
  },
  data: () => ({
    isVisible: false,
    deferredPrompt: null as Event | null,
    downloadIcon: DownloadIcon,
  }),
  created() {
    // Register event handler for PWA install prompt
    window.addEventListener("beforeinstallprompt", (e: Event) => {
      e.preventDefault();
      this.deferredPrompt = e;
      this.isVisible = isChrome();
    });

    // Register event handler for PWA app installed
    window.addEventListener("appinstalled", () => {
      this.isVisible = false;
      this.deferredPrompt = null;
    });
  },

  methods: {
    /**
     * Function to show Actual Install prompt by the browser
     * Type Prompt is extended from Event interface in global interface file custom.d.ts
     */
    async installApp(): Promise<void> {
      (this.deferredPrompt as EventPrompt).prompt();
    },
  },
});

