import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-white dark:bg-dark-primary-1 h-64 md:h-96 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8" }
const _hoisted_2 = { class: "max-w-max mx-auto sm:flex items-center" }
const _hoisted_3 = { class: "sm:ml-6" }
const _hoisted_4 = { class: "sm:border-l sm:border-grey-2 sm:pl-6" }
const _hoisted_5 = { class: "text-4xl font-normal text-primary-3 dark:text-gray-400 tracking-tight sm:text-5xl mt-4" }
const _hoisted_6 = { class: "mt-2 text-base text-grey-8 dark:text-gray-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentTextIcon = _resolveComponent("DocumentTextIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DocumentTextIcon, { class: "sm:block hidden text-primary-4 dark:text-gray-500 h-20 w-20" }),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h1", _hoisted_5, _toDisplayString(_ctx.title), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.message), 1)
        ])
      ])
    ])
  ]))
}