/* eslint-disable max-len */
import error from "@/views/ErrorPage.vue";
import homePage from "@/views/HomePage.vue";
import latestUpdates from "@/views/LatestUpdates.vue";
import specificPageList from "@/views/SpecificPageList.vue";
import specificPageEventList from "@/views/SpecificPageEventList.vue";
import search from "@/views/SearchPage.vue";
import eventView from "@/views/EventView.vue";
import eventDetails from "@/views/EventViewDetails.vue";
import dashboard from "@/views/Dashboard.vue";
import subscriptionList from "@/views/SubscriptionList.vue";
import user from "@/views/User.vue";
import userProfile from "@/views/UserProfile.vue";
import { RouterView } from "@/plugins/vueRouter";
import RouterViewMetaRefresh from "@/components/Shared/RouterViewMetaRefresh.vue";
import notificationList from "@/views/NotificationList.vue";

export default [
  // ----------------  404: Not Found Page -------------------
  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
  {
    path: "/:langCode(hi)/404",
    name: "notFound_hi",
    component: error,
    meta: {
      alwaysRender: true,
      minKarma: 0,
    },
  },

  // ------------------------ Landing Page(hi) ----------------------
  {
    path: "/:langCode(hi)",
    name: "landingPage_hi",
    component: RouterView,
    meta: {
      minKarma: 0,
    },
    children: [
      // -------------------------- Landing Home Page  ----------------------------------
      {
        path: "",
        name: "landingHome_hi",
        meta: {
          minKarma: 25,
        },
        redirect: { name: "jobsHome_hi" },
      },

      // * ---------------------------- System Specific Pages --------------------------- *
      // ----------------  Dashboard -------------------
      {
        path: "dashboard",
        name: "dashboard_hi",
        meta: {
          minKarma: 1,
        },
        component: dashboard,
      },

      // ----------------  User Profile -------------------
      {
        path: "user-profile",
        name: "user_hi",
        component: user,
        alias: ["user"],
        meta: {
          minKarma: 1,
          pageType: "userProfilePage",
        },
        children: [
          {
            path: "",
            name: "userProfile_hi",
            component: userProfile,
          },
        ],
      },

      //  ----------------  User Subscriptions -------------------
      {
        path: "subscriptions",
        name: "userSubscriptions_hi",
        meta: {
          minKarma: 1,
        },
        component: subscriptionList,
      },
      //  ----------------  User Notifications -------------------
      {
        path: "notifications",
        name: "userNotifications_hi",
        meta: {
          minKarma: 1,
        },
        component: notificationList,
      },

      // * ---------------------------- Search Pages --------------------------- *
      // ----------------  Search Page -------------------
      {
        path: "search",
        name: "search_hi",
        component: RouterView,
        children: [
          //  ----------------  Search Home Page -------------------
          {
            path: "",
            name: "searchHome_hi",
            meta: {
              minKarma: 0,
              pageType: "searchPage",
            },
            component: search,
          },
        ],
      },

      // ? ############################# Specific Landing Pages ###############################
      // * ---------------------------- Jobs Landing Page --------------------------- *
      {
        path: "sarkari-naukri",
        name: "jobs_hi",
        alias: ["recruitment"],
        component: RouterView,
        children: [
          // +++++++++++++++++++++++++++++++++ Jobs Home Page +++++++++++++++++++++++++++++++
          {
            path: "",
            name: "jobsHome_hi",
            component: homePage,
            meta: {
              minKarma: 0,
              mode: "job",
              pageType: "landingPage",
            },
          },

          // +++++++++++++++++++++++++++++++++ Jobs Specific Pages +++++++++++++++++++++++++++++++
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          /**
           * ! :id-:name approach cant be used for schema as it requires the hyphen after id ,
           * ! and it is mandatory, if not provided, user gets redirected to 404 page as schema is not validated.
           * ! SAME for all id pages
           */
          {
            path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
            name: "jobsLatestUpdates_hi",
            component: latestUpdates,
            meta: {
              minKarma: 0,
              mode: "job",
              pageType: "latestUpdatePage",
            },
          },

          // +++++++++++++++++++++++++++++++++ Jobs Specific Pages - Static Data +++++++++++++++++++++++++++++++
          // TODO build these pages as per Event Type: job or admission (University <=> Organisation)
          // +++++++++++++++++++++++++++++++++ Jobs Organisation Pages +++++++++++++++++++++++++++++++
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "organisation",
            name: "jobsOrganisation_hi",
            alias: ["organisations"],
            component: RouterView,
            children: [
              // ------------------------ Jobs Organisation Home Page----------------------
              {
                path: "",
                name: "jobsOrganisationHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsOrganisationList_hi" },
              },
              // +++++++++++++++++++++++++++++++++ Jobs Organisation List +++++++++++++++++++++++++++++++
              {
                path: "list",
                name: "jobsOrganisationList_hi",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "job",
                  type: "organisation",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Jobs Specific Organisation Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificOrganisation_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificOrganisationPage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "organisation",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsOrganisationLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "organisation",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission
          // +++++++++++++++++++++++++++++++++ Jobs Exam Pages +++++++++++++++++++++++++++++++
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "exam",
            name: "jobsExam_hi",
            alias: ["exams"],
            component: RouterView,
            children: [
              // ------------------------ Jobs Exam Home Page----------------------
              {
                path: "",
                name: "jobsExamHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsExamList_hi" },
              },
              // +++++++++++++++++++++++++++++++++ Jobs Exam List +++++++++++++++++++++++++++++++
              {
                path: "list",
                name: "jobsExamList_hi",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "job",
                  type: "exam",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Jobs Specific Exam Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificExam_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificExamPage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "exam",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsExamLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "exam",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission
          // ------------------------ Jobs State Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "rajya",
            name: "jobsState_hi",
            alias: ["states"],
            component: RouterView,
            children: [
              // ------------------------ Jobs State Home Page----------------------
              {
                path: "",
                name: "jobsStateHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsStateList_hi" },
              },
              // +++++++++++++++++++++++++++++++++ Jobs State List +++++++++++++++++++++++++++++++
              {
                path: "list",
                name: "jobsStateList_hi",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "job",
                  type: "state",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Jobs Specific State Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificState_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificStatePage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "state",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsStateLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "state",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // ------------------------ Jobs Educational Category Pages ----------------------
          {
            path: "yogyata",
            name: "jobsQualification_hi",
            alias: ["qualifications"],
            component: RouterView,
            children: [
              // ------------------------ Jobs Qualification Home Page----------------------
              {
                path: "",
                name: "jobsQualificationHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsHome_hi" },
              },
              // ------------------------ Jobs Specific Educational Category Page ----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificQualification_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificQualificationPage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "qualification",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsQualificationLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "qualification",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // ------------------------ Jobs Specific Category Page ----------------------
          {
            // Suggested path change from category
            path: "exam-category",
            name: "jobsCategory_hi",
            alias: ["category"],
            component: RouterView,
            children: [
              // ------------------------ Jobs State Home Page----------------------
              {
                path: "",
                name: "jobsCategoryHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "jobsHome_hi" },
              },
              // ------------------------ Jobs Specific Category Page ----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "jobsSpecificCategory_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "jobsSpecificCategoryPage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "category",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Jobs Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-walk-in-interview|latest-fellowships|last-date-soon)",
                    name: "jobsCategoryLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "job",
                      type: "category",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

        ],
      },

      // ? ############################# Admissions Specific Pages ###############################
      // * ----------------------------- Admissions Landing Page --------------------------- *
      {
        path: "admissions",
        name: "admissions_hi",
        alias: ["admission"],
        component: RouterView,
        children: [
          // +++++++++++++++++++++++++++++++++ Admissions Home Page +++++++++++++++++++++++++++++++
          {
            path: "",
            name: "admissionsHome_hi",
            component: homePage,
            meta: {
              minKarma: 0,
              mode: "admission",
              pageType: "landingPage",
            },
          },

          // +++++++++++++++++++++++++++++++++ Admissions Specific Pages +++++++++++++++++++++++++++++++
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-scholarships|last-date-soon|latest-interview)",
            name: "admissionsLatestUpdates_hi",
            component: latestUpdates,
            meta: {
              minKarma: 0,
              mode: "admission",
              pageType: "latestUpdatePage",
            },
          },

          // +++++++++++++++++++++++++++++++++ Admissions Specific Pages - Static Data +++++++++++++++++++++++++++++++
          // ------------------------ Admissions Educational Category Pages ----------------------
          {
            path: "yogyata",
            name: "admissionsQualification_hi",
            alias: ["qualifications"],
            component: RouterView,
            children: [
              // ------------------------ Admissions Qualification Home Page----------------------
              {
                path: "",
                name: "admissionsQualificationHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsHome_hi" },
              },
              // ------------------------ Admissions Specific Educational Category Page ----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificQualification_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificQualificationPage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "qualification",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsQualificationLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "qualification",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // ------------------------ Admissions Stream Pages ----------------------
          {
            path: "stream",
            name: "admissionsStream_hi",
            alias: ["streams"],
            component: RouterView,
            children: [
              // ------------------------ Admissions Stream Home Page----------------------
              {
                path: "",
                name: "admissionsStreamHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsHome_hi" },
              },
              // ------------------------ Admissions Specific Stream Page ---------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificStream_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificStreamPage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "stream",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsStreamLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "stream",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission (University <=> Organisation)
          // ------------------------ Admissions University Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            // Name was suggested to be kept as institution instead of university
            path: "institution",
            name: "admissionsUniversity_hi",
            alias: ["university", "universities", "institutions"],
            component: RouterView,
            children: [
              // ------------------------ Admissions University Home Page----------------------
              {
                path: "",
                name: "admissionsUniversityHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsUniversityList_hi" },
              },
              // +++++++++++++++++++++++++++++++++ Admissions University List +++++++++++++++++++++++++++++++
              {
                path: "list",
                name: "admissionsUniversityList_hi",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "admission",
                  type: "university",
                  pageType: "tagListPage",
                },
              },
            ],
          },
          // ------------------------ Admissions Organisation Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            // Name was suggested to be kept as institution instead of university
            path: "organisation",
            name: "admissionsOrganisation_hi",
            alias: ["university", "universities", "organisations", "institutions"],
            component: RouterView,
            children: [
              // ------------------------ Admissions Organisation Home Page----------------------
              {
                path: "",
                name: "admissionsOrganisationHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsUniversityList_hi" },
              },
              // ------------------------ Admissions Specific University Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificUniversity_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificUniversityPage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "university",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsUniversityLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "university",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission
          // ------------------------ Admissions Exam Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "exam",
            name: "admissionsExam_hi",
            alias: ["exams"],
            component: RouterView,
            children: [
              // ------------------------ Admissions Exam Home Page----------------------
              {
                path: "",
                name: "admissionsExamHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsExamList_hi" },
              },
              // +++++++++++++++++++++++++++++++++ Admissions Exam List +++++++++++++++++++++++++++++++
              {

                path: "list",
                name: "admissionsExamList_hi",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "admission",
                  type: "exam",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Admissions Specific Exam Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificExam_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificExamPage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "exam",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsExamLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "exam",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },

          // TODO build these pages as per Event Type: job or admission
          // ------------------------ Admissions State Pages ----------------------
          // ? Tag list pages are flattened to support adding aliases / secondary links functionality
          {
            path: "rajya",
            name: "admissionsState_hi",
            alias: ["states"],
            component: RouterView,
            children: [
              // ------------------------ Admissions State Home Page----------------------
              {
                path: "",
                name: "admissionsStateHome_hi",
                meta: {
                  minKarma: 0,
                },
                redirect: { name: "admissionsStateList_hi" },
              },
              // +++++++++++++++++++++++++++++++++ Admissions State List +++++++++++++++++++++++++++++++
              {

                path: "list",
                name: "admissionsStateList_hi",
                component: specificPageList,
                meta: {
                  minKarma: 0,
                  mode: "admission",
                  type: "state",
                  pageType: "tagListPage",
                },
              },
              // ------------------------ Admissions Specific Exam Page----------------------
              // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
              {
                path: ":id",
                name: "admissionsSpecificState_hi",
                component: RouterViewMetaRefresh,
                children: [
                  {
                    path: "",
                    name: "admissionsSpecificStatePage_hi",
                    component: specificPageEventList,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "state",
                      pageType: "staticDataTemplatePage",
                    },
                  },
                  // +++++++++++++++++++++++++++++++++ Admissions Specific Page Using Template +++++++++++++++++++++++++++++++
                  // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
                  {
                    path: ":latestUpdateType(latest-notifications|latest-admit-card|sarkari-results|latest-scholarships|last-date-soon|latest-interview)",
                    name: "admissionsStateLatestUpdates_hi",
                    component: latestUpdates,
                    meta: {
                      minKarma: 0,
                      mode: "admission",
                      type: "state",
                      pageType: "filteredTagListPage",
                    },
                  },
                ],
              },
            ],
          },
        ],
      },

      // ? ############################# EPF Admin Pages ###############################
      //  ----------------  Admin Location page -------------------
      {
        path: "location",
        name: "adminLocation_hi",
        component: RouterView,
        children: [
          // ------------------------ Admin Location Home ----------------------
          {
            path: "",
            name: "locationHome_hi",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "locationList_hi" },
          },
          {
            path: "list",
            name: "locationList_hi",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/LocationList.vue"),
          },
        ],
      },
      // * ---------------------------- Admin Event Pages --------------------------- *
      {
        path: "event",
        name: "adminEvent_hi",
        alias: ["events"],
        component: RouterView,
        children: [
          // ------------------------ Admin Event Home ----------------------
          {
            path: "",
            name: "eventHome_hi",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "eventList_hi" },
          },
          // ------------------------ Admin Event List ----------------------
          {
            path: "list",
            name: "eventList_hi",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/EventList.vue"),
          },
          // * ---------------------------- User and Admin Event View Page --------------------------- *
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "view/:id",
            name: "eventView_hi",
            component: eventView,
            meta: {
              minKarma: 0,
            },
            children: [
              {
                path: "",
                name: "eventViewDetails_hi",
                meta: {
                  mode: "view",
                },
                component: eventDetails,
              },
            ],
          },
          // ------------------------ Admin Event Create ----------------------
          {
            path: "create",
            name: "eventCreator_hi",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdate.vue"),
            children: [
              {
                path: "",
                name: "eventCreate_hi",
                meta: {
                  mode: "create",
                },
                component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdateDetails.vue"),
              },
            ],
          },
          {
            path: "check",
            name: "eventCheck_hi",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/DuplicateCheck.vue"),
          },
          // ------------------------ Admin Event and Status Update ----------------------
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "edit/:id",
            name: "eventUpdater_hi",
            component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdate.vue"),
            meta: {
              minKarma: 25,
            },
            children: [
              {
                path: "",
                name: "eventEdit_hi",
                meta: {
                  mode: "edit",
                },
                component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdateDetails.vue"),
              },
              {
                path: "status",
                name: "eventEditStatus_hi",
                meta: {
                  mode: "edit",
                },
                component: () => import(/* webpackChunkName: "admin" */"@/views/EventUpdateStatus.vue"),
              },
            ],
          },
        ],
      },

      // * ---------------------------- Admin Field Name and Field Value Pages --------------------------- *
      {
        path: "fieldname",
        name: "fieldName_hi",
        alias: ["fieldnames"],
        component: RouterView,
        children: [
          // ------------------------ Admin Field Home ----------------------
          {
            path: "",
            name: "fieldHome_hi",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "fieldList_hi" },
          },
          // ------------------------ Admin Field Name List ----------------------
          {
            path: "list",
            name: "fieldList_hi",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/FieldNameList.vue"),
          },
          // ------------------------ Admin Field Name Value List ----------------------
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "view/:id",
            name: "fieldValueList_hi",
            meta: {
              minKarma: 25,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/FieldNameValueList.vue"),
          },
        ],
      },

      // * ---------------------------- Admin Exams Pages --------------------------- *
      {
        path: "exam",
        name: "adminExam_hi",
        alias: ["exams"],
        component: RouterView,
        children: [
          // ------------------------ Admin Exams Home ----------------------
          {
            path: "",
            name: "examHome_hi",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "examList_hi" },
          },
          // ------------------------ Admin Exams List ----------------------
          {
            path: "list",
            name: "examList_hi",
            meta: {
              minKarma: 25,
              tagType: "exam",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTagList.vue"),
          },
          // ------------------------ Admin Exam Create ----------------------
          {
            path: "create",
            name: "examCreate_hi",
            meta: {
              minKarma: 50,
              tagType: "exam",
              mode: "create",

            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTag.vue"),
          },
          // ------------------------ Admin Exam Update ----------------------
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "edit/:id",
            name: "examEdit_hi",
            meta: {
              minKarma: 50,
              tagType: "exam",
              mode: "edit",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTag.vue"),
          },
        ],
      },

      // * ---------------------------- Admin Organisation Pages --------------------------- *
      {
        path: "organisation",
        name: "adminOrganisation_hi",
        alias: ["organisations"],
        component: RouterView,
        children: [
          // ------------------------ Admin Organisations Home ----------------------
          {
            path: "",
            name: "organisationHome_hi",
            meta: {
              minKarma: 25,
            },
            redirect: { name: "organisationList_hi" },
          },
          // ------------------------ Admin Organisations List ----------------------
          {
            path: "list",
            name: "organisationList_hi",
            meta: {
              minKarma: 25,
              tagType: "organisation",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTagList.vue"),
          },

          // ------------------------ Admin Organisation Create ----------------------
          {
            path: "create",
            name: "organisationCreate_hi",
            meta: {
              minKarma: 50,
              tagType: "organisation",
              mode: "create",

            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTag.vue"),
          },
          // ------------------------ Admin Organisation Update ----------------------
          // ? Adding aliases in routes that begin with params isn't supported and breaks redirection
          {
            path: "edit/:id",
            name: "organisationEdit_hi",
            meta: {
              minKarma: 50,
              tagType: "organisation",
              mode: "edit",
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/CoreUpdateTag.vue"),
          },
        ],
      },

      // * ---------------------------- Admin Translation Page --------------------------- *
      {
        path: "translation",
        name: "adminTranslation_hi",
        component: RouterView,
        children: [
          // ------------------------ Admin Translation Home ----------------------
          {
            path: "",
            name: "translationHome_hi",
            meta: {
              minKarma: 50,
            },
            redirect: { name: "translationList_hi" },
          },
          // ------------------------ Admin Translation List ----------------------
          {
            path: "list",
            name: "translationList_hi",
            meta: {
              minKarma: 50,
            },
            component: () => import(/* webpackChunkName: "admin" */"@/views/Translate.vue"),
          },
        ],
      },
    ],
  },
];
