
import ExamCards from "@/components/Generic/ExamCards.vue";
import LatestUpdatesTemplate from "@/components/Shared/LatestUpdates/LatestUpdatesTemplate.vue";
import { getExamIds } from "@/helpers/staticDataParser";
import { getSpecificTemplateEvents, getTagName } from "@/scripts/workerAdapterConnector";
import HeadingSection from "@/components/Generic/HeadingSection.vue";
import { analyticsViewEvent } from "@/helpers/analyticsEvents";
import { getSeperatedIdAndName, setRomanTitle } from "@/helpers/routesHandler";
import { isEmptyObject } from "@/helpers/validations";
import EventBus from "@/helpers/eventBus";
import NoInformation from "@/components/Shared/NoInformation.vue";
import generateSeoSchema from "@/helpers/seoSchema";
import { getOrganizationSchema } from "@/helpers/schemaHelpers";
import { defineComponent } from "@/plugins/vue";
import { DataSetSchema } from "@/helpers/interface";
import {
  EventType, FilterType, Lang, SpecificTypeEvents, StaticDataType, TagType,
} from "@/scripts/interface";

interface DataCheckType {
  created: number;
  eventId: string;
  lastUpdated: number;
  title: { en: string; hi: string; };
  title_roman: { hi: string; };
  valueTimestamp: number;
}

export default defineComponent({
  name: "SpecificPageEventList",
  components: {
    LatestUpdatesTemplate,
    ExamCards,
    HeadingSection,
    NoInformation,
  },
  data: () => ({
    examIds: [] as string[] | undefined,
    specificLatestUpdates: {} as SpecificTypeEvents,
    isShow: false,
    noData: false,
    routeId: "",
    pageType: "" as TagType,
    eventType: "" as EventType,
    isCreated: false,
    title: {} as Lang,
  }),
  computed: {
    /**
     * Gets lang code from the base store
     *
     * @returns {string} - lang code
     */
    langCode(): "en" | "hi" {
      return this.$store.getters["base/getLangCode"];
    },
    /**
     * Computed property of page title
     */
    pageTitle(): string {
      if (isEmptyObject(this.title)) return "";
      return this.title?.[this.langCode]?.string ? this.title[this.langCode]!.string as string : this.title.en.string;
    },
  },
  watch: {
    /**
     * Watcher to watch the route object
     * If language change then no need to set data
     * But when router id change set new data needed
     * If the language changes set the title roman in the route
     * @param {object} newRoute - New route value
     * @param {object} oldRoute - Old route value
     */
    $route(oldRoute, newRoute) {
      const newRouteLang = newRoute.name.split("_")[1];
      const oldRouteLang = oldRoute.name?.split("_")[1];
      const newRouteName = newRoute.name.split("_")[0];
      const oldRouteName = oldRoute.name?.split("_")[0];
      if (newRouteLang === oldRouteLang) {
        this.isCreated = false;
        this.setAllData();
      }
      if (newRouteName === oldRouteName) {
        setRomanTitle(this.title, this.routeId, this.langCode, this.$route);
      }
    },
  },
  /**
   * In this hook we are setting all data
   */
  created() {
    this.setAllData();
    if (this.title.en) setRomanTitle(this.title, this.routeId, this.langCode, this.$route);
  },
  methods: {
    /**
     * Function to set the data of latest update template
     * and set the data of exam cards, amd the title of page
     */
    setAllData(): void {
      if (!this.$route.params.id) return;
      this.eventType = this.$route.meta.mode as EventType;
      this.pageType = this.$route.meta.type as TagType;
      if (!["organisation", "university", "stream", "category", "state", "qualification", "exam"].includes(this.pageType)) return;
      this.routeId = getSeperatedIdAndName(this.$route.params.id as string).id;
      if (!this.isCreated) {
        getTagName(this.pageType, [this.routeId]).then((tagArray) => {
          if (tagArray[0]) {
            const [tagName] = tagArray;
            this.title = {
              en: {
                string: tagName.en.string,
                text: "",
                aliases: [],
              },
              hi: {
                string: tagName.hi?.string || "",
                roman: tagName.hi?.roman || "",
                text: "",
                aliases: [],
              },
            };
            this.isCreated = true;
            analyticsViewEvent(this.pageTitle, this.pageType);
            this.setSpecificLatestEvents(this.routeId, this.eventType, this.pageType);
            if (this.pageType === "organisation" || this.pageType === "university") {
              this.setSeoSchema();
            }
          }
        });
      } else analyticsViewEvent(this.pageTitle, this.pageType);
      if (["category", "stream"].includes(this.pageType)) this.examIds = getExamIds(this.routeId, this.pageType);
      else this.examIds = [];
    },
    /**
     * Function to set the data of specific latest events
     *
     * @param {string} id - Specific tag id to filter events
     * @param {string} type - Type of event
     * @param {string} pageType - Type of page
     */
    setSpecificLatestEvents(id: string, type: EventType, pageType: StaticDataType | FilterType): void {
      const pageCategory = pageType === "university" ? "organisation" : pageType;
      const filters = [{
        type: pageCategory,
        id,
      }];
      getSpecificTemplateEvents(type, filters, 10).then((data) => {
        // check if the data which is returned has any information or not

        const DataCheck: DataCheckType = Object.values(data).find((item: DataCheckType[]) => item.length > 0);
        this.noData = !DataCheck;
        this.specificLatestUpdates = data;
        this.isShow = true;
        EventBus.$emit("dataLoaded", true);
        // Emit dataForScroll event to load scroll bar
        this.$nextTick().then(() => EventBus.$emit("dataForScroll"));
      });
    },

    /**
     * Function to set seo schema for organization pages
     */
    async setSeoSchema(): Promise<void> {
      const organization = await getOrganizationSchema(this.$route, this.pageTitle, this.$router);
      // get schema
      const routeName = (this.$route.name as string).split("_")[0];
      const schema = generateSeoSchema(routeName, { organization } as DataSetSchema);
      const mainObj = {} as { [key: string]: object; };

      // filter schema
      schema["@graph"]?.forEach((obj) => { mainObj[obj["@type" as keyof typeof obj] as keyof typeof mainObj] = obj; });
      this.$meta.script[0].json["@graph"].forEach((obj: object) => { mainObj[obj["@type" as keyof typeof obj] as keyof typeof mainObj] = obj; });
      schema["@graph"] = Object.values(mainObj);

      // set schema
      this.$meta.script = [
        {
          json: schema,
          type: "application/ld+json",
        },
      ];
    },

  },
});
