import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "mx-6" }
const _hoisted_2 = { class: "sm:hidden" }
const _hoisted_3 = ["value", "selected"]
const _hoisted_4 = { class: "hidden sm:block" }
const _hoisted_5 = {
  class: "flex space-x-4",
  "aria-label": "Tabs"
}
const _hoisted_6 = ["aria-current", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_heading_section = _resolveComponent("heading-section")!
  const _component_subscription_list_view = _resolveComponent("subscription-list-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_heading_section, {
      title: _ctx.$t('subscriptionList')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("select", {
          id: "tabs",
          name: "tabs",
          class: "block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md",
          "aria-label": "currentMode",
          onChange: _cache[0] || (_cache[0] = (event)=>{ _ctx.toggleSubscriptionListHandler((event.target as HTMLSelectElement).value)})
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createElementBlock("option", {
              key: tab.name,
              value: tab.id,
              selected: tab.current
            }, _toDisplayString(_ctx.$t(tab.name)), 9, _hoisted_3))
          }), 128))
        ], 32)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("nav", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab) => {
            return (_openBlock(), _createElementBlock("button", {
              key: tab.name,
              class: _normalizeClass([tab.current ? 'bg-primary-1 text-primary-3 dark:bg-dark-primary-2 dark:text-dark-text-1' :
              'text-gray-500 hover:text-gray-700 dark:text-dark-text-1', 'px-3 py-2 font-medium text-base rounded-md']),
              "aria-current": tab.current ? 'page' : undefined,
              onClick: ()=>{_ctx.toggleSubscriptionListHandler(tab.id)}
            }, _toDisplayString(_ctx.$t(tab.name)), 11, _hoisted_6))
          }), 128))
        ])
      ])
    ]),
    _createVNode(_component_subscription_list_view, { mode: _ctx.mode }, null, 8, ["mode"])
  ]))
}